import { createTheme } from 'baseui'

// See all possible keys here:
// https://github.com/uber/baseweb/blob/master/src/theme.ts#L729
// smaller number -> lighter shade
export const primitives = {
  primaryFontFamily: 'Source Sans Pro',

  primaryA: '#0E1117', // Streamlit Black 100
  primaryB: '#fafafa', // Streamlit Black 10
  primary: '#0E1117', // Streamlit Black 100
  primary50: '#f0f2f6', // Streamlit Black 20
  primary100: '#e6eaf1', // Streamlit Black 30
  primary200: '#d5dae5', // Streamlit Black 40
  primary300: '#bfc5d3', // Streamlit Black 50
  primary400: '#a3a8b8', // Streamlit Black 60
  primary500: '#808495', // Streamlit Black 70
  primary600: '#555867', // Streamlit Black 80
  primary700: '#262730', // Streamlit Black 90

  accent: '#1c83e1', // Streamlit Blue 70
  accent50: '#e4f5ff', // Streamlit Blue 10
  accent100: '#c7ebff', // Streamlit Blue 20
  accent200: '#a6dcff', // Streamlit Blue 30
  accent300: '#83c9ff', // Streamlit Blue 40
  accent400: '#60b4ff', // Streamlit Blue 50
  accent500: '#3d9df3', // Streamlit Blue 60
  accent600: '#1c83e1', // Streamlit Blue 70
  accent700: '#0068c9', // Streamlit Blue 80

  // Alert Palette
  negative: '#ff6c6c', // Streamlit Red 60
  negative50: '#fff0f0', // Streamlit Red 10
  negative100: '#ffdede', // Streamlit Red 20
  negative200: '#ffc7c7', // Streamlit Red 30
  negative300: '#ffabab', // Streamlit Red 40
  negative400: '#ff8c8c', // Streamlit Red 50
  negative500: '#ff6c6c', // Streamlit Red 60
  negative600: '#ff4b4b', // Streamlit Red 70
  negative700: '#ff2b2b', // Streamlit Red 80
}

// See all possible keys here:
// https://github.com/uber/baseweb/blob/master/src/theme.ts#L714
const overrides = {
  sizing: {
    scale600: '16px',
  },
  borders: {
    useRoundedCorners: true,
    buttonBorderRadius: '4px',
    popoverBorderRadius: '8px',
    surfaceBorderRadius: '4px',
  },
  colors: {
    borderFocus: primitives.accent500,
    inputFill: primitives.primary50,
    inputBorder: primitives.primary50,
    inputFillDisabled: primitives.primary200,
    inputTextDisabled: primitives.primary600,
    inputPlaceholderDisabled: primitives.primary600,
    inputBorderError: primitives.negative,
    inputFillError: primitives.negative50,
    buttonPrimaryFill: primitives.accent,
    buttonPrimaryHover: primitives.accent500,
    buttonMinimalHover: primitives.primary50,
    buttonTertiaryText: primitives.accent500,
    buttonDisabledFill: primitives.primary200,
    buttonDisabledText: primitives.primary600,
    comboboxListItemFocus: primitives.primary100,
    comboboxListItemHover: primitives.primary100,
    toggleFillChecked: primitives.accent,
    menuFillHover: primitives.primary50,
  },
  zIndex: {
    modal: 1000,
  },
}

export const S4TLightTheme = createTheme(primitives, overrides)
