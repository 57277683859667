import {
  type ReactElement,
  useCallback,
  useEffect,
  useState,
  memo,
} from 'react'
import { useDispatch } from 'react-redux'

import { string as yupString } from 'yup'
import { ModalFooter } from 'baseui/modal'
import { Form, Formik, useFormikContext } from 'formik'

import { type Surface, UserRole } from 'constants/analytics'
import useTrackShare, { Operation } from 'hooks/analytics/useTrackShare'

import WorkspacePermissions from 'constants/workspacePermissions'
import { DISALLOWED_DOMAINS } from 'constants/sharing'

import { ReactComponent as IconArrowForward } from 'assets/svg/arrow_forward.svg'

import Alert from 'components/alert'
import Button from 'components/button'
import { StyledLink } from 'components/link'
import PageSpinner from 'components/pageSpinner'
import { type CloseModalArgs } from 'components/modal'
import TabBody from 'components/modal/components/tabBody/tabBody'
import DeployLimitWarningBox from 'components/deployLimitWarningBox'
import TabBodyPart from 'components/modal/components/tabBody/tabBodyPart'

import useUser from 'api/user'
import { type IApp } from 'api/apps'
import { useEnableViewerAuth } from 'api/viewerAuth'
import { useGetViewers, useSetViewers } from 'api/viewers'

import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import useFreeTierLimits from 'hooks/useFreeTierLimits'

import { confirmationOn, confirmationOff, type ModalName } from 'reducers/modal'

import { useWorkspaceContext } from 'context/workspaceContext'

import DomainsField from './domainsField'
import ViewersField from './viewersField'
import SharingOptionField from './sharingOptionField'

import modalStyles from './modal.module.scss'
import styles from './sharingSettings.module.scss'

export const MAX_VIEWER_EMAILS = 1000
export const MAX_VIEWER_DOMAINS = 100

interface SharingBodyProps {
  app: IApp
  surface: Surface
  name: ModalName
  onClose: (args?: CloseModalArgs) => void
}

interface SharingTabBodyProps {
  app: IApp
  modalName: ModalName
  wsPerms: WorkspacePermissions[]
  hasFreeUserReachLimits: boolean
}

interface FormValues {
  rawViewers: string
  rawDomains: string
  isPublic: boolean
}

export interface FormErrors {
  rawViewers?: string
  rawDomains?: string
}

// split by white space and commas, remove empty strings
export function formatTextInput(rawText: string): string[] {
  return rawText.split(/[\s,]/).filter((s) => s.length > 0)
}

export function validate(values: FormValues): FormErrors {
  const domainError = validateViewerDomains(values)
  const viewerError = validateViewerEmails(formatTextInput(values.rawViewers))

  // this is a little strange, but Formik will not submit if the error property
  // is set explicitly to undefined (most likely using the in operator under the hood)
  // to indicate there is no error the FormErrors object needs to have no properties present
  const errors: FormErrors = {}
  if (domainError !== '') {
    errors.rawDomains = domainError
  }
  if (viewerError !== '') {
    errors.rawViewers = viewerError
  }

  return errors
}

export function validateViewerDomains(values: FormValues): string {
  const domains = formatTextInput(values.rawDomains)

  if (domains.length > MAX_VIEWER_DOMAINS) {
    return `Domain list is too long, max number of domains allowed is ${MAX_VIEWER_DOMAINS}.`
  }

  const errors: {
    invalid: string[]
    disallowed: string[]
    duplicated: string[]
  } = {
    invalid: [],
    disallowed: [],
    duplicated: [],
  }

  // map to track duplicates
  const m: Record<string, number> = {}

  domains.forEach((domain) => {
    const isValid = yupString().email().isValidSync(`stub@${domain}`)

    // only print if it's invalid and the first occurrence
    if (!isValid && !m[domain]) {
      errors.invalid.push(domain)
    }

    const isDisallowed = DISALLOWED_DOMAINS.includes(domain.toLowerCase())
    // only print if it's invalid and the first occurrence
    if (isDisallowed && !m[domain]) {
      errors.disallowed.push(domain)
    }

    // only print if it's valid and the first duplicate seen
    if (isValid && m[domain] === 1) {
      errors.duplicated.push(domain)
      m[domain] += 1
    }

    if (!m[domain]) {
      m[domain] = 1
    }
  })

  function getDisallowedErrorString(): string {
    if (!errors.disallowed.length) {
      return ''
    }

    if (errors.disallowed.length > 1) {
      return `The domains ${errors.disallowed.join(
        ' and ',
      )} are not allowed here, as they are too broad.`
    }

    return `The domain ${errors.disallowed[0]} is not allowed here, as it is too broad.`
  }

  const disallowedString = getDisallowedErrorString()
  const invalidDomainsStrings = errors.invalid.map(
    (domain) => `${domain} is not a valid domain.`,
  )
  const duplicatedDomainsStrings = errors.duplicated.map(
    (domain) => `${domain} is duplicated.`,
  )

  const totalErrorsStrings = [
    disallowedString,
    ...invalidDomainsStrings,
    ...duplicatedDomainsStrings,
  ].filter((v) => !!v)

  return totalErrorsStrings.join('\n')
}

export function validateViewerEmails(viewers: string[]): string {
  if (viewers.length > MAX_VIEWER_EMAILS) {
    return `Email list is too long, max number of emails allowed is ${MAX_VIEWER_EMAILS}.`
  }

  // map to track duplicates
  const m: Record<string, number> = {}
  const errors: string[] = []
  viewers.forEach((email) => {
    const isValid = yupString().email().isValidSync(email)

    // only print if it's invalid and the first occurrence
    if (!isValid && !m[email]) {
      errors.push(`${email as string} is not a valid email address.`)
    }

    // only print if it's valid and the first duplicate seen
    if (isValid && m[email] === 1) {
      errors.push(`${email} is duplicated.`)
      m[email] += 1
    }

    if (!m[email]) {
      m[email] = 1
    }
  })

  return errors.join('\n')
}

const freeTierAppLimitsWarning = (): ReactElement => (
  <DeployLimitWarningBox
    title="You can only have one private app on Community Cloud"
    description="Convert your app to a public app and deploy unlimited public apps."
  />
)

export function SharingTabBody({
  app,
  modalName,
  wsPerms,
  hasFreeUserReachLimits,
}: SharingTabBodyProps): ReactElement {
  const dispatch = useDispatch()
  const { submitForm, isSubmitting, errors, dirty } =
    useFormikContext<FormValues>()

  const [warningComponent, setWarningComponent] = useState<
    ReactElement | undefined
  >()

  const hasDomainAllowList = wsPerms.includes(
    WorkspacePermissions.FEATURE_DOMAIN_ALLOWLISTING,
  )

  const [isSaveDisabled, setDisableButton] = useState(
    !dirty || isSubmitting || !!errors.rawViewers || !!errors.rawDomains,
  )
  const [disableViewersFields, setDisableViewersFields] = useState(isSubmitting)

  useEffect(() => {
    dispatch(
      dirty
        ? confirmationOn({ name: modalName })
        : confirmationOff({ name: modalName }),
    )
  }, [dirty, dispatch, modalName])

  useEffect(() => {
    setDisableButton(
      !dirty ||
        isSubmitting ||
        !!errors.rawViewers ||
        !!errors.rawDomains ||
        disableViewersFields,
    )
  }, [
    dirty,
    isSubmitting,
    errors.rawViewers,
    errors.rawDomains,
    disableViewersFields,
  ])

  const manageAccessBodyPart = (
    <TabBodyPart
      title="Who can manage this app"
      subTitle="Anyone with push access to this app's repo can manage this app."
      body={
        <StyledLink
          to={`https://github.com/${app.owner}/${app.repo}/settings/access`}
          external
          target="_blank"
        >
          View or change access in GitHub{' '}
          <IconArrowForward className={styles.linkIcon} />
        </StyledLink>
      }
    />
  )

  const viewerSettingsInputFields = (
    <>
      <TabBodyPart
        title="Invite viewers by email"
        subTitle="Enter a comma-separated list of emails."
        body={<ViewersField disabled={disableViewersFields} />}
        classNames={{ root: styles.inlineTabBodyPart }}
      />
      {hasDomainAllowList && (
        <TabBodyPart
          title="Invite viewers based on their email domains"
          subTitle="Enter a comma-separated list of allowed domains."
          body={<DomainsField disabled={disableViewersFields} />}
          classNames={{ root: styles.inlineTabBodyPart }}
        />
      )}
    </>
  )

  const checkUserAppLimits = useCallback(
    (sharingOption: string) => {
      if (
        sharingOption === 'private' &&
        hasFreeUserReachLimits &&
        !app.viewerAuthEnabled
      ) {
        setWarningComponent(freeTierAppLimitsWarning)
        setDisableViewersFields(true)
        setDisableButton(true)
      } else {
        setWarningComponent(undefined)
        setDisableButton(false)
        setDisableViewersFields(false)
      }
    },
    [hasFreeUserReachLimits, app.viewerAuthEnabled],
  )

  useEffect(() => {
    if (!hasFreeUserReachLimits) {
      checkUserAppLimits('')
    }
  }, [hasFreeUserReachLimits, checkUserAppLimits])

  const manageViewersBodyPart = (
    <TabBodyPart
      title="Who can view this app"
      body={
        <SharingOptionField
          wsPerms={wsPerms}
          checkUserAppLimits={checkUserAppLimits}
        />
      }
      warningComponent={warningComponent}
    />
  )

  return (
    <>
      <div className={modalStyles.modalBody}>
        <TabBody>
          {manageAccessBodyPart}
          {/* Bundling the next two TabBodyPart components in this empty fragment prevents rendering a separator between them as TabBody handles them as one */}
          <>
            {manageViewersBodyPart}
            {viewerSettingsInputFields}
          </>
        </TabBody>
      </div>
      <ModalFooter className={modalStyles.modalFooter}>
        <Button onClick={submitForm} disabled={isSaveDisabled}>
          Save
        </Button>
      </ModalFooter>
    </>
  )
}

function SharingBody({
  app,
  surface,
  name,
  onClose,
}: SharingBodyProps): ReactElement {
  const { workspaces } = useUser()
  const wsPerms = useWorkspacePermissions(workspaces)
  const hasFreeUserReachLimits = useFreeTierLimits()
  const workspaceContext = useWorkspaceContext()

  const { doEnableViewerAuth, isPending: enableViewerAuthPending } =
    useEnableViewerAuth(app)
  const { error: getError, viewers, domains, isPending } = useGetViewers(app)
  const { error: setError, doSetViewers } = useSetViewers(app)
  const trackShare = useTrackShare()
  const [validateOnChange, setValidateOnChange] = useState(false)

  // wrapper for the validation function to change validateOnChange behavior
  const doValidate = useCallback((values: FormValues): FormErrors => {
    const errors = validate(values)
    setValidateOnChange(!!errors.rawViewers || !!errors.rawDomains)
    return errors
  }, [])

  const rawViewers = viewers?.join('\n')
  const rawDomains = domains?.join('\n')

  return (
    <>
      {isPending || enableViewerAuthPending ? (
        <PageSpinner />
      ) : (
        <>
          {getError && <Alert title="Error" message={getError} />}
          {setError && <Alert title="Error" message={setError} />}
          <Formik
            onSubmit={async (values: FormValues, { setSubmitting }) => {
              const viewersOnLoad = formatTextInput(rawViewers)
              const viewers = formatTextInput(values.rawViewers)
              const domains = formatTextInput(values.rawDomains)
              const enableViewerAuth = !values.isPublic // not public means enable viewer auth

              const newViewers = [
                ...viewers.filter((a) => !viewersOnLoad.includes(a)),
              ]
              const removedViewers = [
                ...viewersOnLoad.filter((a) => !viewers.includes(a)),
              ]

              // optimistically update the apps list, with revalidation by default
              await workspaceContext.UpdateApp(app, {
                viewerAuthEnabled: enableViewerAuth,
              })

              const appId = app.appId ?? ''
              const appWorkspaceName = app.owner ?? ''
              if (newViewers.length > 0) {
                trackShare({
                  surface,
                  // For now, only developers have access to this modal
                  role: UserRole.Developer,
                  emails: newViewers,
                  operation: Operation.AddViewers,
                  appId,
                  appWorkspaceName,
                })
              }
              if (removedViewers.length > 0) {
                trackShare({
                  surface,
                  // For now, only developers have access to this modal
                  role: UserRole.Developer,
                  emails: removedViewers,
                  operation: Operation.RemoveViewers,
                  appId,
                  appWorkspaceName,
                })
              }

              await doSetViewers({ viewers, domains })
              await doEnableViewerAuth(enableViewerAuth)
              setSubmitting(false)

              if (!setError) {
                // Form submission should NOT open a confirmation modal.
                onClose({ disableConfirmation: true })
              }
            }}
            initialValues={{
              rawViewers,
              rawDomains,
              isPublic: !app.viewerAuthEnabled,
            }}
            validate={doValidate}
            validateOnChange={validateOnChange}
            enableReinitialize
          >
            <Form className={modalStyles.modal}>
              <SharingTabBody
                app={app}
                modalName={name}
                wsPerms={wsPerms}
                hasFreeUserReachLimits={hasFreeUserReachLimits}
              />
            </Form>
          </Formik>
        </>
      )}
    </>
  )
}

export default memo(SharingBody)
