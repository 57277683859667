import {
  createContext,
  type ReactElement,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react'

import { mutate } from 'swr'

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import useAppContext from 'api/appContext'

import {
  formatURLDataForDisambiguate,
  type RouteParams,
} from 'pages/streamlitApp/common/helpers'

import useApps, { getAppsKey, type IApp } from '../api/apps'
import { isSubdomainApp } from '../hooks/useUniqueSubdomainFeatures'
import useUser, { type IWorkspace, type WorkspaceInfo } from '../api/user'

import useDisambiguate from '../api/disambiguate'

import { workspaceQueryParameterName } from 'constants/routes'
import { isNonEmptyString } from 'helpers/validation'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'reducers/workspace'
import { currentWorkspaceSelector } from 'reducers/workspace/selectors'

interface Props {
  children: ReactNode
}

interface IWorkspaceContext {
  Id: () => string
  Name: () => string | undefined
  DeleteApp: (appToDelete: IApp) => Promise<() => Promise<void>>
  ForceUpdate: () => Promise<void>
  GetApps: () => IApp[] | undefined
  UpdateApp: (
    app: IApp,
    updatedProperties: Partial<IApp>,
  ) => Promise<() => Promise<void>>
}

class WorkspaceContextService implements IWorkspaceContext {
  private readonly setApps: (
    apps: IApp[] | undefined,
    shouldRevalidate?: boolean,
  ) => Promise<void>

  private apps: IApp[] | undefined

  private readonly workspaceName: string | undefined

  private readonly id: string

  constructor(
    id: string,
    workspace: string | undefined,
    setApps: (
      apps: IApp[] | undefined,
      shouldRevalidate?: boolean,
    ) => Promise<void>,
    apps?: IApp[],
  ) {
    this.id = id
    this.apps = apps
    this.workspaceName = workspace
    this.setApps = setApps
  }

  async DeleteApp(appToDelete: IApp): Promise<() => Promise<void>> {
    // By setting apps to undefined when no apps object exists (say
    // instead of setting it to []), we guarantee that the spinner will
    // show up if a user is loading the dashboard page for the first time
    // instead of showing the empty state while revalidating
    const updateAppList = this.apps?.filter(
      (otherApp) => appToDelete.appId !== otherApp.appId,
    )
    await this.updateAppList(
      this.apps != null ? updateAppList : undefined,
      false,
    )

    return async (): Promise<void> => {
      await this.AddApp(appToDelete)
    }
  }

  async AddApp(app: IApp): Promise<void> {
    let appsToSet: IApp[] = []
    if (this.apps != null) {
      appsToSet = [...this.apps]
    }

    const appAlreadyExists =
      appsToSet.filter((otherApp) => app.appId !== otherApp.appId) !== undefined
    if (!appAlreadyExists) {
      appsToSet.push(app)
    }

    await this.updateAppList(appsToSet, true)
  }

  async UpdateApp(
    app: IApp,
    updatedProperties: Partial<IApp>,
  ): Promise<() => Promise<void>> {
    const oldApps = this.apps
    const updatedApps = this.apps?.map((appToBeUpdated) => {
      if (appToBeUpdated.appId !== app.appId) return appToBeUpdated

      return {
        ...appToBeUpdated,
        ...updatedProperties,
      }
    })

    // update cache optimistically
    // Updating the sharing settings used to force revalidation
    await this.updateAppList(updatedApps, false)

    return async (): Promise<void> => {
      await this.updateAppList(oldApps, false)
    }
  }

  async ForceUpdate(): Promise<void> {
    const appsKey = getAppsKey(
      this.workspaceName
        ? {
            name: this.workspaceName,
            id: this.id,
          }
        : undefined,
    )

    await mutate(appsKey)
    await Promise.resolve(undefined)
  }

  GetApps(): IApp[] | undefined {
    return this.apps
  }

  Name(): string | undefined {
    return this.workspaceName
  }

  Id(): string {
    return this.id
  }

  async updateAppList(
    apps: IApp[] | undefined,
    shouldRevalidate?: boolean,
  ): Promise<void> {
    await this.setApps(apps, shouldRevalidate)
    this.apps = apps
  }
}

const WorkspaceContext = createContext<WorkspaceContextService | undefined>(
  undefined,
)

function useCurrentWorkspace(): IWorkspace | undefined {
  const { workspaces, isPending } = useUser()
  const currentWorkspace = useSelector(currentWorkspaceSelector)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (isPending) {
      // workspaces still loading
      return
    }

    const queryStringWorkspaceName = searchParams.get(
      workspaceQueryParameterName,
    )
    const newWorkspaceName =
      isNonEmptyString(queryStringWorkspaceName) &&
      workspaces?.some((w) => w.name === queryStringWorkspaceName)
        ? queryStringWorkspaceName
        : currentWorkspace

    if (isNonEmptyString(newWorkspaceName)) {
      dispatch(select({ workspace: newWorkspaceName }))
    }
    if (isNonEmptyString(queryStringWorkspaceName)) {
      searchParams.delete(workspaceQueryParameterName)
      setSearchParams(searchParams)
    }
  }, [
    isPending,
    currentWorkspace,
    workspaces,
    dispatch,
    navigate,
    searchParams,
    setSearchParams,
  ])

  return workspaces?.find((w) => w.name === currentWorkspace)
}

function LegacyWorkspaceContextContextProvider({
  children,
}: Props): ReactElement {
  const workspace = useCurrentWorkspace()
  const { apps, setApps } = useApps(workspace)
  return useMemo(() => {
    const workspaceContextService = new WorkspaceContextService(
      workspace?.id ?? '',
      workspace?.name,
      setApps,
      apps,
    )

    return (
      <WorkspaceContext.Provider value={workspaceContextService}>
        {children}
      </WorkspaceContext.Provider>
    )
  }, [workspace?.id, workspace?.name, apps, setApps, children])
}

function SubdomainWorkspaceContextProvider({ children }: Props): ReactElement {
  const appContext = useAppContext()
  const location = useLocation()
  const routeParams = useParams<RouteParams>()
  const { disambiguateUrlData } = formatURLDataForDisambiguate(
    routeParams as RouteParams,
    location.search,
  )
  const { app } = useDisambiguate(disambiguateUrlData)

  return useMemo(() => {
    const apps: IApp[] = []
    if (app != null) {
      apps.push(app)
    }

    const workspaceContextService = new WorkspaceContextService(
      appContext.context?.workspace?.id ?? '',
      appContext.context?.workspace?.name,
      async (
        apps: IApp[] | undefined,
        shouldRevalidate?: boolean,
      ): Promise<void> => {
        await Promise.resolve(undefined)
      },
      apps,
    )
    return (
      <WorkspaceContext.Provider value={workspaceContextService}>
        {children}
      </WorkspaceContext.Provider>
    )
  }, [app, appContext, children])
}

export function WorkspaceContextProvider({ children }: Props): ReactElement {
  const isAppViewerPageOnSubdomain = isSubdomainApp()
  if (isAppViewerPageOnSubdomain) {
    return (
      <SubdomainWorkspaceContextProvider>
        {children}
      </SubdomainWorkspaceContextProvider>
    )
  }
  return (
    <LegacyWorkspaceContextContextProvider>
      {children}
    </LegacyWorkspaceContextContextProvider>
  )
}

export function useWorkspaceContext(): IWorkspaceContext {
  const context = useContext(WorkspaceContext)

  if (context == null) {
    throw new Error(
      'useWorkspaceContext must be used within a SubdomainWorkspaceContextProvider',
    )
  }

  return context
}

export function useWorkspaceInfoFromContext(): WorkspaceInfo | undefined {
  const currentWorkspace = useWorkspaceContext()
  if (currentWorkspace.Name()) {
    return {
      id: currentWorkspace.Id(),
      name: currentWorkspace.Name() as string,
    }
  }

  return undefined
}
