import useSWR from 'swr'

import queryString from 'query-string'

import { type AppStatusValue, type PlatformStatusValue } from 'constants/apps'

import { type APIError } from 'api/types'
import { useGetFavoritedAppIds } from 'api/appFavorite'
import { isNonEmptyString } from 'helpers/validation'
import { type WorkspaceInfo } from './user'
import { getUserApiClient } from './apiV2'
import { isSubdomainApp } from '../hooks/useUniqueSubdomainFeatures'

export interface IAppRaw {
  appId: string
  owner: string
  repo: string
  branch: string
  // eslint-disable-next-line camelcase
  main_module: string
  url: string
  subdomain?: string // TODO: I'm putting this as optional because it is only set on /apps endpoint
  host?: string
  status?: AppStatusValue
  platformStatus?: PlatformStatusValue
  viewOnly?: boolean
  viewerAuthEnabled?: boolean
  isFavorited?: boolean
  isHiddenInProfilePage?: boolean
  isManageableInProfilePage?: boolean
}

export type IApp = Omit<IAppRaw, 'main_module'> & {
  mainModule: string
}

export interface IAppCoordinates {
  appId: string
  owner: string
  repo: string
  branch: string
  mainModule: string
  url: string
}

export interface IResponse {
  apps?: IApp[]
  error?: APIError
  isPending: boolean
  isValidating: boolean
  setApps: (
    apps: IApp[] | undefined,
    shouldRevalidate?: boolean,
  ) => Promise<void>
}

export function toRaw(app: IApp): IAppRaw {
  const { mainModule, ...rest } = app

  return {
    main_module: mainModule,
    ...rest,
  }
}

export function fromRaw(app: IAppRaw): IApp {
  // eslint-disable-next-line camelcase
  const { main_module: mainModule, ...rest } = app

  return {
    mainModule,
    ...rest,
  }
}

function fromRawEnhanced(
  favoritedAppIds = new Set<string>(),
): (app: IAppRaw, index: number) => IApp {
  return (app: IAppRaw) => {
    const transformed = fromRaw(app)
    if (
      isNonEmptyString(transformed.appId) &&
      favoritedAppIds.has(transformed.appId)
    ) {
      transformed.isFavorited = true
    }
    return transformed
  }
}

export type AppsKey = [string, string] | null

export function getAppsKey(workspace?: WorkspaceInfo): AppsKey {
  // If the user is on a subdomain, we should not retrieve apps from the backend, so we return null to short-circuit SWR
  // If the workspace is not defined, it means the user is not on a workspace, so we don't want to call the backend
  if (isSubdomainApp() || !workspace) {
    return null
  }

  // If a workspace is present, we include its information in the key
  return [
    `apps?${queryString.stringify({ workspace_name: workspace.name })}`,
    workspace.id,
  ]
}

async function fetchWorkspaceApps([swrKey, workspaceId]: [
  string,
  string,
]): Promise<{ apps: IAppRaw[] | undefined }> {
  const client = getUserApiClient()

  // We know that the workspace ID might not be present when the page is loaded.
  // In the future, we will change the implementation to not load the apps until the workspace ID is present.
  const result = await client.getUserWorkspace(workspaceId).then((r) => r.data)

  return result
}

export const useApps = (workspace?: WorkspaceInfo): IResponse => {
  const { appIds: favoritedAppIds } = useGetFavoritedAppIds()
  const appsKey = getAppsKey(workspace)
  const { data, isValidating, mutate, error, isLoading } = useSWR<
    { apps: IAppRaw[] | undefined },
    APIError
  >(appsKey, {
    fetcher: fetchWorkspaceApps,
  })

  async function setApps(
    apps: IApp[] | undefined,
    shouldRevalidate = true,
  ): Promise<void> {
    const appsToRaw = apps?.map(toRaw)

    await mutate(
      {
        apps: appsToRaw,
      },
      shouldRevalidate,
    )
  }

  const apps = data?.apps
    ?.map(fromRawEnhanced(favoritedAppIds))
    ?.sort(appComparator)

  return {
    apps,
    error,
    isPending: isLoading,
    setApps,
    isValidating,
  }
}

export function getAppTitle(app: IApp): string {
  const { repo, branch, mainModule } = app
  return `${repo} ∙ ${branch} ∙ ${mainModule}`
}

/**
 * Favorited apps should be shown at the top, and all apps
 * (including favorited ones) should be sorted by title.
 */
function appComparator(a: IApp, b: IApp): number {
  if (a.isFavorited === true && b.isFavorited !== true) {
    return -1
  }
  if (b.isFavorited === true && a.isFavorited !== true) {
    return 1
  }
  return getAppTitle(a).localeCompare(getAppTitle(b))
}

export default useApps
