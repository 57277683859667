import { memo, useEffect, useState } from 'react'
import StreamlitExampleApp from './StreamlitExampleApp'

import CheatSheet from 'assets/images/cheat-sheet.png'
import SophisticatedPaletteStreamlitApp from 'assets/images/sophisticated-palette-streamlit-app.png'
import GravitationalWaveStreamlitApp from 'assets/images/gravitational-wave-streamlit-app.png'
import Gpt3StreamlitApp from 'assets/images/gpt3-streamlit-app.png'
import Streamlit30Days from 'assets/images/streamlit-30-days.png'
import MathGpt from 'assets/images/math-gpt.png'
import StreamlitEchartsDemo from 'assets/images/streamlit-echarts-demo.png'
import StreamlitProphet from 'assets/images/streamlit-prophet.png'
import CrossChainMonitoringTool from 'assets/images/cross-chain-monitoring-tool.png'

import styles from './styles.module.scss'

const IMAGE_CONTAINER_HEIGHT = 350
const OVERFLOW_REPETITIONS = 4

const CAROUSEL_IMAGES: string[] = [
  CheatSheet,
  SophisticatedPaletteStreamlitApp,
  GravitationalWaveStreamlitApp,
  Gpt3StreamlitApp,
  Streamlit30Days,
  MathGpt,
  StreamlitEchartsDemo,
  StreamlitProphet,
  CrossChainMonitoringTool,
]

function CarouselOfStreamlitExampleApp(): JSX.Element {
  const [repetitions, setRepetitions] = useState(calculateRepetitions())

  function calculateRepetitions(): number {
    const viewportHeight = window.innerHeight
    return Math.ceil(viewportHeight / IMAGE_CONTAINER_HEIGHT)
  }

  useEffect(() => {
    function handleResize(): void {
      setRepetitions(calculateRepetitions())
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const carousel = Array.from(
    { length: repetitions + OVERFLOW_REPETITIONS },
    (_, index) => {
      return (
        <StreamlitExampleApp
          key={index}
          image={CAROUSEL_IMAGES[index % CAROUSEL_IMAGES.length]}
        />
      )
    },
  )

  return <div className={styles.carouselContainer}>{carousel}</div>
}

export default memo(CarouselOfStreamlitExampleApp)
