import { useCallback, useEffect, useRef, useState } from 'react'

import axios, { type AxiosError } from 'axios'
import useSWR from 'swr'
import { useLocalStorage } from 'react-use'
import { datadogLogs } from '@datadog/browser-logs'
import Visibility from 'visibilityjs'

import { type APIError } from 'api/types'
import { extractErrorMessage } from 'api/utils'

import { isSubdomainApp } from 'hooks/useUniqueSubdomainFeatures'

import localStorageAvailable from '../helpers/storageUtils'
import { isNonEmptyString } from 'helpers/validation'

const localStorageSessionIdKeyPrefix = 'appSessionId'

export const getOpenUserAppSessionKey = (appId: string): string =>
  `/app/${appId}/event/open`

export const getUniqueSubdomainOpenUserAppSessionKey = (): string =>
  '/app/event/open'

export const useOpenUserAppSessionKey = (appId: string | undefined): string => {
  if (isSubdomainApp()) {
    return getUniqueSubdomainOpenUserAppSessionKey()
  }

  return isNonEmptyString(appId) ? getOpenUserAppSessionKey(appId) : ''
}

export const getFocusUserAppSessionKey = (appId: string): string =>
  `/app/${appId}/event/focus`

export const getUniqueSubdomainFocusUserAppSessionKey = (): string =>
  '/app/event/focus'

export const useFocusUserAppSessionKey = (
  appId: string | undefined,
): string => {
  if (isSubdomainApp()) {
    return getUniqueSubdomainFocusUserAppSessionKey()
  }

  return isNonEmptyString(appId) ? getFocusUserAppSessionKey(appId) : ''
}

export const getLastAppViewsPerUserKey = (appId: string): string =>
  `/app/${appId}/event?type=last-app-views`

export const getUniqueSubdomainLastAppViewsPerUserKey = (): string =>
  '/app/event?type=last-app-views'

export const useLastAppViewsPerUserKey = (
  appId: string | undefined,
): string => {
  if (isSubdomainApp()) {
    return getUniqueSubdomainLastAppViewsPerUserKey()
  }

  return isNonEmptyString(appId) ? getLastAppViewsPerUserKey(appId) : ''
}

export const getSessionKey = (appId?: string): string => {
  return `${localStorageSessionIdKeyPrefix}-${appId as string}`
}

export interface UserAppSession {
  sessionId?: string
  createdAt?: Date
}

interface SendUserAppEventResponse {
  sessionId?: string
  createdAt?: Date
  error?: string
}

interface UseEventSession {
  sessionId: string
  createdAt?: Date
}

export function useEventSession(
  appId: string | undefined,
  isFocusEventsEnabled?: boolean,
  flushIntervalInMs = 15000,
): UseEventSession {
  // Note that checking and setting local storage is not concurrency-safe when the same hook is called
  // in different tabs.
  // use an object as the local-storage item value so that we can add expiration date in a next step
  const key = getSessionKey(appId)
  // use initial value in useLocalStorage so that in the initial run, the value is fetched correctly and we don't query the server
  const initialValue: UserAppSession = JSON.parse(
    localStorageAvailable() ? localStorage.getItem(key) ?? '{}' : '{}',
  )
  const [userAppSession, setUserAppSession] = useLocalStorage<UserAppSession>(
    key,
    initialValue,
  )
  if (userAppSession?.createdAt != null) {
    userAppSession.createdAt = new Date(userAppSession?.createdAt)
  }

  const [skipInitialFocusEvent, setSkipInitialFocusEvent] = useState(false)

  const sessionId = userAppSession?.sessionId ?? initialValue.sessionId
  const createdAt = userAppSession?.createdAt ?? initialValue.createdAt
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  })

  const update = useCallback(
    (updatedUserAppSession: SendUserAppEventResponse) => {
      if (!isMounted.current || isNonEmptyString(updatedUserAppSession.error)) {
        return
      }
      setUserAppSession({
        sessionId: updatedUserAppSession.sessionId,
        createdAt: updatedUserAppSession.createdAt,
      })
    },
    [setUserAppSession],
  )

  const openKey = useOpenUserAppSessionKey(appId)

  useEffect(() => {
    if (
      !isNonEmptyString(appId) ||
      !isNonEmptyString(openKey) ||
      isNonEmptyString(sessionId)
    ) {
      return
    }
    setSkipInitialFocusEvent(true)
    sendOpenEvent(appId, openKey).then((updatedUserAppSession) => {
      update(updatedUserAppSession)
    })
  }, [
    appId,
    sessionId,
    createdAt,
    setUserAppSession,
    setSkipInitialFocusEvent,
    update,
    openKey,
  ])

  useSendFocusEvents(
    appId,
    userAppSession,
    flushIntervalInMs,
    update,
    skipInitialFocusEvent,
    isFocusEventsEnabled,
  )

  return {
    sessionId: userAppSession?.sessionId ?? '',
    createdAt: userAppSession?.createdAt,
  }
}

function useInterval(
  callback: () => void | Promise<void>,
  delay: number,
): React.MutableRefObject<NodeJS.Timeout | undefined> {
  const intervalId = useRef<NodeJS.Timeout>()
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (intervalId.current != null) {
      clearInterval(intervalId.current)
    }

    intervalId.current = setInterval(() => {
      savedCallback.current()
    }, delay)
    return () => {
      if (intervalId.current != null) {
        clearInterval(intervalId.current)
      }
    }
  }, [delay])
  return intervalId
}

export function useSendFocusEvents(
  appId: string | undefined,
  userAppSession: UserAppSession | undefined,
  flushIntervalInMs: number,
  updateCallback: (updatedUserAppSession: SendUserAppEventResponse) => void,
  skipInitialFocusEvent: boolean,
  isFocusEventsEnabled = false,
): void {
  const [_skipInitialFocusEvent, setSkipInitialFocusEvent] = useState(
    skipInitialFocusEvent,
  )
  const updateCallbackRef = useRef(updateCallback)

  useEffect(() => {
    setSkipInitialFocusEvent(skipInitialFocusEvent)
  }, [skipInitialFocusEvent])

  useEffect(() => {
    updateCallbackRef.current = updateCallback
  }, [updateCallback])

  const sessionId = userAppSession?.sessionId
  const createdAt = userAppSession?.createdAt
  const focusKey = useFocusUserAppSessionKey(appId)

  const skipSending =
    !isNonEmptyString(appId) ||
    !isNonEmptyString(focusKey) ||
    !isNonEmptyString(sessionId) ||
    !isFocusEventsEnabled ||
    Visibility.hidden()

  // send focus event before first interval
  useEffect(() => {
    if (skipSending || _skipInitialFocusEvent) {
      return
    }
    setSkipInitialFocusEvent(true)
    sendFocusUserAppEvent(appId, focusKey, {
      sessionId,
      createdAt,
    }).then((res) => {
      updateCallbackRef.current(res)
    })
  }, [
    appId,
    sessionId,
    createdAt,
    skipSending,
    _skipInitialFocusEvent,
    setSkipInitialFocusEvent,
    focusKey,
  ])

  useInterval(async () => {
    // the document.hasFocus check is done additionally to the Visibility.hidden usage above, because if a user has overlayed the browser window (e.g. via Alt + Tab), the window still counts as visible, but not as focused!
    // The document.hasFocus is made just for the interval, because for the initial focus it was observed that document.hasFocus returned false for a page refresh
    if (skipSending || !document.hasFocus()) {
      return
    }
    const updatedUserAppSession = await sendFocusUserAppEvent(appId, focusKey, {
      sessionId,
      createdAt,
    })
    updateCallbackRef.current(updatedUserAppSession)
  }, flushIntervalInMs)
}

export async function sendOpenEvent(
  appId: string,
  openKey: string,
): Promise<SendUserAppEventResponse> {
  try {
    const { data } = await axios.post<SendUserAppEventResponse>(openKey, {})
    if (isNonEmptyString(data.error)) {
      datadogLogs.logger.error(`Failed to send open event: ${data.error}`, {
        app_id: appId,
        event_type: 'open',
      })
    }

    return data
  } catch (exc) {
    // fail writing events silently as this is just a background process
    const errorMessage = extractErrorMessage(exc as AxiosError)
    datadogLogs.logger.error(`Failed to send open event: ${errorMessage}`, {
      app_id: appId,
    })

    return { error: errorMessage }
  }
}

export async function sendFocusUserAppEvent(
  appId: string,
  focusUserAppSessionKey: string,
  userAppSession: UserAppSession,
): Promise<SendUserAppEventResponse> {
  try {
    const { data } = await axios.post<SendUserAppEventResponse>(
      focusUserAppSessionKey,
      {
        sessionId: userAppSession.sessionId,
        createdAt: userAppSession.createdAt,
      },
    )
    if (isNonEmptyString(data.error)) {
      datadogLogs.logger.error(`Failed to send focus event: ${data.error}`, {
        app_id: appId,
        session_id: userAppSession?.sessionId,
        event_type: 'focus',
      })
    }

    return data
  } catch (err) {
    // fail writing events silently as this is just a background process
    const errorMessage = extractErrorMessage(err as AxiosError)
    datadogLogs.logger.error(`Failed to send focus event: ${errorMessage}`, {
      app_id: appId,
      session_id: userAppSession?.sessionId,
      event_type: 'focus',
    })

    return { error: errorMessage }
  }
}

export interface View {
  createdAt: string
  email?: string
  githubLogin?: string
}

interface GetLastAppViewsPerUserResponse {
  views: View[]
  count: number
  error?: string
}
export interface IUseGetLastAppViewsPerUser
  extends GetLastAppViewsPerUserResponse {
  isPending: boolean
}

export function useGetLastAppViewsPerUser(
  appId: string,
): IUseGetLastAppViewsPerUser {
  const { data, error } = useSWR<GetLastAppViewsPerUserResponse, APIError>(
    useLastAppViewsPerUserKey(appId),
  )

  return {
    isPending: data == null && error == null,
    error: error != null ? extractErrorMessage(error) : undefined,
    views: data?.views ?? [],
    count: data?.count ?? 0,
  }
}
