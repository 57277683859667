import { useEffect, useState } from 'react'
import axios, { type AxiosError } from 'axios'
import useSWR, { mutate } from 'swr'

import { type IApp } from 'api/apps'
import { extractErrorMessage } from 'api/utils'
import { getCoordinatesUrl } from 'helpers/urls'
import { isSubdomainApp } from '../hooks/useUniqueSubdomainFeatures'
import { getAppsApiClient } from './apiV2'
import { datadogLogs } from '@datadog/browser-logs'
import { type AppViewersResponseModel } from '../gen'

interface GetViewersResponse {
  viewers?: string[]
  domains?: string[]
  error?: string
}

interface UseGetViewers {
  error?: string
  viewers: string[]
  domains: string[]
  isPending: boolean
}

export type AppViewersKey = [string, string] | null

const getViewersKey = (app: IApp): AppViewersKey => {
  if (isSubdomainApp()) {
    return ['viewers', app.appId]
  }

  return [`${getCoordinatesUrl(app)}/viewers`, app.appId]
}

async function getViewers([
  _url,
  appId,
]: string[]): Promise<AppViewersResponseModel> {
  const client = getAppsApiClient()
  if (appId) {
    if (isSubdomainApp()) {
      const response = await client.getAppViewersFromSubdomain()
      return response.data
    } else {
      const response = await client.getAppViewers(appId)
      return response.data
    }
  } else {
    datadogLogs.logger.info(
      'The viewers endpoint was called without specifying the app ID',
      {
        event_id: 'W00103',
      },
    )
    const status = await axios.get(_url)
    return status.data
  }
}

async function setViewers(
  [_url, appId]: string[],
  viewers: string[],
  domains: string[],
): Promise<void> {
  const client = getAppsApiClient()
  if (appId) {
    if (isSubdomainApp()) {
      await client.updateAppViewersFromSubdomain({
        viewers,
        domains,
      })
    } else {
      await client.updateAppViewers(appId, {
        viewers,
        domains,
      })
    }
  } else {
    datadogLogs.logger.info(
      'The viewers endpoint was called without specifying the app ID',
      {
        event_id: 'W00104',
      },
    )
    await axios.post(_url, { viewers, domains })
  }
}

export function useGetViewers(app: IApp): UseGetViewers {
  const { data, isLoading, error } = useSWR<GetViewersResponse>(
    getViewersKey(app),
    {
      fetcher: getViewers,
    },
  )

  return {
    error,
    viewers: data?.viewers ?? [],
    domains: data?.domains ?? [],
    isPending: isLoading,
  }
}

interface DoSetViewers {
  viewers: string[]
  domains: string[]
}

interface UseSetViewers {
  error: string
  success: boolean
  doSetViewers: ({ viewers, domains }: DoSetViewers) => Promise<void>
  isPending: boolean
}

const successTimeout = 2000

export function useSetViewers(app: IApp): UseSetViewers {
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<boolean>(false)
  const [isPending, setIsPending] = useState(false)

  useEffect(() => {
    if (!success) return undefined
    const timeout = setTimeout(() => {
      setSuccess(false)
    }, successTimeout)
    return () => {
      clearTimeout(timeout)
    }
  }, [success])

  const viewersKey = getViewersKey(app)
  const doSetViewers = async ({
    viewers,
    domains,
  }: DoSetViewers): Promise<void> => {
    if (viewersKey == null) {
      return
    }

    setIsPending(true)
    try {
      await mutate(viewersKey, { viewers, domains }, false) // update cache optimistically
      await setViewers(viewersKey, viewers, domains)

      setIsPending(false)
      setError('')
      setSuccess(true)
    } catch (exc) {
      setIsPending(false)
      setError(extractErrorMessage(exc as AxiosError))
    }

    await mutate(viewersKey) // revalidate cache
  }

  return {
    error,
    success,
    doSetViewers,
    isPending,
  }
}

interface UseSetDeltaViewers {
  error: string
  success: boolean
  doSetDeltaViewers: ({ viewers, domains }: DoSetViewers) => Promise<void>
  isPending: boolean
}

/**
 * This hook fetches the existing viewers first and merges them with the list of newly passed users as the backend endpoint only allows setting the whole list.
 * It is ready when the list of existing viewers was fetched successfully.
 * @param app
 */
export function useSetDeltaViewers(app: IApp): UseSetDeltaViewers {
  const {
    error: doSetViewersError,
    success,
    doSetViewers,
    isPending: isSetViewersPending,
  } = useSetViewers(app)
  const [error, setError] = useState('')

  const viewersKey = getViewersKey(app)

  useEffect(() => {
    setError(doSetViewersError)
  }, [doSetViewersError])

  const doSetDeltaViewers = async ({
    viewers,
    domains,
  }: DoSetViewers): Promise<void> => {
    if (!viewersKey) {
      return
    }

    let existingViewers
    let existingDomains
    try {
      const data = await getViewers(viewersKey)
      existingViewers = data.viewers ?? []
      existingDomains = data.domains ?? []
    } catch (exc) {
      const err = exc as AxiosError
      setError(extractErrorMessage(err))
      return
    }

    // sort new-to-add viewers so that the local cache mutation is in the same order as the sorted-response from the server (ordered by added_at and then alphabetically)
    const sortedViewers = viewers.sort((viewerA: string, viewerB: string) =>
      viewerA.localeCompare(viewerB),
    )

    const newViewers = new Set([...sortedViewers, ...existingViewers])
    const newDomains = new Set([...existingDomains, ...domains])
    doSetViewers({
      viewers: Array.from(newViewers),
      domains: Array.from(newDomains),
    })
  }

  return {
    doSetDeltaViewers,
    error,
    success,
    isPending: isSetViewersPending,
  }
}
