import { useEffect, useState } from 'react'

import queryGithubRepository from 'helpers/queryGithubRepository'

interface IRepositoryData {
  name: string
  isPrivate: boolean
  id?: string
  description?: string | null
  isArchived?: boolean
  isDisabled?: boolean
}

interface IGithubRepository {
  isLoading: boolean
  repository?: IRepositoryData
}

function useGithubRepository(owner: string, name: string): IGithubRepository {
  const [isLoading, setLoading] = useState(true)
  const [repository, setRepository] = useState<IRepositoryData>()

  useEffect(() => {
    queryGithubRepository(owner, name)
      .then((response) => {
        if (response.status === 200) {
          setRepository({
            id: `${response.data.id}`,
            name: response.data.name,
            isArchived: response.data.isArchived,
            isDisabled: response.data.isDisabled,
            isPrivate: response.data.isPrivate,
            description: response.data.description,
          })
        } else {
          setRepository({
            name,
            isPrivate: true,
          })
        }
        setLoading(false)
      })
      .catch((reason) => {
        setLoading(false)
      })
  }, [name, owner])

  return {
    isLoading,
    repository,
  }
}

export default useGithubRepository
