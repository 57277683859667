import { type ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { enqueue } from 'reducers/analytics'

import urls from 'constants/urls'

import { type IApp } from 'api/apps'
import useAppResume from 'api/appResume'
import { extractErrorMessage } from 'api/utils'

import Alert from 'components/alert'
import Button from 'components/button'
import { StyledLink } from 'components/link'

import { ReactComponent as Zzz } from 'assets/svg/emoji_u1f634.svg'

import styles from './styles.module.scss'

interface Props {
  app: IApp
  isOwner: boolean
}

function Suspended({ app, isOwner }: Props): ReactElement {
  const dispatch = useDispatch()
  const { doAppResume, error } = useAppResume(app)

  useEffect(() => {
    dispatch(
      enqueue({
        name: 'app_suspended',
      }),
    )
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      <div className={styles.errorContainer}>
        <Zzz className={styles.sleepingFace} />
        <h1>Zzzz</h1>

        <p>
          This app has gone to sleep due to inactivity. Would you like to wake
          it back up?
        </p>

        <Button
          onClick={async () => {
            await doAppResume()
          }}
          className={styles.restartButton}
          data-testid={isOwner ? 'wakeup-button-owner' : 'wakeup-button-viewer'}
        >
          Yes, get this app back up!
        </Button>

        {error && <Alert title="Error" message={extractErrorMessage(error)} />}

        <footer>
          <div className={styles.smallDivider} />
          If you believe this is a bug, please{' '}
          <StyledLink external to={`${urls.snowflakeSupportLink}`}>
            contact us
          </StyledLink>{' '}
          or{' '}
          <StyledLink external to={`${urls.forum}`}>
            visit the Streamlit forums
          </StyledLink>
          .
        </footer>
      </div>
    </div>
  )
}

export default Suspended
