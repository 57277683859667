import { useState } from 'react'
import { mutate } from 'swr'

import { APP_STATUS } from 'constants/apps'

import mutateAppWithStatus from 'helpers/mutateAppWithStatus'
import { isSubdomainApp } from 'hooks/useUniqueSubdomainFeatures'

import { type APIError } from 'api/types'
import useAppStatus, { useAppStatusKey } from 'api/appStatus'
import { getAppsKey, type IApp } from 'api/apps'
import { getAppsApiClient } from './apiV2'

export interface IUseAppRestart {
  pending: boolean
  error?: APIError
  doAppResume: (callback?: () => void) => Promise<void>
}

async function resumeApp(appId: string): Promise<void> {
  const client = getAppsApiClient()
  if (isSubdomainApp()) {
    await client.resumeAppFromSubdomain()
  } else {
    await client.resumeApp(appId)
  }
}

const useAppResume = (app: IApp): IUseAppRestart => {
  const [pending, setPending] = useState(false)
  const [error, setError] = useState<APIError | undefined>()
  const { isOwner, status } = useAppStatus(app.appId)

  const appStatusKey = useAppStatusKey(app.appId)
  const appsKey = getAppsKey()

  const doAppResume = async (callback?: () => void): Promise<void> => {
    setPending(true)

    try {
      await mutateAppWithStatus(
        {
          appsKey,
          appStatusKey,
        },
        {
          app,
          isOwner,
          status: APP_STATUS.UNKNOWN,
        },
      )

      setPending(false)
      if (callback != null) callback()

      await resumeApp(app.appId)
    } catch (exc) {
      // Reverting to initial state
      await mutateAppWithStatus(
        {
          appsKey,
          appStatusKey,
        },
        {
          app,
          isOwner,
          status,
        },
      )

      setPending(false)
      setError(exc as APIError)
    }

    await Promise.all([mutate(appStatusKey), mutate(appsKey)])
  }

  return {
    pending,
    error,
    doAppResume,
  }
}

export default useAppResume
