import useSWR from 'swr'
import { useDispatch } from 'react-redux'

import { initialize } from 'reducers/analytics'
import { type OnBoardingItem } from 'api/onBoardingStatus'

import type UserPermissions from 'constants/userPermissions'
import type WorkspacePermissions from 'constants/workspacePermissions'
import type SourceProviderConnectionType from 'constants/sourceProviderConnectionType'

import { type APIError } from 'api/types'
import { isNonEmptyString } from 'helpers/validation'
import { isSubdomainApp } from '../hooks/useUniqueSubdomainFeatures'
import { type UserInfoModel, type UserPreferencesResponseModel } from '../gen'
import { getUserApiClient } from './apiV2'

export interface WorkspaceInfo {
  id: string
  name: string
}

export interface IWorkspace extends WorkspaceInfo {
  viewOnly: boolean
  permissions?: WorkspacePermissions[]
}

export interface ISSOInfo {
  email: string
}

export interface ISourceProviderConnection {
  active: boolean
  id: string
  type: SourceProviderConnectionType
}

export interface IGitHubAccountSourceProviderConnection
  extends ISourceProviderConnection {
  login: string
}

export interface IUser {
  canonicalUserId?: string
  login?: string
  email?: string
  ssoEmail?: string
  ssoInfo?: ISSOInfo
}
export interface LinkedUser {
  githubUser?: string
}

export interface IResponse {
  user?: IUser
  permissions?: UserPermissions[]
  workspaces?: IWorkspace[]
  profilePageUserName?: string
  error?: APIError
  status?: number
  setUser: (
    data?: UserInfoModel,
    shouldRevalidate?: boolean,
  ) => Promise<UserInfoModel | undefined>
  isPending: boolean
  isValidating: boolean
  linkedUser: LinkedUser | null
  onBoardingItem?: OnBoardingItem[]
  sourceProviderConnections?: ISourceProviderConnection[]
  preferences?: UserPreferencesResponseModel
}

export function getGitHubSourceProviderConnection(
  sourceProviderConnections?: ISourceProviderConnection[],
): IGitHubAccountSourceProviderConnection | null {
  if (
    sourceProviderConnections != null &&
    sourceProviderConnections.length > 0
  ) {
    return sourceProviderConnections[0] as IGitHubAccountSourceProviderConnection
  }

  return null
}

async function fetchUserInfo(): Promise<UserInfoModel> {
  const client = getUserApiClient()
  return await client
    .getUser({
      validateStatus: function (status) {
        // 200 means the user is logged in
        // 401 means that the user is not logged in
        // 404 means that the user could not be found, which should be similar to 401
        return status === 200 || status === 401 || status === 404
      },
    })
    .then((res) => res.data)
}

function getUserKey(): string | null {
  if (isSubdomainApp()) {
    return null
  }

  return 'user'
}

export const useWorkspaceAssociatedWithConnectedGitHubAccount = ():
  | WorkspaceInfo
  | undefined => {
  const user = useUser()
  if (!user?.workspaces || user.workspaces.length === 0) {
    return undefined
  }

  if (!user.user?.login) {
    return undefined
  }

  const loginForConnectedGitHubAccount = user.user.login

  for (const workspace of user.workspaces) {
    if (workspace.name === loginForConnectedGitHubAccount) {
      return workspace
    }
  }

  return undefined
}

const useUser = (): IResponse => {
  const dispatch = useDispatch()
  const userKey: string | null = getUserKey()

  const { data, isValidating, mutate, error, isLoading } = useSWR<
    UserInfoModel,
    APIError
  >(userKey, {
    fetcher: fetchUserInfo,
  })
  let user: IUser | undefined
  let permissions: UserPermissions[] | undefined
  let workspaces: IWorkspace[] | undefined
  let onBoardingItem: OnBoardingItem[] | undefined
  let sourceProviderConnections: ISourceProviderConnection[] | undefined
  let profilePageUserName: string | undefined
  let preferences: UserPreferencesResponseModel | undefined

  if (data?.user?.login != null) {
    user = {
      ...data.user,
      canonicalUserId: data.canonicalUserId,
      ssoInfo: data.ssoInfo,
    }

    permissions = data.permissions
    workspaces = data.workspaces
    onBoardingItem = data.onboarding
    sourceProviderConnections = data.sourceProviderConnections
    profilePageUserName = data.profilePageUserName
    preferences = data.preferences
  }

  if (user != null) {
    const { canonicalUserId, login, email } = user
    if (
      isNonEmptyString(canonicalUserId) &&
      isNonEmptyString(login) &&
      isNonEmptyString(email)
    ) {
      dispatch(
        initialize({
          id: canonicalUserId,
          login,
          email,
        }),
      )
    }
  }

  const linkedUser: LinkedUser | null = isNonEmptyString(data?.linkedGithubUser)
    ? {
        githubUser: data?.linkedGithubUser,
      }
    : null

  return {
    user,
    permissions,
    workspaces,
    error,
    setUser: mutate,
    isPending: isLoading,
    isValidating,
    linkedUser,
    onBoardingItem,
    sourceProviderConnections,
    profilePageUserName,
    preferences,
  }
}

export default useUser
