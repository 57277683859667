import type React from 'react'
import { type ReactElement, useState, useEffect, useRef } from 'react'
import { type CheckboxOverrides } from 'baseui/checkbox'
import { type PopoverOverrides } from 'baseui/popover'
import { StatefulTooltip } from 'baseui/tooltip'

import WorkspacePermissions from 'constants/workspacePermissions'

import Toggle, {
  toggleOverrides as globalToggleOverrides,
} from 'components/toggle'

import useAppStatus from 'api/appStatus'
import { type IApp } from 'api/apps'
import { useEnableViewerAuth } from 'api/viewerAuth'
import useUser from 'api/user'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'

import { theme } from 'constants/tailwind.config'

interface Props {
  app: IApp
}

const tooltipOverrides: PopoverOverrides = {
  Body: {
    props: {
      'data-testid': 'IsAppPublicToggle:tooltip',
    },
  },
  Inner: {
    style: {
      lineHeight: 1.4,
      padding: '0.25rem 0.5rem',
    },
  },
}

const getToggleOverrides = (disabled: boolean): CheckboxOverrides => ({
  ...globalToggleOverrides,
  Label: {
    props: {
      'data-testid': 'IsAppPublicToggle:label',
    },
    style: {
      color: disabled ? theme.colors.black['600'] : 'initial',
      paddingLeft: '1em',
      fontSize: '0.875rem',
    },
  },
  Input: {
    props: {
      'data-testid': 'IsAppPublicToggle:checkbox',
    },
  },
})

export default function IsAppPublicToggle({ app }: Props): ReactElement {
  const { isOwner, viewerAuthEnabled } = useAppStatus(app.appId)
  const {
    doEnableViewerAuth,
    isPending: isEnableViewerAuthPending,
    error,
  } = useEnableViewerAuth(app)
  const [isPublic, setIsPublic] = useState(!viewerAuthEnabled)
  const [lastToggledAt, setLastToggledAt] = useState<Date | undefined>()

  const previousViewerAuth = useRef<boolean>(viewerAuthEnabled)

  const { workspaces } = useUser()
  const wsPerms = useWorkspacePermissions(workspaces)

  const isPrivateWorkspace = wsPerms.includes(
    WorkspacePermissions.PRIVATE_WORKSPACE,
  )

  // Sync the button state with the server state. However, don't change the visual buttons state
  // when the post-request to the server is still in progress
  // or when the viewerAuthEnabled value did not change (this check prevents visual blips between state changes)
  useEffect(() => {
    if (
      isEnableViewerAuthPending ||
      previousViewerAuth.current === viewerAuthEnabled
    ) {
      return
    }

    previousViewerAuth.current = viewerAuthEnabled
    setIsPublic(!viewerAuthEnabled)
  }, [viewerAuthEnabled, isEnableViewerAuthPending])

  useEffect(() => {
    // If the viewerAuth change call has an error, reverse the local state change of the button to last state
    if (error) {
      setIsPublic(!previousViewerAuth.current)
    }

    // use lastToggledAt here to cover the case that `error` is always the same
    // which would prevent the useEffect from being triggered
  }, [error, lastToggledAt])

  const toggle = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (isPrivateWorkspace) {
      return
    }

    const value = e.currentTarget.checked
    setIsPublic(value)
    await doEnableViewerAuth(!value)
    setLastToggledAt(new Date())
  }

  return (
    <StatefulTooltip
      content={
        (!isOwner || isPrivateWorkspace) &&
        'You don’t have permission to change this setting'
      }
      accessibilityType="tooltip"
      placement="bottomLeft"
      popoverMargin={2}
      overrides={tooltipOverrides}
    >
      {/* NOTE: This <div> wraps the children of the <Toggle> element as the
      tooltip component works only with 1 child. */}
      <div
        className="flex items-center"
        data-testid="IsAppPublicToggle:toggleWrapper"
      >
        <Toggle
          checked={isPublic}
          disabled={!isOwner || isEnableViewerAuthPending || isPrivateWorkspace}
          onChange={toggle}
          overrides={getToggleOverrides(!isOwner || isPrivateWorkspace)}
        >
          Make this app public
        </Toggle>
      </div>
    </StatefulTooltip>
  )
}
