export const DISALLOWED_DOMAINS = [
  'aol.com',
  'bol.com.br',
  'comcast.net',
  'free.fr',
  'gmail.com',
  'gmail.de',
  'gmx.de',
  'googlemail.com',
  'hostmail.com',
  'hotmail.co.uk',
  'hotmail.fr',
  'libero.it',
  'live.com',
  'mail.ru',
  'msn.com',
  'orange.fr',
  'outlook.com',
  'protonmail.com',
  'qq.com',
  'rediffmail.com',
  'uol.com.br',
  'wanadoo.fr',
  'web.de',
  'yahoo.com',
  'yahoo.com.br',
  'yahoo.co.in',
  'yahoo.co.uk',
  'yahoo.de',
  'yahoo.fr',
  'yahoo.it',
  'yandex.ru',
  'ymail.com',
  'zohomail.eu',
]
