import type React from 'react'
import { type ReactElement, useState, memo } from 'react'

import { Checkbox, type CheckboxOverrides } from 'baseui/checkbox'

interface PreviewOptionProps {
  optionName: string
  params: string[]
  setParams: React.Dispatch<React.SetStateAction<string[]>>
}

export const toggleOverrides: CheckboxOverrides = {
  Root: {
    style: ({ $theme }) => ({
      marginLeft: '-0.10em',
      marginRight: '0.40em',
      alignItems: 'center',
    }),
  },
  Label: {
    style: ({ $theme }) => ({
      fontSize: '0.875em',
    }),
  },
  Checkmark: {
    style: ({ $checked, $theme }) => ({
      height: '0.875em',
      width: '0.875em',
      opacity: $checked ? '100%' : '25%',
      backgroundColor: $checked ? $theme.colors.black : null,
      borderWidth: '1px',
    }),
  },
}

function PreviewOption({
  optionName,
  params,
  setParams,
}: PreviewOptionProps): ReactElement {
  const formattedOptionName = optionName.toLowerCase().replace(/\s/g, '_')
  const [checked, setChecked] = useState(params.includes(formattedOptionName))

  return (
    <Checkbox
      checked={checked}
      overrides={toggleOverrides}
      onChange={() => {
        setChecked(!checked)
        setParams((params: string[]) => {
          return checked
            ? params.filter((param) => param !== formattedOptionName)
            : [...params, formattedOptionName]
        })
      }}
      data-testid="previewOptionCheckbox"
    >
      {optionName}
    </Checkbox>
  )
}

export default memo(PreviewOption)
