import { type ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Form, Formik, useFormikContext } from 'formik'
import { ModalFooter } from 'baseui/modal'

import { type IApp } from 'api/apps'
import { useGetSecrets, useUpdateSecrets } from 'api/secrets'
import { extractErrorMessage } from 'api/utils'

import Button from 'components/button'
import PageSpinner from 'components/pageSpinner'
import SecretsField from 'components/deployForm/components/secretsField'
import TabBody from 'components/modal/components/tabBody/tabBody'
import TabBodyPart from 'components/modal/components/tabBody/tabBodyPart'
import { type CloseModalArgs } from 'components/modal'

import { confirmationOff, confirmationOn, type ModalName } from 'reducers/modal'

import modalStyles from './modal.module.scss'
import styles from './secretsSettings.module.scss'

interface SecretsBodyProps {
  app: IApp
  name: ModalName
  onClose: (args?: CloseModalArgs) => void
}

interface SecretsTabBodyProps {
  name: ModalName
  errors?: string[]
}

interface FormValues {
  secrets: string
}

export function SecretsTabBody({
  name,
  errors: apiErrors,
}: SecretsTabBodyProps): ReactElement {
  const dispatch = useDispatch()
  const { submitForm, isSubmitting, errors, dirty } =
    useFormikContext<FormValues>()

  const isSaveDisabled = !dirty || isSubmitting || !!errors.secrets

  useEffect(() => {
    dispatch(dirty ? confirmationOn({ name }) : confirmationOff({ name }))
  }, [dirty, dispatch, name])

  return (
    <>
      <div className={modalStyles.modalBody}>
        <TabBody>
          <TabBodyPart
            title="Secrets"
            body={<SecretsField errors={apiErrors} />}
            classNames={{ header: styles.bodyAsSubTitle }}
          />
        </TabBody>
      </div>
      <ModalFooter className={modalStyles.modalFooter}>
        <Button onClick={submitForm} disabled={isSaveDisabled}>
          Save
        </Button>
      </ModalFooter>
    </>
  )
}

function SecretsBody({ app, name, onClose }: SecretsBodyProps): ReactElement {
  const { error: getError, secrets, isPending } = useGetSecrets(app.appId)
  const { error: updateError, doUpdateSecrets } = useUpdateSecrets(app.appId)

  const apiErrors: string[] = []

  if (getError) {
    apiErrors.push(extractErrorMessage(getError))
  }

  if (updateError) {
    apiErrors.push(extractErrorMessage(updateError))
  }

  if (isPending) {
    return <PageSpinner />
  }

  return (
    <>
      <Formik
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          await doUpdateSecrets(values.secrets)
          setSubmitting(false)

          if (!updateError) {
            // Form submission should NOT open a confirmation modal.
            onClose({ disableConfirmation: true })
          }
        }}
        initialValues={{ secrets }}
        enableReinitialize
      >
        <Form className={modalStyles.modal}>
          <SecretsTabBody name={name} errors={apiErrors} />
        </Form>
      </Formik>
    </>
  )
}

export default SecretsBody
