import { memo, type ReactElement } from 'react'
import { SIZE, StyledSpinnerNext as Spinner } from 'baseui/spinner'
import LinkButton from '../../../components/linkButton'

import styles from './linkedAccounts.module.scss'

interface LinkedElementProps {
  connectionName: string
  username: string
  onClick?: () => void
  isLoading?: boolean
  disabledButton?: boolean
}

export const LinkedElement = memo(function LinkedElement({
  connectionName,
  username,
  onClick,
  isLoading = false,
  disabledButton = false,
}: LinkedElementProps): ReactElement {
  return (
    <div className={styles.linkedElement}>
      <span>
        Logged in as <strong>{username}</strong> using{' '}
        <strong>{connectionName}</strong>.
      </span>
      {isLoading && (
        <Spinner className={styles.disconnect} $size={SIZE.small} />
      )}
      {!disabledButton && !isLoading && onClick && (
        <LinkButton className={styles.disconnect} onClick={onClick}>
          Disconnect
        </LinkButton>
      )}
    </div>
  )
})
