import { useDispatch } from 'react-redux'
import { useLocalStorage } from 'react-use'
import { useEffect } from 'react'

import useUser from 'api/user'
import { useWorkspaceContext } from 'context/workspaceContext'
import {
  select as selectWorkspaceAction,
  type Workspace,
} from 'reducers/workspace'
import { isNonEmptyString } from 'helpers/validation'

function useWorkspaceInStorage(): void {
  const dispatch = useDispatch()
  const { user, workspaces = [], linkedUser } = useUser()

  const [workspaceFromStorage, setWorkspaceToStorage] =
    useLocalStorage<Workspace>('workspace', '')
  const currentWorkspace = useWorkspaceContext().Name()
  const userWorkspacesIncludesWorkspaceFromStorage = workspaces.some(
    (workspace) => workspace.name === workspaceFromStorage,
  )

  useEffect(
    function setDefaultWorkspace() {
      if (isNonEmptyString(currentWorkspace)) {
        return
      }

      if (
        isNonEmptyString(workspaceFromStorage) &&
        userWorkspacesIncludesWorkspaceFromStorage
      ) {
        return
      }

      const login = user?.login

      if (isNonEmptyString(login)) {
        dispatch(
          selectWorkspaceAction({
            workspace: login,
          }),
        )

        return
      }

      if (workspaces.length > 0 && linkedUser?.githubUser === undefined) {
        dispatch(
          selectWorkspaceAction({
            workspace: workspaces[0].name,
          }),
        )
      }
    },
    [
      dispatch,
      currentWorkspace,
      workspaceFromStorage,
      userWorkspacesIncludesWorkspaceFromStorage,
      user?.login,
      workspaces,
      linkedUser?.githubUser,
    ],
  )

  // Save on redux the workspace from storage
  useEffect(
    function setWorkspaceFromLocalStorageToState() {
      if (isNonEmptyString(currentWorkspace)) {
        return
      }

      if (!userWorkspacesIncludesWorkspaceFromStorage) {
        return
      }

      if (isNonEmptyString(workspaceFromStorage)) {
        dispatch(
          selectWorkspaceAction({
            workspace: workspaceFromStorage,
          }),
        )
      }
    },
    [
      dispatch,
      currentWorkspace,
      workspaceFromStorage,
      userWorkspacesIncludesWorkspaceFromStorage,
    ],
  )

  // Save workspace on storage
  useEffect(
    function setWorkspaceStateToLocalStorage() {
      if (isNonEmptyString(currentWorkspace)) {
        setWorkspaceToStorage(currentWorkspace)
      }
    },
    [dispatch, currentWorkspace, setWorkspaceToStorage],
  )
}

export default useWorkspaceInStorage
