import { type ReactElement, memo } from 'react'
import classNames from 'classnames'
import { StyledSpinnerNext as Spinner } from 'baseui/spinner'

import styles from './styles.module.scss'

interface Props {
  message?: string

  // When true, the spinner is an absolute-positioned overlay on top of the entire app, with
  // translucent background.
  // When false, this spinner is positioned in the relative context. Useful for flex containers, for
  // example.
  overlay?: boolean
  title?: string
}

function PageSpinner({ message, overlay, title }: Props): ReactElement {
  return (
    <div
      className={classNames(styles.spinnerContainer, {
        [styles.overlay]: overlay,
      })}
      title={title}
    >
      <Spinner />
      {message && <p>{message}</p>}
    </div>
  )
}

export default memo(PageSpinner)
