import { analytics } from '../../autogen/proto/proto'
import { type Message } from 'protobufjs'
import { type AnalyticsEvent } from './analyticsEvent'
import { v4 as uuidv4 } from 'uuid'
import TraceContext = analytics.TraceContext
import { Base64 } from 'js-base64'

type AnalyticsEventKey = keyof typeof analytics

export function getEventName(value: { name: string }): string {
  return `analytics.${value.name}`
}

export function serializeToBase64EncodedBytes(event: AnalyticsEvent): string {
  const classForEvent = getMessageClassForEvent(
    event.commonProperties?.eventName,
  )
  const serializedMessage = classForEvent.encode(event).finish()

  return Base64.fromUint8Array(serializedMessage, true)
}

export function getMessageClassForEvent(
  eventName: string | undefined | null,
): typeof Message<AnalyticsEvent> {
  if (!eventName) {
    throw new Error('The event name must be non-empty')
  }

  const nameComponents = eventName.split('.')
  if (nameComponents.length !== 2 || nameComponents[0] !== 'analytics') {
    throw new Error(
      'The event name must have the format analytics.{MessageName}',
    )
  }

  const messageName: string = nameComponents[1]
  const messageClass = getMessageClass(messageName as AnalyticsEventKey)
  if (!messageClass) {
    throw new Error(`'${eventName}' is not defined in the analytics package`)
  }

  return messageClass
}

export function setEventProperties(event: AnalyticsEvent): AnalyticsEvent {
  let commonProperties = event.commonProperties
  if (!commonProperties) {
    throw new Error('The commonProperties field must be set')
  }

  const eventClass = getMessageClassForEvent(commonProperties.eventName)
  if (!commonProperties.id) {
    commonProperties.id = uuidv4()
  }

  if (!commonProperties.timestamp) {
    // We set the current timestamp in the field, and we will use int in communityEventSink.ts to calculate
    // the difference between this time (creation time) and the publishing to community time, which will then be used
    // to calculate the event creation time in server time
    commonProperties.timestamp = new Date().getTime()
  }

  commonProperties = setTraceContextProperties(commonProperties)

  event.commonProperties = commonProperties

  return eventClass.fromObject(event) as AnalyticsEvent
}

function setTraceContextProperties(
  commonProperties: analytics.ICommonProperties,
): analytics.ICommonProperties {
  let traceContext = commonProperties.traceContext
  if (!traceContext) {
    traceContext = TraceContext.create()
  } else {
    traceContext = TraceContext.fromObject(traceContext)
  }

  if (!traceContext.traceId) {
    // TODO: Find a way to extract the trace ID from the client
  }

  if (!traceContext.spanId) {
    // TODO: Find a way to extract the span ID from the client
  }

  if (!traceContext.parentSpanId) {
    // TODO: Find a way to extract the parent span ID from the client
  }

  if (!traceContext.source) {
    traceContext.source = 'web'
  }

  commonProperties.traceContext = traceContext
  return commonProperties
}

function getMessageClass(
  key: keyof typeof analytics,
): typeof Message<AnalyticsEvent> {
  return analytics[key] as unknown as typeof Message<AnalyticsEvent>
}
