import { type ReactNode, type ReactElement, type ReactText } from 'react'
import { StatefulTooltip } from 'baseui/tooltip'

import STYLES from './tooltipStyles'

interface Props {
  children: ReactElement | ReactText
  content: ReactNode
  placement?: 'bottomLeft' | 'bottomRight' | 'left'
}

function Tooltip({
  children,
  content,
  placement = 'bottomLeft',
}: Props): ReactElement {
  return (
    <StatefulTooltip content={content} {...STYLES[placement]}>
      {children}
    </StatefulTooltip>
  )
}

export default Tooltip
