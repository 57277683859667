import type React from 'react'
import {
  type ReactElement,
  type SetStateAction,
  useState,
  memo,
  useMemo,
  useCallback,
} from 'react'
import _noop from 'lodash/noop'

import Modal, { SideNavigationBody } from 'components/modal'
import modalOverrides from 'modals/modalOverrides'

import { useSelector } from 'reducers'
import {
  makeModalPayloadSelector,
  type ModalName,
  type AnalyticsPayload,
} from 'reducers/modal'

import { Surface } from 'constants/analytics'
import useTrackAnalytics from 'hooks/analytics/useTrackAnalytics'

import { useWorkspaceContext } from 'context/workspaceContext'

import AppViewersBody from './components/appViewers/appViewers'

interface ModalProps {
  onClose?: () => void
}

export interface InnerBodyProps {
  menuItems: MenuItems
  workspaceName?: string
  name: ModalName
}

export enum MenuItemId {
  Workspace = 'workspace',
  AppViewers = 'app-viewers',
}

export interface MenuItem {
  title: React.ReactNode
  itemId: MenuItemId
}

export type MenuItems = MenuItem[]

export function InnerBody({
  menuItems,
  workspaceName,
  name,
}: InnerBodyProps): ReactElement {
  const selectModalPayload = useMemo(makeModalPayloadSelector, [])
  const modalPayload = useSelector((state) =>
    selectModalPayload(state, name),
  ) as AnalyticsPayload | undefined
  const [activeNav, setActiveNav] = useState<MenuItemId>(
    getDefaultActiveNav(modalPayload?.activeNav, menuItems),
  )

  const trackAnalytics = useTrackAnalytics()

  const setActiveNavWithTracking = useCallback(
    (newActiveNav: SetStateAction<MenuItemId>) => {
      trackAnalytics({
        surface: Surface.AnalyticsModalTabSwitch,
        tab: newActiveNav as MenuItemId,
        workspaceName,
        // No app-specific analytics has been chosen.
        appId: undefined,
        role: undefined,
      })
      setActiveNav(newActiveNav)
    },
    [workspaceName, setActiveNav, trackAnalytics],
  )

  return (
    <SideNavigationBody<MenuItemId>
      menuItems={menuItems}
      setActiveNav={setActiveNavWithTracking}
      activeNav={activeNav}
      title="Analytics"
      modalName={name}
      bottomFade
    >
      {activeNav === MenuItemId.AppViewers && (
        <AppViewersBody app={modalPayload?.app} />
      )}
    </SideNavigationBody>
  )
}

export function getMenuItems(): MenuItems {
  const menuItems: MenuItems = [
    {
      title: 'App viewers',
      itemId: MenuItemId.AppViewers,
    },
  ]
  return menuItems
}

function AnalyticsModal({ onClose = _noop }: ModalProps): ReactElement | null {
  const name = 'ANALYTICS'

  const workspaceContext = useWorkspaceContext()
  const workspaceName = workspaceContext.Name()
  const menuItems: MenuItems = getMenuItems()

  return (
    <Modal name={name} onClose={onClose} overrides={modalOverrides}>
      {() => (
        <InnerBody
          menuItems={menuItems}
          workspaceName={workspaceName}
          name={name}
        />
      )}
    </Modal>
  )
}

export function getDefaultActiveNav(
  preferredActiveNav: MenuItemId | undefined,
  menuItems: MenuItems,
): MenuItemId {
  return preferredActiveNav ?? menuItems[0].itemId
}

export default memo(AnalyticsModal)
