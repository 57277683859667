import { type ReactElement, memo, useMemo } from 'react'
import { type Option, type Value } from 'baseui/select'

import { datadogLogs } from '@datadog/browser-logs'

import { getAppTitle, type IApp } from 'api/apps'
import { useGetLastAppViewsPerUser } from 'api/appEvents'

import Alert from 'components/alert'
import PageSpinner from 'components/pageSpinner'
import TabBody from 'components/modal/components/tabBody/tabBody'
import TabBodyPart from 'components/modal/components/tabBody/tabBodyPart'

import eventIds from 'constants/eventIds'

import MostRecentAppViewers from 'modals/analytics/components/appViewers/mostRecentAppViewers'
import NumberOfViewers from 'modals/analytics/components/appViewers/numberOfViewers'

import { useWorkspaceContext } from 'context/workspaceContext'

interface AppViewersBodyProps {
  app?: IApp
}

function AppViewersBody({ app }: AppViewersBodyProps): ReactElement {
  const workspaceContext = useWorkspaceContext()
  const apps = workspaceContext.GetApps()
  const appsAreLoading = apps === undefined

  const filteredApps = useMemo(() => removeAppsWithoutAppId(apps), [apps])
  const appOptions = filteredApps.map(appToOption)

  const selectedApp = getSelectedApp(appOptions, app)
  const selectedAppId = selectedApp[0]?.id ? `${selectedApp[0].id}` : ''

  const {
    views,
    count,
    error,
    isPending: viewsAreLoading,
  } = useGetLastAppViewsPerUser(selectedAppId)

  if (appsAreLoading) {
    return (
      <TabBody>
        <TabBodyPart title="App viewers" body={<PageSpinner />} />
      </TabBody>
    )
  }

  if (appOptions.length === 0) {
    return (
      <TabBody>
        <TabBodyPart
          title="App viewers"
          body="No apps have been deployed yet."
        />
      </TabBody>
    )
  }

  let analyticsDetailsElement: JSX.Element | null = (
    <MostRecentAppViewers views={views} />
  )
  if (viewsAreLoading) {
    analyticsDetailsElement = <PageSpinner />
  } else if (error) {
    analyticsDetailsElement = <Alert title="Error" message={error} hideClose />
  } else if (views.length === 0) {
    analyticsDetailsElement = null
  }

  return (
    <TabBody>
      <TabBodyPart
        title="App viewers"
        body={
          <>
            {!viewsAreLoading && !error && <NumberOfViewers count={count} />}
            {analyticsDetailsElement}
          </>
        }
      />
    </TabBody>
  )
}

export function removeAppsWithoutAppId(apps: IApp[] | undefined): IApp[] {
  if (!apps) {
    return []
  }

  return apps.filter((app) => {
    if (!app.appId) {
      // This should not happen! If it does, log the error
      // so that we can investigate the cause.
      // (HK) TODO: Go over this item in a couple of weeks,
      // and see if the error was ever reported. If not, remove the log.
      datadogLogs.logger.error(
        'Ignore app in analytics modal as appId is missing',
        {
          event_id: eventIds.MissingAppIdForAnalytics,
          app,
        },
      )
      return false
    }
    return true
  })
}

export function appToOption(app: IApp): Option {
  return {
    id: app.appId,
    label: getAppTitle(app),
  }
}

export function getSelectedApp(
  appOptions: Option[],
  selectedApp?: IApp,
): Value {
  // Use the first app, if no selection was provided.
  const fallbackApp = appOptions.length > 0 ? [appOptions[0]] : []
  return selectedApp?.appId ? [appToOption(selectedApp)] : fallbackApp
}

export default memo(AppViewersBody)
