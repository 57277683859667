import { type ReactElement } from 'react'

import classNames from 'classnames'
import { StatefulTooltip } from 'baseui/tooltip'
import { type PopoverOverrides } from 'baseui/popover'

import Toggle from 'components/toggle/toggle'

import { ReactComponent as DomainSharingIcon } from 'assets/svg/domain-sharing-icon.svg'

import styles from './domains.module.scss'

const tooltipOverrides: PopoverOverrides = {
  Body: {
    props: {
      'data-testid': 'DomainRow:tooltip',
    },
  },
  Inner: {
    style: {
      lineHeight: 1.4,
      padding: '0.25rem 0.5rem',
    },
  },
}

interface DomainRowProps {
  isOwner: boolean
  domain: string
  isShared: boolean
  toggleDomainIsShared: (domain: string, isShared: boolean) => void
}

function DomainRow({
  isOwner,
  domain,
  isShared,
  toggleDomainIsShared,
}: DomainRowProps): ReactElement {
  const handleClick = (): void => {
    const newIsShared = !isShared
    toggleDomainIsShared(domain, newIsShared)
  }

  const disabledClass = isOwner ? '' : styles.disabled
  // Make the whole row clickable. The properties are required for interactive events to prevent lint errors.
  // Only set role to button and set handlers if the row is clickable; otherwise, the cursor-not-allowed CSS property is overridden
  const divButtonProps = isOwner
    ? {
        role: isOwner ? 'button' : '',
        tabIndex: 0,
        onKeyPress: handleClick,
        onClick: handleClick,
      }
    : {}
  return (
    <StatefulTooltip
      content={!isOwner && 'You don’t have permission to change this setting'}
      accessibilityType="tooltip"
      placement="bottomLeft"
      popoverMargin={2}
      overrides={tooltipOverrides}
    >
      <div
        key={domain}
        className={classNames(styles.domainRow, disabledClass)}
        {...divButtonProps}
      >
        <DomainSharingIcon className={styles.domainIcon} />
        <span
          className={styles.domainText}
        >{`Everyone at ${domain} can access this app.`}</span>
        <Toggle
          disabled={!isOwner}
          checked={isShared}
          ariaLabel={`toggle sharing with ${domain}`}
        />
      </div>
    </StatefulTooltip>
  )
}

export default DomainRow
