import useSWR from 'swr'

import {
  APP_STATUS,
  type AppStatusValue,
  type PlatformStatusValue,
} from 'constants/apps'

import { isSubdomainApp } from 'hooks/useUniqueSubdomainFeatures'

import { type APIError } from 'api/types'
import { type AppStatusResponseModel } from '../gen'
import { getAppsApiClient } from './apiV2'

export interface IUseAppStatus {
  status: AppStatusValue
  platformStatus: PlatformStatusValue
  isOwner: boolean
  creatorId: string
  viewerAuthEnabled: boolean
  streamlitVersion?: string
  isPending: boolean
  error?: APIError
  isValidating: boolean
  setStatus: (
    data: { status?: AppStatusValue; isOwner?: boolean },
    shouldRevalidate?: boolean,
  ) => Promise<{ status: number } | undefined>
}

export type AppStatusKey = [string, string] | null

export const useAppStatusKey = (appId: string): AppStatusKey => {
  if (!appId) {
    return null
  }

  return [`apps/${appId}/status`, appId]
}

export async function fetchAppStatus([
  _url,
  appId,
]: string[]): Promise<AppStatusResponseModel> {
  const client = getAppsApiClient()
  if (isSubdomainApp()) {
    const status = await client.getAppStatusFromSubdomain()
    return status.data
  } else {
    const status = await client.getAppStatus(appId)
    return status.data
  }
}

const useAppStatus = (appId: string): IUseAppStatus => {
  const swrKey: AppStatusKey = useAppStatusKey(appId)
  const { data, error, isValidating, mutate, isLoading } = useSWR(swrKey, {
    refreshInterval: 2000,
    fetcher: fetchAppStatus,
  })

  // if we receive a 404, treat the app as being in the deleting stage
  if (error?.response?.status === 404) {
    mutate({ ...data, status: APP_STATUS.DELETING }, false)
  }

  return {
    status: error != null ? null : data?.status,
    platformStatus: data?.platformStatus,
    isOwner: data?.isOwner,
    creatorId: data?.creatorId,
    viewerAuthEnabled: data?.viewerAuthEnabled,
    error,
    isPending: isLoading,
    setStatus: mutate,
    isValidating,
    streamlitVersion: data?.streamlitVersion,
  }
}

export default useAppStatus
