import axios, { type AxiosResponse } from 'axios'
import queryString from 'query-string'
import { useState, useEffect } from 'react'

import { toaster } from 'baseui/toast'

import { type APIError } from 'api/types'
import { extractErrorMessage } from 'api/utils'
import { isNonEmptyString } from 'helpers/validation'

interface UpgradeRepoScopeResponse {
  redirect?: string
  success: boolean
  error?: string
}

export interface UseUpgradeRepoScope {
  doUpgradeRepoScope: () => Promise<void>
  error?: APIError
  success: boolean
  pending: boolean
}

export const getUpgradeRepoScopeKey = (): string => 'upgrade-repo-scope'

const useUpgradeRepoScope = (): UseUpgradeRepoScope => {
  const [error, setError] = useState<APIError | undefined>()
  const [success, setSuccess] = useState(false)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    if (error == null) return
    toaster.show(extractErrorMessage(error), {
      kind: 'negative',
    })
  }, [error])

  const doUpgradeRepoScope = async (): Promise<void> => {
    setPending(true)
    try {
      const { data }: AxiosResponse<UpgradeRepoScopeResponse> =
        await axios.post(getUpgradeRepoScopeKey())

      if (!isNonEmptyString(data.redirect)) {
        throw new Error('[U1] Request to upgrade failed')
      }
      const { url, query } = queryString.parseUrl(data.redirect) as {
        url: string
        // eslint-disable-next-line camelcase
        query: { redirect_uri: string }
      }

      const parsedRedirect = queryString.parseUrl(query.redirect_uri)
      const redirectTo = queryString.stringifyUrl({
        url,
        query: {
          ...query,
          redirect_uri: queryString.stringifyUrl({
            url: parsedRedirect.url,
            query: {
              redirectUrl: '/redirect',
            },
          }),
        },
      })
      setSuccess(true)
      window.location.href = redirectTo
    } catch (exc) {
      setError(exc as APIError)
      setSuccess(false)
    }
    setPending(false)
  }

  return {
    doUpgradeRepoScope,
    error,
    success,
    pending,
  }
}

export default useUpgradeRepoScope
