import { useEffect } from 'react'

const DEFAULT_FAVICON = `${process.env.PUBLIC_URL ?? ''}/favicon.svg`
const DEFAULT_ALT_FAVICON = `${process.env.PUBLIC_URL ?? ''}/favicon.ico`

export default function useFavicon(favicon?: string): void {
  const newFavicon = favicon ?? DEFAULT_FAVICON
  const newAltFavicon = favicon ?? DEFAULT_ALT_FAVICON

  // Helmet doesn't work for favicons.
  useEffect(() => {
    if (document == null) {
      return
    }

    const faviconEl: HTMLElement | null = document.getElementById('favicon')
    const altFaviconEl: HTMLElement | null =
      document.getElementById('alternate-favicon')

    if (faviconEl != null && altFaviconEl != null) {
      let el: HTMLLinkElement = faviconEl as HTMLLinkElement
      el.href = newFavicon
      el.remove()

      el = altFaviconEl as HTMLLinkElement
      el.href = newAltFavicon
      el.remove()

      document.head.appendChild(faviconEl)
      document.head.appendChild(altFaviconEl)
    }
  }, [newFavicon, newAltFavicon])
}
