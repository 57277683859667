import { type ReactElement, memo } from 'react'

import EmojiSpinner from 'components/emojiSpinner/emojiSpinner'

import styles from './styles.module.scss'

interface Props {
  message?: string
}

export function DeployingState({ message }: Props): ReactElement {
  return (
    <div className={styles.pendingState}>
      <EmojiSpinner />
      <p>{message}</p>
    </div>
  )
}

export default memo(DeployingState)
