import { type ReactElement } from 'react'
import { StatefulTooltip } from 'baseui/tooltip'
import { type PopoverOverrides } from 'baseui/popover'

import { ReactComponent as IconHelp } from 'assets/svg/icon_help.svg'

import styles from './appViewers.module.scss'

interface NumberOfViewersProps {
  count: number
}

const tooltipOverrides: PopoverOverrides = {
  Body: {
    style: {
      textAlign: 'center',
    },
  },
}

export default function NumberOfViewers({
  count,
}: NumberOfViewersProps): ReactElement {
  const formattedCount = new Intl.NumberFormat().format(count)
  return (
    <div className={styles.totalUniqueViewers}>
      {count > 0 ? (
        <>
          This app has had <strong>{formattedCount}</strong> unique{' '}
          {count > 1 ? 'viewers' : 'viewer'}.{' '}
          <StatefulTooltip
            accessibilityType="tooltip"
            content={
              <>
                This only counts viewers who viewed this app starting April
                2022.
                <br />
                Click this icon to learn more about how we count app viewers.
              </>
            }
            placement="top"
            popoverMargin={2}
            renderAll
            overrides={tooltipOverrides}
          >
            <a
              href="https://docs.streamlit.io/streamlit-community-cloud/manage-your-app/app-analytics#app-viewers"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.tooltipLink}
            >
              <IconHelp className={styles.iconHelp} />
            </a>
          </StatefulTooltip>
        </>
      ) : (
        'Nobody has viewed this app yet.'
      )}
    </div>
  )
}
