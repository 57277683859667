import { type ReactElement, type ReactNode } from 'react'
import classNames from 'classnames'
import _noop from 'lodash/noop'

import { StyledSpinnerNext as Spinner, SIZE } from 'baseui/spinner'

import styles from './styles.module.scss'

interface Props {
  children: ReactNode
  disabled?: boolean
  onClick?: () => void
  isLoading?: boolean
  className?: string
}

function LinkButton({
  children,
  onClick = _noop,
  disabled,
  isLoading,
  className,
}: Props): ReactElement {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        styles.linkButton,
        {
          [styles.isLoading]: isLoading,
        },
        className,
      )}
    >
      {children}
      {isLoading && (
        <span className={styles.spinner}>
          <Spinner $size={SIZE.small} />
        </span>
      )}
    </button>
  )
}

export default LinkButton
