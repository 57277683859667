import { mutate } from 'swr'

import { type AppStatusValue } from 'constants/apps'

import { type AppsKey, type IApp, type IAppRaw } from 'api/apps'
import { type AppStatusKey } from '../api/appStatus'

interface IMutateParams {
  app: IApp
  isOwner: boolean
  status: AppStatusValue
}

interface IMutateKeys {
  appStatusKey: AppStatusKey
  appsKey: AppsKey
}

async function mutateAppWithStatus(
  { appsKey, appStatusKey }: IMutateKeys,
  { app, status, isOwner }: IMutateParams,
): Promise<void> {
  // Optimistically set the status locally and ping server for latest status.
  await mutate(
    appStatusKey,
    {
      status,
      isOwner,
    },
    /* Do not revalidate yet */ false,
  )

  await mutate(
    appsKey,
    ({ apps }: { apps?: IAppRaw[] } = {}) => ({
      // By setting apps to undefined when no apps object exists (say
      // instead of setting it to []), we guarantee that the spinner will
      // show up if a user is loading the dashboard page for the first time
      // instead of showing the empty state while revalidating
      apps: apps?.map((curr) => {
        if (curr.appId === app.appId) {
          return {
            ...curr,
            status,
          }
        }

        return curr
      }),
    }),
    /* Do not revalidate yet */ false,
  )
}

export default mutateAppWithStatus
