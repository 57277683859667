import { createSelector } from '@reduxjs/toolkit'

import { type RootState } from 'reducers'
import { type State } from 'reducers/linkedRedirect'

export const baseSelector = (state: RootState): State => state.linkedRedirect
export const linkedRedirectSelector = createSelector(
  baseSelector,
  ({ hasSeenLinkedRedirect = false, hasSeenLinkedRedirectSetter = false }) => ({
    hasSeenLinkedRedirect,
    hasSeenLinkedRedirectSetter,
  }),
)
