import { type ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setRedirect } from 'reducers/redirect'
import { setLinkedRedirectSetter } from 'reducers/linkedRedirect'

import { useGitHubLogin } from 'api/login'
import PageSpinner from 'components/pageSpinner'

import { type LinkedUser } from 'api/user'
import ErrorPage from 'pages/error/errorPage'

function LoginRedirect({
  githubLoginID,
}: {
  githubLoginID?: string
}): ReactElement {
  const { doLogin, doRedirect, redirectUrl, error } = useGitHubLogin()

  useEffect(() => {
    if (redirectUrl) return

    doLogin()
  }, [redirectUrl, doLogin])

  useEffect(() => {
    if (!redirectUrl) return

    doRedirect(githubLoginID)
  }, [redirectUrl, doRedirect, githubLoginID])

  if (error) {
    return <ErrorPage details={error} />
  }

  return <PageSpinner overlay />
}

export function LinkedUserRedirect({
  linkedUser,
}: {
  linkedUser: LinkedUser
}): ReactElement {
  const dispatch = useDispatch()
  const location = useLocation()

  dispatch(
    setRedirect({
      url: location.pathname + location.search,
    }),
  )
  dispatch(
    setLinkedRedirectSetter({
      hasSeenLinkedRedirectSetter: true,
    }),
  )

  const { githubUser } = linkedUser
  return <LoginRedirect githubLoginID={githubUser} />
}

export default LoginRedirect
