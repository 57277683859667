import { type ReactElement } from 'react'

import { ReactComponent as IconInfo } from 'assets/svg/icon_info.svg'
import { ReactComponent as IconNext } from 'assets/svg/icon_arrow_forward_blue_700.svg'

import LinkButton from '../linkButton'
import styles from './notificationBox.module.scss'

interface Action {
  text: string
  onClick: () => void
}

export interface NotificationBoxProps {
  title?: string | ReactElement
  description: string | ReactElement
  action: Action
}

function NotificationBox({
  title,
  description,
  action,
}: NotificationBoxProps): ReactElement {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.notificationIcon}>
          <IconInfo />
        </div>
        <div className={styles.bodyContainer}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
          <div className={styles.actionContainer}>
            <LinkButton onClick={action.onClick} data-test-id={'action-button'}>
              <div className={styles.actionButton}>
                <div className={styles.actionText}>
                  <span>{action.text}</span>
                </div>
                <div className={styles.actionIcon}>
                  <IconNext></IconNext>
                </div>
              </div>
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationBox
