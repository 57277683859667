import useSWR from 'swr'

import { isSubdomainApp } from '../hooks/useUniqueSubdomainFeatures'

interface GetWorkspaceDomainsResponse {
  error?: string
  domains?: string[]
}

interface UseGetWorkspaceDomains {
  error?: string
  domains: string[]
  isPending: boolean
}

const useWorkspaceDomainsKey = (
  workspaceId: string,
  shouldFetch: boolean,
): string | null => {
  if (!shouldFetch) {
    return null
  }

  if (isSubdomainApp()) {
    return '/workspace/domains'
  }

  return `/workspace/${workspaceId}/domains`
}

function useGetWorkspaceDomains(
  workspaceId: string,
  shouldFetch = true,
): UseGetWorkspaceDomains {
  const key = useWorkspaceDomainsKey(workspaceId, shouldFetch)

  const { data, error, isLoading } = useSWR<GetWorkspaceDomainsResponse>(key)

  return {
    error,
    domains: data?.domains ?? [],
    isPending: shouldFetch && isLoading,
  }
}

export default useGetWorkspaceDomains
