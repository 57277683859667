import styles from './SmallDivider.module.scss'
import { type ReactElement, memo } from 'react'
import classNames from 'classnames'

interface ISmallDividerProps {
  className?: string
}

function SmallDivider({ className }: ISmallDividerProps): ReactElement {
  return <div className={classNames(styles.smallDivider, className)}></div>
}

export default memo(SmallDivider)
