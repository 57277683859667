import { type ReactNode, type ReactElement } from 'react'

import { StreamlitAppContextProvider } from 'context/streamlitAppContext'

interface Props {
  children: ReactNode
}

function StateProvider({ children }: Props): ReactElement {
  return <StreamlitAppContextProvider>{children}</StreamlitAppContextProvider>
}

export default StateProvider
