import { v4 as uuidv4, validate as validateUUID } from 'uuid'

const MACHINE_ID_KEY = 'machineId'

export function fetchOrCreateMachineIdFromStorage(): string | null {
  const storedMachineId = localStorage.getItem(MACHINE_ID_KEY)
  if (storedMachineId && validateUUID(storedMachineId)) {
    return storedMachineId
  }

  const generatedMachineId = uuidv4()
  try {
    localStorage.setItem(MACHINE_ID_KEY, generatedMachineId)
  } catch {
    return null
  }
  return generatedMachineId
}
