import { type ReactElement, type SyntheticEvent } from 'react'
import _noop from 'lodash/noop'
import { ModalHeader, ModalBody } from 'baseui/modal'

import { type DeletePayload } from 'reducers/modal'

import Button from 'components/button'
import Alert from 'components/alert'
import Modal from 'components/modal'

import { type IApp } from 'api/apps'
import useAppDelete from 'api/appDelete'
import { extractErrorMessage } from 'api/utils'

import styles from 'modals/styles.module.scss'

import { type APIError } from 'api/types'

interface ModalProps {
  onClose?: () => void
  onDelete?: () => void
}

interface BodyProps {
  app: IApp
  onClose: () => void
  onDelete: () => void
}

export function InnerBody({ app, onDelete, onClose }: BodyProps): ReactElement {
  const { doAppDelete, error, pending } = useAppDelete(app)

  const onSubmit = (evt: SyntheticEvent): void => {
    evt.preventDefault()
    doAppDelete(
      function onSuccess() {
        onDelete()
        onClose()
      },
      function onError(_err: APIError) {
        onClose()
      },
    )
  }

  return (
    <>
      <ModalHeader>
        <h2 className={styles.modalHeader}>Delete app</h2>
      </ModalHeader>
      <ModalBody>
        <div className={styles.body}>
          {error && (
            <Alert title="Error" message={extractErrorMessage(error)} />
          )}
          <p>
            Deleting your app is permanent and <b>can&apos;t be undone</b>.
            You&apos;ll need to redeploy from GitHub. Are you sure you want to
            continue?
          </p>
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.button}
            theme="destructive"
            type="submit"
            onClick={onSubmit}
            isLoading={pending}
            data-testid="delete-app-confirmation"
          >
            Delete
          </Button>
          <Button
            className={styles.closeButton}
            theme="cancel"
            type="submit"
            onClick={onClose}
            data-testid="delete-app-cancelation"
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </>
  )
}

function DeleteAppModal({
  onClose = _noop,
  onDelete = _noop,
}: ModalProps): ReactElement {
  return (
    <Modal<DeletePayload>
      name="DELETE"
      onClose={onClose}
      overrides={{
        Dialog: {
          style: () => ({
            borderRadius: '0rem',
          }),
        },
        Close: {
          style: () => ({
            color: '#a3a8b8',
            marginTop: '16px',
          }),
        },
      }}
    >
      {({ closeModal, modalPayload }) =>
        modalPayload?.app && (
          <InnerBody
            app={modalPayload.app}
            onDelete={onDelete}
            onClose={closeModal}
          />
        )
      }
    </Modal>
  )
}

export default DeleteAppModal
