import { memo, type ReactElement, type ReactNode } from 'react'

import useWorkspaceInStorage from './hooks/useWorkspaceInStorage'
import useOnBoardingFlowInStorage from './hooks/useOnBoardingFlowInStorage'

interface Props {
  children: ReactNode
}

function LocalStorageProvider({ children }: Props): ReactElement {
  useWorkspaceInStorage()
  useOnBoardingFlowInStorage()

  return <>{children}</>
}

export default memo(LocalStorageProvider)
