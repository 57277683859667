import { type ReactElement, useState, memo, useEffect, useRef } from 'react'

import EmojiSpinner from 'components/emojiSpinner/emojiSpinner'
import { ReactComponent as StreamlitLogo } from 'assets/svg/streamlit-logo.svg'

import styles from './styles.module.scss'

interface Props {
  message?: string
  appId: string
}

const MOBILE_THRESHOLD = 768

function DeployingStateRedesign({ message, appId }: Props): ReactElement {
  const appScreenshotURL = `https://storage.googleapis.com/s4a-prod-share-preview/default/st_app_screenshot_image/${appId}/Raw_App_Screenshot.png`
  const [isImageLoaded, setIsImageLoaded] = useState<boolean | null>(null)
  const [isMobile, setIsMobile] = useState(false)
  const image = useRef(new Image())
  if (image.current != null) {
    image.current.src = appScreenshotURL
  }

  useEffect(() => {
    const imgCurrent = image.current

    const handleImageLoad = (): void => {
      setIsImageLoaded(true)
    }

    const handleImageError = (): void => {
      setIsImageLoaded(false)
    }

    imgCurrent.addEventListener('load', handleImageLoad)
    imgCurrent.addEventListener('error', handleImageError)

    const handleResize = (): void => {
      setIsMobile(window.innerWidth < MOBILE_THRESHOLD)
    }

    handleResize() // Check initial viewport width

    window.addEventListener('resize', handleResize)

    return () => {
      imgCurrent.removeEventListener('load', handleImageLoad)
      imgCurrent.removeEventListener('error', handleImageError)

      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      className={
        isImageLoaded && !isMobile
          ? styles.pendingStateRedesignScreenshot
          : styles.pendingStateRedesignNoScreenshot
      }
    >
      <StreamlitLogo className={styles.logo} />
      <EmojiSpinner isAppLoadRedesign />
      <p>{message}</p>
      {isImageLoaded && !isMobile && (
        <div className={styles.appScreenshotBorderFrame}>
          <img ref={image} alt="App Screenshot" />
        </div>
      )}
    </div>
  )
}

export default memo(DeployingStateRedesign)
