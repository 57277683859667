import { type ReactElement, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  Modal as BaseModal,
  type ModalProps as BaseModalProps,
  type CLOSE_SOURCE,
  ROLE,
  SIZE,
} from 'baseui/modal'

import {
  type ModalName,
  type ModalPayload,
  closeModal,
  makeModalPayloadSelector,
  makeModalIsOpenSelector,
  makeModalRequireConfirmationSelector,
} from 'reducers/modal'

import { useSelector } from 'reducers'

export interface CloseModalArgs {
  closeSource?: CLOSE_SOURCE[keyof CLOSE_SOURCE]
  disableConfirmation?: boolean
}

interface ChildArgs<T> {
  closeModal: (args?: CloseModalArgs) => void
  modalPayload: T
}

type ModalProps<T> = BaseModalProps & {
  name: ModalName
  onConfirm?: () => void
  children: (args: ChildArgs<T>) => ReactElement | undefined
}

function Modal<T extends ModalPayload>({
  children,
  name,
  onConfirm,
  onClose,
  ...rest
}: ModalProps<T>): ReactElement {
  const selectModalIsOpen = useMemo(makeModalIsOpenSelector, [])
  const isOpen = useSelector((state) => selectModalIsOpen(state, name))

  const selectModalRequireConfirmation = useMemo(
    makeModalRequireConfirmationSelector,
    [],
  )
  const requireConfirmation = useSelector((state) =>
    selectModalRequireConfirmation(state, name),
  )

  const selectModalPayload = useMemo(makeModalPayloadSelector, [])
  const modalPayload = useSelector((state) =>
    selectModalPayload(state, name),
  ) as T

  const dispatch = useDispatch()

  const handleClose = (args: CloseModalArgs = {}): void => {
    const { disableConfirmation } = args
    if (!disableConfirmation && requireConfirmation && onConfirm) {
      // Do NOT close the modal. Show a confirmation modal instead.
      onConfirm()
    } else {
      // Do whatever is needed before closing the modal.
      if (onClose) {
        onClose({})
      }
      dispatch(closeModal({ name }))
    }
  }

  return (
    <BaseModal
      isOpen={isOpen}
      animate
      closeable
      autoFocus
      onClose={handleClose}
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Dialog: {
          style: () => ({
            borderRadius: '.5rem',
          }),
        },
      }}
      // unstable_ModalBackdropScroll will be implemented as default
      // behaviour in the future so adding it here will ensure
      // no change in behaviour when we upgrade.
      unstable_ModalBackdropScroll
      {...rest}
    >
      {children({ closeModal: handleClose, modalPayload })}
    </BaseModal>
  )
}

export default Modal
