import type React from 'react'
import { type ReactElement } from 'react'

import classnames from 'classnames'

import { type WarningBoxProps } from '../../../warningBox/warningBox'

import styles from './tabBodyPart.module.scss'

export interface TabBodyPartProps {
  title: string
  subTitle?: string
  body?: React.ReactNode
  warningComponent?: ReactElement<WarningBoxProps>
  classNames?: {
    root?: string
    header?: string
    warning?: string
  }
}

function TabBodyPart({
  title,
  subTitle,
  body,
  warningComponent,
  classNames,
}: TabBodyPartProps): ReactElement {
  return (
    <div className={classnames(styles.root, classNames?.root)}>
      <div className={classnames(styles.header, classNames?.header)}>
        <h3>{title}</h3>
        {subTitle && <h4>{subTitle}</h4>}
      </div>
      {body}
      {warningComponent && (
        <div className={classnames(styles.warning, classNames?.warning)}>
          {warningComponent}
        </div>
      )}
    </div>
  )
}

export default TabBodyPart
