import { type ReactElement, useEffect, useState } from 'react'

import { type InputOverrides, Input, type InputProps } from 'baseui/input'

import {
  Field,
  Form,
  Formik,
  type FormikHelpers,
  type FormikProps,
  useFormikContext,
} from 'formik'

import { Surface, UserRole } from 'constants/analytics'
import { type IApp } from 'api/apps'

import useTrackShare, { Operation } from 'hooks/analytics/useTrackShare'
import { useSetDeltaViewers } from 'api/viewers'

import Alert from 'components/alert'
import Button from 'components/button'

import {
  validateViewerEmails,
  formatTextInput,
} from 'modals/appSettings/components/sharingSettings'

import styles from './emailsInput.module.scss'

interface FormValues {
  emails: string
}

interface Props {
  app: IApp
  isOwner: boolean
}

let timeout: NodeJS.Timeout
const sleep = async (ms: number): Promise<unknown> =>
  await new Promise((resolve) => {
    timeout = setTimeout(resolve, ms)
  })

export function EmailsInputForm({ app, isOwner }: Props): ReactElement {
  const { error, isPending, doSetDeltaViewers } = useSetDeltaViewers(app)
  const trackShare = useTrackShare()

  const onSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>,
  ): Promise<void> => {
    if (values.emails === '' || error) {
      return
    }

    const emails = formatTextInput(values.emails)
    await doSetDeltaViewers({
      viewers: emails,
      domains: [],
    })

    const role = isOwner ? UserRole.Developer : UserRole.Viewer
    const appId = app.appId ?? ''
    const appWorkspaceName = app.owner ?? ''
    trackShare({
      surface: Surface.InAppShareMenu,
      role,
      emails,
      operation: Operation.AddViewers,
      appId,
      appWorkspaceName,
    })

    resetForm()
  }

  const FormBody = ({
    isValid,
    isValidating,
    values,
    isSubmitting,
    errors,
  }: FormikProps<FormValues>): ReactElement => {
    const [emailErrors, setEmailErrors] = useState<string | undefined>()

    useEffect((): (() => void) => {
      setEmailErrors('')
      if (!errors?.emails) {
        return () => false
      }
      clearTimeout(timeout)
      sleep(750).then(() => {
        setEmailErrors(errors.emails)
      })

      return (): void => {
        clearTimeout(timeout)
      }
    }, [errors])

    const buttonDisabled =
      values.emails === '' || isSubmitting || isValidating || !isValid
    return (
      <>
        <Form acceptCharset="UTF-8" className={styles.form}>
          <EmailsFormikField />
          <Button
            type="submit"
            isLoading={isPending}
            disabled={buttonDisabled}
            className={styles.inviteButton}
          >
            Invite
          </Button>
        </Form>
        {emailErrors && (
          <div className={styles.errorMessage}>
            {errors.emails?.split('\n').map((error) => (
              // Each error in errors is unique based on how validateViewerEmails
              // is implemented and is therefore used over index as key.
              <p key={error}>{error}</p>
            ))}
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {error && <Alert title="Error" message={error} />}

      <Formik
        initialValues={{ emails: '' }}
        onSubmit={onSubmit}
        validateOnChange
        validateOnBlur
      >
        {FormBody}
      </Formik>
    </>
  )
}

export const validateEmails = (emails: string): string | undefined => {
  const error = validateViewerEmails(formatTextInput(emails))
  if (error === '') {
    return undefined
  }
  return error
}

const emailsField = 'emails'
export function EmailsFormikField(): ReactElement {
  const formik = useFormikContext<FormValues>()

  return (
    <Field
      id={emailsField}
      name={emailsField}
      validate={validateEmails}
      error={formik.errors.emails}
      as={EmailsInput}
    />
  )
}

const inputOverrides: InputOverrides = {
  Root: {
    style: {
      borderBottomLeftRadius: '0.25rem',
      borderBottomRightRadius: '0px',
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0px',
      height: '100%',
    },
  },
}

function EmailsInput(props: InputProps): ReactElement {
  return (
    <div className={styles.inputField}>
      <Input
        type="text"
        overrides={inputOverrides}
        placeholder="Emails, comma separated"
        {...props}
      />
    </div>
  )
}
