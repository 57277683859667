import { memo, type ReactElement } from 'react'

import { ReactComponent as IconAlert } from 'assets/svg/icon_alert.svg'

import WarningBox from 'components/warningBox'

import styles from './styles.module.scss'

interface Props {
  title: string
  description: string
  className?: string
}

function deployLimitWarningBox({
  title,
  description,
  className,
}: Props): ReactElement {
  return (
    <div className={className}>
      <WarningBox
        icon={<IconAlert className={styles.warningIcon} />}
        title={<span className={styles.title}>{title}</span>}
        description={<span className={styles.description}>{description}</span>}
      />
    </div>
  )
}

export default memo(deployLimitWarningBox)
