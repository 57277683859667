import { forwardRef, type ReactElement, type Ref, memo } from 'react'
import classNames from 'classnames'

import { ReactComponent as IconHamburger } from 'assets/svg/icon_hamburger.svg'

import styles from './hamburgerMenu.module.scss'

function HamburgerMenu(
  {
    theme,
    disabled,
    className,
    ...rest
  }: { theme: 'dark' | 'light'; disabled?: boolean; className?: string },
  ref: Ref<HTMLButtonElement>,
): ReactElement {
  return (
    <button
      ref={ref}
      type="button"
      data-testid="app-menu"
      className={classNames(
        styles.button,
        styles[`button_${theme}`],
        {
          [styles.disabled]: disabled,
        },
        className,
      )}
      {...rest}
    >
      <IconHamburger className={styles.icon} />
    </button>
  )
}

export default memo(forwardRef(HamburgerMenu))
