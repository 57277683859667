const eventIds = {
  StartDeployTrigger: 'W00001',
  FailDeploy: 'W00002',
  FinishDeployTrigger: 'W00003',
  CompleteDeploy: 'W00004',
  SuccessFavoriteApp: 'W00005',
  FailFavoriteApp: 'W00006',
  MissingAppIdForAnalytics: 'W00007',
  CreateDevContainer: 'CreateDevContainer',
  CreateDevContainerError: 'CreateDevContainerError',
  CreateDevContainerSuccess: 'CreateDevContainerSuccess',
  CreateFromFork: 'CreateFromFork',
  CreateFromForkError: 'CreateFromForkError',
  CreateFromForkSuccess: 'CreateFromForkSuccess',
  CreateFromTemplate: 'CreateFromTemplate',
  CreateFromTemplateError: 'CreateFromTemplateError',
  CreateFromTemplateSuccess: 'CreateFromTemplateSuccess',
  EditInGithubCodespaces: 'EditInGithubCodespaces',
  EditInGithubCodespacesError: 'EditInGithubCodespacesError',
  EditInGithubCodespacesSuccess: 'EditInGithubCodespacesSuccess',
  GetAppTemplates: 'GetAppTemplates',
  GetAppTemplatesError: 'GetAppTemplatesError',
  GetAppTemplatesSuccess: 'GetAppTemplatesSuccess',
  ShareApp: 'share_app',
  AppRenderError: 'AppRenderError',
}

export default eventIds
