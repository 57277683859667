import { type ReactElement, type ReactNode } from 'react'
import { type BaseInputProps } from 'baseui/input'

import LinkButton from 'components/linkButton'

import styles from './field.module.scss'

export type FieldProps<T> = Omit<BaseInputProps<T>, 'error'> & {
  label?: ReactElement | string
  error?: string
  children?: ReactNode
  labelButton?: string
  onClickLabelButton?: () => void
}

function Field<T>({
  label,
  id,
  error,
  children,
  labelButton,
  onClickLabelButton,
}: FieldProps<T>): ReactElement {
  return (
    <div>
      <div className={styles.labelContainer}>
        {label && <label htmlFor={id}>{label}</label>}
        {labelButton && onClickLabelButton && (
          <LinkButton
            className={styles.alignRight}
            onClick={onClickLabelButton}
          >
            {labelButton}
          </LinkButton>
        )}
      </div>
      {children}
      {error && <p className={styles.error}>{error}</p>}
    </div>
  )
}

export default Field
