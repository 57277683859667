import { useCallback, useReducer } from 'react'
import useWebSocket, {
  type Options,
  type ReadyState,
} from 'react-use-websocket'
import last from 'lodash/last'

import { type IApp } from 'api/apps'
import useAppStatus from 'api/appStatus'
import { APP_STATUS } from 'constants/apps'
import { isSubdomainApp } from './useUniqueSubdomainFeatures'

export interface ILogLine {
  TimestampNanos: number
  Text: string
}

interface IUseLogs {
  logs: ILogLine[]
  readyState: ReadyState
  appendLog: (log: string) => void
}

interface State {
  logs: ILogLine[]
}

interface Action {
  type: 'ADD_LOGS'
  payload: { logs: ILogLine[] }
}

const initialState: State = {
  logs: [],
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'ADD_LOGS': {
      const logs = [...state.logs, ...action.payload.logs]
      return {
        ...state,
        logs,
      }
    }
    default: {
      return state
    }
  }
}

export function useWebSocketBaseURI(app: IApp): string {
  const hostedOnSubdomain = isSubdomainApp()

  const { location } = window
  const protocol = location.protocol === 'https:' ? 'wss:' : 'ws:'

  if (hostedOnSubdomain) {
    return `${protocol}//${app.host as string}/~`
  }

  const encodedBranch = encodeURIComponent(app.branch)
  const encodedMainModule = encodeURIComponent(app.mainModule)

  return `${protocol}//${app.host as string}/${app.owner}/${app.repo}/${encodedBranch}/${encodedMainModule}`
}

export function useWebSocketURI(app: IApp): string {
  const baseUri = useWebSocketBaseURI(app)

  return `${baseUri}/logstream`
}

export function getWebsocketOptions(
  dispatch: (action: Action) => void,
): Options {
  return {
    retryOnError: true,
    reconnectAttempts: 10,
    reconnectInterval: 1000,
    shouldReconnect: () => true,
    onMessage: (event) => {
      if (event.isTrusted && event.data != null) {
        const logs = JSON.parse(event.data)

        dispatch({
          type: 'ADD_LOGS',
          payload: {
            logs,
          },
        })
      }
    },
  }
}

function useLogs(app: IApp): IUseLogs {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { status } = useAppStatus(app.appId)
  const logStreamWebSocketURI = useWebSocketURI(app)

  // only connect to the websocket if we expect logstream to be up
  const connect =
    status != null &&
    ![APP_STATUS.UNKNOWN, APP_STATUS.CREATING, APP_STATUS.IS_SHUTDOWN].includes(
      status,
    )

  const { readyState } = useWebSocket(
    connect ? logStreamWebSocketURI : null,
    getWebsocketOptions(dispatch),
  )

  const lastLog = last(state.logs)
  const lastLogTimestampNs = (lastLog?.TimestampNanos ?? 0) + 1

  const appendLog = useCallback(
    (log: string): void => {
      dispatch({
        type: 'ADD_LOGS',
        payload: {
          logs: [
            {
              TimestampNanos: lastLogTimestampNs,
              Text: log,
            },
          ],
        },
      })
    },
    [dispatch, lastLogTimestampNs],
  )

  return {
    logs: state.logs,
    readyState,
    appendLog,
  }
}

export default useLogs
