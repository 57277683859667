import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type Surface } from 'constants/analytics'
import { type IApp } from 'api/apps'

import { type MenuItemId as UserSettingsMenuItemId } from 'modals/userSettings/userSettings'
import { type MenuItemId as AnalyticsMenuItemId } from 'modals/analytics/analytics'

export interface DeletePayload {
  app: IApp
}

export interface RestartPayload {
  app: IApp
}

export interface AppSettingsPayload {
  app: IApp
  surface: Surface
}

export interface AnalyticsPayload {
  activeNav?: AnalyticsMenuItemId
  app: IApp
}

export interface UsersSettingsPayload {
  activeNav?: UserSettingsMenuItemId
}

export interface ShareMenuPayload {
  app: IApp
  isOwner: boolean
}

export interface ConfirmationPayload {
  title: string
  message: string
  cancelButtonText?: string
  confirmButtonText?: string
  onConfirm: () => void
}

export type ModalPayload =
  | DeletePayload
  | RestartPayload
  | AppSettingsPayload
  | ConfirmationPayload
  | AnalyticsPayload
  | UsersSettingsPayload
  | undefined

type ModalActionPayload =
  | {
      name: 'CONFIRM'
      modalPayload?: ConfirmationPayload
    }
  | {
      name: 'DELETE'
      modalPayload?: DeletePayload
    }
  | {
      name: 'RESTART'
      modalPayload?: RestartPayload
    }
  | {
      name: 'APP_SETTINGS'
      modalPayload?: AppSettingsPayload
    }
  | {
      name: 'ADVANCED_SETTINGS'
    }
  | {
      name: 'ANALYTICS'
      modalPayload?: AnalyticsPayload
    }
  | {
      name: 'USER_SETTINGS'
      modalPayload?: UsersSettingsPayload
    }
  | {
      name: 'SIMPLE_LOGIN'
    }
  | {
      name: 'SHARE_MENU'
      modalPayload?: ShareMenuPayload
    }
  | {
      name: 'IN_BROWSER_EDITOR_LEGAL_DISCLAIMER'
    }

export interface ModalState<T = undefined> {
  isOpen: boolean
  requireConfirmation?: boolean
  modalPayload?: T
}

export interface State {
  CONFIRM: ModalState<ConfirmationPayload>
  DELETE: ModalState<DeletePayload>
  RESTART: ModalState<RestartPayload>
  APP_SETTINGS: ModalState<AppSettingsPayload>
  ADVANCED_SETTINGS: ModalState
  ANALYTICS: ModalState<AnalyticsPayload>
  USER_SETTINGS: ModalState<UsersSettingsPayload>
  SIMPLE_LOGIN: ModalState
  SHARE_MENU: ModalState
  IN_BROWSER_EDITOR_LEGAL_DISCLAIMER: ModalState
}

export type ModalName = keyof State

// initialize initialState with all modals closed
const initialState: State = {
  CONFIRM: {
    isOpen: false,
  },
  DELETE: {
    isOpen: false,
  },
  RESTART: {
    isOpen: false,
  },
  APP_SETTINGS: {
    isOpen: false,
  },
  ADVANCED_SETTINGS: {
    isOpen: false,
  },
  ANALYTICS: {
    isOpen: false,
  },
  USER_SETTINGS: {
    isOpen: false,
  },
  SIMPLE_LOGIN: {
    isOpen: false,
  },
  SHARE_MENU: {
    isOpen: false,
  },
  IN_BROWSER_EDITOR_LEGAL_DISCLAIMER: {
    isOpen: false,
  },
}

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<ModalActionPayload>) => {
      state[action.payload.name] = {
        isOpen: true,
      }

      if ('modalPayload' in action.payload) {
        state[action.payload.name].modalPayload = action.payload.modalPayload
      }
    },
    closeModal: (state, action: PayloadAction<ModalActionPayload>) => {
      state[action.payload.name] = {
        isOpen: false,
      }
    },
    confirmationOn: (state, action: PayloadAction<ModalActionPayload>) => {
      state[action.payload.name].requireConfirmation = true
    },
    confirmationOff: (state, action: PayloadAction<ModalActionPayload>) => {
      state[action.payload.name].requireConfirmation = false
    },
  },
})

export const { showModal, closeModal, confirmationOn, confirmationOff } =
  slice.actions

export default slice
