import { type ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'

import useUser from 'api/user'

import UserSettingsModal from 'modals/userSettings'
import AnalyticsModal from 'modals/analytics'
import { Link as StreamlitLink } from 'components/link'

import { warningInfoSelector } from 'reducers/warningInfo'

import { ReactComponent as IconMenu } from 'assets/svg/icon_menu.svg'
import { ReactComponent as StreamlitLogo } from 'assets/svg/streamlit-logo.svg'
import { ReactComponent as IconLinkOut } from 'assets/svg/icon_link_out_arrow.svg'
import styles from './styles.module.scss'
import WorkspaceSwitcher from 'components/workspaceSwitcher/workspaceSwitcher'
import Routes from 'constants/routes'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import { showModal } from 'reducers/modal'
import { useWorkspaceContext } from 'context/workspaceContext'
import Tooltip from 'components/tooltip'
import { analytics } from '../../autogen/proto/proto'
import { useAnalyticsClientContext } from '../../context/analyticsClientContext'
import { startStreamlitLogin } from '../../api/login'
import CreateAppClickEvent = analytics.CreateAppClickEvent
import Source = analytics.ProfilePageViewEvent.Source

interface IHeaderProps {
  hideUserSettings?: boolean
  hideAnalytics?: boolean
  disableLogoLink?: boolean
  showExternalLinksNavigation?: boolean
  hideMenu?: boolean
}

function Header({
  hideUserSettings = false,
  hideAnalytics = false,
  disableLogoLink = false,
  showExternalLinksNavigation = false,
  hideMenu = false,
}: IHeaderProps): ReactElement {
  const { user, workspaces, isPending: isUserDataLoading } = useUser()
  const currentWorkspace = useWorkspaceContext().Name() || ''
  const warningInfo = useSelector(warningInfoSelector)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const analyticsClient = useAnalyticsClientContext()
  const routesWithoutCreateAppButton: string[] = [
    Routes.NEW_APP,
    Routes.NEW_APP_DEPLOY_FROM_TEMPLATE,
    Routes.DEPLOY,
    Routes.DEPLOY_SAMPLE_APP,
    Routes.CREATE_FROM_TEMPLATE,
  ]
  const shouldCreateButtonBeDisplayed =
    user && !routesWithoutCreateAppButton.includes(location.pathname)
  const routesWithoutSigningButtons: string[] = [
    Routes.DASHBOARD,
    Routes.SIGNUP,
    Routes.LOGIN,
  ]
  const shouldSigningButtonsBeDisplayed =
    !user && !routesWithoutSigningButtons.includes(location.pathname)

  // NOTE: This is false in the case where there is no currentWorkspace, and we
  // keep the "New app" button enabled as it is repurposed to link to the
  // "link to GitHub" flow.
  const isViewOnlyWorkspace = !!workspaces?.find(
    (w) => w.name === currentWorkspace,
  )?.viewOnly

  const checkDisallowDeploy =
    (url: string): (() => boolean) =>
    (): boolean => {
      if (!user?.email && !user?.login) {
        dispatch(
          showModal({
            name: 'CONFIRM',
            modalPayload: {
              title: 'Warning',
              message: 'You must be connected to GitHub to deploy an app.',
              confirmButtonText: 'Connect to GitHub',
              onConfirm: () => {
                navigate(url)
              },
            },
          }),
        )

        return false
      }

      navigate(url)
      return true
    }

  const sendCreateAppClickAnalytics = async (): Promise<void> => {
    await analyticsClient.publish(
      CreateAppClickEvent.create({
        commonProperties: {
          eventName: 'analytics.CreateAppClickEvent',
        },
        principal: {
          canonicalUserId: user?.canonicalUserId,
        },
      }),
    )
  }

  const onCreateAppClick = async (): Promise<void> => {
    void sendCreateAppClickAnalytics()
    checkDisallowDeploy(Routes.NEW_APP)()
  }

  return isUserDataLoading ? (
    <></>
  ) : (
    <>
      {user && !hideUserSettings && <UserSettingsModal />}
      {user && !hideAnalytics && <AnalyticsModal />}
      <header className={styles.header}>
        {user && (
          <WorkspaceSwitcher
            buttonClassName={styles.navAction}
            hideAnalytics={hideAnalytics}
            hideUserSettings={hideUserSettings}
          />
        )}
        {!user && (
          <Link
            title="Streamlit home"
            to={Routes.DASHBOARD}
            className={classNames(
              {
                [styles.disableLink]: disableLogoLink,
              },
              'flex items-center px-6 border-r h-full hover:bg-black-200',
            )}
          >
            <h1>
              <StreamlitLogo />
            </h1>
          </Link>
        )}
        {showExternalLinksNavigation && (
          <nav className={styles.externalLinkNavigation}>
            <ExternalLinksNavigation
              listStyles={styles.externalLinkList}
              linkStyles={styles.externalLink}
            />
          </nav>
        )}
        {shouldSigningButtonsBeDisplayed && (
          <div className="flex flex-row md:px-6 gap-4 w-full justify-end">
            <button
              className="text-blue-700 hover:text-blue-600"
              onClick={(e) => {
                e.preventDefault()
                startStreamlitLogin()
              }}
            >
              Sign in
            </button>
            <button
              className="border rounded px-4 py-2 bg-blue-700 hover:bg-blue-600 text-white"
              onClick={(e) => {
                e.preventDefault()
                startStreamlitLogin({ signup: true })
              }}
            >
              Sign up
            </button>
          </div>
        )}
        {(showExternalLinksNavigation || !hideMenu) && (
          <StatefulPopover
            placement={PLACEMENT.bottomRight}
            content={
              <div className={styles.burgerMenuPopoverContainer}>
                {showExternalLinksNavigation && (
                  <ExternalLinksNavigation
                    listStyles="flex flex-col py-2 gap-2"
                    linkStyles={styles.burgerMenuListElement}
                  />
                )}
              </div>
            }
          >
            <div className={styles.openMenuButtonContainer}>
              <OpenMenuButton hasWarning={!!warningInfo?.warningText} />
            </div>
          </StatefulPopover>
        )}
        {shouldCreateButtonBeDisplayed && (
          <Tooltip
            content={
              isViewOnlyWorkspace
                ? `To deploy an app you must be an admin of at least one repo in the ${currentWorkspace} GitHub organization.`
                : ''
            }
            placement="bottomRight"
          >
            <div className={styles.createAppButtonWrapper}>
              <button
                data-testid="new-app-button"
                disabled={isViewOnlyWorkspace}
                className={styles.createAppButton}
                onClick={onCreateAppClick}
              >
                Create app
              </button>
            </div>
          </Tooltip>
        )}
      </header>
    </>
  )
}

function ExternalLinksNavigation({
  listStyles,
  linkStyles,
}: {
  listStyles: string
  linkStyles: string
}): ReactElement {
  const analyticsClient = useAnalyticsClientContext()
  const {
    user,
    workspaces,
    profilePageUserName,
    isPending: isUserDataLoading,
  } = useUser()
  const currentWorkspace = useWorkspaceContext().Name() || ''
  const location = useLocation()
  const userProfileMatch = useMatch(Routes.PROFILE_PAGE)
  const showProfilePage = !_isEmpty(profilePageUserName)
  const isSelectedPage = (path: string): boolean => {
    return location.pathname === path
  }
  const isForeignProfileSelected = (): boolean => {
    return (
      userProfileMatch !== null &&
      (_isNil(user) || userProfileMatch.params.userName !== profilePageUserName)
    )
  }
  const isViewOnlyWorkspace = !!workspaces?.find(
    (w) => w.name === currentWorkspace,
  )?.viewOnly
  const routesWithoutCreateAppButton: string[] = [
    Routes.NEW_APP,
    Routes.NEW_APP_DEPLOY_FROM_TEMPLATE,
    Routes.DEPLOY,
    Routes.DEPLOY_SAMPLE_APP,
    Routes.CREATE_FROM_TEMPLATE,
  ]
  const shouldCreateButtonBeDisplayed =
    user &&
    !isViewOnlyWorkspace &&
    !routesWithoutCreateAppButton.includes(location.pathname)

  const sendCreateAppClickAnalytics = async (): Promise<void> => {
    await analyticsClient.publish(
      CreateAppClickEvent.create({
        commonProperties: {
          eventName: 'analytics.CreateAppClickEvent',
        },
        principal: {
          canonicalUserId: user?.canonicalUserId,
        },
      }),
    )
  }
  return isUserDataLoading ? (
    <></>
  ) : (
    <div className={listStyles}>
      {user && (
        <StreamlitLink
          to={Routes.DASHBOARD}
          className={classNames(
            linkStyles,
            isSelectedPage(Routes.DASHBOARD)
              ? styles.navBarSectionSelected
              : undefined,
          )}
        >
          My apps
        </StreamlitLink>
      )}
      {showProfilePage && (
        <StreamlitLink
          to={`/user/${profilePageUserName ?? ''}`}
          className={classNames(
            linkStyles,
            isSelectedPage(`/user/${profilePageUserName ?? ''}`)
              ? styles.navBarSectionSelected
              : undefined,
          )}
          state={{ source: Source.NAV_BAR }}
        >
          My profile
        </StreamlitLink>
      )}
      <StreamlitLink
        to={Routes.EXPLORE}
        className={classNames(
          linkStyles,
          isSelectedPage(Routes.EXPLORE) || isForeignProfileSelected()
            ? styles.navBarSectionSelected
            : undefined,
        )}
      >
        Explore
      </StreamlitLink>

      <StreamlitLink
        external={true}
        to={'https://discuss.streamlit.io/'}
        className={classNames(linkStyles, 'gap-2')}
      >
        Discuss
        <IconLinkOut className={styles.iconAlert} />
      </StreamlitLink>

      {shouldCreateButtonBeDisplayed && (
        <div className={styles.newAppButton}>
          <div
            className="flex hover:bg-black-200"
            onClick={sendCreateAppClickAnalytics}
          >
            <StreamlitLink to={Routes.NEW_APP} className={linkStyles}>
              Create app
            </StreamlitLink>
          </div>
        </div>
      )}
    </div>
  )
}

function OpenMenuButton({ hasWarning }: { hasWarning: boolean }): ReactElement {
  return (
    <button aria-label="Open menu" className={styles.openMenuButton}>
      <IconMenu className={styles.menuIcon} />
    </button>
  )
}

export default Header
