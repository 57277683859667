import { createSlice, type Draft, type PayloadAction } from '@reduxjs/toolkit'

type EventProperties = Record<string, string | number | string[] | undefined>

enum SegmentMethods {
  page = 'page',
  track = 'track',
  trackLink = 'trackLink',
  trackForm = 'trackForm',
}

type SegmentEvent =
  | {
      method: SegmentMethods.track
      name: string
      properties?: EventProperties
    }
  | {
      method: SegmentMethods.trackLink
      name: string
      ref: HTMLElement
      properties?: EventProperties
    }
  | {
      method: SegmentMethods.trackForm
      name: string
      ref: HTMLFormElement
      properties?: EventProperties
    }
  | {
      method: SegmentMethods.page
      name: string
      properties?: EventProperties
    }

interface State {
  initialized: boolean
  pending: SegmentEvent[]
}

const initialState: State = {
  initialized: false,
  pending: [],
}

interface InitializeActionPayload {
  id: string
  login: string
  email: string
}

type EnqueueActionPayload = Omit<SegmentEvent, 'method'> & {
  method?: SegmentMethods
}

function sendEvent(event: SegmentEvent): void {
  switch (event.method) {
    case SegmentMethods.track: {
      analytics.track(event.name, event.properties)

      break
    }
    case SegmentMethods.trackLink: {
      analytics.trackLink(event.ref, event.name, event.properties)

      break
    }
    case SegmentMethods.trackForm: {
      analytics.trackForm(event.ref, event.name, event.properties)

      break
    }
    // case SegmentMethods.page: {
    //   analytics.page(event.name, event.properties)
    //
    //   break
    // }
    default: {
      break
    }
  }
}

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    initialize: (
      state: Draft<State>,
      action: PayloadAction<InitializeActionPayload>,
    ) => {
      if (state.initialized) return

      analytics.identify(action.payload.id, action.payload)

      state.initialized = true

      state.pending.forEach((event) => {
        sendEvent(event as SegmentEvent)
      })
    },
    enqueue: (
      state: Draft<State>,
      action: PayloadAction<EnqueueActionPayload>,
    ) => {
      const { payload } = action
      const event = {
        ...payload,
        method: payload.method || SegmentMethods.track,
      }

      if (state.initialized) {
        sendEvent(event as SegmentEvent)
      } else {
        state.pending.push(event as Draft<SegmentEvent>)
      }
    },
  },
})

export const { initialize, enqueue } = slice.actions

export default slice
