import { memo, type ReactElement, useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { OnboardingFlow, setStep } from 'reducers/onboardingFlow'

import LoginPage from './loginPage'
import { startStreamlitLogin } from '../../api/login'
import { useLocation } from 'react-router-dom'
import Routes from '../../constants/routes'

function LoginFlow(): ReactElement {
  const dispatch = useDispatch()
  const location = useLocation()

  const onClick = useCallback(() => {
    return () => {
      const isOnSignUpPage = location.pathname === Routes.SIGNUP

      dispatch(setStep({ name: OnboardingFlow.signupForm }))
      startStreamlitLogin({ signup: isOnSignUpPage })
    }
  }, [dispatch, location.pathname])

  return <LoginPage onClick={onClick} showSpinner={false} />
}

export default memo(LoginFlow)
