import useApps from 'api/apps'
import useUser from 'api/user'

import { useWorkspaceInfoFromContext } from 'context/workspaceContext'

import useWorkspacePermissions from 'hooks/useWorkspacePermissions'

import WorkspacePermissions from 'constants/workspacePermissions'

const FREE_PRIVATE_APPS = 1

export default function useFreeTierLimits(): boolean {
  const currentWorkspace = useWorkspaceInfoFromContext()
  const { apps } = useApps(currentWorkspace)

  const { workspaces } = useUser()
  const wsPerms = useWorkspacePermissions(workspaces)
  const canDeployUnlimitedPrivateApps = wsPerms.includes(
    WorkspacePermissions.FEATURE_UNLIMITED_PRIVATE_APPS,
  )

  if (canDeployUnlimitedPrivateApps) return false

  const numPrivateApps =
    apps?.filter(({ viewerAuthEnabled }) => viewerAuthEnabled)?.length ?? 0

  return numPrivateApps >= FREE_PRIVATE_APPS
}
