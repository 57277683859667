import { createSlice, type PayloadAction, type Draft } from '@reduxjs/toolkit'

export type Workspace = string

export interface State {
  selected?: Workspace
}

const initialState: State = {}

interface SelectActionPayload {
  workspace: Workspace
}

const slice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    select: (
      state: Draft<State>,
      action: PayloadAction<SelectActionPayload>,
    ) => {
      state.selected = action.payload.workspace
    },
  },
})

export const { select } = slice.actions

export default slice
