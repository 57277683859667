import { memo, type ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import _noop from 'lodash/noop'

import { useCustomSubdomainFeature } from 'hooks/useUniqueSubdomainFeatures'
import { type Surface } from 'constants/analytics'
import { type IApp } from 'api/apps'

import Modal, {
  type CloseModalArgs,
  SideNavigationBody,
} from 'components/modal'

import modalOverrides from 'modals/modalOverrides'

import {
  type AppSettingsPayload,
  closeModal,
  confirmationOff,
  type ModalName,
  showModal,
} from 'reducers/modal'

import SecretsBody from './components/secretsSettings'
import SharingBody from './components/sharingSettings'
import DomainManagementBody from './components/domainManagementSettings'

interface ModalProps {
  onClose?: (args?: CloseModalArgs) => void
  hideTab?: MenuItemId[]
}

interface InnerBodyProps {
  app: IApp
  surface: Surface
  name: ModalName
  onClose: (args?: CloseModalArgs) => void
  hideTab?: MenuItemId[]
}

interface TabsProps extends Omit<InnerBodyProps, 'hideTab'> {
  menuItems: MenuItems
}

export enum MenuItemId {
  Sharing = 'SHARING',
  Secrets = 'SECRETS',
  General = 'GENERAL',
}

export interface MenuItem {
  title: string
  itemId: MenuItemId
}
export type MenuItems = MenuItem[]

export function getMenuItems(
  customSubdomainsEnabled: boolean,
  hideTab?: MenuItemId[],
): MenuItems {
  const menuItems: MenuItems = []

  if (customSubdomainsEnabled) {
    menuItems.push({
      title: 'General',
      itemId: MenuItemId.General,
    })
  }

  menuItems.push(
    {
      title: 'Sharing',
      itemId: MenuItemId.Sharing,
    },
    {
      title: 'Secrets',
      itemId: MenuItemId.Secrets,
    },
  )

  if (hideTab?.length && customSubdomainsEnabled) {
    /**
     * If customSubdomain is enabled, we do not want to hide MenuItemId.General,
     * because customSubdomain functionality resides in General tab
     * TODO in the future we should consider renaming itemID for customSubdomain
     * TODO and/or refactor filtering functionality
     */
    const hideTabWithoutGeneral = hideTab.filter((menuItem) => {
      return menuItem !== MenuItemId.General
    })
    return menuItems.filter(
      (menuItem) =>
        menuItem.itemId === MenuItemId.General ||
        !hideTabWithoutGeneral.includes(menuItem.itemId),
    )
  }
  if (hideTab?.length) {
    return menuItems.filter((menuItem) => hideTab.includes(menuItem.itemId))
  }

  return menuItems
}

export const InnerBody = memo(function InnerBody({
  app,
  surface,
  name,
  onClose,
  hideTab,
}: InnerBodyProps): ReactElement {
  const customSubdomainsEnabled = useCustomSubdomainFeature()
  const menuItems = getMenuItems(customSubdomainsEnabled, hideTab)

  return (
    <Tabs
      app={app}
      surface={surface}
      name={name}
      onClose={onClose}
      menuItems={menuItems}
    />
  )
})

export const Tabs = memo(function Tabs({
  app,
  surface,
  name,
  onClose,
  menuItems,
}: TabsProps): ReactElement {
  const [activeNav, setActiveNav] = useState<MenuItemId>(menuItems[0].itemId)

  return (
    <SideNavigationBody<MenuItemId>
      title="App settings"
      activeNav={activeNav}
      setActiveNav={setActiveNav}
      menuItems={menuItems}
      modalName={name}
    >
      {activeNav === MenuItemId.General && (
        <DomainManagementBody app={app} name={name} onClose={onClose} />
      )}
      {activeNav === MenuItemId.Sharing && (
        <SharingBody
          app={app}
          surface={surface}
          name={name}
          onClose={onClose}
        />
      )}
      {activeNav === MenuItemId.Secrets && (
        <SecretsBody app={app} name={name} onClose={onClose} />
      )}
    </SideNavigationBody>
  )
})

function AppSettingsModal({
  onClose = _noop,
  hideTab,
}: ModalProps): ReactElement | null {
  const name = 'APP_SETTINGS'
  const dispatch = useDispatch()

  const onConfirm = (): void => {
    dispatch(
      showModal({
        name: 'CONFIRM',
        modalPayload: {
          title: 'You have unsaved changes',
          message: '',
          confirmButtonText: 'Discard',
          cancelButtonText: 'Go back',
          onConfirm: () => {
            onClose()
            dispatch(confirmationOff({ name }))
            dispatch(closeModal({ name }))
          },
        },
      }),
    )
  }

  return (
    <Modal<AppSettingsPayload>
      name={name}
      onConfirm={onConfirm}
      onClose={onClose}
      overrides={modalOverrides}
    >
      {({ closeModal, modalPayload }) =>
        modalPayload?.app && (
          <InnerBody
            app={modalPayload.app}
            surface={modalPayload.surface}
            name={name}
            onClose={closeModal}
            hideTab={hideTab}
          />
        )
      }
    </Modal>
  )
}

export default memo(AppSettingsModal)
