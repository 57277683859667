import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  type ICallbacks,
  type IMenuItem,
  MenuItemType,
} from 'hooks/useCoreCommunication'

import urls from 'constants/urls'
import { Pages, workspaceQueryParameterName } from 'constants/routes'
import { generateAppURL } from 'helpers/constantsHelpers'

import useAppContext, { hasUserWorkspacePermission } from 'api/appContext'

import { useWorkspaceContext } from 'context/workspaceContext'
import { PermissionModel } from '../../../../gen'
import { isSubdomainApp } from '../../../../hooks/useUniqueSubdomainFeatures'

interface IResponse {
  menuItems: IMenuItem[]
  menuCallbacks: ICallbacks
}

interface MenuItemParams {
  appId: string
  appOwner: string
  repository: string
  branch: string
  mainModule: string
}
export default function useMenuItems({
  appId,
  appOwner,
  repository,
  branch,
  mainModule,
}: MenuItemParams): IResponse {
  const navigate = useNavigate()
  const workspaceContext = useWorkspaceContext()

  const { context } = useAppContext(appId)
  const hasAccessToAppWorkspace = useMemo(() => {
    return hasUserWorkspacePermission(
      context?.user,
      PermissionModel.Workspace_ViewAccess,
    )
  }, [context])

  const menuItems: IMenuItem[] = useMemo((): IMenuItem[] => {
    const items: IMenuItem[] = []

    if (hasAccessToAppWorkspace) {
      items.push({
        type: MenuItemType.text,
        label: 'View all apps',
        key: 'viewAllApps',
      })
    }

    items.push({
      type: MenuItemType.text,
      label: 'About Streamlit Cloud',
      key: 'about',
    })

    return items
  }, [hasAccessToAppWorkspace])

  const appOnSubdomain = isSubdomainApp()
  const menuCallbacks: ICallbacks = useMemo(() => {
    const callbacks: ICallbacks = {}
    callbacks.about = () => {
      window.open(urls.cloudMarketing)
    }

    if (hasAccessToAppWorkspace) {
      callbacks.viewAllApps = () => {
        if (appOnSubdomain) {
          window.location.href = generateAppURL({
            section: Pages.DASHBOARD,
            searchParams: new URLSearchParams(
              `${workspaceQueryParameterName}=${workspaceContext.Name() || ''}`,
            ),
          })
        } else {
          navigate('/')
        }
      }
    }
    return callbacks
  }, [hasAccessToAppWorkspace, appOnSubdomain, navigate, workspaceContext])

  return {
    menuItems,
    menuCallbacks,
  }
}
