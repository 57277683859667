import { type ReactElement, useState } from 'react'

import Button from 'components/button/button'
import { TabBodyPart } from 'components/modal/components/tabBody'

import { DeleteAccountModal } from './DeleteAccountModal'
import { DELETE_ACCOUNT, ACCOUNT_DELETE_SUBTITLE } from './messages'

interface IDeleteAccountTabProps {
  email: string
  maybeLogin?: string
}
export function DeleteAccountTab({
  email,
  maybeLogin,
}: IDeleteAccountTabProps): ReactElement {
  /**
   * Render Delete Account Button and manage state of Delete Account Modal
   */

  const [isOpen, setIsOpen] = useState(false)

  return (
    <TabBodyPart
      title={DELETE_ACCOUNT}
      subTitle={ACCOUNT_DELETE_SUBTITLE}
      body={
        <>
          <Button
            theme="destructive_outline"
            onClick={() => {
              setIsOpen(true)
            }}
          >
            {DELETE_ACCOUNT}
          </Button>
          <DeleteAccountModal
            email={email}
            maybeLogin={maybeLogin}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </>
      }
    />
  )
}
