import { useState } from 'react'
import { mutate } from 'swr'

import { APP_STATUS } from 'constants/apps'

import { isSubdomainApp } from 'hooks/useUniqueSubdomainFeatures'

import { type APIError } from 'api/types'
import { useAppSecretsKey } from 'api/secrets'
import { useAppStatusKey } from 'api/appStatus'
import { type IApp } from 'api/apps'

import { useWorkspaceContext } from 'context/workspaceContext'
import { getAppsApiClient } from './apiV2'

export interface IUseAppDelete {
  pending: boolean
  error?: APIError
  doAppDelete: (
    onSuccess: () => void,
    onFailure: (error: APIError) => void,
  ) => Promise<void>
}

async function deleteApp(appId: string): Promise<void> {
  const client = getAppsApiClient()
  if (isSubdomainApp()) {
    await client.deleteAppFromSubdomain()
  } else {
    await client.deleteApp(appId)
  }
}

const useAppDelete = (app: IApp): IUseAppDelete => {
  const [error, setError] = useState<APIError | undefined>()
  const [pending, setPending] = useState(false)

  const appStatusKey = useAppStatusKey(app.appId)
  const secretsKey = useAppSecretsKey(app.appId)
  const workspaceContext = useWorkspaceContext()

  const doAppDelete = async (
    onSuccess: () => void,
    onFailure: (error: APIError) => void,
  ): Promise<void> => {
    setPending(true)

    let revertUpdate: (() => Promise<void>) | undefined
    try {
      // Optimistically set apps table locally and ping server for latest values.
      revertUpdate = await workspaceContext.DeleteApp(app)
      // Optimistically set the status locally and ping server for latest status.
      await mutate(
        appStatusKey,
        {
          status: APP_STATUS.DELETING,
          isOwner: true,
        },
        /* Do not revalidate */ false,
      )

      // Optimistically clear secrets locally and ping server for latest status.
      await mutate(
        secretsKey,
        {
          secrets: '',
        },
        /* Do not revalidate */ false,
      )

      await deleteApp(app.appId)

      setPending(false)

      onSuccess()
    } catch (exc) {
      setPending(false)
      setError(exc as APIError)
      onFailure(exc as APIError)
      if (revertUpdate !== undefined) {
        await revertUpdate()
      }
    }

    await workspaceContext.ForceUpdate()
    await Promise.all([mutate(appStatusKey), mutate(secretsKey)])
  }

  return {
    pending,
    error,
    doAppDelete,
  }
}

export default useAppDelete
