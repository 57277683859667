import queryString from 'query-string'
import axios, { type AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'

import { toaster } from 'baseui/toast'
import { type APIError } from 'api/types'
import { extractErrorMessage } from 'api/utils'
import { isNonEmptyString } from 'helpers/validation'
import useUser from './user'
import { type LimitedUserInfoModel } from '../gen'

interface BaseLoginResponse {
  redirectUrl?: string
}

export interface SuccessfulLoginResponse extends BaseLoginResponse {
  success: true
  user?: LimitedUserInfoModel
}

export interface FailedLoginResponse extends BaseLoginResponse {
  success: false
  error: string
}

export interface LoginOptions {
  signup?: boolean
}

export interface IUseLogin {
  pending: boolean
  redirectUrl?: string
  error?: APIError
  doLogin: (options?: LoginOptions) => Promise<void>
  doRedirect: (githubLoginID?: string) => void
}

const getStreamlitLoginKey = ({
  signup = false,
}: LoginOptions = {}): string => {
  if (signup) {
    return '/api/v1/login/sso?signup=true'
  }

  return '/api/v1/login/sso'
}

export function startStreamlitLogin(options?: LoginOptions): void {
  window.location.replace(getStreamlitLoginKey(options))
}

export const useGitHubLogin = (): IUseLogin => {
  const { setUser } = useUser()
  const [error, setError] = useState<APIError | undefined>()
  const [redirectUrl, setRedirectUrl] = useState('')
  const [pending, setPending] = useState(false)

  useEffect(() => {
    if (error == null) return

    toaster.show(extractErrorMessage(error), { kind: 'negative' })
  }, [error])

  const doLogin = useCallback(
    async (options?: LoginOptions): Promise<void> => {
      setPending(true)
      try {
        const {
          data,
        }: AxiosResponse<SuccessfulLoginResponse | FailedLoginResponse> =
          await axios.post('login')

        if (data.success && data.user?.login) {
          await setUser({
            user: {
              login: data.user?.login,
              email: data.user?.email,
            },
          })
        }

        if (data.redirectUrl) {
          setRedirectUrl(data.redirectUrl)
        }
      } catch (exc) {
        setError(exc as APIError)
      }

      setPending(false)
    },
    [setUser],
  )

  const doRedirect = useCallback(
    (githubLoginID?: string) => {
      if (!isNonEmptyString(redirectUrl)) return

      const { url, query } = queryString.parseUrl(redirectUrl)
      window.location.href = queryString.stringifyUrl({
        url,
        query: {
          ...query,

          // TODO: move this URL parsing to the backend. Issue #
          //
          // `login` suggests a specific GitHub account to use
          // https://docs.github.com/en/developers/apps/building-github-apps/identifying-and-authorizing-users-for-github-apps#parameters
          login: githubLoginID,
        },
      })
    },
    [redirectUrl],
  )

  return {
    pending,
    redirectUrl,
    doLogin,
    doRedirect,
    error,
  }
}
