import { type ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import trim from 'lodash/trim'
import debounce from 'lodash/debounce'
import classNames from 'classnames'
import FileSaver from 'file-saver'
import Ansi from 'ansi-to-react'
import { Resizable } from 're-resizable'
import { CSSTransition } from 'react-transition-group'
import { useLocalStorage } from 'react-use'

import { OverflowTooltip } from 'components/tooltip'

import TerminalMenu from 'components/terminalMenu'
import useEmbellishedLogs from 'hooks/useEmbellishedLogs'
import { type AppState } from 'pages/streamlitApp/common/appStateMachine'
import { type IApp } from 'api/apps'
import { type ILogLine } from 'hooks/useLogs'

import { showModal } from 'reducers/modal'
import { MenuItemId as AnalyticsMenuItemId } from 'modals/analytics/analytics'
import { openAnalyticsModal } from 'components/workspaceSwitcher/workspaceSwitcher'

import { ReactComponent as IconAlert } from 'assets/svg/icon_alert.svg'
import { ReactComponent as IconArrowLeft } from 'assets/svg/icon_arrow_left.svg'
import { ReactComponent as IconArrowRight } from 'assets/svg/icon_arrow_right.svg'

import { Surface, UserRole } from 'constants/analytics'
import { type IUser } from 'api/user'
import useTrackAnalytics from 'hooks/analytics/useTrackAnalytics'

import { useWorkspaceContext } from 'context/workspaceContext'

import styles from './styles.module.scss'

import './ansi-styles.css'

export interface Props {
  user?: IUser
  app: IApp
  appState: AppState
  hasError: boolean
  isCollapsed: boolean
  toggleCollapse: () => void
  doLogout?: () => void
  className?: string
  viewerAuthEnabled: boolean
}

// See https://www.mtu.edu/umc/services/digital/writing/characters-avoid/
const BAD_CHARS_RE = /[/\\#%&{}<>*? $!'":@+`|=]/g
// eslint-disable-next-line no-control-regex
const ANSI_COLOR_CODES_RE = /\x1b\[[0-9;]+m/g

function downloadLogs(appPath: string, logs: ILogLine[]): void {
  let logsText = logs.reduce((acc, log) => `${acc}\n${log.Text}`, '')
  logsText = logsText.replace(ANSI_COLOR_CODES_RE, '')
  const cleanName = trim(appPath.replace(BAD_CHARS_RE, '-'), '-')
  const currTime = new Date().toISOString()
  const fileName = `logs-${cleanName}-${currTime}.txt`
  const blob = new Blob([logsText], {
    type: 'text/plain;charset=utf-8',
  })

  FileSaver.saveAs(blob, fileName)
}

const SCROLL_DEBOUNCE_TIME = 200
const CALCULATE_WIDTH_DEBOUNCE_TIME = 200

function Terminal({
  user,
  app,
  appState,
  doLogout,
  hasError,
  isCollapsed,
  toggleCollapse,
  className,
  viewerAuthEnabled,
}: Props): ReactElement {
  const [sharedTerminalWidth, setSharedTerminalWidth] = useLocalStorage<number>(
    'terminalWidth',
    undefined,
  )

  const [width, setWidth] = useState(
    Math.min(window.innerWidth * 0.8, sharedTerminalWidth || 400),
  )
  const [isScrollAtBottom, setScrollAtBottom] = useState(true)

  const dispatch = useDispatch()
  const logs = useEmbellishedLogs(app, appState)
  const buttonEl = useRef<HTMLButtonElement>(null)
  const terminalEl = useRef<HTMLPreElement>(null)
  const terminalContainerEl = useRef<HTMLDivElement>(null)

  const currentWorkspaceName = useWorkspaceContext().Name()
  const trackAnalytics = useTrackAnalytics()

  const appPath = `${app.owner}/${app.repo}/${app.branch}/${app.mainModule}`

  const trackScrolling = debounce(() => {
    if (!terminalEl.current) return

    const { scrollHeight, scrollTop, clientHeight } = terminalEl.current
    const isAtBottom = Math.ceil(scrollHeight - scrollTop) === clientHeight

    setScrollAtBottom(isAtBottom)
  }, SCROLL_DEBOUNCE_TIME)

  useEffect(() => {
    if (!terminalEl.current || !isScrollAtBottom) return

    terminalEl.current.scrollTop = terminalEl.current.scrollHeight
  }, [isScrollAtBottom, logs, isCollapsed])

  useEffect(() => {
    const checkTerminalWidthState = debounce(() => {
      const { innerWidth } = window

      if (innerWidth < 400) setWidth(window.innerWidth)

      if (innerWidth > 400) setWidth(400)

      if (innerWidth > 1200) setWidth(window.innerWidth / 3)
    }, CALCULATE_WIDTH_DEBOUNCE_TIME)

    window.addEventListener('resize', checkTerminalWidthState)

    return () => {
      window.removeEventListener('resize', checkTerminalWidthState)
    }
  }, [])

  useEffect(() => {
    if (!terminalContainerEl.current || isCollapsed) return

    setWidth(terminalContainerEl.current.offsetWidth)
  }, [isCollapsed])

  return (
    <>
      <CSSTransition
        in={isCollapsed}
        timeout={300}
        mountOnEnter
        unmountOnExit
        nodeRef={buttonEl}
        classNames={{
          enter: styles.terminalButtonEnter,
          enterActive: styles.terminalButtonEnterActive,
          exit: styles.terminalButtonExit,
          exitActive: styles.terminalButtonExitActive,
        }}
      >
        <button
          className={classNames(
            styles.terminalButton,
            {
              [styles.hasError]: hasError,
            },
            className,
          )}
          onClick={toggleCollapse}
          ref={buttonEl}
          data-testid="manage-app-button"
        >
          {hasError && (
            <>
              <IconAlert />
              Manage app
            </>
          )}

          {!hasError && (
            <>
              <IconArrowLeft />
              Manage app
            </>
          )}
        </button>
      </CSSTransition>

      <CSSTransition
        in={!isCollapsed}
        timeout={300}
        mountOnEnter
        unmountOnExit
        nodeRef={terminalContainerEl}
        classNames={{
          enter: styles.terminalContainerEnter,
          enterActive: styles.terminalContainerEnterActive,
          exit: styles.terminalContainerExit,
          exitActive: styles.terminalContainerExitActive,
        }}
      >
        <Resizable
          data-testid="resizableComponent"
          enable={{
            top: false,
            right: false,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          style={{ position: undefined }}
          className={styles.terminalResizable}
          size={{ width, height: window.innerHeight }}
          onResizeStop={(e, direction, ref, d) => {
            setWidth(width + d.width)
            setSharedTerminalWidth(width + d.width)
          }}
        >
          <div
            ref={terminalContainerEl}
            className={classNames(
              styles.terminalContainer,
              {
                [styles.hasError]: hasError,
              },
              className,
            )}
          >
            <pre
              className={styles.logs}
              ref={terminalEl}
              onScroll={trackScrolling}
            >
              {/* @todo We'll have to change this when we have an actual unique id */}
              {/* eslint-disable react/no-array-index-key */}
              {logs.map((logLine, idx) => {
                return (
                  <p key={idx}>
                    <Ansi useClasses>{logLine.Text}</Ansi>
                  </p>
                )
              })}
              {/* eslint-enable react/no-array-index-key */}
            </pre>
            <div className={styles.statusBar}>
              {hasError && <IconAlert className={styles.iconAlert} />}

              <div className={styles.branch}>{app.branch}</div>

              <div className={styles.path}>
                <OverflowTooltip content={appPath}>{appPath}</OverflowTooltip>
              </div>

              <div className={styles.actions}>
                <TerminalMenu
                  user={user}
                  onLogout={doLogout}
                  onDownloadLog={() => {
                    downloadLogs(appPath, logs)
                  }}
                  onDelete={() => {
                    dispatch(
                      showModal({
                        name: 'DELETE',
                        modalPayload: { app },
                      }),
                    )
                  }}
                  onRebuild={() => {
                    dispatch(
                      showModal({
                        name: 'RESTART',
                        modalPayload: { app },
                      }),
                    )
                  }}
                  openAnalytics={() => {
                    trackAnalytics({
                      surface: Surface.CloudLogsAppAnalytics,
                      tab: AnalyticsMenuItemId.AppViewers,
                      workspaceName: currentWorkspaceName,
                      appId: app.appId,
                      role: app.viewOnly ? UserRole.Viewer : UserRole.Developer,
                    })
                    openAnalyticsModal(dispatch, {
                      activeNav: AnalyticsMenuItemId.AppViewers,
                      app,
                    })
                  }}
                  openSettings={() => {
                    dispatch(
                      showModal({
                        name: 'APP_SETTINGS',
                        modalPayload: {
                          app: {
                            ...app,
                            viewerAuthEnabled,
                          },
                          surface: Surface.CloudLogsAppSettings,
                        },
                      }),
                    )
                  }}
                />

                <button onClick={toggleCollapse}>
                  <IconArrowRight />
                </button>
              </div>
            </div>
          </div>
        </Resizable>
      </CSSTransition>
    </>
  )
}

export default Terminal
