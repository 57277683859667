export const ADJECTIVES = [
  'Anonymous',
  'Unknown',
  'Mysterious',
  'Enigmatic',
  'Hidden',
  'Secret',
  'Secretive',
  'Disappearing',
  'Unseen',
  'Covert',
  'Stealthy',
  'Undercover',
  'Shy',
  'Sneaky',
  'Cloaked',
  'Undisclosed',
  'Veiled',
  'Shadowy',
  'Abstruse',
  'Incognito',
  'Masked',
  'New',
  'Flying',
  'Floating',
  'Levitating',
  'Aerial',
  'Hovering',
  'Soaring',
  'Inflatable',
  'Drifting',
  'Gliding',
  'Fluttering',
  'Rising',
  'Ascending',
  'Climbing',
  'Spiraling',
  'Skyrocketing',
  'Surging',
]

export const NOUNS = [
  'Balloon',
  'Zeppelin',
  'Glider',
  'Dirigible',
  'Airship',
  'Aerostat',
  'Blimp',
  'Gondola',
  'Autogyro',
  'Ornithopter',
  'Aerodyne',
  'Cloud',
  'Bubble',
  'Float',
  'Helium',
  'Mist',
  'Cirrus',
  'Cirrocumulus',
  'Cirrostratus',
  'Altocumulus',
  'Altostratus',
  'Nimbostratus',
  'Stratocumulus',
  'Stratus',
  'Cumulus',
  'Cumulonimbus',
  'Biscuit',
  'Bread',
  'Bagel',
  'Bread Roll',
  'Bun',
  'Flatbread',
  'Muffin',
  'Brownie',
  'Cake',
  'Cookie',
  'Pastry',
  'Pie',
  'Tart',
  'Torte',
  'Danish',
  'Naan',
  'Paratha',
  'Pineapple Cake',
  'Mooncake',
  'Toast',
  'Cornbread',
  'Roll',
  'Sourdough',
  'Pita',
  'Brioche',
  'Ciabatta',
  'Focaccia',
  'Baguette',
  'Challah',
  'Pretzel',
  'Tortilla',
]
