import { type ReactElement } from 'react'
import { useStyletron } from 'styletron-react'
import { Notification } from 'baseui/notification'

import { ReactComponent as IconAlert } from 'assets/svg/icon_alert.svg'

import styles from '../../linkedAccounts.module.scss'

import { ACCOUNT_DELETED_WARNING } from './messages'

export function DeleteAccountNotification(): ReactElement {
  const [css] = useStyletron()

  return (
    <Notification
      kind="negative"
      overrides={{
        Body: {
          style: () => ({
            width: '100%',
          }),
        },
      }}
    >
      <div
        className={css({
          display: 'flex',
        })}
      >
        <IconAlert className={styles.iconAlert} />

        <p className={styles.notificationText}>{ACCOUNT_DELETED_WARNING}</p>
      </div>
    </Notification>
  )
}
