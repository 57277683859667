import { type ReactElement, useCallback, useEffect, memo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'

import { type IApp } from 'api/apps'

import { ReactComponent as ExplodingHead } from 'assets/svg/emoji_u1f92f.svg'

import { StyledLink } from 'components/link'
import Page from 'components/page'
import Button from 'components/button'
import LinkButton from 'components/linkButton'

import urls from 'constants/urls'

import { isVersionWithMemoryLeak } from 'helpers/streamlitVersions'

import { showModal } from 'reducers/modal'
import { enqueue } from 'reducers/analytics'

import styles from './styles.module.scss'

interface Props {
  app: IApp
  isOwner: boolean
  isPrivateRepo: boolean
  streamlitVersion?: string
}

const HasMemoryLeakContent = ({
  app,
  streamlitVersion,
}: {
  app: IApp
  streamlitVersion?: string
}): ReactElement => (
  <p className={styles.bodyText}>
    It&apos;s using too much memory! But there&apos;s an easy solution:
    upgrading the Streamlit version of this app from {streamlitVersion} to the
    latest release, which{' '}
    <StyledLink external to={urls.memoryLeakReleaseNotes}>
      makes apps use less memory.
    </StyledLink>{' '}
  </p>
)

const NoMemoryLeakContent = ({
  app,
  isOwner,
}: {
  app: IApp
  isOwner: boolean
}): ReactElement => {
  const dispatch = useDispatch()
  const rebootApp = useCallback(() => {
    dispatch(
      showModal({
        name: 'RESTART',
        modalPayload: { app },
      }),
    )
  }, [dispatch, app])

  return (
    <p className={styles.bodyText}>
      It&apos;s using too much memory! A few possible solutions:
      <div className={styles.container}>
        <ul>
          <li>
            <StyledLink external to={urls.upgradeStVersionInstructions}>
              Upgrade the Streamlit version
            </StyledLink>{' '}
            of this app to the latest release.
          </li>
          <li>
            <StyledLink external to={urls.resourceLimits}>
              Follow these tips
            </StyledLink>{' '}
            to make the app use less memory.
          </li>
          {isOwner && (
            <li>
              <LinkButton onClick={rebootApp}> Reboot the app </LinkButton> to
              clear the memory. The app might go over its resource limits again,
              though!
            </li>
          )}
          <li>
            <StyledLink external to={urls.resourceIncreaseForm}>
              Fill out this form
            </StyledLink>{' '}
            if you have a good-for-the-world use case and need more resources.
          </li>
        </ul>
      </div>
    </p>
  )
}

const UpgradeInstructionsButton = (): ReactElement => {
  const viewUpgradeInstructions = useCallback(() => {
    window.open(urls.upgradeStVersionInstructions)
  }, [])

  return (
    <Button className={styles.action} onClick={viewUpgradeInstructions}>
      View upgrade instructions
    </Button>
  )
}

const NotifyAppDevButton = ({ app }: { app: IApp }): ReactElement => {
  const openNewIssuePage = useCallback((): void => {
    const newIssueUrl = `https://github.com/${app.owner}/${app.repo}/issues/new`
    const appURL = window.location.origin + app.url
    const title = encodeURIComponent('Over resource limits on Streamlit Cloud')
    const description = encodeURIComponent(
      [
        'Hey there :wave: Just wanted to let you know that',
        `[your app on Streamlit Cloud deployed from this repo](${appURL}) has`,
        'gone over its resource limits. Access to the app is temporarily limited.',
        'Visit the app to see more details and possible solutions.',
      ].join(' '),
    )
    const queryParams = `title=${title}&body=${description}`

    window.open(`${newIssueUrl}?${queryParams}`)
  }, [app.owner, app.repo, app.url])

  return (
    <Button className={styles.action} onClick={openNewIssuePage}>
      Notify app developer
    </Button>
  )
}

function ResourceBusy({
  app,
  isOwner,
  isPrivateRepo,
  streamlitVersion,
}: Props): ReactElement {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      enqueue({
        name: 'app_over_capacity',
      }),
    )
  }, [dispatch])

  const versionHasMemoryLeak = isVersionWithMemoryLeak(streamlitVersion)

  const buttonOrDivider = (): ReactElement => {
    if (isOwner) {
      return versionHasMemoryLeak ? (
        <UpgradeInstructionsButton />
      ) : (
        <hr className={styles.smallDivider} />
      )
    }

    return isPrivateRepo ? (
      <hr className={styles.smallDivider} />
    ) : (
      <NotifyAppDevButton app={app} />
    )
  }

  return (
    <Page header={false}>
      <Helmet>
        <title>Over capacity</title>
      </Helmet>
      <span className={styles.explodingHeadEmoji}>
        <ExplodingHead />
      </span>
      <h1 className={styles.heading}>
        This app has gone over its resource limits.
      </h1>

      {versionHasMemoryLeak ? (
        <HasMemoryLeakContent app={app} streamlitVersion={streamlitVersion} />
      ) : (
        <NoMemoryLeakContent app={app} isOwner={isOwner} />
      )}

      {buttonOrDivider()}

      <div className={styles.contactSupport}>
        <p>
          If you need help,{' '}
          <StyledLink
            external
            to={urls.forumBasePath}
            className={styles.mailTo}
          >
            ask in the forum
          </StyledLink>{' '}
          or{' '}
          <StyledLink
            external
            to={urls.snowflakeSupportLink}
            className={styles.mailTo}
          >
            contact support
          </StyledLink>
          .
        </p>
      </div>
    </Page>
  )
}

export default memo(ResourceBusy)
