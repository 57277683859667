import axios, { type AxiosResponse } from 'axios'

import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { toaster } from 'baseui/toast'

import queryString from 'query-string'

import { setRedirect } from 'reducers/redirect'

import { type APIError } from 'api/types'
import { extractErrorMessage } from 'api/utils'
import { isNonEmptyString } from 'helpers/validation'

interface IDisconnectResponse {
  success: boolean
  error?: string
  redirectUrl?: string
}

interface IUseDisconnect {
  pending: boolean
  success?: boolean
  error?: APIError
  doDisconnect: () => Promise<void>
}

export const getDisconnectKey = (): string => 'disconnect'

const useDisconnect = (): IUseDisconnect => {
  const location = useLocation()
  const dispatch = useDispatch()

  const [pending, setPending] = useState(false)
  const [success, setSuccess] = useState<boolean>()
  const [error, setError] = useState<APIError | undefined>()

  useEffect(() => {
    if (error == null) return

    toaster.show(extractErrorMessage(error), { kind: 'negative' })
  }, [error])

  const doDisconnect = useCallback(async (): Promise<void> => {
    setPending(true)

    try {
      const { data }: AxiosResponse<IDisconnectResponse> = await axios.post(
        getDisconnectKey(),
        { LogoutMode: 'github' },
      )

      if (isNonEmptyString(data.redirectUrl)) {
        const url = data.redirectUrl // capture the value for the callback closure
        const callback = (): void => {
          window.location.href = url
        }

        // set redirect after disconnect to return to current location with showUserSettings enabled
        const { query } = queryString.parseUrl(location.search)
        dispatch(
          setRedirect({
            callback,
            url: queryString.stringifyUrl({
              url: location.pathname,
              query: {
                ...query,
                showUserSettings: 'true',
              },
            }),
          }),
        )
      } else {
        setSuccess(false)
        setPending(false)
      }

      setSuccess(data.success)
    } catch (exc) {
      setSuccess(false)
      setError(exc as APIError)
      setPending(false)
    }
  }, [dispatch, location])

  return {
    pending,
    success,
    error,
    doDisconnect,
  }
}

export default useDisconnect
