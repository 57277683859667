import { type ReactElement, memo } from 'react'

import TabBody from 'components/modal/components/tabBody/tabBody'
import TabBodyPart from 'components/modal/components/tabBody/tabBodyPart'
import Badge from 'components/modal/components/tabBody/badge'

import { StyledLink } from 'components/link'

import urls from 'constants/urls'

import styles from './support.module.scss'

function SupportBody(): ReactElement {
  return (
    <TabBody>
      <TabBodyPart
        title="General Support"
        body={
          <div className={styles.container}>
            <p>
              For help using Streamlit, deploying apps, or answering general
              questions see:{' '}
            </p>
            <ul>
              <li>
                <StyledLink external to={urls.documentationMain}>
                  Documentation
                </StyledLink>
              </li>
              <li>
                <div className={styles.liContainer}>
                  <StyledLink external to={urls.forumBasePath}>
                    Forums at discuss.streamlit.io
                  </StyledLink>
                  <Badge icon="🥇" title="Highly recommended!" color="orange" />
                </div>
              </li>
            </ul>
          </div>
        }
      />
      <TabBodyPart
        title="Account Support"
        body={
          <p>
            If you need help deleting an app, or have specific account requests
            contact{' '}
            <StyledLink external to={urls.snowflakeCommunityForumLink}>
              {urls.snowflakeSupport}
            </StyledLink>
            .
          </p>
        }
      />
      <TabBodyPart
        title="Legal"
        body={
          <div className={styles.container}>
            <p>
              We’ve assembled these documents for your reference. From time to
              time this list may change as we update these documents and create
              others to further serve the community.
              <ul>
                <li>
                  <div className={styles.liContainer}>
                    <StyledLink external to={urls.termsOfUse}>
                      Terms of Service
                    </StyledLink>
                  </div>
                </li>
                <li>
                  <div className={styles.liContainer}>
                    <StyledLink external to={urls.privacyPolicy}>
                      Privacy Notice
                    </StyledLink>
                  </div>
                </li>
              </ul>
            </p>
          </div>
        }
      />
    </TabBody>
  )
}

export default memo(SupportBody)
