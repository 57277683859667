import { useMemo } from 'react'

import type WorkspacePermissions from 'constants/workspacePermissions'
import { useWorkspaceContext } from 'context/workspaceContext'

import { type IWorkspace } from 'api/user'

/*
useWorkspacePermissions outputs the currently selected workspace's permissions
*/
export default function useWorkspacePermissions(
  workspaces: IWorkspace[] | undefined,
): WorkspacePermissions[] {
  const currentWorkspace = useWorkspaceContext().Name()

  return useMemo(
    () =>
      workspaces?.find((w) => w.name === currentWorkspace)?.permissions ?? [],
    [currentWorkspace, workspaces],
  )
}
