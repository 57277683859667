/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const userpermissions = $root.userpermissions = (() => {

    /**
     * Namespace userpermissions.
     * @exports userpermissions
     * @namespace
     */
    const userpermissions = {};

    /**
     * UserPermission enum.
     * @name userpermissions.UserPermission
     * @enum {number}
     * @property {number} UNKNOWN_PERMISSION=0 UNKNOWN_PERMISSION value
     * @property {number} ROLE_S4T=1 ROLE_S4T value
     * @property {number} FEATURE_PRIVATE_REPO=2 FEATURE_PRIVATE_REPO value
     * @property {number} ROLE_ENTHUSIAST=7 ROLE_ENTHUSIAST value
     * @property {number} USER_BLOCKED=10 USER_BLOCKED value
     * @property {number} ROLE_STREAMLIT_INTERNAL_USER=12 ROLE_STREAMLIT_INTERNAL_USER value
     * @property {number} FEATURE_GITHUB_REPO_OAUTH_SCOPE=14 FEATURE_GITHUB_REPO_OAUTH_SCOPE value
     * @property {number} EXCLUDED_FROM_MINING_BLOCK=17 EXCLUDED_FROM_MINING_BLOCK value
     * @property {number} SUSPICIOUS_ACCOUNT=18 SUSPICIOUS_ACCOUNT value
     * @property {number} FEATURE_EXEC_PLANE_MIGRATION_CLUSTER=19 FEATURE_EXEC_PLANE_MIGRATION_CLUSTER value
     * @property {number} USER_UNBLOCKED_1=20 USER_UNBLOCKED_1 value
     * @property {number} USER_UNBLOCKED_2=21 USER_UNBLOCKED_2 value
     */
    userpermissions.UserPermission = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_PERMISSION"] = 0;
        values[valuesById[1] = "ROLE_S4T"] = 1;
        values[valuesById[2] = "FEATURE_PRIVATE_REPO"] = 2;
        values[valuesById[7] = "ROLE_ENTHUSIAST"] = 7;
        values[valuesById[10] = "USER_BLOCKED"] = 10;
        values[valuesById[12] = "ROLE_STREAMLIT_INTERNAL_USER"] = 12;
        values[valuesById[14] = "FEATURE_GITHUB_REPO_OAUTH_SCOPE"] = 14;
        values[valuesById[17] = "EXCLUDED_FROM_MINING_BLOCK"] = 17;
        values[valuesById[18] = "SUSPICIOUS_ACCOUNT"] = 18;
        values[valuesById[19] = "FEATURE_EXEC_PLANE_MIGRATION_CLUSTER"] = 19;
        values[valuesById[20] = "USER_UNBLOCKED_1"] = 20;
        values[valuesById[21] = "USER_UNBLOCKED_2"] = 21;
        return values;
    })();

    return userpermissions;
})();

export const workspacepermissions = $root.workspacepermissions = (() => {

    /**
     * Namespace workspacepermissions.
     * @exports workspacepermissions
     * @namespace
     */
    const workspacepermissions = {};

    /**
     * WorkspacePermission enum.
     * @name workspacepermissions.WorkspacePermission
     * @enum {number}
     * @property {number} UNKNOWN_PERMISSION=0 UNKNOWN_PERMISSION value
     * @property {number} ROLE_S4T=1 ROLE_S4T value
     * @property {number} FEATURE_UNLIMITED_APPS=6 FEATURE_UNLIMITED_APPS value
     * @property {number} ROLE_ENTHUSIAST=7 ROLE_ENTHUSIAST value
     * @property {number} ROLE_STREAMLIT_INTERNAL_WORKSPACE=12 ROLE_STREAMLIT_INTERNAL_WORKSPACE value
     * @property {number} FEATURE_NO_APP_SPINDOWN=13 FEATURE_NO_APP_SPINDOWN value
     * @property {number} FEATURE_DOMAIN_ALLOWLISTING=16 FEATURE_DOMAIN_ALLOWLISTING value
     * @property {number} FEATURE_UNIQUE_APP_SUBDOMAINS=18 FEATURE_UNIQUE_APP_SUBDOMAINS value
     * @property {number} FEATURE_FREE_TIER_ENFORCEMENT=20 FEATURE_FREE_TIER_ENFORCEMENT value
     * @property {number} PRIVATE_WORKSPACE=21 PRIVATE_WORKSPACE value
     * @property {number} FEATURE_UNLIMITED_PRIVATE_APPS=22 FEATURE_UNLIMITED_PRIVATE_APPS value
     * @property {number} FEATURE_CUSTOM_APP_SUBDOMAINS=23 FEATURE_CUSTOM_APP_SUBDOMAINS value
     * @property {number} FEATURE_APP_LOAD_REDESIGN=24 FEATURE_APP_LOAD_REDESIGN value
     */
    workspacepermissions.WorkspacePermission = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_PERMISSION"] = 0;
        values[valuesById[1] = "ROLE_S4T"] = 1;
        values[valuesById[6] = "FEATURE_UNLIMITED_APPS"] = 6;
        values[valuesById[7] = "ROLE_ENTHUSIAST"] = 7;
        values[valuesById[12] = "ROLE_STREAMLIT_INTERNAL_WORKSPACE"] = 12;
        values[valuesById[13] = "FEATURE_NO_APP_SPINDOWN"] = 13;
        values[valuesById[16] = "FEATURE_DOMAIN_ALLOWLISTING"] = 16;
        values[valuesById[18] = "FEATURE_UNIQUE_APP_SUBDOMAINS"] = 18;
        values[valuesById[20] = "FEATURE_FREE_TIER_ENFORCEMENT"] = 20;
        values[valuesById[21] = "PRIVATE_WORKSPACE"] = 21;
        values[valuesById[22] = "FEATURE_UNLIMITED_PRIVATE_APPS"] = 22;
        values[valuesById[23] = "FEATURE_CUSTOM_APP_SUBDOMAINS"] = 23;
        values[valuesById[24] = "FEATURE_APP_LOAD_REDESIGN"] = 24;
        return values;
    })();

    return workspacepermissions;
})();

export const analytics = $root.analytics = (() => {

    /**
     * Namespace analytics.
     * @exports analytics
     * @namespace
     */
    const analytics = {};

    analytics.TraceContext = (function() {

        /**
         * Properties of a TraceContext.
         * @memberof analytics
         * @interface ITraceContext
         * @property {string|null} [source] TraceContext source
         * @property {string|null} [traceId] TraceContext traceId
         * @property {string|null} [spanId] TraceContext spanId
         * @property {string|null} [parentSpanId] TraceContext parentSpanId
         */

        /**
         * Constructs a new TraceContext.
         * @memberof analytics
         * @classdesc Represents a TraceContext.
         * @implements ITraceContext
         * @constructor
         * @param {analytics.ITraceContext=} [properties] Properties to set
         */
        function TraceContext(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TraceContext source.
         * @member {string} source
         * @memberof analytics.TraceContext
         * @instance
         */
        TraceContext.prototype.source = "";

        /**
         * TraceContext traceId.
         * @member {string} traceId
         * @memberof analytics.TraceContext
         * @instance
         */
        TraceContext.prototype.traceId = "";

        /**
         * TraceContext spanId.
         * @member {string} spanId
         * @memberof analytics.TraceContext
         * @instance
         */
        TraceContext.prototype.spanId = "";

        /**
         * TraceContext parentSpanId.
         * @member {string} parentSpanId
         * @memberof analytics.TraceContext
         * @instance
         */
        TraceContext.prototype.parentSpanId = "";

        /**
         * Creates a new TraceContext instance using the specified properties.
         * @function create
         * @memberof analytics.TraceContext
         * @static
         * @param {analytics.ITraceContext=} [properties] Properties to set
         * @returns {analytics.TraceContext} TraceContext instance
         */
        TraceContext.create = function create(properties) {
            return new TraceContext(properties);
        };

        /**
         * Encodes the specified TraceContext message. Does not implicitly {@link analytics.TraceContext.verify|verify} messages.
         * @function encode
         * @memberof analytics.TraceContext
         * @static
         * @param {analytics.ITraceContext} message TraceContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TraceContext.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.source);
            if (message.traceId != null && Object.hasOwnProperty.call(message, "traceId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.traceId);
            if (message.spanId != null && Object.hasOwnProperty.call(message, "spanId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.spanId);
            if (message.parentSpanId != null && Object.hasOwnProperty.call(message, "parentSpanId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.parentSpanId);
            return writer;
        };

        /**
         * Encodes the specified TraceContext message, length delimited. Does not implicitly {@link analytics.TraceContext.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.TraceContext
         * @static
         * @param {analytics.ITraceContext} message TraceContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TraceContext.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TraceContext message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.TraceContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.TraceContext} TraceContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TraceContext.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.TraceContext();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.source = reader.string();
                    break;
                case 2:
                    message.traceId = reader.string();
                    break;
                case 3:
                    message.spanId = reader.string();
                    break;
                case 4:
                    message.parentSpanId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TraceContext message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.TraceContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.TraceContext} TraceContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TraceContext.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TraceContext message.
         * @function verify
         * @memberof analytics.TraceContext
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TraceContext.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.source != null && message.hasOwnProperty("source"))
                if (!$util.isString(message.source))
                    return "source: string expected";
            if (message.traceId != null && message.hasOwnProperty("traceId"))
                if (!$util.isString(message.traceId))
                    return "traceId: string expected";
            if (message.spanId != null && message.hasOwnProperty("spanId"))
                if (!$util.isString(message.spanId))
                    return "spanId: string expected";
            if (message.parentSpanId != null && message.hasOwnProperty("parentSpanId"))
                if (!$util.isString(message.parentSpanId))
                    return "parentSpanId: string expected";
            return null;
        };

        /**
         * Creates a TraceContext message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.TraceContext
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.TraceContext} TraceContext
         */
        TraceContext.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.TraceContext)
                return object;
            let message = new $root.analytics.TraceContext();
            if (object.source != null)
                message.source = String(object.source);
            if (object.traceId != null)
                message.traceId = String(object.traceId);
            if (object.spanId != null)
                message.spanId = String(object.spanId);
            if (object.parentSpanId != null)
                message.parentSpanId = String(object.parentSpanId);
            return message;
        };

        /**
         * Creates a plain object from a TraceContext message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.TraceContext
         * @static
         * @param {analytics.TraceContext} message TraceContext
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TraceContext.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.source = "";
                object.traceId = "";
                object.spanId = "";
                object.parentSpanId = "";
            }
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = message.source;
            if (message.traceId != null && message.hasOwnProperty("traceId"))
                object.traceId = message.traceId;
            if (message.spanId != null && message.hasOwnProperty("spanId"))
                object.spanId = message.spanId;
            if (message.parentSpanId != null && message.hasOwnProperty("parentSpanId"))
                object.parentSpanId = message.parentSpanId;
            return object;
        };

        /**
         * Converts this TraceContext to JSON.
         * @function toJSON
         * @memberof analytics.TraceContext
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TraceContext.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TraceContext;
    })();

    analytics.CommonProperties = (function() {

        /**
         * Properties of a CommonProperties.
         * @memberof analytics
         * @interface ICommonProperties
         * @property {analytics.ITraceContext|null} [traceContext] CommonProperties traceContext
         * @property {string|null} [eventName] CommonProperties eventName
         * @property {string|null} [id] CommonProperties id
         * @property {number|Long|null} [timestamp] CommonProperties timestamp
         */

        /**
         * Constructs a new CommonProperties.
         * @memberof analytics
         * @classdesc Represents a CommonProperties.
         * @implements ICommonProperties
         * @constructor
         * @param {analytics.ICommonProperties=} [properties] Properties to set
         */
        function CommonProperties(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommonProperties traceContext.
         * @member {analytics.ITraceContext|null|undefined} traceContext
         * @memberof analytics.CommonProperties
         * @instance
         */
        CommonProperties.prototype.traceContext = null;

        /**
         * CommonProperties eventName.
         * @member {string} eventName
         * @memberof analytics.CommonProperties
         * @instance
         */
        CommonProperties.prototype.eventName = "";

        /**
         * CommonProperties id.
         * @member {string} id
         * @memberof analytics.CommonProperties
         * @instance
         */
        CommonProperties.prototype.id = "";

        /**
         * CommonProperties timestamp.
         * @member {number|Long} timestamp
         * @memberof analytics.CommonProperties
         * @instance
         */
        CommonProperties.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CommonProperties instance using the specified properties.
         * @function create
         * @memberof analytics.CommonProperties
         * @static
         * @param {analytics.ICommonProperties=} [properties] Properties to set
         * @returns {analytics.CommonProperties} CommonProperties instance
         */
        CommonProperties.create = function create(properties) {
            return new CommonProperties(properties);
        };

        /**
         * Encodes the specified CommonProperties message. Does not implicitly {@link analytics.CommonProperties.verify|verify} messages.
         * @function encode
         * @memberof analytics.CommonProperties
         * @static
         * @param {analytics.ICommonProperties} message CommonProperties message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommonProperties.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.traceContext != null && Object.hasOwnProperty.call(message, "traceContext"))
                $root.analytics.TraceContext.encode(message.traceContext, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.eventName != null && Object.hasOwnProperty.call(message, "eventName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.eventName);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.timestamp);
            return writer;
        };

        /**
         * Encodes the specified CommonProperties message, length delimited. Does not implicitly {@link analytics.CommonProperties.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.CommonProperties
         * @static
         * @param {analytics.ICommonProperties} message CommonProperties message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommonProperties.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CommonProperties message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.CommonProperties
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.CommonProperties} CommonProperties
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommonProperties.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.CommonProperties();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.traceContext = $root.analytics.TraceContext.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.eventName = reader.string();
                    break;
                case 3:
                    message.id = reader.string();
                    break;
                case 4:
                    message.timestamp = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CommonProperties message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.CommonProperties
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.CommonProperties} CommonProperties
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommonProperties.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CommonProperties message.
         * @function verify
         * @memberof analytics.CommonProperties
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CommonProperties.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.traceContext != null && message.hasOwnProperty("traceContext")) {
                let error = $root.analytics.TraceContext.verify(message.traceContext);
                if (error)
                    return "traceContext." + error;
            }
            if (message.eventName != null && message.hasOwnProperty("eventName"))
                if (!$util.isString(message.eventName))
                    return "eventName: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a CommonProperties message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.CommonProperties
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.CommonProperties} CommonProperties
         */
        CommonProperties.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.CommonProperties)
                return object;
            let message = new $root.analytics.CommonProperties();
            if (object.traceContext != null) {
                if (typeof object.traceContext !== "object")
                    throw TypeError(".analytics.CommonProperties.traceContext: object expected");
                message.traceContext = $root.analytics.TraceContext.fromObject(object.traceContext);
            }
            if (object.eventName != null)
                message.eventName = String(object.eventName);
            if (object.id != null)
                message.id = String(object.id);
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CommonProperties message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.CommonProperties
         * @static
         * @param {analytics.CommonProperties} message CommonProperties
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommonProperties.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.traceContext = null;
                object.eventName = "";
                object.id = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
            }
            if (message.traceContext != null && message.hasOwnProperty("traceContext"))
                object.traceContext = $root.analytics.TraceContext.toObject(message.traceContext, options);
            if (message.eventName != null && message.hasOwnProperty("eventName"))
                object.eventName = message.eventName;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
            return object;
        };

        /**
         * Converts this CommonProperties to JSON.
         * @function toJSON
         * @memberof analytics.CommonProperties
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommonProperties.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommonProperties;
    })();

    analytics.BaseEvent = (function() {

        /**
         * Properties of a BaseEvent.
         * @memberof analytics
         * @interface IBaseEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] BaseEvent commonProperties
         */

        /**
         * Constructs a new BaseEvent.
         * @memberof analytics
         * @classdesc Represents a BaseEvent.
         * @implements IBaseEvent
         * @constructor
         * @param {analytics.IBaseEvent=} [properties] Properties to set
         */
        function BaseEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BaseEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.BaseEvent
         * @instance
         */
        BaseEvent.prototype.commonProperties = null;

        /**
         * Creates a new BaseEvent instance using the specified properties.
         * @function create
         * @memberof analytics.BaseEvent
         * @static
         * @param {analytics.IBaseEvent=} [properties] Properties to set
         * @returns {analytics.BaseEvent} BaseEvent instance
         */
        BaseEvent.create = function create(properties) {
            return new BaseEvent(properties);
        };

        /**
         * Encodes the specified BaseEvent message. Does not implicitly {@link analytics.BaseEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.BaseEvent
         * @static
         * @param {analytics.IBaseEvent} message BaseEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BaseEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified BaseEvent message, length delimited. Does not implicitly {@link analytics.BaseEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.BaseEvent
         * @static
         * @param {analytics.IBaseEvent} message BaseEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BaseEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BaseEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.BaseEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.BaseEvent} BaseEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BaseEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.BaseEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BaseEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.BaseEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.BaseEvent} BaseEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BaseEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BaseEvent message.
         * @function verify
         * @memberof analytics.BaseEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BaseEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            return null;
        };

        /**
         * Creates a BaseEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.BaseEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.BaseEvent} BaseEvent
         */
        BaseEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.BaseEvent)
                return object;
            let message = new $root.analytics.BaseEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.BaseEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            return message;
        };

        /**
         * Creates a plain object from a BaseEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.BaseEvent
         * @static
         * @param {analytics.BaseEvent} message BaseEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BaseEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.commonProperties = null;
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            return object;
        };

        /**
         * Converts this BaseEvent to JSON.
         * @function toJSON
         * @memberof analytics.BaseEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BaseEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BaseEvent;
    })();

    analytics.SampleEvent = (function() {

        /**
         * Properties of a SampleEvent.
         * @memberof analytics
         * @interface ISampleEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] SampleEvent commonProperties
         * @property {string|null} [appId] SampleEvent appId
         */

        /**
         * Constructs a new SampleEvent.
         * @memberof analytics
         * @classdesc Represents a SampleEvent.
         * @implements ISampleEvent
         * @constructor
         * @param {analytics.ISampleEvent=} [properties] Properties to set
         */
        function SampleEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SampleEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.SampleEvent
         * @instance
         */
        SampleEvent.prototype.commonProperties = null;

        /**
         * SampleEvent appId.
         * @member {string} appId
         * @memberof analytics.SampleEvent
         * @instance
         */
        SampleEvent.prototype.appId = "";

        /**
         * Creates a new SampleEvent instance using the specified properties.
         * @function create
         * @memberof analytics.SampleEvent
         * @static
         * @param {analytics.ISampleEvent=} [properties] Properties to set
         * @returns {analytics.SampleEvent} SampleEvent instance
         */
        SampleEvent.create = function create(properties) {
            return new SampleEvent(properties);
        };

        /**
         * Encodes the specified SampleEvent message. Does not implicitly {@link analytics.SampleEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.SampleEvent
         * @static
         * @param {analytics.ISampleEvent} message SampleEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SampleEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified SampleEvent message, length delimited. Does not implicitly {@link analytics.SampleEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.SampleEvent
         * @static
         * @param {analytics.ISampleEvent} message SampleEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SampleEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SampleEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.SampleEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.SampleEvent} SampleEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SampleEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.SampleEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SampleEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.SampleEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.SampleEvent} SampleEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SampleEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SampleEvent message.
         * @function verify
         * @memberof analytics.SampleEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SampleEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates a SampleEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.SampleEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.SampleEvent} SampleEvent
         */
        SampleEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.SampleEvent)
                return object;
            let message = new $root.analytics.SampleEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.SampleEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from a SampleEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.SampleEvent
         * @static
         * @param {analytics.SampleEvent} message SampleEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SampleEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this SampleEvent to JSON.
         * @function toJSON
         * @memberof analytics.SampleEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SampleEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SampleEvent;
    })();

    analytics.Principal = (function() {

        /**
         * Properties of a Principal.
         * @memberof analytics
         * @interface IPrincipal
         * @property {string|null} [canonicalUserId] Principal canonicalUserId
         */

        /**
         * Constructs a new Principal.
         * @memberof analytics
         * @classdesc Represents a Principal.
         * @implements IPrincipal
         * @constructor
         * @param {analytics.IPrincipal=} [properties] Properties to set
         */
        function Principal(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Principal canonicalUserId.
         * @member {string} canonicalUserId
         * @memberof analytics.Principal
         * @instance
         */
        Principal.prototype.canonicalUserId = "";

        /**
         * Creates a new Principal instance using the specified properties.
         * @function create
         * @memberof analytics.Principal
         * @static
         * @param {analytics.IPrincipal=} [properties] Properties to set
         * @returns {analytics.Principal} Principal instance
         */
        Principal.create = function create(properties) {
            return new Principal(properties);
        };

        /**
         * Encodes the specified Principal message. Does not implicitly {@link analytics.Principal.verify|verify} messages.
         * @function encode
         * @memberof analytics.Principal
         * @static
         * @param {analytics.IPrincipal} message Principal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Principal.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.canonicalUserId != null && Object.hasOwnProperty.call(message, "canonicalUserId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.canonicalUserId);
            return writer;
        };

        /**
         * Encodes the specified Principal message, length delimited. Does not implicitly {@link analytics.Principal.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.Principal
         * @static
         * @param {analytics.IPrincipal} message Principal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Principal.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Principal message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.Principal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.Principal} Principal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Principal.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.Principal();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.canonicalUserId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Principal message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.Principal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.Principal} Principal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Principal.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Principal message.
         * @function verify
         * @memberof analytics.Principal
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Principal.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.canonicalUserId != null && message.hasOwnProperty("canonicalUserId"))
                if (!$util.isString(message.canonicalUserId))
                    return "canonicalUserId: string expected";
            return null;
        };

        /**
         * Creates a Principal message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.Principal
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.Principal} Principal
         */
        Principal.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.Principal)
                return object;
            let message = new $root.analytics.Principal();
            if (object.canonicalUserId != null)
                message.canonicalUserId = String(object.canonicalUserId);
            return message;
        };

        /**
         * Creates a plain object from a Principal message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.Principal
         * @static
         * @param {analytics.Principal} message Principal
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Principal.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.canonicalUserId = "";
            if (message.canonicalUserId != null && message.hasOwnProperty("canonicalUserId"))
                object.canonicalUserId = message.canonicalUserId;
            return object;
        };

        /**
         * Converts this Principal to JSON.
         * @function toJSON
         * @memberof analytics.Principal
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Principal.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Principal;
    })();

    analytics.UserAppFavoriteChangedEvent = (function() {

        /**
         * Properties of a UserAppFavoriteChangedEvent.
         * @memberof analytics
         * @interface IUserAppFavoriteChangedEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] UserAppFavoriteChangedEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] UserAppFavoriteChangedEvent principal
         * @property {string|null} [appId] UserAppFavoriteChangedEvent appId
         * @property {boolean|null} [isFavorite] UserAppFavoriteChangedEvent isFavorite
         */

        /**
         * Constructs a new UserAppFavoriteChangedEvent.
         * @memberof analytics
         * @classdesc Represents a UserAppFavoriteChangedEvent.
         * @implements IUserAppFavoriteChangedEvent
         * @constructor
         * @param {analytics.IUserAppFavoriteChangedEvent=} [properties] Properties to set
         */
        function UserAppFavoriteChangedEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserAppFavoriteChangedEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @instance
         */
        UserAppFavoriteChangedEvent.prototype.commonProperties = null;

        /**
         * UserAppFavoriteChangedEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @instance
         */
        UserAppFavoriteChangedEvent.prototype.principal = null;

        /**
         * UserAppFavoriteChangedEvent appId.
         * @member {string} appId
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @instance
         */
        UserAppFavoriteChangedEvent.prototype.appId = "";

        /**
         * UserAppFavoriteChangedEvent isFavorite.
         * @member {boolean} isFavorite
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @instance
         */
        UserAppFavoriteChangedEvent.prototype.isFavorite = false;

        /**
         * Creates a new UserAppFavoriteChangedEvent instance using the specified properties.
         * @function create
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @static
         * @param {analytics.IUserAppFavoriteChangedEvent=} [properties] Properties to set
         * @returns {analytics.UserAppFavoriteChangedEvent} UserAppFavoriteChangedEvent instance
         */
        UserAppFavoriteChangedEvent.create = function create(properties) {
            return new UserAppFavoriteChangedEvent(properties);
        };

        /**
         * Encodes the specified UserAppFavoriteChangedEvent message. Does not implicitly {@link analytics.UserAppFavoriteChangedEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @static
         * @param {analytics.IUserAppFavoriteChangedEvent} message UserAppFavoriteChangedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserAppFavoriteChangedEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.appId);
            if (message.isFavorite != null && Object.hasOwnProperty.call(message, "isFavorite"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isFavorite);
            return writer;
        };

        /**
         * Encodes the specified UserAppFavoriteChangedEvent message, length delimited. Does not implicitly {@link analytics.UserAppFavoriteChangedEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @static
         * @param {analytics.IUserAppFavoriteChangedEvent} message UserAppFavoriteChangedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserAppFavoriteChangedEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserAppFavoriteChangedEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.UserAppFavoriteChangedEvent} UserAppFavoriteChangedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserAppFavoriteChangedEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.UserAppFavoriteChangedEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.appId = reader.string();
                    break;
                case 7:
                    message.isFavorite = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserAppFavoriteChangedEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.UserAppFavoriteChangedEvent} UserAppFavoriteChangedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserAppFavoriteChangedEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserAppFavoriteChangedEvent message.
         * @function verify
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserAppFavoriteChangedEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.isFavorite != null && message.hasOwnProperty("isFavorite"))
                if (typeof message.isFavorite !== "boolean")
                    return "isFavorite: boolean expected";
            return null;
        };

        /**
         * Creates a UserAppFavoriteChangedEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.UserAppFavoriteChangedEvent} UserAppFavoriteChangedEvent
         */
        UserAppFavoriteChangedEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.UserAppFavoriteChangedEvent)
                return object;
            let message = new $root.analytics.UserAppFavoriteChangedEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.UserAppFavoriteChangedEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.UserAppFavoriteChangedEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.isFavorite != null)
                message.isFavorite = Boolean(object.isFavorite);
            return message;
        };

        /**
         * Creates a plain object from a UserAppFavoriteChangedEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @static
         * @param {analytics.UserAppFavoriteChangedEvent} message UserAppFavoriteChangedEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserAppFavoriteChangedEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
                object.appId = "";
                object.isFavorite = false;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.isFavorite != null && message.hasOwnProperty("isFavorite"))
                object.isFavorite = message.isFavorite;
            return object;
        };

        /**
         * Converts this UserAppFavoriteChangedEvent to JSON.
         * @function toJSON
         * @memberof analytics.UserAppFavoriteChangedEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserAppFavoriteChangedEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserAppFavoriteChangedEvent;
    })();

    analytics.AppDeploymentCompletedEvent = (function() {

        /**
         * Properties of an AppDeploymentCompletedEvent.
         * @memberof analytics
         * @interface IAppDeploymentCompletedEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppDeploymentCompletedEvent commonProperties
         * @property {string|null} [appId] AppDeploymentCompletedEvent appId
         * @property {analytics.AppDeploymentCompletedEvent.ReferrerType|null} [referrer] AppDeploymentCompletedEvent referrer
         * @property {analytics.IPrincipal|null} [principal] AppDeploymentCompletedEvent principal
         * @property {string|null} [repository] AppDeploymentCompletedEvent repository
         */

        /**
         * Constructs a new AppDeploymentCompletedEvent.
         * @memberof analytics
         * @classdesc Represents an AppDeploymentCompletedEvent.
         * @implements IAppDeploymentCompletedEvent
         * @constructor
         * @param {analytics.IAppDeploymentCompletedEvent=} [properties] Properties to set
         */
        function AppDeploymentCompletedEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppDeploymentCompletedEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppDeploymentCompletedEvent
         * @instance
         */
        AppDeploymentCompletedEvent.prototype.commonProperties = null;

        /**
         * AppDeploymentCompletedEvent appId.
         * @member {string} appId
         * @memberof analytics.AppDeploymentCompletedEvent
         * @instance
         */
        AppDeploymentCompletedEvent.prototype.appId = "";

        /**
         * AppDeploymentCompletedEvent referrer.
         * @member {analytics.AppDeploymentCompletedEvent.ReferrerType} referrer
         * @memberof analytics.AppDeploymentCompletedEvent
         * @instance
         */
        AppDeploymentCompletedEvent.prototype.referrer = 0;

        /**
         * AppDeploymentCompletedEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.AppDeploymentCompletedEvent
         * @instance
         */
        AppDeploymentCompletedEvent.prototype.principal = null;

        /**
         * AppDeploymentCompletedEvent repository.
         * @member {string|null|undefined} repository
         * @memberof analytics.AppDeploymentCompletedEvent
         * @instance
         */
        AppDeploymentCompletedEvent.prototype.repository = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * AppDeploymentCompletedEvent _repository.
         * @member {"repository"|undefined} _repository
         * @memberof analytics.AppDeploymentCompletedEvent
         * @instance
         */
        Object.defineProperty(AppDeploymentCompletedEvent.prototype, "_repository", {
            get: $util.oneOfGetter($oneOfFields = ["repository"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AppDeploymentCompletedEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppDeploymentCompletedEvent
         * @static
         * @param {analytics.IAppDeploymentCompletedEvent=} [properties] Properties to set
         * @returns {analytics.AppDeploymentCompletedEvent} AppDeploymentCompletedEvent instance
         */
        AppDeploymentCompletedEvent.create = function create(properties) {
            return new AppDeploymentCompletedEvent(properties);
        };

        /**
         * Encodes the specified AppDeploymentCompletedEvent message. Does not implicitly {@link analytics.AppDeploymentCompletedEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppDeploymentCompletedEvent
         * @static
         * @param {analytics.IAppDeploymentCompletedEvent} message AppDeploymentCompletedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppDeploymentCompletedEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            if (message.referrer != null && Object.hasOwnProperty.call(message, "referrer"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.referrer);
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.repository != null && Object.hasOwnProperty.call(message, "repository"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.repository);
            return writer;
        };

        /**
         * Encodes the specified AppDeploymentCompletedEvent message, length delimited. Does not implicitly {@link analytics.AppDeploymentCompletedEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppDeploymentCompletedEvent
         * @static
         * @param {analytics.IAppDeploymentCompletedEvent} message AppDeploymentCompletedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppDeploymentCompletedEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppDeploymentCompletedEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppDeploymentCompletedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppDeploymentCompletedEvent} AppDeploymentCompletedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppDeploymentCompletedEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppDeploymentCompletedEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                case 6:
                    message.referrer = reader.int32();
                    break;
                case 7:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.repository = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppDeploymentCompletedEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppDeploymentCompletedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppDeploymentCompletedEvent} AppDeploymentCompletedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppDeploymentCompletedEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppDeploymentCompletedEvent message.
         * @function verify
         * @memberof analytics.AppDeploymentCompletedEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppDeploymentCompletedEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.referrer != null && message.hasOwnProperty("referrer"))
                switch (message.referrer) {
                default:
                    return "referrer: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.repository != null && message.hasOwnProperty("repository")) {
                properties._repository = 1;
                if (!$util.isString(message.repository))
                    return "repository: string expected";
            }
            return null;
        };

        /**
         * Creates an AppDeploymentCompletedEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppDeploymentCompletedEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppDeploymentCompletedEvent} AppDeploymentCompletedEvent
         */
        AppDeploymentCompletedEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppDeploymentCompletedEvent)
                return object;
            let message = new $root.analytics.AppDeploymentCompletedEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppDeploymentCompletedEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            switch (object.referrer) {
            case "REFERRER_TYPE_UNSPECIFIED":
            case 0:
                message.referrer = 0;
                break;
            case "REFERRER_TYPE_DEFAULT":
            case 1:
                message.referrer = 1;
                break;
            case "REFERRER_TYPE_FORK":
            case 2:
                message.referrer = 2;
                break;
            case "REFERRER_TYPE_TEMPLATE":
            case 3:
                message.referrer = 3;
                break;
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.AppDeploymentCompletedEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.repository != null)
                message.repository = String(object.repository);
            return message;
        };

        /**
         * Creates a plain object from an AppDeploymentCompletedEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppDeploymentCompletedEvent
         * @static
         * @param {analytics.AppDeploymentCompletedEvent} message AppDeploymentCompletedEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppDeploymentCompletedEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
                object.referrer = options.enums === String ? "REFERRER_TYPE_UNSPECIFIED" : 0;
                object.principal = null;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.referrer != null && message.hasOwnProperty("referrer"))
                object.referrer = options.enums === String ? $root.analytics.AppDeploymentCompletedEvent.ReferrerType[message.referrer] : message.referrer;
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.repository != null && message.hasOwnProperty("repository")) {
                object.repository = message.repository;
                if (options.oneofs)
                    object._repository = "repository";
            }
            return object;
        };

        /**
         * Converts this AppDeploymentCompletedEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppDeploymentCompletedEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppDeploymentCompletedEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * ReferrerType enum.
         * @name analytics.AppDeploymentCompletedEvent.ReferrerType
         * @enum {number}
         * @property {number} REFERRER_TYPE_UNSPECIFIED=0 REFERRER_TYPE_UNSPECIFIED value
         * @property {number} REFERRER_TYPE_DEFAULT=1 REFERRER_TYPE_DEFAULT value
         * @property {number} REFERRER_TYPE_FORK=2 REFERRER_TYPE_FORK value
         * @property {number} REFERRER_TYPE_TEMPLATE=3 REFERRER_TYPE_TEMPLATE value
         */
        AppDeploymentCompletedEvent.ReferrerType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "REFERRER_TYPE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "REFERRER_TYPE_DEFAULT"] = 1;
            values[valuesById[2] = "REFERRER_TYPE_FORK"] = 2;
            values[valuesById[3] = "REFERRER_TYPE_TEMPLATE"] = 3;
            return values;
        })();

        return AppDeploymentCompletedEvent;
    })();

    analytics.AppCreateEvent = (function() {

        /**
         * Properties of an AppCreateEvent.
         * @memberof analytics
         * @interface IAppCreateEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppCreateEvent commonProperties
         * @property {string|null} [appId] AppCreateEvent appId
         */

        /**
         * Constructs a new AppCreateEvent.
         * @memberof analytics
         * @classdesc Represents an AppCreateEvent.
         * @implements IAppCreateEvent
         * @constructor
         * @param {analytics.IAppCreateEvent=} [properties] Properties to set
         */
        function AppCreateEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppCreateEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppCreateEvent
         * @instance
         */
        AppCreateEvent.prototype.commonProperties = null;

        /**
         * AppCreateEvent appId.
         * @member {string} appId
         * @memberof analytics.AppCreateEvent
         * @instance
         */
        AppCreateEvent.prototype.appId = "";

        /**
         * Creates a new AppCreateEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppCreateEvent
         * @static
         * @param {analytics.IAppCreateEvent=} [properties] Properties to set
         * @returns {analytics.AppCreateEvent} AppCreateEvent instance
         */
        AppCreateEvent.create = function create(properties) {
            return new AppCreateEvent(properties);
        };

        /**
         * Encodes the specified AppCreateEvent message. Does not implicitly {@link analytics.AppCreateEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppCreateEvent
         * @static
         * @param {analytics.IAppCreateEvent} message AppCreateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppCreateEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified AppCreateEvent message, length delimited. Does not implicitly {@link analytics.AppCreateEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppCreateEvent
         * @static
         * @param {analytics.IAppCreateEvent} message AppCreateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppCreateEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppCreateEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppCreateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppCreateEvent} AppCreateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppCreateEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppCreateEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppCreateEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppCreateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppCreateEvent} AppCreateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppCreateEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppCreateEvent message.
         * @function verify
         * @memberof analytics.AppCreateEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppCreateEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates an AppCreateEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppCreateEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppCreateEvent} AppCreateEvent
         */
        AppCreateEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppCreateEvent)
                return object;
            let message = new $root.analytics.AppCreateEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppCreateEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from an AppCreateEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppCreateEvent
         * @static
         * @param {analytics.AppCreateEvent} message AppCreateEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppCreateEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this AppCreateEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppCreateEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppCreateEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppCreateEvent;
    })();

    analytics.AppResumeEvent = (function() {

        /**
         * Properties of an AppResumeEvent.
         * @memberof analytics
         * @interface IAppResumeEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppResumeEvent commonProperties
         * @property {string|null} [appId] AppResumeEvent appId
         */

        /**
         * Constructs a new AppResumeEvent.
         * @memberof analytics
         * @classdesc Represents an AppResumeEvent.
         * @implements IAppResumeEvent
         * @constructor
         * @param {analytics.IAppResumeEvent=} [properties] Properties to set
         */
        function AppResumeEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppResumeEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppResumeEvent
         * @instance
         */
        AppResumeEvent.prototype.commonProperties = null;

        /**
         * AppResumeEvent appId.
         * @member {string} appId
         * @memberof analytics.AppResumeEvent
         * @instance
         */
        AppResumeEvent.prototype.appId = "";

        /**
         * Creates a new AppResumeEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppResumeEvent
         * @static
         * @param {analytics.IAppResumeEvent=} [properties] Properties to set
         * @returns {analytics.AppResumeEvent} AppResumeEvent instance
         */
        AppResumeEvent.create = function create(properties) {
            return new AppResumeEvent(properties);
        };

        /**
         * Encodes the specified AppResumeEvent message. Does not implicitly {@link analytics.AppResumeEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppResumeEvent
         * @static
         * @param {analytics.IAppResumeEvent} message AppResumeEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppResumeEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified AppResumeEvent message, length delimited. Does not implicitly {@link analytics.AppResumeEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppResumeEvent
         * @static
         * @param {analytics.IAppResumeEvent} message AppResumeEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppResumeEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppResumeEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppResumeEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppResumeEvent} AppResumeEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppResumeEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppResumeEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppResumeEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppResumeEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppResumeEvent} AppResumeEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppResumeEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppResumeEvent message.
         * @function verify
         * @memberof analytics.AppResumeEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppResumeEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates an AppResumeEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppResumeEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppResumeEvent} AppResumeEvent
         */
        AppResumeEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppResumeEvent)
                return object;
            let message = new $root.analytics.AppResumeEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppResumeEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from an AppResumeEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppResumeEvent
         * @static
         * @param {analytics.AppResumeEvent} message AppResumeEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppResumeEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this AppResumeEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppResumeEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppResumeEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppResumeEvent;
    })();

    analytics.AppRebootEvent = (function() {

        /**
         * Properties of an AppRebootEvent.
         * @memberof analytics
         * @interface IAppRebootEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppRebootEvent commonProperties
         * @property {string|null} [appId] AppRebootEvent appId
         */

        /**
         * Constructs a new AppRebootEvent.
         * @memberof analytics
         * @classdesc Represents an AppRebootEvent.
         * @implements IAppRebootEvent
         * @constructor
         * @param {analytics.IAppRebootEvent=} [properties] Properties to set
         */
        function AppRebootEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppRebootEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppRebootEvent
         * @instance
         */
        AppRebootEvent.prototype.commonProperties = null;

        /**
         * AppRebootEvent appId.
         * @member {string} appId
         * @memberof analytics.AppRebootEvent
         * @instance
         */
        AppRebootEvent.prototype.appId = "";

        /**
         * Creates a new AppRebootEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppRebootEvent
         * @static
         * @param {analytics.IAppRebootEvent=} [properties] Properties to set
         * @returns {analytics.AppRebootEvent} AppRebootEvent instance
         */
        AppRebootEvent.create = function create(properties) {
            return new AppRebootEvent(properties);
        };

        /**
         * Encodes the specified AppRebootEvent message. Does not implicitly {@link analytics.AppRebootEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppRebootEvent
         * @static
         * @param {analytics.IAppRebootEvent} message AppRebootEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppRebootEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified AppRebootEvent message, length delimited. Does not implicitly {@link analytics.AppRebootEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppRebootEvent
         * @static
         * @param {analytics.IAppRebootEvent} message AppRebootEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppRebootEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppRebootEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppRebootEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppRebootEvent} AppRebootEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppRebootEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppRebootEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppRebootEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppRebootEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppRebootEvent} AppRebootEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppRebootEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppRebootEvent message.
         * @function verify
         * @memberof analytics.AppRebootEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppRebootEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates an AppRebootEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppRebootEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppRebootEvent} AppRebootEvent
         */
        AppRebootEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppRebootEvent)
                return object;
            let message = new $root.analytics.AppRebootEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppRebootEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from an AppRebootEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppRebootEvent
         * @static
         * @param {analytics.AppRebootEvent} message AppRebootEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppRebootEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this AppRebootEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppRebootEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppRebootEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppRebootEvent;
    })();

    analytics.AppUpdateEvent = (function() {

        /**
         * Properties of an AppUpdateEvent.
         * @memberof analytics
         * @interface IAppUpdateEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppUpdateEvent commonProperties
         * @property {string|null} [appId] AppUpdateEvent appId
         */

        /**
         * Constructs a new AppUpdateEvent.
         * @memberof analytics
         * @classdesc Represents an AppUpdateEvent.
         * @implements IAppUpdateEvent
         * @constructor
         * @param {analytics.IAppUpdateEvent=} [properties] Properties to set
         */
        function AppUpdateEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppUpdateEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppUpdateEvent
         * @instance
         */
        AppUpdateEvent.prototype.commonProperties = null;

        /**
         * AppUpdateEvent appId.
         * @member {string} appId
         * @memberof analytics.AppUpdateEvent
         * @instance
         */
        AppUpdateEvent.prototype.appId = "";

        /**
         * Creates a new AppUpdateEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppUpdateEvent
         * @static
         * @param {analytics.IAppUpdateEvent=} [properties] Properties to set
         * @returns {analytics.AppUpdateEvent} AppUpdateEvent instance
         */
        AppUpdateEvent.create = function create(properties) {
            return new AppUpdateEvent(properties);
        };

        /**
         * Encodes the specified AppUpdateEvent message. Does not implicitly {@link analytics.AppUpdateEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppUpdateEvent
         * @static
         * @param {analytics.IAppUpdateEvent} message AppUpdateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppUpdateEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified AppUpdateEvent message, length delimited. Does not implicitly {@link analytics.AppUpdateEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppUpdateEvent
         * @static
         * @param {analytics.IAppUpdateEvent} message AppUpdateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppUpdateEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppUpdateEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppUpdateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppUpdateEvent} AppUpdateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppUpdateEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppUpdateEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppUpdateEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppUpdateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppUpdateEvent} AppUpdateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppUpdateEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppUpdateEvent message.
         * @function verify
         * @memberof analytics.AppUpdateEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppUpdateEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates an AppUpdateEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppUpdateEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppUpdateEvent} AppUpdateEvent
         */
        AppUpdateEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppUpdateEvent)
                return object;
            let message = new $root.analytics.AppUpdateEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppUpdateEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from an AppUpdateEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppUpdateEvent
         * @static
         * @param {analytics.AppUpdateEvent} message AppUpdateEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppUpdateEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this AppUpdateEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppUpdateEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppUpdateEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppUpdateEvent;
    })();

    analytics.AppManagerBootEvent = (function() {

        /**
         * Properties of an AppManagerBootEvent.
         * @memberof analytics
         * @interface IAppManagerBootEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppManagerBootEvent commonProperties
         * @property {string|null} [appId] AppManagerBootEvent appId
         * @property {string|null} [syncSpanId] AppManagerBootEvent syncSpanId
         */

        /**
         * Constructs a new AppManagerBootEvent.
         * @memberof analytics
         * @classdesc Represents an AppManagerBootEvent.
         * @implements IAppManagerBootEvent
         * @constructor
         * @param {analytics.IAppManagerBootEvent=} [properties] Properties to set
         */
        function AppManagerBootEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppManagerBootEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppManagerBootEvent
         * @instance
         */
        AppManagerBootEvent.prototype.commonProperties = null;

        /**
         * AppManagerBootEvent appId.
         * @member {string} appId
         * @memberof analytics.AppManagerBootEvent
         * @instance
         */
        AppManagerBootEvent.prototype.appId = "";

        /**
         * AppManagerBootEvent syncSpanId.
         * @member {string} syncSpanId
         * @memberof analytics.AppManagerBootEvent
         * @instance
         */
        AppManagerBootEvent.prototype.syncSpanId = "";

        /**
         * Creates a new AppManagerBootEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppManagerBootEvent
         * @static
         * @param {analytics.IAppManagerBootEvent=} [properties] Properties to set
         * @returns {analytics.AppManagerBootEvent} AppManagerBootEvent instance
         */
        AppManagerBootEvent.create = function create(properties) {
            return new AppManagerBootEvent(properties);
        };

        /**
         * Encodes the specified AppManagerBootEvent message. Does not implicitly {@link analytics.AppManagerBootEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppManagerBootEvent
         * @static
         * @param {analytics.IAppManagerBootEvent} message AppManagerBootEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppManagerBootEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            if (message.syncSpanId != null && Object.hasOwnProperty.call(message, "syncSpanId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.syncSpanId);
            return writer;
        };

        /**
         * Encodes the specified AppManagerBootEvent message, length delimited. Does not implicitly {@link analytics.AppManagerBootEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppManagerBootEvent
         * @static
         * @param {analytics.IAppManagerBootEvent} message AppManagerBootEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppManagerBootEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppManagerBootEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppManagerBootEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppManagerBootEvent} AppManagerBootEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppManagerBootEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppManagerBootEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                case 6:
                    message.syncSpanId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppManagerBootEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppManagerBootEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppManagerBootEvent} AppManagerBootEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppManagerBootEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppManagerBootEvent message.
         * @function verify
         * @memberof analytics.AppManagerBootEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppManagerBootEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.syncSpanId != null && message.hasOwnProperty("syncSpanId"))
                if (!$util.isString(message.syncSpanId))
                    return "syncSpanId: string expected";
            return null;
        };

        /**
         * Creates an AppManagerBootEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppManagerBootEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppManagerBootEvent} AppManagerBootEvent
         */
        AppManagerBootEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppManagerBootEvent)
                return object;
            let message = new $root.analytics.AppManagerBootEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppManagerBootEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.syncSpanId != null)
                message.syncSpanId = String(object.syncSpanId);
            return message;
        };

        /**
         * Creates a plain object from an AppManagerBootEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppManagerBootEvent
         * @static
         * @param {analytics.AppManagerBootEvent} message AppManagerBootEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppManagerBootEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
                object.syncSpanId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.syncSpanId != null && message.hasOwnProperty("syncSpanId"))
                object.syncSpanId = message.syncSpanId;
            return object;
        };

        /**
         * Converts this AppManagerBootEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppManagerBootEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppManagerBootEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppManagerBootEvent;
    })();

    analytics.AppManagerReadyEvent = (function() {

        /**
         * Properties of an AppManagerReadyEvent.
         * @memberof analytics
         * @interface IAppManagerReadyEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppManagerReadyEvent commonProperties
         * @property {string|null} [appId] AppManagerReadyEvent appId
         * @property {number|null} [timedelta] AppManagerReadyEvent timedelta
         */

        /**
         * Constructs a new AppManagerReadyEvent.
         * @memberof analytics
         * @classdesc Represents an AppManagerReadyEvent.
         * @implements IAppManagerReadyEvent
         * @constructor
         * @param {analytics.IAppManagerReadyEvent=} [properties] Properties to set
         */
        function AppManagerReadyEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppManagerReadyEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppManagerReadyEvent
         * @instance
         */
        AppManagerReadyEvent.prototype.commonProperties = null;

        /**
         * AppManagerReadyEvent appId.
         * @member {string} appId
         * @memberof analytics.AppManagerReadyEvent
         * @instance
         */
        AppManagerReadyEvent.prototype.appId = "";

        /**
         * AppManagerReadyEvent timedelta.
         * @member {number} timedelta
         * @memberof analytics.AppManagerReadyEvent
         * @instance
         */
        AppManagerReadyEvent.prototype.timedelta = 0;

        /**
         * Creates a new AppManagerReadyEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppManagerReadyEvent
         * @static
         * @param {analytics.IAppManagerReadyEvent=} [properties] Properties to set
         * @returns {analytics.AppManagerReadyEvent} AppManagerReadyEvent instance
         */
        AppManagerReadyEvent.create = function create(properties) {
            return new AppManagerReadyEvent(properties);
        };

        /**
         * Encodes the specified AppManagerReadyEvent message. Does not implicitly {@link analytics.AppManagerReadyEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppManagerReadyEvent
         * @static
         * @param {analytics.IAppManagerReadyEvent} message AppManagerReadyEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppManagerReadyEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            if (message.timedelta != null && Object.hasOwnProperty.call(message, "timedelta"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.timedelta);
            return writer;
        };

        /**
         * Encodes the specified AppManagerReadyEvent message, length delimited. Does not implicitly {@link analytics.AppManagerReadyEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppManagerReadyEvent
         * @static
         * @param {analytics.IAppManagerReadyEvent} message AppManagerReadyEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppManagerReadyEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppManagerReadyEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppManagerReadyEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppManagerReadyEvent} AppManagerReadyEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppManagerReadyEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppManagerReadyEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                case 6:
                    message.timedelta = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppManagerReadyEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppManagerReadyEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppManagerReadyEvent} AppManagerReadyEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppManagerReadyEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppManagerReadyEvent message.
         * @function verify
         * @memberof analytics.AppManagerReadyEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppManagerReadyEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                if (typeof message.timedelta !== "number")
                    return "timedelta: number expected";
            return null;
        };

        /**
         * Creates an AppManagerReadyEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppManagerReadyEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppManagerReadyEvent} AppManagerReadyEvent
         */
        AppManagerReadyEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppManagerReadyEvent)
                return object;
            let message = new $root.analytics.AppManagerReadyEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppManagerReadyEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.timedelta != null)
                message.timedelta = Number(object.timedelta);
            return message;
        };

        /**
         * Creates a plain object from an AppManagerReadyEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppManagerReadyEvent
         * @static
         * @param {analytics.AppManagerReadyEvent} message AppManagerReadyEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppManagerReadyEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
                object.timedelta = 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                object.timedelta = options.json && !isFinite(message.timedelta) ? String(message.timedelta) : message.timedelta;
            return object;
        };

        /**
         * Converts this AppManagerReadyEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppManagerReadyEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppManagerReadyEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppManagerReadyEvent;
    })();

    analytics.AppManagerUpdateEvent = (function() {

        /**
         * Properties of an AppManagerUpdateEvent.
         * @memberof analytics
         * @interface IAppManagerUpdateEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppManagerUpdateEvent commonProperties
         * @property {string|null} [appId] AppManagerUpdateEvent appId
         */

        /**
         * Constructs a new AppManagerUpdateEvent.
         * @memberof analytics
         * @classdesc Represents an AppManagerUpdateEvent.
         * @implements IAppManagerUpdateEvent
         * @constructor
         * @param {analytics.IAppManagerUpdateEvent=} [properties] Properties to set
         */
        function AppManagerUpdateEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppManagerUpdateEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppManagerUpdateEvent
         * @instance
         */
        AppManagerUpdateEvent.prototype.commonProperties = null;

        /**
         * AppManagerUpdateEvent appId.
         * @member {string} appId
         * @memberof analytics.AppManagerUpdateEvent
         * @instance
         */
        AppManagerUpdateEvent.prototype.appId = "";

        /**
         * Creates a new AppManagerUpdateEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppManagerUpdateEvent
         * @static
         * @param {analytics.IAppManagerUpdateEvent=} [properties] Properties to set
         * @returns {analytics.AppManagerUpdateEvent} AppManagerUpdateEvent instance
         */
        AppManagerUpdateEvent.create = function create(properties) {
            return new AppManagerUpdateEvent(properties);
        };

        /**
         * Encodes the specified AppManagerUpdateEvent message. Does not implicitly {@link analytics.AppManagerUpdateEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppManagerUpdateEvent
         * @static
         * @param {analytics.IAppManagerUpdateEvent} message AppManagerUpdateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppManagerUpdateEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified AppManagerUpdateEvent message, length delimited. Does not implicitly {@link analytics.AppManagerUpdateEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppManagerUpdateEvent
         * @static
         * @param {analytics.IAppManagerUpdateEvent} message AppManagerUpdateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppManagerUpdateEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppManagerUpdateEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppManagerUpdateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppManagerUpdateEvent} AppManagerUpdateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppManagerUpdateEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppManagerUpdateEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppManagerUpdateEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppManagerUpdateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppManagerUpdateEvent} AppManagerUpdateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppManagerUpdateEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppManagerUpdateEvent message.
         * @function verify
         * @memberof analytics.AppManagerUpdateEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppManagerUpdateEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates an AppManagerUpdateEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppManagerUpdateEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppManagerUpdateEvent} AppManagerUpdateEvent
         */
        AppManagerUpdateEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppManagerUpdateEvent)
                return object;
            let message = new $root.analytics.AppManagerUpdateEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppManagerUpdateEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from an AppManagerUpdateEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppManagerUpdateEvent
         * @static
         * @param {analytics.AppManagerUpdateEvent} message AppManagerUpdateEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppManagerUpdateEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this AppManagerUpdateEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppManagerUpdateEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppManagerUpdateEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppManagerUpdateEvent;
    })();

    analytics.AppSourceDownloadStartEvent = (function() {

        /**
         * Properties of an AppSourceDownloadStartEvent.
         * @memberof analytics
         * @interface IAppSourceDownloadStartEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppSourceDownloadStartEvent commonProperties
         * @property {string|null} [appId] AppSourceDownloadStartEvent appId
         */

        /**
         * Constructs a new AppSourceDownloadStartEvent.
         * @memberof analytics
         * @classdesc Represents an AppSourceDownloadStartEvent.
         * @implements IAppSourceDownloadStartEvent
         * @constructor
         * @param {analytics.IAppSourceDownloadStartEvent=} [properties] Properties to set
         */
        function AppSourceDownloadStartEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppSourceDownloadStartEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppSourceDownloadStartEvent
         * @instance
         */
        AppSourceDownloadStartEvent.prototype.commonProperties = null;

        /**
         * AppSourceDownloadStartEvent appId.
         * @member {string} appId
         * @memberof analytics.AppSourceDownloadStartEvent
         * @instance
         */
        AppSourceDownloadStartEvent.prototype.appId = "";

        /**
         * Creates a new AppSourceDownloadStartEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppSourceDownloadStartEvent
         * @static
         * @param {analytics.IAppSourceDownloadStartEvent=} [properties] Properties to set
         * @returns {analytics.AppSourceDownloadStartEvent} AppSourceDownloadStartEvent instance
         */
        AppSourceDownloadStartEvent.create = function create(properties) {
            return new AppSourceDownloadStartEvent(properties);
        };

        /**
         * Encodes the specified AppSourceDownloadStartEvent message. Does not implicitly {@link analytics.AppSourceDownloadStartEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppSourceDownloadStartEvent
         * @static
         * @param {analytics.IAppSourceDownloadStartEvent} message AppSourceDownloadStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSourceDownloadStartEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified AppSourceDownloadStartEvent message, length delimited. Does not implicitly {@link analytics.AppSourceDownloadStartEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppSourceDownloadStartEvent
         * @static
         * @param {analytics.IAppSourceDownloadStartEvent} message AppSourceDownloadStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSourceDownloadStartEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppSourceDownloadStartEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppSourceDownloadStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppSourceDownloadStartEvent} AppSourceDownloadStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSourceDownloadStartEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppSourceDownloadStartEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppSourceDownloadStartEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppSourceDownloadStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppSourceDownloadStartEvent} AppSourceDownloadStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSourceDownloadStartEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppSourceDownloadStartEvent message.
         * @function verify
         * @memberof analytics.AppSourceDownloadStartEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppSourceDownloadStartEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates an AppSourceDownloadStartEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppSourceDownloadStartEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppSourceDownloadStartEvent} AppSourceDownloadStartEvent
         */
        AppSourceDownloadStartEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppSourceDownloadStartEvent)
                return object;
            let message = new $root.analytics.AppSourceDownloadStartEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppSourceDownloadStartEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from an AppSourceDownloadStartEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppSourceDownloadStartEvent
         * @static
         * @param {analytics.AppSourceDownloadStartEvent} message AppSourceDownloadStartEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppSourceDownloadStartEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this AppSourceDownloadStartEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppSourceDownloadStartEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppSourceDownloadStartEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppSourceDownloadStartEvent;
    })();

    analytics.AppSourceDownloadEndEvent = (function() {

        /**
         * Properties of an AppSourceDownloadEndEvent.
         * @memberof analytics
         * @interface IAppSourceDownloadEndEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppSourceDownloadEndEvent commonProperties
         * @property {string|null} [appId] AppSourceDownloadEndEvent appId
         * @property {number|null} [timedelta] AppSourceDownloadEndEvent timedelta
         */

        /**
         * Constructs a new AppSourceDownloadEndEvent.
         * @memberof analytics
         * @classdesc Represents an AppSourceDownloadEndEvent.
         * @implements IAppSourceDownloadEndEvent
         * @constructor
         * @param {analytics.IAppSourceDownloadEndEvent=} [properties] Properties to set
         */
        function AppSourceDownloadEndEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppSourceDownloadEndEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppSourceDownloadEndEvent
         * @instance
         */
        AppSourceDownloadEndEvent.prototype.commonProperties = null;

        /**
         * AppSourceDownloadEndEvent appId.
         * @member {string} appId
         * @memberof analytics.AppSourceDownloadEndEvent
         * @instance
         */
        AppSourceDownloadEndEvent.prototype.appId = "";

        /**
         * AppSourceDownloadEndEvent timedelta.
         * @member {number} timedelta
         * @memberof analytics.AppSourceDownloadEndEvent
         * @instance
         */
        AppSourceDownloadEndEvent.prototype.timedelta = 0;

        /**
         * Creates a new AppSourceDownloadEndEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppSourceDownloadEndEvent
         * @static
         * @param {analytics.IAppSourceDownloadEndEvent=} [properties] Properties to set
         * @returns {analytics.AppSourceDownloadEndEvent} AppSourceDownloadEndEvent instance
         */
        AppSourceDownloadEndEvent.create = function create(properties) {
            return new AppSourceDownloadEndEvent(properties);
        };

        /**
         * Encodes the specified AppSourceDownloadEndEvent message. Does not implicitly {@link analytics.AppSourceDownloadEndEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppSourceDownloadEndEvent
         * @static
         * @param {analytics.IAppSourceDownloadEndEvent} message AppSourceDownloadEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSourceDownloadEndEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            if (message.timedelta != null && Object.hasOwnProperty.call(message, "timedelta"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.timedelta);
            return writer;
        };

        /**
         * Encodes the specified AppSourceDownloadEndEvent message, length delimited. Does not implicitly {@link analytics.AppSourceDownloadEndEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppSourceDownloadEndEvent
         * @static
         * @param {analytics.IAppSourceDownloadEndEvent} message AppSourceDownloadEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSourceDownloadEndEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppSourceDownloadEndEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppSourceDownloadEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppSourceDownloadEndEvent} AppSourceDownloadEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSourceDownloadEndEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppSourceDownloadEndEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                case 6:
                    message.timedelta = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppSourceDownloadEndEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppSourceDownloadEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppSourceDownloadEndEvent} AppSourceDownloadEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSourceDownloadEndEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppSourceDownloadEndEvent message.
         * @function verify
         * @memberof analytics.AppSourceDownloadEndEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppSourceDownloadEndEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                if (typeof message.timedelta !== "number")
                    return "timedelta: number expected";
            return null;
        };

        /**
         * Creates an AppSourceDownloadEndEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppSourceDownloadEndEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppSourceDownloadEndEvent} AppSourceDownloadEndEvent
         */
        AppSourceDownloadEndEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppSourceDownloadEndEvent)
                return object;
            let message = new $root.analytics.AppSourceDownloadEndEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppSourceDownloadEndEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.timedelta != null)
                message.timedelta = Number(object.timedelta);
            return message;
        };

        /**
         * Creates a plain object from an AppSourceDownloadEndEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppSourceDownloadEndEvent
         * @static
         * @param {analytics.AppSourceDownloadEndEvent} message AppSourceDownloadEndEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppSourceDownloadEndEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
                object.timedelta = 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                object.timedelta = options.json && !isFinite(message.timedelta) ? String(message.timedelta) : message.timedelta;
            return object;
        };

        /**
         * Converts this AppSourceDownloadEndEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppSourceDownloadEndEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppSourceDownloadEndEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppSourceDownloadEndEvent;
    })();

    analytics.AppSyncStartEvent = (function() {

        /**
         * Properties of an AppSyncStartEvent.
         * @memberof analytics
         * @interface IAppSyncStartEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppSyncStartEvent commonProperties
         * @property {string|null} [appId] AppSyncStartEvent appId
         * @property {boolean|null} [initial] AppSyncStartEvent initial
         */

        /**
         * Constructs a new AppSyncStartEvent.
         * @memberof analytics
         * @classdesc Represents an AppSyncStartEvent.
         * @implements IAppSyncStartEvent
         * @constructor
         * @param {analytics.IAppSyncStartEvent=} [properties] Properties to set
         */
        function AppSyncStartEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppSyncStartEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppSyncStartEvent
         * @instance
         */
        AppSyncStartEvent.prototype.commonProperties = null;

        /**
         * AppSyncStartEvent appId.
         * @member {string} appId
         * @memberof analytics.AppSyncStartEvent
         * @instance
         */
        AppSyncStartEvent.prototype.appId = "";

        /**
         * AppSyncStartEvent initial.
         * @member {boolean} initial
         * @memberof analytics.AppSyncStartEvent
         * @instance
         */
        AppSyncStartEvent.prototype.initial = false;

        /**
         * Creates a new AppSyncStartEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppSyncStartEvent
         * @static
         * @param {analytics.IAppSyncStartEvent=} [properties] Properties to set
         * @returns {analytics.AppSyncStartEvent} AppSyncStartEvent instance
         */
        AppSyncStartEvent.create = function create(properties) {
            return new AppSyncStartEvent(properties);
        };

        /**
         * Encodes the specified AppSyncStartEvent message. Does not implicitly {@link analytics.AppSyncStartEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppSyncStartEvent
         * @static
         * @param {analytics.IAppSyncStartEvent} message AppSyncStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSyncStartEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            if (message.initial != null && Object.hasOwnProperty.call(message, "initial"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.initial);
            return writer;
        };

        /**
         * Encodes the specified AppSyncStartEvent message, length delimited. Does not implicitly {@link analytics.AppSyncStartEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppSyncStartEvent
         * @static
         * @param {analytics.IAppSyncStartEvent} message AppSyncStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSyncStartEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppSyncStartEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppSyncStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppSyncStartEvent} AppSyncStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSyncStartEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppSyncStartEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                case 6:
                    message.initial = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppSyncStartEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppSyncStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppSyncStartEvent} AppSyncStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSyncStartEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppSyncStartEvent message.
         * @function verify
         * @memberof analytics.AppSyncStartEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppSyncStartEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.initial != null && message.hasOwnProperty("initial"))
                if (typeof message.initial !== "boolean")
                    return "initial: boolean expected";
            return null;
        };

        /**
         * Creates an AppSyncStartEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppSyncStartEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppSyncStartEvent} AppSyncStartEvent
         */
        AppSyncStartEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppSyncStartEvent)
                return object;
            let message = new $root.analytics.AppSyncStartEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppSyncStartEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.initial != null)
                message.initial = Boolean(object.initial);
            return message;
        };

        /**
         * Creates a plain object from an AppSyncStartEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppSyncStartEvent
         * @static
         * @param {analytics.AppSyncStartEvent} message AppSyncStartEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppSyncStartEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
                object.initial = false;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.initial != null && message.hasOwnProperty("initial"))
                object.initial = message.initial;
            return object;
        };

        /**
         * Converts this AppSyncStartEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppSyncStartEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppSyncStartEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppSyncStartEvent;
    })();

    analytics.AppSourceUpdateStartEvent = (function() {

        /**
         * Properties of an AppSourceUpdateStartEvent.
         * @memberof analytics
         * @interface IAppSourceUpdateStartEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppSourceUpdateStartEvent commonProperties
         * @property {string|null} [appId] AppSourceUpdateStartEvent appId
         */

        /**
         * Constructs a new AppSourceUpdateStartEvent.
         * @memberof analytics
         * @classdesc Represents an AppSourceUpdateStartEvent.
         * @implements IAppSourceUpdateStartEvent
         * @constructor
         * @param {analytics.IAppSourceUpdateStartEvent=} [properties] Properties to set
         */
        function AppSourceUpdateStartEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppSourceUpdateStartEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppSourceUpdateStartEvent
         * @instance
         */
        AppSourceUpdateStartEvent.prototype.commonProperties = null;

        /**
         * AppSourceUpdateStartEvent appId.
         * @member {string} appId
         * @memberof analytics.AppSourceUpdateStartEvent
         * @instance
         */
        AppSourceUpdateStartEvent.prototype.appId = "";

        /**
         * Creates a new AppSourceUpdateStartEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppSourceUpdateStartEvent
         * @static
         * @param {analytics.IAppSourceUpdateStartEvent=} [properties] Properties to set
         * @returns {analytics.AppSourceUpdateStartEvent} AppSourceUpdateStartEvent instance
         */
        AppSourceUpdateStartEvent.create = function create(properties) {
            return new AppSourceUpdateStartEvent(properties);
        };

        /**
         * Encodes the specified AppSourceUpdateStartEvent message. Does not implicitly {@link analytics.AppSourceUpdateStartEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppSourceUpdateStartEvent
         * @static
         * @param {analytics.IAppSourceUpdateStartEvent} message AppSourceUpdateStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSourceUpdateStartEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified AppSourceUpdateStartEvent message, length delimited. Does not implicitly {@link analytics.AppSourceUpdateStartEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppSourceUpdateStartEvent
         * @static
         * @param {analytics.IAppSourceUpdateStartEvent} message AppSourceUpdateStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSourceUpdateStartEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppSourceUpdateStartEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppSourceUpdateStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppSourceUpdateStartEvent} AppSourceUpdateStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSourceUpdateStartEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppSourceUpdateStartEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppSourceUpdateStartEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppSourceUpdateStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppSourceUpdateStartEvent} AppSourceUpdateStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSourceUpdateStartEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppSourceUpdateStartEvent message.
         * @function verify
         * @memberof analytics.AppSourceUpdateStartEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppSourceUpdateStartEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates an AppSourceUpdateStartEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppSourceUpdateStartEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppSourceUpdateStartEvent} AppSourceUpdateStartEvent
         */
        AppSourceUpdateStartEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppSourceUpdateStartEvent)
                return object;
            let message = new $root.analytics.AppSourceUpdateStartEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppSourceUpdateStartEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from an AppSourceUpdateStartEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppSourceUpdateStartEvent
         * @static
         * @param {analytics.AppSourceUpdateStartEvent} message AppSourceUpdateStartEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppSourceUpdateStartEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this AppSourceUpdateStartEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppSourceUpdateStartEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppSourceUpdateStartEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppSourceUpdateStartEvent;
    })();

    analytics.AppSourceUpdateEndEvent = (function() {

        /**
         * Properties of an AppSourceUpdateEndEvent.
         * @memberof analytics
         * @interface IAppSourceUpdateEndEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppSourceUpdateEndEvent commonProperties
         * @property {string|null} [appId] AppSourceUpdateEndEvent appId
         * @property {number|null} [timedelta] AppSourceUpdateEndEvent timedelta
         */

        /**
         * Constructs a new AppSourceUpdateEndEvent.
         * @memberof analytics
         * @classdesc Represents an AppSourceUpdateEndEvent.
         * @implements IAppSourceUpdateEndEvent
         * @constructor
         * @param {analytics.IAppSourceUpdateEndEvent=} [properties] Properties to set
         */
        function AppSourceUpdateEndEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppSourceUpdateEndEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppSourceUpdateEndEvent
         * @instance
         */
        AppSourceUpdateEndEvent.prototype.commonProperties = null;

        /**
         * AppSourceUpdateEndEvent appId.
         * @member {string} appId
         * @memberof analytics.AppSourceUpdateEndEvent
         * @instance
         */
        AppSourceUpdateEndEvent.prototype.appId = "";

        /**
         * AppSourceUpdateEndEvent timedelta.
         * @member {number} timedelta
         * @memberof analytics.AppSourceUpdateEndEvent
         * @instance
         */
        AppSourceUpdateEndEvent.prototype.timedelta = 0;

        /**
         * Creates a new AppSourceUpdateEndEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppSourceUpdateEndEvent
         * @static
         * @param {analytics.IAppSourceUpdateEndEvent=} [properties] Properties to set
         * @returns {analytics.AppSourceUpdateEndEvent} AppSourceUpdateEndEvent instance
         */
        AppSourceUpdateEndEvent.create = function create(properties) {
            return new AppSourceUpdateEndEvent(properties);
        };

        /**
         * Encodes the specified AppSourceUpdateEndEvent message. Does not implicitly {@link analytics.AppSourceUpdateEndEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppSourceUpdateEndEvent
         * @static
         * @param {analytics.IAppSourceUpdateEndEvent} message AppSourceUpdateEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSourceUpdateEndEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            if (message.timedelta != null && Object.hasOwnProperty.call(message, "timedelta"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.timedelta);
            return writer;
        };

        /**
         * Encodes the specified AppSourceUpdateEndEvent message, length delimited. Does not implicitly {@link analytics.AppSourceUpdateEndEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppSourceUpdateEndEvent
         * @static
         * @param {analytics.IAppSourceUpdateEndEvent} message AppSourceUpdateEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSourceUpdateEndEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppSourceUpdateEndEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppSourceUpdateEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppSourceUpdateEndEvent} AppSourceUpdateEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSourceUpdateEndEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppSourceUpdateEndEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                case 6:
                    message.timedelta = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppSourceUpdateEndEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppSourceUpdateEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppSourceUpdateEndEvent} AppSourceUpdateEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSourceUpdateEndEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppSourceUpdateEndEvent message.
         * @function verify
         * @memberof analytics.AppSourceUpdateEndEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppSourceUpdateEndEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                if (typeof message.timedelta !== "number")
                    return "timedelta: number expected";
            return null;
        };

        /**
         * Creates an AppSourceUpdateEndEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppSourceUpdateEndEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppSourceUpdateEndEvent} AppSourceUpdateEndEvent
         */
        AppSourceUpdateEndEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppSourceUpdateEndEvent)
                return object;
            let message = new $root.analytics.AppSourceUpdateEndEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppSourceUpdateEndEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.timedelta != null)
                message.timedelta = Number(object.timedelta);
            return message;
        };

        /**
         * Creates a plain object from an AppSourceUpdateEndEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppSourceUpdateEndEvent
         * @static
         * @param {analytics.AppSourceUpdateEndEvent} message AppSourceUpdateEndEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppSourceUpdateEndEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
                object.timedelta = 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                object.timedelta = options.json && !isFinite(message.timedelta) ? String(message.timedelta) : message.timedelta;
            return object;
        };

        /**
         * Converts this AppSourceUpdateEndEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppSourceUpdateEndEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppSourceUpdateEndEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppSourceUpdateEndEvent;
    })();

    analytics.AppDependencyInstallStartEvent = (function() {

        /**
         * Properties of an AppDependencyInstallStartEvent.
         * @memberof analytics
         * @interface IAppDependencyInstallStartEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppDependencyInstallStartEvent commonProperties
         * @property {string|null} [appId] AppDependencyInstallStartEvent appId
         */

        /**
         * Constructs a new AppDependencyInstallStartEvent.
         * @memberof analytics
         * @classdesc Represents an AppDependencyInstallStartEvent.
         * @implements IAppDependencyInstallStartEvent
         * @constructor
         * @param {analytics.IAppDependencyInstallStartEvent=} [properties] Properties to set
         */
        function AppDependencyInstallStartEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppDependencyInstallStartEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppDependencyInstallStartEvent
         * @instance
         */
        AppDependencyInstallStartEvent.prototype.commonProperties = null;

        /**
         * AppDependencyInstallStartEvent appId.
         * @member {string} appId
         * @memberof analytics.AppDependencyInstallStartEvent
         * @instance
         */
        AppDependencyInstallStartEvent.prototype.appId = "";

        /**
         * Creates a new AppDependencyInstallStartEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppDependencyInstallStartEvent
         * @static
         * @param {analytics.IAppDependencyInstallStartEvent=} [properties] Properties to set
         * @returns {analytics.AppDependencyInstallStartEvent} AppDependencyInstallStartEvent instance
         */
        AppDependencyInstallStartEvent.create = function create(properties) {
            return new AppDependencyInstallStartEvent(properties);
        };

        /**
         * Encodes the specified AppDependencyInstallStartEvent message. Does not implicitly {@link analytics.AppDependencyInstallStartEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppDependencyInstallStartEvent
         * @static
         * @param {analytics.IAppDependencyInstallStartEvent} message AppDependencyInstallStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppDependencyInstallStartEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified AppDependencyInstallStartEvent message, length delimited. Does not implicitly {@link analytics.AppDependencyInstallStartEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppDependencyInstallStartEvent
         * @static
         * @param {analytics.IAppDependencyInstallStartEvent} message AppDependencyInstallStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppDependencyInstallStartEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppDependencyInstallStartEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppDependencyInstallStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppDependencyInstallStartEvent} AppDependencyInstallStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppDependencyInstallStartEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppDependencyInstallStartEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppDependencyInstallStartEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppDependencyInstallStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppDependencyInstallStartEvent} AppDependencyInstallStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppDependencyInstallStartEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppDependencyInstallStartEvent message.
         * @function verify
         * @memberof analytics.AppDependencyInstallStartEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppDependencyInstallStartEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates an AppDependencyInstallStartEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppDependencyInstallStartEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppDependencyInstallStartEvent} AppDependencyInstallStartEvent
         */
        AppDependencyInstallStartEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppDependencyInstallStartEvent)
                return object;
            let message = new $root.analytics.AppDependencyInstallStartEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppDependencyInstallStartEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from an AppDependencyInstallStartEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppDependencyInstallStartEvent
         * @static
         * @param {analytics.AppDependencyInstallStartEvent} message AppDependencyInstallStartEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppDependencyInstallStartEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this AppDependencyInstallStartEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppDependencyInstallStartEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppDependencyInstallStartEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppDependencyInstallStartEvent;
    })();

    analytics.AppDependencyInstallEndEvent = (function() {

        /**
         * Properties of an AppDependencyInstallEndEvent.
         * @memberof analytics
         * @interface IAppDependencyInstallEndEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppDependencyInstallEndEvent commonProperties
         * @property {string|null} [appId] AppDependencyInstallEndEvent appId
         * @property {number|null} [timedelta] AppDependencyInstallEndEvent timedelta
         * @property {string|null} [packageManager] AppDependencyInstallEndEvent packageManager
         * @property {string|null} [requestedDependencies] AppDependencyInstallEndEvent requestedDependencies
         * @property {number|Long|null} [requestedDependenciesSize] AppDependencyInstallEndEvent requestedDependenciesSize
         * @property {string|null} [requestedAptDependencies] AppDependencyInstallEndEvent requestedAptDependencies
         * @property {number|Long|null} [requestedAptDependenciesSize] AppDependencyInstallEndEvent requestedAptDependenciesSize
         */

        /**
         * Constructs a new AppDependencyInstallEndEvent.
         * @memberof analytics
         * @classdesc Represents an AppDependencyInstallEndEvent.
         * @implements IAppDependencyInstallEndEvent
         * @constructor
         * @param {analytics.IAppDependencyInstallEndEvent=} [properties] Properties to set
         */
        function AppDependencyInstallEndEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppDependencyInstallEndEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppDependencyInstallEndEvent
         * @instance
         */
        AppDependencyInstallEndEvent.prototype.commonProperties = null;

        /**
         * AppDependencyInstallEndEvent appId.
         * @member {string} appId
         * @memberof analytics.AppDependencyInstallEndEvent
         * @instance
         */
        AppDependencyInstallEndEvent.prototype.appId = "";

        /**
         * AppDependencyInstallEndEvent timedelta.
         * @member {number} timedelta
         * @memberof analytics.AppDependencyInstallEndEvent
         * @instance
         */
        AppDependencyInstallEndEvent.prototype.timedelta = 0;

        /**
         * AppDependencyInstallEndEvent packageManager.
         * @member {string} packageManager
         * @memberof analytics.AppDependencyInstallEndEvent
         * @instance
         */
        AppDependencyInstallEndEvent.prototype.packageManager = "";

        /**
         * AppDependencyInstallEndEvent requestedDependencies.
         * @member {string} requestedDependencies
         * @memberof analytics.AppDependencyInstallEndEvent
         * @instance
         */
        AppDependencyInstallEndEvent.prototype.requestedDependencies = "";

        /**
         * AppDependencyInstallEndEvent requestedDependenciesSize.
         * @member {number|Long} requestedDependenciesSize
         * @memberof analytics.AppDependencyInstallEndEvent
         * @instance
         */
        AppDependencyInstallEndEvent.prototype.requestedDependenciesSize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AppDependencyInstallEndEvent requestedAptDependencies.
         * @member {string} requestedAptDependencies
         * @memberof analytics.AppDependencyInstallEndEvent
         * @instance
         */
        AppDependencyInstallEndEvent.prototype.requestedAptDependencies = "";

        /**
         * AppDependencyInstallEndEvent requestedAptDependenciesSize.
         * @member {number|Long} requestedAptDependenciesSize
         * @memberof analytics.AppDependencyInstallEndEvent
         * @instance
         */
        AppDependencyInstallEndEvent.prototype.requestedAptDependenciesSize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new AppDependencyInstallEndEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppDependencyInstallEndEvent
         * @static
         * @param {analytics.IAppDependencyInstallEndEvent=} [properties] Properties to set
         * @returns {analytics.AppDependencyInstallEndEvent} AppDependencyInstallEndEvent instance
         */
        AppDependencyInstallEndEvent.create = function create(properties) {
            return new AppDependencyInstallEndEvent(properties);
        };

        /**
         * Encodes the specified AppDependencyInstallEndEvent message. Does not implicitly {@link analytics.AppDependencyInstallEndEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppDependencyInstallEndEvent
         * @static
         * @param {analytics.IAppDependencyInstallEndEvent} message AppDependencyInstallEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppDependencyInstallEndEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            if (message.timedelta != null && Object.hasOwnProperty.call(message, "timedelta"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.timedelta);
            if (message.packageManager != null && Object.hasOwnProperty.call(message, "packageManager"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.packageManager);
            if (message.requestedDependencies != null && Object.hasOwnProperty.call(message, "requestedDependencies"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.requestedDependencies);
            if (message.requestedDependenciesSize != null && Object.hasOwnProperty.call(message, "requestedDependenciesSize"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.requestedDependenciesSize);
            if (message.requestedAptDependencies != null && Object.hasOwnProperty.call(message, "requestedAptDependencies"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.requestedAptDependencies);
            if (message.requestedAptDependenciesSize != null && Object.hasOwnProperty.call(message, "requestedAptDependenciesSize"))
                writer.uint32(/* id 11, wireType 0 =*/88).int64(message.requestedAptDependenciesSize);
            return writer;
        };

        /**
         * Encodes the specified AppDependencyInstallEndEvent message, length delimited. Does not implicitly {@link analytics.AppDependencyInstallEndEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppDependencyInstallEndEvent
         * @static
         * @param {analytics.IAppDependencyInstallEndEvent} message AppDependencyInstallEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppDependencyInstallEndEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppDependencyInstallEndEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppDependencyInstallEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppDependencyInstallEndEvent} AppDependencyInstallEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppDependencyInstallEndEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppDependencyInstallEndEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                case 6:
                    message.timedelta = reader.float();
                    break;
                case 7:
                    message.packageManager = reader.string();
                    break;
                case 8:
                    message.requestedDependencies = reader.string();
                    break;
                case 9:
                    message.requestedDependenciesSize = reader.int64();
                    break;
                case 10:
                    message.requestedAptDependencies = reader.string();
                    break;
                case 11:
                    message.requestedAptDependenciesSize = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppDependencyInstallEndEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppDependencyInstallEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppDependencyInstallEndEvent} AppDependencyInstallEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppDependencyInstallEndEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppDependencyInstallEndEvent message.
         * @function verify
         * @memberof analytics.AppDependencyInstallEndEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppDependencyInstallEndEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                if (typeof message.timedelta !== "number")
                    return "timedelta: number expected";
            if (message.packageManager != null && message.hasOwnProperty("packageManager"))
                if (!$util.isString(message.packageManager))
                    return "packageManager: string expected";
            if (message.requestedDependencies != null && message.hasOwnProperty("requestedDependencies"))
                if (!$util.isString(message.requestedDependencies))
                    return "requestedDependencies: string expected";
            if (message.requestedDependenciesSize != null && message.hasOwnProperty("requestedDependenciesSize"))
                if (!$util.isInteger(message.requestedDependenciesSize) && !(message.requestedDependenciesSize && $util.isInteger(message.requestedDependenciesSize.low) && $util.isInteger(message.requestedDependenciesSize.high)))
                    return "requestedDependenciesSize: integer|Long expected";
            if (message.requestedAptDependencies != null && message.hasOwnProperty("requestedAptDependencies"))
                if (!$util.isString(message.requestedAptDependencies))
                    return "requestedAptDependencies: string expected";
            if (message.requestedAptDependenciesSize != null && message.hasOwnProperty("requestedAptDependenciesSize"))
                if (!$util.isInteger(message.requestedAptDependenciesSize) && !(message.requestedAptDependenciesSize && $util.isInteger(message.requestedAptDependenciesSize.low) && $util.isInteger(message.requestedAptDependenciesSize.high)))
                    return "requestedAptDependenciesSize: integer|Long expected";
            return null;
        };

        /**
         * Creates an AppDependencyInstallEndEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppDependencyInstallEndEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppDependencyInstallEndEvent} AppDependencyInstallEndEvent
         */
        AppDependencyInstallEndEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppDependencyInstallEndEvent)
                return object;
            let message = new $root.analytics.AppDependencyInstallEndEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppDependencyInstallEndEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.timedelta != null)
                message.timedelta = Number(object.timedelta);
            if (object.packageManager != null)
                message.packageManager = String(object.packageManager);
            if (object.requestedDependencies != null)
                message.requestedDependencies = String(object.requestedDependencies);
            if (object.requestedDependenciesSize != null)
                if ($util.Long)
                    (message.requestedDependenciesSize = $util.Long.fromValue(object.requestedDependenciesSize)).unsigned = false;
                else if (typeof object.requestedDependenciesSize === "string")
                    message.requestedDependenciesSize = parseInt(object.requestedDependenciesSize, 10);
                else if (typeof object.requestedDependenciesSize === "number")
                    message.requestedDependenciesSize = object.requestedDependenciesSize;
                else if (typeof object.requestedDependenciesSize === "object")
                    message.requestedDependenciesSize = new $util.LongBits(object.requestedDependenciesSize.low >>> 0, object.requestedDependenciesSize.high >>> 0).toNumber();
            if (object.requestedAptDependencies != null)
                message.requestedAptDependencies = String(object.requestedAptDependencies);
            if (object.requestedAptDependenciesSize != null)
                if ($util.Long)
                    (message.requestedAptDependenciesSize = $util.Long.fromValue(object.requestedAptDependenciesSize)).unsigned = false;
                else if (typeof object.requestedAptDependenciesSize === "string")
                    message.requestedAptDependenciesSize = parseInt(object.requestedAptDependenciesSize, 10);
                else if (typeof object.requestedAptDependenciesSize === "number")
                    message.requestedAptDependenciesSize = object.requestedAptDependenciesSize;
                else if (typeof object.requestedAptDependenciesSize === "object")
                    message.requestedAptDependenciesSize = new $util.LongBits(object.requestedAptDependenciesSize.low >>> 0, object.requestedAptDependenciesSize.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an AppDependencyInstallEndEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppDependencyInstallEndEvent
         * @static
         * @param {analytics.AppDependencyInstallEndEvent} message AppDependencyInstallEndEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppDependencyInstallEndEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
                object.timedelta = 0;
                object.packageManager = "";
                object.requestedDependencies = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.requestedDependenciesSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.requestedDependenciesSize = options.longs === String ? "0" : 0;
                object.requestedAptDependencies = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.requestedAptDependenciesSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.requestedAptDependenciesSize = options.longs === String ? "0" : 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                object.timedelta = options.json && !isFinite(message.timedelta) ? String(message.timedelta) : message.timedelta;
            if (message.packageManager != null && message.hasOwnProperty("packageManager"))
                object.packageManager = message.packageManager;
            if (message.requestedDependencies != null && message.hasOwnProperty("requestedDependencies"))
                object.requestedDependencies = message.requestedDependencies;
            if (message.requestedDependenciesSize != null && message.hasOwnProperty("requestedDependenciesSize"))
                if (typeof message.requestedDependenciesSize === "number")
                    object.requestedDependenciesSize = options.longs === String ? String(message.requestedDependenciesSize) : message.requestedDependenciesSize;
                else
                    object.requestedDependenciesSize = options.longs === String ? $util.Long.prototype.toString.call(message.requestedDependenciesSize) : options.longs === Number ? new $util.LongBits(message.requestedDependenciesSize.low >>> 0, message.requestedDependenciesSize.high >>> 0).toNumber() : message.requestedDependenciesSize;
            if (message.requestedAptDependencies != null && message.hasOwnProperty("requestedAptDependencies"))
                object.requestedAptDependencies = message.requestedAptDependencies;
            if (message.requestedAptDependenciesSize != null && message.hasOwnProperty("requestedAptDependenciesSize"))
                if (typeof message.requestedAptDependenciesSize === "number")
                    object.requestedAptDependenciesSize = options.longs === String ? String(message.requestedAptDependenciesSize) : message.requestedAptDependenciesSize;
                else
                    object.requestedAptDependenciesSize = options.longs === String ? $util.Long.prototype.toString.call(message.requestedAptDependenciesSize) : options.longs === Number ? new $util.LongBits(message.requestedAptDependenciesSize.low >>> 0, message.requestedAptDependenciesSize.high >>> 0).toNumber() : message.requestedAptDependenciesSize;
            return object;
        };

        /**
         * Converts this AppDependencyInstallEndEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppDependencyInstallEndEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppDependencyInstallEndEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppDependencyInstallEndEvent;
    })();

    analytics.AppSyncEndEvent = (function() {

        /**
         * Properties of an AppSyncEndEvent.
         * @memberof analytics
         * @interface IAppSyncEndEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppSyncEndEvent commonProperties
         * @property {string|null} [appId] AppSyncEndEvent appId
         * @property {number|null} [timedelta] AppSyncEndEvent timedelta
         */

        /**
         * Constructs a new AppSyncEndEvent.
         * @memberof analytics
         * @classdesc Represents an AppSyncEndEvent.
         * @implements IAppSyncEndEvent
         * @constructor
         * @param {analytics.IAppSyncEndEvent=} [properties] Properties to set
         */
        function AppSyncEndEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppSyncEndEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppSyncEndEvent
         * @instance
         */
        AppSyncEndEvent.prototype.commonProperties = null;

        /**
         * AppSyncEndEvent appId.
         * @member {string} appId
         * @memberof analytics.AppSyncEndEvent
         * @instance
         */
        AppSyncEndEvent.prototype.appId = "";

        /**
         * AppSyncEndEvent timedelta.
         * @member {number} timedelta
         * @memberof analytics.AppSyncEndEvent
         * @instance
         */
        AppSyncEndEvent.prototype.timedelta = 0;

        /**
         * Creates a new AppSyncEndEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppSyncEndEvent
         * @static
         * @param {analytics.IAppSyncEndEvent=} [properties] Properties to set
         * @returns {analytics.AppSyncEndEvent} AppSyncEndEvent instance
         */
        AppSyncEndEvent.create = function create(properties) {
            return new AppSyncEndEvent(properties);
        };

        /**
         * Encodes the specified AppSyncEndEvent message. Does not implicitly {@link analytics.AppSyncEndEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppSyncEndEvent
         * @static
         * @param {analytics.IAppSyncEndEvent} message AppSyncEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSyncEndEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            if (message.timedelta != null && Object.hasOwnProperty.call(message, "timedelta"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.timedelta);
            return writer;
        };

        /**
         * Encodes the specified AppSyncEndEvent message, length delimited. Does not implicitly {@link analytics.AppSyncEndEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppSyncEndEvent
         * @static
         * @param {analytics.IAppSyncEndEvent} message AppSyncEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSyncEndEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppSyncEndEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppSyncEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppSyncEndEvent} AppSyncEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSyncEndEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppSyncEndEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                case 6:
                    message.timedelta = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppSyncEndEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppSyncEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppSyncEndEvent} AppSyncEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSyncEndEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppSyncEndEvent message.
         * @function verify
         * @memberof analytics.AppSyncEndEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppSyncEndEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                if (typeof message.timedelta !== "number")
                    return "timedelta: number expected";
            return null;
        };

        /**
         * Creates an AppSyncEndEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppSyncEndEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppSyncEndEvent} AppSyncEndEvent
         */
        AppSyncEndEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppSyncEndEvent)
                return object;
            let message = new $root.analytics.AppSyncEndEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppSyncEndEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.timedelta != null)
                message.timedelta = Number(object.timedelta);
            return message;
        };

        /**
         * Creates a plain object from an AppSyncEndEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppSyncEndEvent
         * @static
         * @param {analytics.AppSyncEndEvent} message AppSyncEndEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppSyncEndEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
                object.timedelta = 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                object.timedelta = options.json && !isFinite(message.timedelta) ? String(message.timedelta) : message.timedelta;
            return object;
        };

        /**
         * Converts this AppSyncEndEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppSyncEndEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppSyncEndEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppSyncEndEvent;
    })();

    analytics.AppReadyEvent = (function() {

        /**
         * Properties of an AppReadyEvent.
         * @memberof analytics
         * @interface IAppReadyEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppReadyEvent commonProperties
         * @property {string|null} [appId] AppReadyEvent appId
         * @property {number|null} [timedelta] AppReadyEvent timedelta
         */

        /**
         * Constructs a new AppReadyEvent.
         * @memberof analytics
         * @classdesc Represents an AppReadyEvent.
         * @implements IAppReadyEvent
         * @constructor
         * @param {analytics.IAppReadyEvent=} [properties] Properties to set
         */
        function AppReadyEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppReadyEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppReadyEvent
         * @instance
         */
        AppReadyEvent.prototype.commonProperties = null;

        /**
         * AppReadyEvent appId.
         * @member {string} appId
         * @memberof analytics.AppReadyEvent
         * @instance
         */
        AppReadyEvent.prototype.appId = "";

        /**
         * AppReadyEvent timedelta.
         * @member {number} timedelta
         * @memberof analytics.AppReadyEvent
         * @instance
         */
        AppReadyEvent.prototype.timedelta = 0;

        /**
         * Creates a new AppReadyEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppReadyEvent
         * @static
         * @param {analytics.IAppReadyEvent=} [properties] Properties to set
         * @returns {analytics.AppReadyEvent} AppReadyEvent instance
         */
        AppReadyEvent.create = function create(properties) {
            return new AppReadyEvent(properties);
        };

        /**
         * Encodes the specified AppReadyEvent message. Does not implicitly {@link analytics.AppReadyEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppReadyEvent
         * @static
         * @param {analytics.IAppReadyEvent} message AppReadyEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppReadyEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            if (message.timedelta != null && Object.hasOwnProperty.call(message, "timedelta"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.timedelta);
            return writer;
        };

        /**
         * Encodes the specified AppReadyEvent message, length delimited. Does not implicitly {@link analytics.AppReadyEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppReadyEvent
         * @static
         * @param {analytics.IAppReadyEvent} message AppReadyEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppReadyEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppReadyEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppReadyEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppReadyEvent} AppReadyEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppReadyEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppReadyEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                case 6:
                    message.timedelta = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppReadyEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppReadyEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppReadyEvent} AppReadyEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppReadyEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppReadyEvent message.
         * @function verify
         * @memberof analytics.AppReadyEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppReadyEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                if (typeof message.timedelta !== "number")
                    return "timedelta: number expected";
            return null;
        };

        /**
         * Creates an AppReadyEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppReadyEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppReadyEvent} AppReadyEvent
         */
        AppReadyEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppReadyEvent)
                return object;
            let message = new $root.analytics.AppReadyEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppReadyEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.timedelta != null)
                message.timedelta = Number(object.timedelta);
            return message;
        };

        /**
         * Creates a plain object from an AppReadyEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppReadyEvent
         * @static
         * @param {analytics.AppReadyEvent} message AppReadyEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppReadyEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
                object.timedelta = 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                object.timedelta = options.json && !isFinite(message.timedelta) ? String(message.timedelta) : message.timedelta;
            return object;
        };

        /**
         * Converts this AppReadyEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppReadyEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppReadyEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppReadyEvent;
    })();

    analytics.AppFirstViewEvent = (function() {

        /**
         * Properties of an AppFirstViewEvent.
         * @memberof analytics
         * @interface IAppFirstViewEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppFirstViewEvent commonProperties
         * @property {string|null} [appId] AppFirstViewEvent appId
         * @property {number|null} [timedelta] AppFirstViewEvent timedelta
         */

        /**
         * Constructs a new AppFirstViewEvent.
         * @memberof analytics
         * @classdesc Represents an AppFirstViewEvent.
         * @implements IAppFirstViewEvent
         * @constructor
         * @param {analytics.IAppFirstViewEvent=} [properties] Properties to set
         */
        function AppFirstViewEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppFirstViewEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppFirstViewEvent
         * @instance
         */
        AppFirstViewEvent.prototype.commonProperties = null;

        /**
         * AppFirstViewEvent appId.
         * @member {string} appId
         * @memberof analytics.AppFirstViewEvent
         * @instance
         */
        AppFirstViewEvent.prototype.appId = "";

        /**
         * AppFirstViewEvent timedelta.
         * @member {number} timedelta
         * @memberof analytics.AppFirstViewEvent
         * @instance
         */
        AppFirstViewEvent.prototype.timedelta = 0;

        /**
         * Creates a new AppFirstViewEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppFirstViewEvent
         * @static
         * @param {analytics.IAppFirstViewEvent=} [properties] Properties to set
         * @returns {analytics.AppFirstViewEvent} AppFirstViewEvent instance
         */
        AppFirstViewEvent.create = function create(properties) {
            return new AppFirstViewEvent(properties);
        };

        /**
         * Encodes the specified AppFirstViewEvent message. Does not implicitly {@link analytics.AppFirstViewEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppFirstViewEvent
         * @static
         * @param {analytics.IAppFirstViewEvent} message AppFirstViewEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppFirstViewEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            if (message.timedelta != null && Object.hasOwnProperty.call(message, "timedelta"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.timedelta);
            return writer;
        };

        /**
         * Encodes the specified AppFirstViewEvent message, length delimited. Does not implicitly {@link analytics.AppFirstViewEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppFirstViewEvent
         * @static
         * @param {analytics.IAppFirstViewEvent} message AppFirstViewEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppFirstViewEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppFirstViewEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppFirstViewEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppFirstViewEvent} AppFirstViewEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppFirstViewEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppFirstViewEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                case 6:
                    message.timedelta = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppFirstViewEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppFirstViewEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppFirstViewEvent} AppFirstViewEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppFirstViewEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppFirstViewEvent message.
         * @function verify
         * @memberof analytics.AppFirstViewEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppFirstViewEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                if (typeof message.timedelta !== "number")
                    return "timedelta: number expected";
            return null;
        };

        /**
         * Creates an AppFirstViewEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppFirstViewEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppFirstViewEvent} AppFirstViewEvent
         */
        AppFirstViewEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppFirstViewEvent)
                return object;
            let message = new $root.analytics.AppFirstViewEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppFirstViewEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.timedelta != null)
                message.timedelta = Number(object.timedelta);
            return message;
        };

        /**
         * Creates a plain object from an AppFirstViewEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppFirstViewEvent
         * @static
         * @param {analytics.AppFirstViewEvent} message AppFirstViewEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppFirstViewEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
                object.timedelta = 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.timedelta != null && message.hasOwnProperty("timedelta"))
                object.timedelta = options.json && !isFinite(message.timedelta) ? String(message.timedelta) : message.timedelta;
            return object;
        };

        /**
         * Converts this AppFirstViewEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppFirstViewEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppFirstViewEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppFirstViewEvent;
    })();

    analytics.HubAppGallerySearchQueryEvent = (function() {

        /**
         * Properties of a HubAppGallerySearchQueryEvent.
         * @memberof analytics
         * @interface IHubAppGallerySearchQueryEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] HubAppGallerySearchQueryEvent commonProperties
         * @property {string|null} [searchId] HubAppGallerySearchQueryEvent searchId
         * @property {string|null} [query] HubAppGallerySearchQueryEvent query
         * @property {string|null} [category] HubAppGallerySearchQueryEvent category
         * @property {string|null} [userId] HubAppGallerySearchQueryEvent userId
         * @property {number|Long|null} [queryDurationMs] HubAppGallerySearchQueryEvent queryDurationMs
         * @property {Array.<analytics.IHubAppGallerySearchResult>|null} [results] HubAppGallerySearchQueryEvent results
         */

        /**
         * Constructs a new HubAppGallerySearchQueryEvent.
         * @memberof analytics
         * @classdesc Represents a HubAppGallerySearchQueryEvent.
         * @implements IHubAppGallerySearchQueryEvent
         * @constructor
         * @param {analytics.IHubAppGallerySearchQueryEvent=} [properties] Properties to set
         */
        function HubAppGallerySearchQueryEvent(properties) {
            this.results = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HubAppGallerySearchQueryEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @instance
         */
        HubAppGallerySearchQueryEvent.prototype.commonProperties = null;

        /**
         * HubAppGallerySearchQueryEvent searchId.
         * @member {string} searchId
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @instance
         */
        HubAppGallerySearchQueryEvent.prototype.searchId = "";

        /**
         * HubAppGallerySearchQueryEvent query.
         * @member {string} query
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @instance
         */
        HubAppGallerySearchQueryEvent.prototype.query = "";

        /**
         * HubAppGallerySearchQueryEvent category.
         * @member {string} category
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @instance
         */
        HubAppGallerySearchQueryEvent.prototype.category = "";

        /**
         * HubAppGallerySearchQueryEvent userId.
         * @member {string} userId
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @instance
         */
        HubAppGallerySearchQueryEvent.prototype.userId = "";

        /**
         * HubAppGallerySearchQueryEvent queryDurationMs.
         * @member {number|Long} queryDurationMs
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @instance
         */
        HubAppGallerySearchQueryEvent.prototype.queryDurationMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HubAppGallerySearchQueryEvent results.
         * @member {Array.<analytics.IHubAppGallerySearchResult>} results
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @instance
         */
        HubAppGallerySearchQueryEvent.prototype.results = $util.emptyArray;

        /**
         * Creates a new HubAppGallerySearchQueryEvent instance using the specified properties.
         * @function create
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @static
         * @param {analytics.IHubAppGallerySearchQueryEvent=} [properties] Properties to set
         * @returns {analytics.HubAppGallerySearchQueryEvent} HubAppGallerySearchQueryEvent instance
         */
        HubAppGallerySearchQueryEvent.create = function create(properties) {
            return new HubAppGallerySearchQueryEvent(properties);
        };

        /**
         * Encodes the specified HubAppGallerySearchQueryEvent message. Does not implicitly {@link analytics.HubAppGallerySearchQueryEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @static
         * @param {analytics.IHubAppGallerySearchQueryEvent} message HubAppGallerySearchQueryEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HubAppGallerySearchQueryEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.searchId != null && Object.hasOwnProperty.call(message, "searchId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.searchId);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.query);
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.category);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.userId);
            if (message.queryDurationMs != null && Object.hasOwnProperty.call(message, "queryDurationMs"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.queryDurationMs);
            if (message.results != null && message.results.length)
                for (let i = 0; i < message.results.length; ++i)
                    $root.analytics.HubAppGallerySearchResult.encode(message.results[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HubAppGallerySearchQueryEvent message, length delimited. Does not implicitly {@link analytics.HubAppGallerySearchQueryEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @static
         * @param {analytics.IHubAppGallerySearchQueryEvent} message HubAppGallerySearchQueryEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HubAppGallerySearchQueryEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HubAppGallerySearchQueryEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.HubAppGallerySearchQueryEvent} HubAppGallerySearchQueryEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HubAppGallerySearchQueryEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.HubAppGallerySearchQueryEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.searchId = reader.string();
                    break;
                case 6:
                    message.query = reader.string();
                    break;
                case 7:
                    message.category = reader.string();
                    break;
                case 8:
                    message.userId = reader.string();
                    break;
                case 9:
                    message.queryDurationMs = reader.int64();
                    break;
                case 10:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.analytics.HubAppGallerySearchResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HubAppGallerySearchQueryEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.HubAppGallerySearchQueryEvent} HubAppGallerySearchQueryEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HubAppGallerySearchQueryEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HubAppGallerySearchQueryEvent message.
         * @function verify
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HubAppGallerySearchQueryEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.searchId != null && message.hasOwnProperty("searchId"))
                if (!$util.isString(message.searchId))
                    return "searchId: string expected";
            if (message.query != null && message.hasOwnProperty("query"))
                if (!$util.isString(message.query))
                    return "query: string expected";
            if (message.category != null && message.hasOwnProperty("category"))
                if (!$util.isString(message.category))
                    return "category: string expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.queryDurationMs != null && message.hasOwnProperty("queryDurationMs"))
                if (!$util.isInteger(message.queryDurationMs) && !(message.queryDurationMs && $util.isInteger(message.queryDurationMs.low) && $util.isInteger(message.queryDurationMs.high)))
                    return "queryDurationMs: integer|Long expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (let i = 0; i < message.results.length; ++i) {
                    let error = $root.analytics.HubAppGallerySearchResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            return null;
        };

        /**
         * Creates a HubAppGallerySearchQueryEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.HubAppGallerySearchQueryEvent} HubAppGallerySearchQueryEvent
         */
        HubAppGallerySearchQueryEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.HubAppGallerySearchQueryEvent)
                return object;
            let message = new $root.analytics.HubAppGallerySearchQueryEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.HubAppGallerySearchQueryEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.searchId != null)
                message.searchId = String(object.searchId);
            if (object.query != null)
                message.query = String(object.query);
            if (object.category != null)
                message.category = String(object.category);
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.queryDurationMs != null)
                if ($util.Long)
                    (message.queryDurationMs = $util.Long.fromValue(object.queryDurationMs)).unsigned = false;
                else if (typeof object.queryDurationMs === "string")
                    message.queryDurationMs = parseInt(object.queryDurationMs, 10);
                else if (typeof object.queryDurationMs === "number")
                    message.queryDurationMs = object.queryDurationMs;
                else if (typeof object.queryDurationMs === "object")
                    message.queryDurationMs = new $util.LongBits(object.queryDurationMs.low >>> 0, object.queryDurationMs.high >>> 0).toNumber();
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".analytics.HubAppGallerySearchQueryEvent.results: array expected");
                message.results = [];
                for (let i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".analytics.HubAppGallerySearchQueryEvent.results: object expected");
                    message.results[i] = $root.analytics.HubAppGallerySearchResult.fromObject(object.results[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a HubAppGallerySearchQueryEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @static
         * @param {analytics.HubAppGallerySearchQueryEvent} message HubAppGallerySearchQueryEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HubAppGallerySearchQueryEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.results = [];
            if (options.defaults) {
                object.commonProperties = null;
                object.searchId = "";
                object.query = "";
                object.category = "";
                object.userId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.queryDurationMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.queryDurationMs = options.longs === String ? "0" : 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.searchId != null && message.hasOwnProperty("searchId"))
                object.searchId = message.searchId;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = message.query;
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = message.category;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.queryDurationMs != null && message.hasOwnProperty("queryDurationMs"))
                if (typeof message.queryDurationMs === "number")
                    object.queryDurationMs = options.longs === String ? String(message.queryDurationMs) : message.queryDurationMs;
                else
                    object.queryDurationMs = options.longs === String ? $util.Long.prototype.toString.call(message.queryDurationMs) : options.longs === Number ? new $util.LongBits(message.queryDurationMs.low >>> 0, message.queryDurationMs.high >>> 0).toNumber() : message.queryDurationMs;
            if (message.results && message.results.length) {
                object.results = [];
                for (let j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.analytics.HubAppGallerySearchResult.toObject(message.results[j], options);
            }
            return object;
        };

        /**
         * Converts this HubAppGallerySearchQueryEvent to JSON.
         * @function toJSON
         * @memberof analytics.HubAppGallerySearchQueryEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HubAppGallerySearchQueryEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HubAppGallerySearchQueryEvent;
    })();

    analytics.HubAppGallerySearchResult = (function() {

        /**
         * Properties of a HubAppGallerySearchResult.
         * @memberof analytics
         * @interface IHubAppGallerySearchResult
         * @property {string|null} [appId] HubAppGallerySearchResult appId
         * @property {string|null} [appStatus] HubAppGallerySearchResult appStatus
         * @property {number|null} [score] HubAppGallerySearchResult score
         * @property {number|Long|null} [uniqueViews] HubAppGallerySearchResult uniqueViews
         */

        /**
         * Constructs a new HubAppGallerySearchResult.
         * @memberof analytics
         * @classdesc Represents a HubAppGallerySearchResult.
         * @implements IHubAppGallerySearchResult
         * @constructor
         * @param {analytics.IHubAppGallerySearchResult=} [properties] Properties to set
         */
        function HubAppGallerySearchResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HubAppGallerySearchResult appId.
         * @member {string} appId
         * @memberof analytics.HubAppGallerySearchResult
         * @instance
         */
        HubAppGallerySearchResult.prototype.appId = "";

        /**
         * HubAppGallerySearchResult appStatus.
         * @member {string} appStatus
         * @memberof analytics.HubAppGallerySearchResult
         * @instance
         */
        HubAppGallerySearchResult.prototype.appStatus = "";

        /**
         * HubAppGallerySearchResult score.
         * @member {number} score
         * @memberof analytics.HubAppGallerySearchResult
         * @instance
         */
        HubAppGallerySearchResult.prototype.score = 0;

        /**
         * HubAppGallerySearchResult uniqueViews.
         * @member {number|Long} uniqueViews
         * @memberof analytics.HubAppGallerySearchResult
         * @instance
         */
        HubAppGallerySearchResult.prototype.uniqueViews = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new HubAppGallerySearchResult instance using the specified properties.
         * @function create
         * @memberof analytics.HubAppGallerySearchResult
         * @static
         * @param {analytics.IHubAppGallerySearchResult=} [properties] Properties to set
         * @returns {analytics.HubAppGallerySearchResult} HubAppGallerySearchResult instance
         */
        HubAppGallerySearchResult.create = function create(properties) {
            return new HubAppGallerySearchResult(properties);
        };

        /**
         * Encodes the specified HubAppGallerySearchResult message. Does not implicitly {@link analytics.HubAppGallerySearchResult.verify|verify} messages.
         * @function encode
         * @memberof analytics.HubAppGallerySearchResult
         * @static
         * @param {analytics.IHubAppGallerySearchResult} message HubAppGallerySearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HubAppGallerySearchResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.appId);
            if (message.appStatus != null && Object.hasOwnProperty.call(message, "appStatus"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.appStatus);
            if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.score);
            if (message.uniqueViews != null && Object.hasOwnProperty.call(message, "uniqueViews"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.uniqueViews);
            return writer;
        };

        /**
         * Encodes the specified HubAppGallerySearchResult message, length delimited. Does not implicitly {@link analytics.HubAppGallerySearchResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.HubAppGallerySearchResult
         * @static
         * @param {analytics.IHubAppGallerySearchResult} message HubAppGallerySearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HubAppGallerySearchResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HubAppGallerySearchResult message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.HubAppGallerySearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.HubAppGallerySearchResult} HubAppGallerySearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HubAppGallerySearchResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.HubAppGallerySearchResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.appId = reader.string();
                    break;
                case 2:
                    message.appStatus = reader.string();
                    break;
                case 3:
                    message.score = reader.float();
                    break;
                case 4:
                    message.uniqueViews = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HubAppGallerySearchResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.HubAppGallerySearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.HubAppGallerySearchResult} HubAppGallerySearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HubAppGallerySearchResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HubAppGallerySearchResult message.
         * @function verify
         * @memberof analytics.HubAppGallerySearchResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HubAppGallerySearchResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.appStatus != null && message.hasOwnProperty("appStatus"))
                if (!$util.isString(message.appStatus))
                    return "appStatus: string expected";
            if (message.score != null && message.hasOwnProperty("score"))
                if (typeof message.score !== "number")
                    return "score: number expected";
            if (message.uniqueViews != null && message.hasOwnProperty("uniqueViews"))
                if (!$util.isInteger(message.uniqueViews) && !(message.uniqueViews && $util.isInteger(message.uniqueViews.low) && $util.isInteger(message.uniqueViews.high)))
                    return "uniqueViews: integer|Long expected";
            return null;
        };

        /**
         * Creates a HubAppGallerySearchResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.HubAppGallerySearchResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.HubAppGallerySearchResult} HubAppGallerySearchResult
         */
        HubAppGallerySearchResult.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.HubAppGallerySearchResult)
                return object;
            let message = new $root.analytics.HubAppGallerySearchResult();
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.appStatus != null)
                message.appStatus = String(object.appStatus);
            if (object.score != null)
                message.score = Number(object.score);
            if (object.uniqueViews != null)
                if ($util.Long)
                    (message.uniqueViews = $util.Long.fromValue(object.uniqueViews)).unsigned = false;
                else if (typeof object.uniqueViews === "string")
                    message.uniqueViews = parseInt(object.uniqueViews, 10);
                else if (typeof object.uniqueViews === "number")
                    message.uniqueViews = object.uniqueViews;
                else if (typeof object.uniqueViews === "object")
                    message.uniqueViews = new $util.LongBits(object.uniqueViews.low >>> 0, object.uniqueViews.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a HubAppGallerySearchResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.HubAppGallerySearchResult
         * @static
         * @param {analytics.HubAppGallerySearchResult} message HubAppGallerySearchResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HubAppGallerySearchResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.appId = "";
                object.appStatus = "";
                object.score = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.uniqueViews = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.uniqueViews = options.longs === String ? "0" : 0;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.appStatus != null && message.hasOwnProperty("appStatus"))
                object.appStatus = message.appStatus;
            if (message.score != null && message.hasOwnProperty("score"))
                object.score = options.json && !isFinite(message.score) ? String(message.score) : message.score;
            if (message.uniqueViews != null && message.hasOwnProperty("uniqueViews"))
                if (typeof message.uniqueViews === "number")
                    object.uniqueViews = options.longs === String ? String(message.uniqueViews) : message.uniqueViews;
                else
                    object.uniqueViews = options.longs === String ? $util.Long.prototype.toString.call(message.uniqueViews) : options.longs === Number ? new $util.LongBits(message.uniqueViews.low >>> 0, message.uniqueViews.high >>> 0).toNumber() : message.uniqueViews;
            return object;
        };

        /**
         * Converts this HubAppGallerySearchResult to JSON.
         * @function toJSON
         * @memberof analytics.HubAppGallerySearchResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HubAppGallerySearchResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HubAppGallerySearchResult;
    })();

    analytics.HubAppGallerySearchResultClickedEvent = (function() {

        /**
         * Properties of a HubAppGallerySearchResultClickedEvent.
         * @memberof analytics
         * @interface IHubAppGallerySearchResultClickedEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] HubAppGallerySearchResultClickedEvent commonProperties
         * @property {string|null} [searchId] HubAppGallerySearchResultClickedEvent searchId
         * @property {string|null} [appId] HubAppGallerySearchResultClickedEvent appId
         * @property {string|null} [appStatus] HubAppGallerySearchResultClickedEvent appStatus
         * @property {number|Long|null} [searchPosition] HubAppGallerySearchResultClickedEvent searchPosition
         */

        /**
         * Constructs a new HubAppGallerySearchResultClickedEvent.
         * @memberof analytics
         * @classdesc Represents a HubAppGallerySearchResultClickedEvent.
         * @implements IHubAppGallerySearchResultClickedEvent
         * @constructor
         * @param {analytics.IHubAppGallerySearchResultClickedEvent=} [properties] Properties to set
         */
        function HubAppGallerySearchResultClickedEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HubAppGallerySearchResultClickedEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @instance
         */
        HubAppGallerySearchResultClickedEvent.prototype.commonProperties = null;

        /**
         * HubAppGallerySearchResultClickedEvent searchId.
         * @member {string} searchId
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @instance
         */
        HubAppGallerySearchResultClickedEvent.prototype.searchId = "";

        /**
         * HubAppGallerySearchResultClickedEvent appId.
         * @member {string} appId
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @instance
         */
        HubAppGallerySearchResultClickedEvent.prototype.appId = "";

        /**
         * HubAppGallerySearchResultClickedEvent appStatus.
         * @member {string} appStatus
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @instance
         */
        HubAppGallerySearchResultClickedEvent.prototype.appStatus = "";

        /**
         * HubAppGallerySearchResultClickedEvent searchPosition.
         * @member {number|Long} searchPosition
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @instance
         */
        HubAppGallerySearchResultClickedEvent.prototype.searchPosition = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new HubAppGallerySearchResultClickedEvent instance using the specified properties.
         * @function create
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @static
         * @param {analytics.IHubAppGallerySearchResultClickedEvent=} [properties] Properties to set
         * @returns {analytics.HubAppGallerySearchResultClickedEvent} HubAppGallerySearchResultClickedEvent instance
         */
        HubAppGallerySearchResultClickedEvent.create = function create(properties) {
            return new HubAppGallerySearchResultClickedEvent(properties);
        };

        /**
         * Encodes the specified HubAppGallerySearchResultClickedEvent message. Does not implicitly {@link analytics.HubAppGallerySearchResultClickedEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @static
         * @param {analytics.IHubAppGallerySearchResultClickedEvent} message HubAppGallerySearchResultClickedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HubAppGallerySearchResultClickedEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.searchId != null && Object.hasOwnProperty.call(message, "searchId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.searchId);
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.appId);
            if (message.appStatus != null && Object.hasOwnProperty.call(message, "appStatus"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.appStatus);
            if (message.searchPosition != null && Object.hasOwnProperty.call(message, "searchPosition"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.searchPosition);
            return writer;
        };

        /**
         * Encodes the specified HubAppGallerySearchResultClickedEvent message, length delimited. Does not implicitly {@link analytics.HubAppGallerySearchResultClickedEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @static
         * @param {analytics.IHubAppGallerySearchResultClickedEvent} message HubAppGallerySearchResultClickedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HubAppGallerySearchResultClickedEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HubAppGallerySearchResultClickedEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.HubAppGallerySearchResultClickedEvent} HubAppGallerySearchResultClickedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HubAppGallerySearchResultClickedEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.HubAppGallerySearchResultClickedEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.searchId = reader.string();
                    break;
                case 6:
                    message.appId = reader.string();
                    break;
                case 7:
                    message.appStatus = reader.string();
                    break;
                case 8:
                    message.searchPosition = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HubAppGallerySearchResultClickedEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.HubAppGallerySearchResultClickedEvent} HubAppGallerySearchResultClickedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HubAppGallerySearchResultClickedEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HubAppGallerySearchResultClickedEvent message.
         * @function verify
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HubAppGallerySearchResultClickedEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.searchId != null && message.hasOwnProperty("searchId"))
                if (!$util.isString(message.searchId))
                    return "searchId: string expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.appStatus != null && message.hasOwnProperty("appStatus"))
                if (!$util.isString(message.appStatus))
                    return "appStatus: string expected";
            if (message.searchPosition != null && message.hasOwnProperty("searchPosition"))
                if (!$util.isInteger(message.searchPosition) && !(message.searchPosition && $util.isInteger(message.searchPosition.low) && $util.isInteger(message.searchPosition.high)))
                    return "searchPosition: integer|Long expected";
            return null;
        };

        /**
         * Creates a HubAppGallerySearchResultClickedEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.HubAppGallerySearchResultClickedEvent} HubAppGallerySearchResultClickedEvent
         */
        HubAppGallerySearchResultClickedEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.HubAppGallerySearchResultClickedEvent)
                return object;
            let message = new $root.analytics.HubAppGallerySearchResultClickedEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.HubAppGallerySearchResultClickedEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.searchId != null)
                message.searchId = String(object.searchId);
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.appStatus != null)
                message.appStatus = String(object.appStatus);
            if (object.searchPosition != null)
                if ($util.Long)
                    (message.searchPosition = $util.Long.fromValue(object.searchPosition)).unsigned = false;
                else if (typeof object.searchPosition === "string")
                    message.searchPosition = parseInt(object.searchPosition, 10);
                else if (typeof object.searchPosition === "number")
                    message.searchPosition = object.searchPosition;
                else if (typeof object.searchPosition === "object")
                    message.searchPosition = new $util.LongBits(object.searchPosition.low >>> 0, object.searchPosition.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a HubAppGallerySearchResultClickedEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @static
         * @param {analytics.HubAppGallerySearchResultClickedEvent} message HubAppGallerySearchResultClickedEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HubAppGallerySearchResultClickedEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.searchId = "";
                object.appId = "";
                object.appStatus = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.searchPosition = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.searchPosition = options.longs === String ? "0" : 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.searchId != null && message.hasOwnProperty("searchId"))
                object.searchId = message.searchId;
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.appStatus != null && message.hasOwnProperty("appStatus"))
                object.appStatus = message.appStatus;
            if (message.searchPosition != null && message.hasOwnProperty("searchPosition"))
                if (typeof message.searchPosition === "number")
                    object.searchPosition = options.longs === String ? String(message.searchPosition) : message.searchPosition;
                else
                    object.searchPosition = options.longs === String ? $util.Long.prototype.toString.call(message.searchPosition) : options.longs === Number ? new $util.LongBits(message.searchPosition.low >>> 0, message.searchPosition.high >>> 0).toNumber() : message.searchPosition;
            return object;
        };

        /**
         * Converts this HubAppGallerySearchResultClickedEvent to JSON.
         * @function toJSON
         * @memberof analytics.HubAppGallerySearchResultClickedEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HubAppGallerySearchResultClickedEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HubAppGallerySearchResultClickedEvent;
    })();

    analytics.HubAppGallerySearchQuerySortingEvent = (function() {

        /**
         * Properties of a HubAppGallerySearchQuerySortingEvent.
         * @memberof analytics
         * @interface IHubAppGallerySearchQuerySortingEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] HubAppGallerySearchQuerySortingEvent commonProperties
         * @property {string|null} [searchId] HubAppGallerySearchQuerySortingEvent searchId
         * @property {string|null} [sortedBy] HubAppGallerySearchQuerySortingEvent sortedBy
         * @property {Array.<string>|null} [appIdsOrdered] HubAppGallerySearchQuerySortingEvent appIdsOrdered
         * @property {boolean|null} [isInitial] HubAppGallerySearchQuerySortingEvent isInitial
         */

        /**
         * Constructs a new HubAppGallerySearchQuerySortingEvent.
         * @memberof analytics
         * @classdesc Represents a HubAppGallerySearchQuerySortingEvent.
         * @implements IHubAppGallerySearchQuerySortingEvent
         * @constructor
         * @param {analytics.IHubAppGallerySearchQuerySortingEvent=} [properties] Properties to set
         */
        function HubAppGallerySearchQuerySortingEvent(properties) {
            this.appIdsOrdered = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HubAppGallerySearchQuerySortingEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @instance
         */
        HubAppGallerySearchQuerySortingEvent.prototype.commonProperties = null;

        /**
         * HubAppGallerySearchQuerySortingEvent searchId.
         * @member {string} searchId
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @instance
         */
        HubAppGallerySearchQuerySortingEvent.prototype.searchId = "";

        /**
         * HubAppGallerySearchQuerySortingEvent sortedBy.
         * @member {string} sortedBy
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @instance
         */
        HubAppGallerySearchQuerySortingEvent.prototype.sortedBy = "";

        /**
         * HubAppGallerySearchQuerySortingEvent appIdsOrdered.
         * @member {Array.<string>} appIdsOrdered
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @instance
         */
        HubAppGallerySearchQuerySortingEvent.prototype.appIdsOrdered = $util.emptyArray;

        /**
         * HubAppGallerySearchQuerySortingEvent isInitial.
         * @member {boolean} isInitial
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @instance
         */
        HubAppGallerySearchQuerySortingEvent.prototype.isInitial = false;

        /**
         * Creates a new HubAppGallerySearchQuerySortingEvent instance using the specified properties.
         * @function create
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @static
         * @param {analytics.IHubAppGallerySearchQuerySortingEvent=} [properties] Properties to set
         * @returns {analytics.HubAppGallerySearchQuerySortingEvent} HubAppGallerySearchQuerySortingEvent instance
         */
        HubAppGallerySearchQuerySortingEvent.create = function create(properties) {
            return new HubAppGallerySearchQuerySortingEvent(properties);
        };

        /**
         * Encodes the specified HubAppGallerySearchQuerySortingEvent message. Does not implicitly {@link analytics.HubAppGallerySearchQuerySortingEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @static
         * @param {analytics.IHubAppGallerySearchQuerySortingEvent} message HubAppGallerySearchQuerySortingEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HubAppGallerySearchQuerySortingEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.searchId != null && Object.hasOwnProperty.call(message, "searchId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.searchId);
            if (message.sortedBy != null && Object.hasOwnProperty.call(message, "sortedBy"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.sortedBy);
            if (message.appIdsOrdered != null && message.appIdsOrdered.length)
                for (let i = 0; i < message.appIdsOrdered.length; ++i)
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.appIdsOrdered[i]);
            if (message.isInitial != null && Object.hasOwnProperty.call(message, "isInitial"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isInitial);
            return writer;
        };

        /**
         * Encodes the specified HubAppGallerySearchQuerySortingEvent message, length delimited. Does not implicitly {@link analytics.HubAppGallerySearchQuerySortingEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @static
         * @param {analytics.IHubAppGallerySearchQuerySortingEvent} message HubAppGallerySearchQuerySortingEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HubAppGallerySearchQuerySortingEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HubAppGallerySearchQuerySortingEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.HubAppGallerySearchQuerySortingEvent} HubAppGallerySearchQuerySortingEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HubAppGallerySearchQuerySortingEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.HubAppGallerySearchQuerySortingEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.searchId = reader.string();
                    break;
                case 6:
                    message.sortedBy = reader.string();
                    break;
                case 7:
                    if (!(message.appIdsOrdered && message.appIdsOrdered.length))
                        message.appIdsOrdered = [];
                    message.appIdsOrdered.push(reader.string());
                    break;
                case 8:
                    message.isInitial = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HubAppGallerySearchQuerySortingEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.HubAppGallerySearchQuerySortingEvent} HubAppGallerySearchQuerySortingEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HubAppGallerySearchQuerySortingEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HubAppGallerySearchQuerySortingEvent message.
         * @function verify
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HubAppGallerySearchQuerySortingEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.searchId != null && message.hasOwnProperty("searchId"))
                if (!$util.isString(message.searchId))
                    return "searchId: string expected";
            if (message.sortedBy != null && message.hasOwnProperty("sortedBy"))
                if (!$util.isString(message.sortedBy))
                    return "sortedBy: string expected";
            if (message.appIdsOrdered != null && message.hasOwnProperty("appIdsOrdered")) {
                if (!Array.isArray(message.appIdsOrdered))
                    return "appIdsOrdered: array expected";
                for (let i = 0; i < message.appIdsOrdered.length; ++i)
                    if (!$util.isString(message.appIdsOrdered[i]))
                        return "appIdsOrdered: string[] expected";
            }
            if (message.isInitial != null && message.hasOwnProperty("isInitial"))
                if (typeof message.isInitial !== "boolean")
                    return "isInitial: boolean expected";
            return null;
        };

        /**
         * Creates a HubAppGallerySearchQuerySortingEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.HubAppGallerySearchQuerySortingEvent} HubAppGallerySearchQuerySortingEvent
         */
        HubAppGallerySearchQuerySortingEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.HubAppGallerySearchQuerySortingEvent)
                return object;
            let message = new $root.analytics.HubAppGallerySearchQuerySortingEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.HubAppGallerySearchQuerySortingEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.searchId != null)
                message.searchId = String(object.searchId);
            if (object.sortedBy != null)
                message.sortedBy = String(object.sortedBy);
            if (object.appIdsOrdered) {
                if (!Array.isArray(object.appIdsOrdered))
                    throw TypeError(".analytics.HubAppGallerySearchQuerySortingEvent.appIdsOrdered: array expected");
                message.appIdsOrdered = [];
                for (let i = 0; i < object.appIdsOrdered.length; ++i)
                    message.appIdsOrdered[i] = String(object.appIdsOrdered[i]);
            }
            if (object.isInitial != null)
                message.isInitial = Boolean(object.isInitial);
            return message;
        };

        /**
         * Creates a plain object from a HubAppGallerySearchQuerySortingEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @static
         * @param {analytics.HubAppGallerySearchQuerySortingEvent} message HubAppGallerySearchQuerySortingEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HubAppGallerySearchQuerySortingEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.appIdsOrdered = [];
            if (options.defaults) {
                object.commonProperties = null;
                object.searchId = "";
                object.sortedBy = "";
                object.isInitial = false;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.searchId != null && message.hasOwnProperty("searchId"))
                object.searchId = message.searchId;
            if (message.sortedBy != null && message.hasOwnProperty("sortedBy"))
                object.sortedBy = message.sortedBy;
            if (message.appIdsOrdered && message.appIdsOrdered.length) {
                object.appIdsOrdered = [];
                for (let j = 0; j < message.appIdsOrdered.length; ++j)
                    object.appIdsOrdered[j] = message.appIdsOrdered[j];
            }
            if (message.isInitial != null && message.hasOwnProperty("isInitial"))
                object.isInitial = message.isInitial;
            return object;
        };

        /**
         * Converts this HubAppGallerySearchQuerySortingEvent to JSON.
         * @function toJSON
         * @memberof analytics.HubAppGallerySearchQuerySortingEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HubAppGallerySearchQuerySortingEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HubAppGallerySearchQuerySortingEvent;
    })();

    analytics.UserActiveEvent = (function() {

        /**
         * Properties of a UserActiveEvent.
         * @memberof analytics
         * @interface IUserActiveEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] UserActiveEvent commonProperties
         * @property {analytics.IPrincipal|null} [user] UserActiveEvent user
         * @property {string|null} [site] UserActiveEvent site
         */

        /**
         * Constructs a new UserActiveEvent.
         * @memberof analytics
         * @classdesc Represents a UserActiveEvent.
         * @implements IUserActiveEvent
         * @constructor
         * @param {analytics.IUserActiveEvent=} [properties] Properties to set
         */
        function UserActiveEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserActiveEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.UserActiveEvent
         * @instance
         */
        UserActiveEvent.prototype.commonProperties = null;

        /**
         * UserActiveEvent user.
         * @member {analytics.IPrincipal|null|undefined} user
         * @memberof analytics.UserActiveEvent
         * @instance
         */
        UserActiveEvent.prototype.user = null;

        /**
         * UserActiveEvent site.
         * @member {string} site
         * @memberof analytics.UserActiveEvent
         * @instance
         */
        UserActiveEvent.prototype.site = "";

        /**
         * Creates a new UserActiveEvent instance using the specified properties.
         * @function create
         * @memberof analytics.UserActiveEvent
         * @static
         * @param {analytics.IUserActiveEvent=} [properties] Properties to set
         * @returns {analytics.UserActiveEvent} UserActiveEvent instance
         */
        UserActiveEvent.create = function create(properties) {
            return new UserActiveEvent(properties);
        };

        /**
         * Encodes the specified UserActiveEvent message. Does not implicitly {@link analytics.UserActiveEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.UserActiveEvent
         * @static
         * @param {analytics.IUserActiveEvent} message UserActiveEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserActiveEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.analytics.Principal.encode(message.user, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.site != null && Object.hasOwnProperty.call(message, "site"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.site);
            return writer;
        };

        /**
         * Encodes the specified UserActiveEvent message, length delimited. Does not implicitly {@link analytics.UserActiveEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.UserActiveEvent
         * @static
         * @param {analytics.IUserActiveEvent} message UserActiveEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserActiveEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserActiveEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.UserActiveEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.UserActiveEvent} UserActiveEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserActiveEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.UserActiveEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.user = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.site = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserActiveEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.UserActiveEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.UserActiveEvent} UserActiveEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserActiveEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserActiveEvent message.
         * @function verify
         * @memberof analytics.UserActiveEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserActiveEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.analytics.Principal.verify(message.user);
                if (error)
                    return "user." + error;
            }
            if (message.site != null && message.hasOwnProperty("site"))
                if (!$util.isString(message.site))
                    return "site: string expected";
            return null;
        };

        /**
         * Creates a UserActiveEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.UserActiveEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.UserActiveEvent} UserActiveEvent
         */
        UserActiveEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.UserActiveEvent)
                return object;
            let message = new $root.analytics.UserActiveEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.UserActiveEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".analytics.UserActiveEvent.user: object expected");
                message.user = $root.analytics.Principal.fromObject(object.user);
            }
            if (object.site != null)
                message.site = String(object.site);
            return message;
        };

        /**
         * Creates a plain object from a UserActiveEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.UserActiveEvent
         * @static
         * @param {analytics.UserActiveEvent} message UserActiveEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserActiveEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.user = null;
                object.site = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.analytics.Principal.toObject(message.user, options);
            if (message.site != null && message.hasOwnProperty("site"))
                object.site = message.site;
            return object;
        };

        /**
         * Converts this UserActiveEvent to JSON.
         * @function toJSON
         * @memberof analytics.UserActiveEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserActiveEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserActiveEvent;
    })();

    analytics.CreateAppClickEvent = (function() {

        /**
         * Properties of a CreateAppClickEvent.
         * @memberof analytics
         * @interface ICreateAppClickEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] CreateAppClickEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] CreateAppClickEvent principal
         */

        /**
         * Constructs a new CreateAppClickEvent.
         * @memberof analytics
         * @classdesc Represents a CreateAppClickEvent.
         * @implements ICreateAppClickEvent
         * @constructor
         * @param {analytics.ICreateAppClickEvent=} [properties] Properties to set
         */
        function CreateAppClickEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateAppClickEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.CreateAppClickEvent
         * @instance
         */
        CreateAppClickEvent.prototype.commonProperties = null;

        /**
         * CreateAppClickEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.CreateAppClickEvent
         * @instance
         */
        CreateAppClickEvent.prototype.principal = null;

        /**
         * Creates a new CreateAppClickEvent instance using the specified properties.
         * @function create
         * @memberof analytics.CreateAppClickEvent
         * @static
         * @param {analytics.ICreateAppClickEvent=} [properties] Properties to set
         * @returns {analytics.CreateAppClickEvent} CreateAppClickEvent instance
         */
        CreateAppClickEvent.create = function create(properties) {
            return new CreateAppClickEvent(properties);
        };

        /**
         * Encodes the specified CreateAppClickEvent message. Does not implicitly {@link analytics.CreateAppClickEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.CreateAppClickEvent
         * @static
         * @param {analytics.ICreateAppClickEvent} message CreateAppClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppClickEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateAppClickEvent message, length delimited. Does not implicitly {@link analytics.CreateAppClickEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.CreateAppClickEvent
         * @static
         * @param {analytics.ICreateAppClickEvent} message CreateAppClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppClickEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateAppClickEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.CreateAppClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.CreateAppClickEvent} CreateAppClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppClickEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.CreateAppClickEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateAppClickEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.CreateAppClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.CreateAppClickEvent} CreateAppClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppClickEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateAppClickEvent message.
         * @function verify
         * @memberof analytics.CreateAppClickEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateAppClickEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            return null;
        };

        /**
         * Creates a CreateAppClickEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.CreateAppClickEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.CreateAppClickEvent} CreateAppClickEvent
         */
        CreateAppClickEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.CreateAppClickEvent)
                return object;
            let message = new $root.analytics.CreateAppClickEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.CreateAppClickEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.CreateAppClickEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateAppClickEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.CreateAppClickEvent
         * @static
         * @param {analytics.CreateAppClickEvent} message CreateAppClickEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateAppClickEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            return object;
        };

        /**
         * Converts this CreateAppClickEvent to JSON.
         * @function toJSON
         * @memberof analytics.CreateAppClickEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateAppClickEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateAppClickEvent;
    })();

    analytics.TemplateOnDashboardClickEvent = (function() {

        /**
         * Properties of a TemplateOnDashboardClickEvent.
         * @memberof analytics
         * @interface ITemplateOnDashboardClickEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] TemplateOnDashboardClickEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] TemplateOnDashboardClickEvent principal
         * @property {string|null} [templateId] TemplateOnDashboardClickEvent templateId
         */

        /**
         * Constructs a new TemplateOnDashboardClickEvent.
         * @memberof analytics
         * @classdesc Represents a TemplateOnDashboardClickEvent.
         * @implements ITemplateOnDashboardClickEvent
         * @constructor
         * @param {analytics.ITemplateOnDashboardClickEvent=} [properties] Properties to set
         */
        function TemplateOnDashboardClickEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TemplateOnDashboardClickEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @instance
         */
        TemplateOnDashboardClickEvent.prototype.commonProperties = null;

        /**
         * TemplateOnDashboardClickEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @instance
         */
        TemplateOnDashboardClickEvent.prototype.principal = null;

        /**
         * TemplateOnDashboardClickEvent templateId.
         * @member {string} templateId
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @instance
         */
        TemplateOnDashboardClickEvent.prototype.templateId = "";

        /**
         * Creates a new TemplateOnDashboardClickEvent instance using the specified properties.
         * @function create
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @static
         * @param {analytics.ITemplateOnDashboardClickEvent=} [properties] Properties to set
         * @returns {analytics.TemplateOnDashboardClickEvent} TemplateOnDashboardClickEvent instance
         */
        TemplateOnDashboardClickEvent.create = function create(properties) {
            return new TemplateOnDashboardClickEvent(properties);
        };

        /**
         * Encodes the specified TemplateOnDashboardClickEvent message. Does not implicitly {@link analytics.TemplateOnDashboardClickEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @static
         * @param {analytics.ITemplateOnDashboardClickEvent} message TemplateOnDashboardClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TemplateOnDashboardClickEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.templateId != null && Object.hasOwnProperty.call(message, "templateId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.templateId);
            return writer;
        };

        /**
         * Encodes the specified TemplateOnDashboardClickEvent message, length delimited. Does not implicitly {@link analytics.TemplateOnDashboardClickEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @static
         * @param {analytics.ITemplateOnDashboardClickEvent} message TemplateOnDashboardClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TemplateOnDashboardClickEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TemplateOnDashboardClickEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.TemplateOnDashboardClickEvent} TemplateOnDashboardClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TemplateOnDashboardClickEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.TemplateOnDashboardClickEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.templateId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TemplateOnDashboardClickEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.TemplateOnDashboardClickEvent} TemplateOnDashboardClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TemplateOnDashboardClickEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TemplateOnDashboardClickEvent message.
         * @function verify
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TemplateOnDashboardClickEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.templateId != null && message.hasOwnProperty("templateId"))
                if (!$util.isString(message.templateId))
                    return "templateId: string expected";
            return null;
        };

        /**
         * Creates a TemplateOnDashboardClickEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.TemplateOnDashboardClickEvent} TemplateOnDashboardClickEvent
         */
        TemplateOnDashboardClickEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.TemplateOnDashboardClickEvent)
                return object;
            let message = new $root.analytics.TemplateOnDashboardClickEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.TemplateOnDashboardClickEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.TemplateOnDashboardClickEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.templateId != null)
                message.templateId = String(object.templateId);
            return message;
        };

        /**
         * Creates a plain object from a TemplateOnDashboardClickEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @static
         * @param {analytics.TemplateOnDashboardClickEvent} message TemplateOnDashboardClickEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TemplateOnDashboardClickEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
                object.templateId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.templateId != null && message.hasOwnProperty("templateId"))
                object.templateId = message.templateId;
            return object;
        };

        /**
         * Converts this TemplateOnDashboardClickEvent to JSON.
         * @function toJSON
         * @memberof analytics.TemplateOnDashboardClickEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TemplateOnDashboardClickEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TemplateOnDashboardClickEvent;
    })();

    analytics.ViewDemoClickEvent = (function() {

        /**
         * Properties of a ViewDemoClickEvent.
         * @memberof analytics
         * @interface IViewDemoClickEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] ViewDemoClickEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] ViewDemoClickEvent principal
         * @property {string|null} [templateId] ViewDemoClickEvent templateId
         * @property {analytics.ViewDemoClickEvent.Source|null} [source] ViewDemoClickEvent source
         */

        /**
         * Constructs a new ViewDemoClickEvent.
         * @memberof analytics
         * @classdesc Represents a ViewDemoClickEvent.
         * @implements IViewDemoClickEvent
         * @constructor
         * @param {analytics.IViewDemoClickEvent=} [properties] Properties to set
         */
        function ViewDemoClickEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ViewDemoClickEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.ViewDemoClickEvent
         * @instance
         */
        ViewDemoClickEvent.prototype.commonProperties = null;

        /**
         * ViewDemoClickEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.ViewDemoClickEvent
         * @instance
         */
        ViewDemoClickEvent.prototype.principal = null;

        /**
         * ViewDemoClickEvent templateId.
         * @member {string} templateId
         * @memberof analytics.ViewDemoClickEvent
         * @instance
         */
        ViewDemoClickEvent.prototype.templateId = "";

        /**
         * ViewDemoClickEvent source.
         * @member {analytics.ViewDemoClickEvent.Source} source
         * @memberof analytics.ViewDemoClickEvent
         * @instance
         */
        ViewDemoClickEvent.prototype.source = 0;

        /**
         * Creates a new ViewDemoClickEvent instance using the specified properties.
         * @function create
         * @memberof analytics.ViewDemoClickEvent
         * @static
         * @param {analytics.IViewDemoClickEvent=} [properties] Properties to set
         * @returns {analytics.ViewDemoClickEvent} ViewDemoClickEvent instance
         */
        ViewDemoClickEvent.create = function create(properties) {
            return new ViewDemoClickEvent(properties);
        };

        /**
         * Encodes the specified ViewDemoClickEvent message. Does not implicitly {@link analytics.ViewDemoClickEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.ViewDemoClickEvent
         * @static
         * @param {analytics.IViewDemoClickEvent} message ViewDemoClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ViewDemoClickEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.templateId != null && Object.hasOwnProperty.call(message, "templateId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.templateId);
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.source);
            return writer;
        };

        /**
         * Encodes the specified ViewDemoClickEvent message, length delimited. Does not implicitly {@link analytics.ViewDemoClickEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.ViewDemoClickEvent
         * @static
         * @param {analytics.IViewDemoClickEvent} message ViewDemoClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ViewDemoClickEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ViewDemoClickEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.ViewDemoClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.ViewDemoClickEvent} ViewDemoClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ViewDemoClickEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.ViewDemoClickEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.templateId = reader.string();
                    break;
                case 7:
                    message.source = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ViewDemoClickEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.ViewDemoClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.ViewDemoClickEvent} ViewDemoClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ViewDemoClickEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ViewDemoClickEvent message.
         * @function verify
         * @memberof analytics.ViewDemoClickEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ViewDemoClickEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.templateId != null && message.hasOwnProperty("templateId"))
                if (!$util.isString(message.templateId))
                    return "templateId: string expected";
            if (message.source != null && message.hasOwnProperty("source"))
                switch (message.source) {
                default:
                    return "source: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a ViewDemoClickEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.ViewDemoClickEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.ViewDemoClickEvent} ViewDemoClickEvent
         */
        ViewDemoClickEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.ViewDemoClickEvent)
                return object;
            let message = new $root.analytics.ViewDemoClickEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.ViewDemoClickEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.ViewDemoClickEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.templateId != null)
                message.templateId = String(object.templateId);
            switch (object.source) {
            case "DASHBOARD_PAGE":
            case 0:
                message.source = 0;
                break;
            case "TEMPLATE_PICKER_PAGE":
            case 1:
                message.source = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ViewDemoClickEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.ViewDemoClickEvent
         * @static
         * @param {analytics.ViewDemoClickEvent} message ViewDemoClickEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ViewDemoClickEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
                object.templateId = "";
                object.source = options.enums === String ? "DASHBOARD_PAGE" : 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.templateId != null && message.hasOwnProperty("templateId"))
                object.templateId = message.templateId;
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = options.enums === String ? $root.analytics.ViewDemoClickEvent.Source[message.source] : message.source;
            return object;
        };

        /**
         * Converts this ViewDemoClickEvent to JSON.
         * @function toJSON
         * @memberof analytics.ViewDemoClickEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ViewDemoClickEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Source enum.
         * @name analytics.ViewDemoClickEvent.Source
         * @enum {number}
         * @property {number} DASHBOARD_PAGE=0 DASHBOARD_PAGE value
         * @property {number} TEMPLATE_PICKER_PAGE=1 TEMPLATE_PICKER_PAGE value
         */
        ViewDemoClickEvent.Source = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DASHBOARD_PAGE"] = 0;
            values[valuesById[1] = "TEMPLATE_PICKER_PAGE"] = 1;
            return values;
        })();

        return ViewDemoClickEvent;
    })();

    analytics.CreateAppFromRepoClickEvent = (function() {

        /**
         * Properties of a CreateAppFromRepoClickEvent.
         * @memberof analytics
         * @interface ICreateAppFromRepoClickEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] CreateAppFromRepoClickEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] CreateAppFromRepoClickEvent principal
         */

        /**
         * Constructs a new CreateAppFromRepoClickEvent.
         * @memberof analytics
         * @classdesc Represents a CreateAppFromRepoClickEvent.
         * @implements ICreateAppFromRepoClickEvent
         * @constructor
         * @param {analytics.ICreateAppFromRepoClickEvent=} [properties] Properties to set
         */
        function CreateAppFromRepoClickEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateAppFromRepoClickEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.CreateAppFromRepoClickEvent
         * @instance
         */
        CreateAppFromRepoClickEvent.prototype.commonProperties = null;

        /**
         * CreateAppFromRepoClickEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.CreateAppFromRepoClickEvent
         * @instance
         */
        CreateAppFromRepoClickEvent.prototype.principal = null;

        /**
         * Creates a new CreateAppFromRepoClickEvent instance using the specified properties.
         * @function create
         * @memberof analytics.CreateAppFromRepoClickEvent
         * @static
         * @param {analytics.ICreateAppFromRepoClickEvent=} [properties] Properties to set
         * @returns {analytics.CreateAppFromRepoClickEvent} CreateAppFromRepoClickEvent instance
         */
        CreateAppFromRepoClickEvent.create = function create(properties) {
            return new CreateAppFromRepoClickEvent(properties);
        };

        /**
         * Encodes the specified CreateAppFromRepoClickEvent message. Does not implicitly {@link analytics.CreateAppFromRepoClickEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.CreateAppFromRepoClickEvent
         * @static
         * @param {analytics.ICreateAppFromRepoClickEvent} message CreateAppFromRepoClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppFromRepoClickEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateAppFromRepoClickEvent message, length delimited. Does not implicitly {@link analytics.CreateAppFromRepoClickEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.CreateAppFromRepoClickEvent
         * @static
         * @param {analytics.ICreateAppFromRepoClickEvent} message CreateAppFromRepoClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppFromRepoClickEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateAppFromRepoClickEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.CreateAppFromRepoClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.CreateAppFromRepoClickEvent} CreateAppFromRepoClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppFromRepoClickEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.CreateAppFromRepoClickEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateAppFromRepoClickEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.CreateAppFromRepoClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.CreateAppFromRepoClickEvent} CreateAppFromRepoClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppFromRepoClickEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateAppFromRepoClickEvent message.
         * @function verify
         * @memberof analytics.CreateAppFromRepoClickEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateAppFromRepoClickEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            return null;
        };

        /**
         * Creates a CreateAppFromRepoClickEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.CreateAppFromRepoClickEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.CreateAppFromRepoClickEvent} CreateAppFromRepoClickEvent
         */
        CreateAppFromRepoClickEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.CreateAppFromRepoClickEvent)
                return object;
            let message = new $root.analytics.CreateAppFromRepoClickEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.CreateAppFromRepoClickEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.CreateAppFromRepoClickEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateAppFromRepoClickEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.CreateAppFromRepoClickEvent
         * @static
         * @param {analytics.CreateAppFromRepoClickEvent} message CreateAppFromRepoClickEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateAppFromRepoClickEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            return object;
        };

        /**
         * Converts this CreateAppFromRepoClickEvent to JSON.
         * @function toJSON
         * @memberof analytics.CreateAppFromRepoClickEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateAppFromRepoClickEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateAppFromRepoClickEvent;
    })();

    analytics.CreateAppFromTemplateClickEvent = (function() {

        /**
         * Properties of a CreateAppFromTemplateClickEvent.
         * @memberof analytics
         * @interface ICreateAppFromTemplateClickEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] CreateAppFromTemplateClickEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] CreateAppFromTemplateClickEvent principal
         */

        /**
         * Constructs a new CreateAppFromTemplateClickEvent.
         * @memberof analytics
         * @classdesc Represents a CreateAppFromTemplateClickEvent.
         * @implements ICreateAppFromTemplateClickEvent
         * @constructor
         * @param {analytics.ICreateAppFromTemplateClickEvent=} [properties] Properties to set
         */
        function CreateAppFromTemplateClickEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateAppFromTemplateClickEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.CreateAppFromTemplateClickEvent
         * @instance
         */
        CreateAppFromTemplateClickEvent.prototype.commonProperties = null;

        /**
         * CreateAppFromTemplateClickEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.CreateAppFromTemplateClickEvent
         * @instance
         */
        CreateAppFromTemplateClickEvent.prototype.principal = null;

        /**
         * Creates a new CreateAppFromTemplateClickEvent instance using the specified properties.
         * @function create
         * @memberof analytics.CreateAppFromTemplateClickEvent
         * @static
         * @param {analytics.ICreateAppFromTemplateClickEvent=} [properties] Properties to set
         * @returns {analytics.CreateAppFromTemplateClickEvent} CreateAppFromTemplateClickEvent instance
         */
        CreateAppFromTemplateClickEvent.create = function create(properties) {
            return new CreateAppFromTemplateClickEvent(properties);
        };

        /**
         * Encodes the specified CreateAppFromTemplateClickEvent message. Does not implicitly {@link analytics.CreateAppFromTemplateClickEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.CreateAppFromTemplateClickEvent
         * @static
         * @param {analytics.ICreateAppFromTemplateClickEvent} message CreateAppFromTemplateClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppFromTemplateClickEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateAppFromTemplateClickEvent message, length delimited. Does not implicitly {@link analytics.CreateAppFromTemplateClickEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.CreateAppFromTemplateClickEvent
         * @static
         * @param {analytics.ICreateAppFromTemplateClickEvent} message CreateAppFromTemplateClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppFromTemplateClickEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateAppFromTemplateClickEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.CreateAppFromTemplateClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.CreateAppFromTemplateClickEvent} CreateAppFromTemplateClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppFromTemplateClickEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.CreateAppFromTemplateClickEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateAppFromTemplateClickEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.CreateAppFromTemplateClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.CreateAppFromTemplateClickEvent} CreateAppFromTemplateClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppFromTemplateClickEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateAppFromTemplateClickEvent message.
         * @function verify
         * @memberof analytics.CreateAppFromTemplateClickEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateAppFromTemplateClickEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            return null;
        };

        /**
         * Creates a CreateAppFromTemplateClickEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.CreateAppFromTemplateClickEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.CreateAppFromTemplateClickEvent} CreateAppFromTemplateClickEvent
         */
        CreateAppFromTemplateClickEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.CreateAppFromTemplateClickEvent)
                return object;
            let message = new $root.analytics.CreateAppFromTemplateClickEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.CreateAppFromTemplateClickEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.CreateAppFromTemplateClickEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateAppFromTemplateClickEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.CreateAppFromTemplateClickEvent
         * @static
         * @param {analytics.CreateAppFromTemplateClickEvent} message CreateAppFromTemplateClickEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateAppFromTemplateClickEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            return object;
        };

        /**
         * Converts this CreateAppFromTemplateClickEvent to JSON.
         * @function toJSON
         * @memberof analytics.CreateAppFromTemplateClickEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateAppFromTemplateClickEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateAppFromTemplateClickEvent;
    })();

    analytics.CreateAppFromRepoDeployClickEvent = (function() {

        /**
         * Properties of a CreateAppFromRepoDeployClickEvent.
         * @memberof analytics
         * @interface ICreateAppFromRepoDeployClickEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] CreateAppFromRepoDeployClickEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] CreateAppFromRepoDeployClickEvent principal
         * @property {string|null} [repository] CreateAppFromRepoDeployClickEvent repository
         * @property {string|null} [branch] CreateAppFromRepoDeployClickEvent branch
         * @property {string|null} [mainModule] CreateAppFromRepoDeployClickEvent mainModule
         */

        /**
         * Constructs a new CreateAppFromRepoDeployClickEvent.
         * @memberof analytics
         * @classdesc Represents a CreateAppFromRepoDeployClickEvent.
         * @implements ICreateAppFromRepoDeployClickEvent
         * @constructor
         * @param {analytics.ICreateAppFromRepoDeployClickEvent=} [properties] Properties to set
         */
        function CreateAppFromRepoDeployClickEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateAppFromRepoDeployClickEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @instance
         */
        CreateAppFromRepoDeployClickEvent.prototype.commonProperties = null;

        /**
         * CreateAppFromRepoDeployClickEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @instance
         */
        CreateAppFromRepoDeployClickEvent.prototype.principal = null;

        /**
         * CreateAppFromRepoDeployClickEvent repository.
         * @member {string} repository
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @instance
         */
        CreateAppFromRepoDeployClickEvent.prototype.repository = "";

        /**
         * CreateAppFromRepoDeployClickEvent branch.
         * @member {string} branch
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @instance
         */
        CreateAppFromRepoDeployClickEvent.prototype.branch = "";

        /**
         * CreateAppFromRepoDeployClickEvent mainModule.
         * @member {string} mainModule
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @instance
         */
        CreateAppFromRepoDeployClickEvent.prototype.mainModule = "";

        /**
         * Creates a new CreateAppFromRepoDeployClickEvent instance using the specified properties.
         * @function create
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @static
         * @param {analytics.ICreateAppFromRepoDeployClickEvent=} [properties] Properties to set
         * @returns {analytics.CreateAppFromRepoDeployClickEvent} CreateAppFromRepoDeployClickEvent instance
         */
        CreateAppFromRepoDeployClickEvent.create = function create(properties) {
            return new CreateAppFromRepoDeployClickEvent(properties);
        };

        /**
         * Encodes the specified CreateAppFromRepoDeployClickEvent message. Does not implicitly {@link analytics.CreateAppFromRepoDeployClickEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @static
         * @param {analytics.ICreateAppFromRepoDeployClickEvent} message CreateAppFromRepoDeployClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppFromRepoDeployClickEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.repository != null && Object.hasOwnProperty.call(message, "repository"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.repository);
            if (message.branch != null && Object.hasOwnProperty.call(message, "branch"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.branch);
            if (message.mainModule != null && Object.hasOwnProperty.call(message, "mainModule"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.mainModule);
            return writer;
        };

        /**
         * Encodes the specified CreateAppFromRepoDeployClickEvent message, length delimited. Does not implicitly {@link analytics.CreateAppFromRepoDeployClickEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @static
         * @param {analytics.ICreateAppFromRepoDeployClickEvent} message CreateAppFromRepoDeployClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppFromRepoDeployClickEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateAppFromRepoDeployClickEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.CreateAppFromRepoDeployClickEvent} CreateAppFromRepoDeployClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppFromRepoDeployClickEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.CreateAppFromRepoDeployClickEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.repository = reader.string();
                    break;
                case 7:
                    message.branch = reader.string();
                    break;
                case 8:
                    message.mainModule = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateAppFromRepoDeployClickEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.CreateAppFromRepoDeployClickEvent} CreateAppFromRepoDeployClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppFromRepoDeployClickEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateAppFromRepoDeployClickEvent message.
         * @function verify
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateAppFromRepoDeployClickEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.repository != null && message.hasOwnProperty("repository"))
                if (!$util.isString(message.repository))
                    return "repository: string expected";
            if (message.branch != null && message.hasOwnProperty("branch"))
                if (!$util.isString(message.branch))
                    return "branch: string expected";
            if (message.mainModule != null && message.hasOwnProperty("mainModule"))
                if (!$util.isString(message.mainModule))
                    return "mainModule: string expected";
            return null;
        };

        /**
         * Creates a CreateAppFromRepoDeployClickEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.CreateAppFromRepoDeployClickEvent} CreateAppFromRepoDeployClickEvent
         */
        CreateAppFromRepoDeployClickEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.CreateAppFromRepoDeployClickEvent)
                return object;
            let message = new $root.analytics.CreateAppFromRepoDeployClickEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.CreateAppFromRepoDeployClickEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.CreateAppFromRepoDeployClickEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.repository != null)
                message.repository = String(object.repository);
            if (object.branch != null)
                message.branch = String(object.branch);
            if (object.mainModule != null)
                message.mainModule = String(object.mainModule);
            return message;
        };

        /**
         * Creates a plain object from a CreateAppFromRepoDeployClickEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @static
         * @param {analytics.CreateAppFromRepoDeployClickEvent} message CreateAppFromRepoDeployClickEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateAppFromRepoDeployClickEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
                object.repository = "";
                object.branch = "";
                object.mainModule = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.repository != null && message.hasOwnProperty("repository"))
                object.repository = message.repository;
            if (message.branch != null && message.hasOwnProperty("branch"))
                object.branch = message.branch;
            if (message.mainModule != null && message.hasOwnProperty("mainModule"))
                object.mainModule = message.mainModule;
            return object;
        };

        /**
         * Converts this CreateAppFromRepoDeployClickEvent to JSON.
         * @function toJSON
         * @memberof analytics.CreateAppFromRepoDeployClickEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateAppFromRepoDeployClickEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateAppFromRepoDeployClickEvent;
    })();

    analytics.CreateAppFromForkDeployClickEvent = (function() {

        /**
         * Properties of a CreateAppFromForkDeployClickEvent.
         * @memberof analytics
         * @interface ICreateAppFromForkDeployClickEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] CreateAppFromForkDeployClickEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] CreateAppFromForkDeployClickEvent principal
         * @property {string|null} [sourceAppId] CreateAppFromForkDeployClickEvent sourceAppId
         * @property {string|null} [repository] CreateAppFromForkDeployClickEvent repository
         * @property {boolean|null} [openCodespaces] CreateAppFromForkDeployClickEvent openCodespaces
         * @property {string|null} [branch] CreateAppFromForkDeployClickEvent branch
         * @property {string|null} [mainModule] CreateAppFromForkDeployClickEvent mainModule
         */

        /**
         * Constructs a new CreateAppFromForkDeployClickEvent.
         * @memberof analytics
         * @classdesc Represents a CreateAppFromForkDeployClickEvent.
         * @implements ICreateAppFromForkDeployClickEvent
         * @constructor
         * @param {analytics.ICreateAppFromForkDeployClickEvent=} [properties] Properties to set
         */
        function CreateAppFromForkDeployClickEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateAppFromForkDeployClickEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @instance
         */
        CreateAppFromForkDeployClickEvent.prototype.commonProperties = null;

        /**
         * CreateAppFromForkDeployClickEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @instance
         */
        CreateAppFromForkDeployClickEvent.prototype.principal = null;

        /**
         * CreateAppFromForkDeployClickEvent sourceAppId.
         * @member {string} sourceAppId
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @instance
         */
        CreateAppFromForkDeployClickEvent.prototype.sourceAppId = "";

        /**
         * CreateAppFromForkDeployClickEvent repository.
         * @member {string} repository
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @instance
         */
        CreateAppFromForkDeployClickEvent.prototype.repository = "";

        /**
         * CreateAppFromForkDeployClickEvent openCodespaces.
         * @member {boolean} openCodespaces
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @instance
         */
        CreateAppFromForkDeployClickEvent.prototype.openCodespaces = false;

        /**
         * CreateAppFromForkDeployClickEvent branch.
         * @member {string} branch
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @instance
         */
        CreateAppFromForkDeployClickEvent.prototype.branch = "";

        /**
         * CreateAppFromForkDeployClickEvent mainModule.
         * @member {string} mainModule
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @instance
         */
        CreateAppFromForkDeployClickEvent.prototype.mainModule = "";

        /**
         * Creates a new CreateAppFromForkDeployClickEvent instance using the specified properties.
         * @function create
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @static
         * @param {analytics.ICreateAppFromForkDeployClickEvent=} [properties] Properties to set
         * @returns {analytics.CreateAppFromForkDeployClickEvent} CreateAppFromForkDeployClickEvent instance
         */
        CreateAppFromForkDeployClickEvent.create = function create(properties) {
            return new CreateAppFromForkDeployClickEvent(properties);
        };

        /**
         * Encodes the specified CreateAppFromForkDeployClickEvent message. Does not implicitly {@link analytics.CreateAppFromForkDeployClickEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @static
         * @param {analytics.ICreateAppFromForkDeployClickEvent} message CreateAppFromForkDeployClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppFromForkDeployClickEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.sourceAppId != null && Object.hasOwnProperty.call(message, "sourceAppId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.sourceAppId);
            if (message.repository != null && Object.hasOwnProperty.call(message, "repository"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.repository);
            if (message.openCodespaces != null && Object.hasOwnProperty.call(message, "openCodespaces"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.openCodespaces);
            if (message.branch != null && Object.hasOwnProperty.call(message, "branch"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.branch);
            if (message.mainModule != null && Object.hasOwnProperty.call(message, "mainModule"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.mainModule);
            return writer;
        };

        /**
         * Encodes the specified CreateAppFromForkDeployClickEvent message, length delimited. Does not implicitly {@link analytics.CreateAppFromForkDeployClickEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @static
         * @param {analytics.ICreateAppFromForkDeployClickEvent} message CreateAppFromForkDeployClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppFromForkDeployClickEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateAppFromForkDeployClickEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.CreateAppFromForkDeployClickEvent} CreateAppFromForkDeployClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppFromForkDeployClickEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.CreateAppFromForkDeployClickEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.sourceAppId = reader.string();
                    break;
                case 7:
                    message.repository = reader.string();
                    break;
                case 8:
                    message.openCodespaces = reader.bool();
                    break;
                case 9:
                    message.branch = reader.string();
                    break;
                case 10:
                    message.mainModule = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateAppFromForkDeployClickEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.CreateAppFromForkDeployClickEvent} CreateAppFromForkDeployClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppFromForkDeployClickEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateAppFromForkDeployClickEvent message.
         * @function verify
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateAppFromForkDeployClickEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.sourceAppId != null && message.hasOwnProperty("sourceAppId"))
                if (!$util.isString(message.sourceAppId))
                    return "sourceAppId: string expected";
            if (message.repository != null && message.hasOwnProperty("repository"))
                if (!$util.isString(message.repository))
                    return "repository: string expected";
            if (message.openCodespaces != null && message.hasOwnProperty("openCodespaces"))
                if (typeof message.openCodespaces !== "boolean")
                    return "openCodespaces: boolean expected";
            if (message.branch != null && message.hasOwnProperty("branch"))
                if (!$util.isString(message.branch))
                    return "branch: string expected";
            if (message.mainModule != null && message.hasOwnProperty("mainModule"))
                if (!$util.isString(message.mainModule))
                    return "mainModule: string expected";
            return null;
        };

        /**
         * Creates a CreateAppFromForkDeployClickEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.CreateAppFromForkDeployClickEvent} CreateAppFromForkDeployClickEvent
         */
        CreateAppFromForkDeployClickEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.CreateAppFromForkDeployClickEvent)
                return object;
            let message = new $root.analytics.CreateAppFromForkDeployClickEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.CreateAppFromForkDeployClickEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.CreateAppFromForkDeployClickEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.sourceAppId != null)
                message.sourceAppId = String(object.sourceAppId);
            if (object.repository != null)
                message.repository = String(object.repository);
            if (object.openCodespaces != null)
                message.openCodespaces = Boolean(object.openCodespaces);
            if (object.branch != null)
                message.branch = String(object.branch);
            if (object.mainModule != null)
                message.mainModule = String(object.mainModule);
            return message;
        };

        /**
         * Creates a plain object from a CreateAppFromForkDeployClickEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @static
         * @param {analytics.CreateAppFromForkDeployClickEvent} message CreateAppFromForkDeployClickEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateAppFromForkDeployClickEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
                object.sourceAppId = "";
                object.repository = "";
                object.openCodespaces = false;
                object.branch = "";
                object.mainModule = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.sourceAppId != null && message.hasOwnProperty("sourceAppId"))
                object.sourceAppId = message.sourceAppId;
            if (message.repository != null && message.hasOwnProperty("repository"))
                object.repository = message.repository;
            if (message.openCodespaces != null && message.hasOwnProperty("openCodespaces"))
                object.openCodespaces = message.openCodespaces;
            if (message.branch != null && message.hasOwnProperty("branch"))
                object.branch = message.branch;
            if (message.mainModule != null && message.hasOwnProperty("mainModule"))
                object.mainModule = message.mainModule;
            return object;
        };

        /**
         * Converts this CreateAppFromForkDeployClickEvent to JSON.
         * @function toJSON
         * @memberof analytics.CreateAppFromForkDeployClickEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateAppFromForkDeployClickEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateAppFromForkDeployClickEvent;
    })();

    analytics.CreateAppFromTemplateDeployClickEvent = (function() {

        /**
         * Properties of a CreateAppFromTemplateDeployClickEvent.
         * @memberof analytics
         * @interface ICreateAppFromTemplateDeployClickEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] CreateAppFromTemplateDeployClickEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] CreateAppFromTemplateDeployClickEvent principal
         * @property {string|null} [templateId] CreateAppFromTemplateDeployClickEvent templateId
         * @property {string|null} [repository] CreateAppFromTemplateDeployClickEvent repository
         * @property {boolean|null} [openCodespaces] CreateAppFromTemplateDeployClickEvent openCodespaces
         * @property {string|null} [branch] CreateAppFromTemplateDeployClickEvent branch
         * @property {string|null} [mainModule] CreateAppFromTemplateDeployClickEvent mainModule
         */

        /**
         * Constructs a new CreateAppFromTemplateDeployClickEvent.
         * @memberof analytics
         * @classdesc Represents a CreateAppFromTemplateDeployClickEvent.
         * @implements ICreateAppFromTemplateDeployClickEvent
         * @constructor
         * @param {analytics.ICreateAppFromTemplateDeployClickEvent=} [properties] Properties to set
         */
        function CreateAppFromTemplateDeployClickEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateAppFromTemplateDeployClickEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @instance
         */
        CreateAppFromTemplateDeployClickEvent.prototype.commonProperties = null;

        /**
         * CreateAppFromTemplateDeployClickEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @instance
         */
        CreateAppFromTemplateDeployClickEvent.prototype.principal = null;

        /**
         * CreateAppFromTemplateDeployClickEvent templateId.
         * @member {string} templateId
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @instance
         */
        CreateAppFromTemplateDeployClickEvent.prototype.templateId = "";

        /**
         * CreateAppFromTemplateDeployClickEvent repository.
         * @member {string} repository
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @instance
         */
        CreateAppFromTemplateDeployClickEvent.prototype.repository = "";

        /**
         * CreateAppFromTemplateDeployClickEvent openCodespaces.
         * @member {boolean} openCodespaces
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @instance
         */
        CreateAppFromTemplateDeployClickEvent.prototype.openCodespaces = false;

        /**
         * CreateAppFromTemplateDeployClickEvent branch.
         * @member {string} branch
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @instance
         */
        CreateAppFromTemplateDeployClickEvent.prototype.branch = "";

        /**
         * CreateAppFromTemplateDeployClickEvent mainModule.
         * @member {string} mainModule
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @instance
         */
        CreateAppFromTemplateDeployClickEvent.prototype.mainModule = "";

        /**
         * Creates a new CreateAppFromTemplateDeployClickEvent instance using the specified properties.
         * @function create
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @static
         * @param {analytics.ICreateAppFromTemplateDeployClickEvent=} [properties] Properties to set
         * @returns {analytics.CreateAppFromTemplateDeployClickEvent} CreateAppFromTemplateDeployClickEvent instance
         */
        CreateAppFromTemplateDeployClickEvent.create = function create(properties) {
            return new CreateAppFromTemplateDeployClickEvent(properties);
        };

        /**
         * Encodes the specified CreateAppFromTemplateDeployClickEvent message. Does not implicitly {@link analytics.CreateAppFromTemplateDeployClickEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @static
         * @param {analytics.ICreateAppFromTemplateDeployClickEvent} message CreateAppFromTemplateDeployClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppFromTemplateDeployClickEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.templateId != null && Object.hasOwnProperty.call(message, "templateId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.templateId);
            if (message.repository != null && Object.hasOwnProperty.call(message, "repository"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.repository);
            if (message.openCodespaces != null && Object.hasOwnProperty.call(message, "openCodespaces"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.openCodespaces);
            if (message.branch != null && Object.hasOwnProperty.call(message, "branch"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.branch);
            if (message.mainModule != null && Object.hasOwnProperty.call(message, "mainModule"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.mainModule);
            return writer;
        };

        /**
         * Encodes the specified CreateAppFromTemplateDeployClickEvent message, length delimited. Does not implicitly {@link analytics.CreateAppFromTemplateDeployClickEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @static
         * @param {analytics.ICreateAppFromTemplateDeployClickEvent} message CreateAppFromTemplateDeployClickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAppFromTemplateDeployClickEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateAppFromTemplateDeployClickEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.CreateAppFromTemplateDeployClickEvent} CreateAppFromTemplateDeployClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppFromTemplateDeployClickEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.CreateAppFromTemplateDeployClickEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.templateId = reader.string();
                    break;
                case 7:
                    message.repository = reader.string();
                    break;
                case 8:
                    message.openCodespaces = reader.bool();
                    break;
                case 9:
                    message.branch = reader.string();
                    break;
                case 10:
                    message.mainModule = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateAppFromTemplateDeployClickEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.CreateAppFromTemplateDeployClickEvent} CreateAppFromTemplateDeployClickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAppFromTemplateDeployClickEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateAppFromTemplateDeployClickEvent message.
         * @function verify
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateAppFromTemplateDeployClickEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.templateId != null && message.hasOwnProperty("templateId"))
                if (!$util.isString(message.templateId))
                    return "templateId: string expected";
            if (message.repository != null && message.hasOwnProperty("repository"))
                if (!$util.isString(message.repository))
                    return "repository: string expected";
            if (message.openCodespaces != null && message.hasOwnProperty("openCodespaces"))
                if (typeof message.openCodespaces !== "boolean")
                    return "openCodespaces: boolean expected";
            if (message.branch != null && message.hasOwnProperty("branch"))
                if (!$util.isString(message.branch))
                    return "branch: string expected";
            if (message.mainModule != null && message.hasOwnProperty("mainModule"))
                if (!$util.isString(message.mainModule))
                    return "mainModule: string expected";
            return null;
        };

        /**
         * Creates a CreateAppFromTemplateDeployClickEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.CreateAppFromTemplateDeployClickEvent} CreateAppFromTemplateDeployClickEvent
         */
        CreateAppFromTemplateDeployClickEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.CreateAppFromTemplateDeployClickEvent)
                return object;
            let message = new $root.analytics.CreateAppFromTemplateDeployClickEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.CreateAppFromTemplateDeployClickEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.CreateAppFromTemplateDeployClickEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.templateId != null)
                message.templateId = String(object.templateId);
            if (object.repository != null)
                message.repository = String(object.repository);
            if (object.openCodespaces != null)
                message.openCodespaces = Boolean(object.openCodespaces);
            if (object.branch != null)
                message.branch = String(object.branch);
            if (object.mainModule != null)
                message.mainModule = String(object.mainModule);
            return message;
        };

        /**
         * Creates a plain object from a CreateAppFromTemplateDeployClickEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @static
         * @param {analytics.CreateAppFromTemplateDeployClickEvent} message CreateAppFromTemplateDeployClickEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateAppFromTemplateDeployClickEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
                object.templateId = "";
                object.repository = "";
                object.openCodespaces = false;
                object.branch = "";
                object.mainModule = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.templateId != null && message.hasOwnProperty("templateId"))
                object.templateId = message.templateId;
            if (message.repository != null && message.hasOwnProperty("repository"))
                object.repository = message.repository;
            if (message.openCodespaces != null && message.hasOwnProperty("openCodespaces"))
                object.openCodespaces = message.openCodespaces;
            if (message.branch != null && message.hasOwnProperty("branch"))
                object.branch = message.branch;
            if (message.mainModule != null && message.hasOwnProperty("mainModule"))
                object.mainModule = message.mainModule;
            return object;
        };

        /**
         * Converts this CreateAppFromTemplateDeployClickEvent to JSON.
         * @function toJSON
         * @memberof analytics.CreateAppFromTemplateDeployClickEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateAppFromTemplateDeployClickEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateAppFromTemplateDeployClickEvent;
    })();

    analytics.AppResourceUtilisationEvent = (function() {

        /**
         * Properties of an AppResourceUtilisationEvent.
         * @memberof analytics
         * @interface IAppResourceUtilisationEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppResourceUtilisationEvent commonProperties
         * @property {string|null} [appId] AppResourceUtilisationEvent appId
         * @property {number|null} [memoryBytes] AppResourceUtilisationEvent memoryBytes
         * @property {number|null} [cpuCores] AppResourceUtilisationEvent cpuCores
         * @property {number|null} [diskBytes] AppResourceUtilisationEvent diskBytes
         */

        /**
         * Constructs a new AppResourceUtilisationEvent.
         * @memberof analytics
         * @classdesc Represents an AppResourceUtilisationEvent.
         * @implements IAppResourceUtilisationEvent
         * @constructor
         * @param {analytics.IAppResourceUtilisationEvent=} [properties] Properties to set
         */
        function AppResourceUtilisationEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppResourceUtilisationEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppResourceUtilisationEvent
         * @instance
         */
        AppResourceUtilisationEvent.prototype.commonProperties = null;

        /**
         * AppResourceUtilisationEvent appId.
         * @member {string} appId
         * @memberof analytics.AppResourceUtilisationEvent
         * @instance
         */
        AppResourceUtilisationEvent.prototype.appId = "";

        /**
         * AppResourceUtilisationEvent memoryBytes.
         * @member {number} memoryBytes
         * @memberof analytics.AppResourceUtilisationEvent
         * @instance
         */
        AppResourceUtilisationEvent.prototype.memoryBytes = 0;

        /**
         * AppResourceUtilisationEvent cpuCores.
         * @member {number} cpuCores
         * @memberof analytics.AppResourceUtilisationEvent
         * @instance
         */
        AppResourceUtilisationEvent.prototype.cpuCores = 0;

        /**
         * AppResourceUtilisationEvent diskBytes.
         * @member {number} diskBytes
         * @memberof analytics.AppResourceUtilisationEvent
         * @instance
         */
        AppResourceUtilisationEvent.prototype.diskBytes = 0;

        /**
         * Creates a new AppResourceUtilisationEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppResourceUtilisationEvent
         * @static
         * @param {analytics.IAppResourceUtilisationEvent=} [properties] Properties to set
         * @returns {analytics.AppResourceUtilisationEvent} AppResourceUtilisationEvent instance
         */
        AppResourceUtilisationEvent.create = function create(properties) {
            return new AppResourceUtilisationEvent(properties);
        };

        /**
         * Encodes the specified AppResourceUtilisationEvent message. Does not implicitly {@link analytics.AppResourceUtilisationEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppResourceUtilisationEvent
         * @static
         * @param {analytics.IAppResourceUtilisationEvent} message AppResourceUtilisationEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppResourceUtilisationEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appId);
            if (message.memoryBytes != null && Object.hasOwnProperty.call(message, "memoryBytes"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.memoryBytes);
            if (message.cpuCores != null && Object.hasOwnProperty.call(message, "cpuCores"))
                writer.uint32(/* id 7, wireType 1 =*/57).double(message.cpuCores);
            if (message.diskBytes != null && Object.hasOwnProperty.call(message, "diskBytes"))
                writer.uint32(/* id 8, wireType 1 =*/65).double(message.diskBytes);
            return writer;
        };

        /**
         * Encodes the specified AppResourceUtilisationEvent message, length delimited. Does not implicitly {@link analytics.AppResourceUtilisationEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppResourceUtilisationEvent
         * @static
         * @param {analytics.IAppResourceUtilisationEvent} message AppResourceUtilisationEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppResourceUtilisationEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppResourceUtilisationEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppResourceUtilisationEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppResourceUtilisationEvent} AppResourceUtilisationEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppResourceUtilisationEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppResourceUtilisationEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appId = reader.string();
                    break;
                case 6:
                    message.memoryBytes = reader.double();
                    break;
                case 7:
                    message.cpuCores = reader.double();
                    break;
                case 8:
                    message.diskBytes = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppResourceUtilisationEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppResourceUtilisationEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppResourceUtilisationEvent} AppResourceUtilisationEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppResourceUtilisationEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppResourceUtilisationEvent message.
         * @function verify
         * @memberof analytics.AppResourceUtilisationEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppResourceUtilisationEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.memoryBytes != null && message.hasOwnProperty("memoryBytes"))
                if (typeof message.memoryBytes !== "number")
                    return "memoryBytes: number expected";
            if (message.cpuCores != null && message.hasOwnProperty("cpuCores"))
                if (typeof message.cpuCores !== "number")
                    return "cpuCores: number expected";
            if (message.diskBytes != null && message.hasOwnProperty("diskBytes"))
                if (typeof message.diskBytes !== "number")
                    return "diskBytes: number expected";
            return null;
        };

        /**
         * Creates an AppResourceUtilisationEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppResourceUtilisationEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppResourceUtilisationEvent} AppResourceUtilisationEvent
         */
        AppResourceUtilisationEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppResourceUtilisationEvent)
                return object;
            let message = new $root.analytics.AppResourceUtilisationEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppResourceUtilisationEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.memoryBytes != null)
                message.memoryBytes = Number(object.memoryBytes);
            if (object.cpuCores != null)
                message.cpuCores = Number(object.cpuCores);
            if (object.diskBytes != null)
                message.diskBytes = Number(object.diskBytes);
            return message;
        };

        /**
         * Creates a plain object from an AppResourceUtilisationEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppResourceUtilisationEvent
         * @static
         * @param {analytics.AppResourceUtilisationEvent} message AppResourceUtilisationEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppResourceUtilisationEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.appId = "";
                object.memoryBytes = 0;
                object.cpuCores = 0;
                object.diskBytes = 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.memoryBytes != null && message.hasOwnProperty("memoryBytes"))
                object.memoryBytes = options.json && !isFinite(message.memoryBytes) ? String(message.memoryBytes) : message.memoryBytes;
            if (message.cpuCores != null && message.hasOwnProperty("cpuCores"))
                object.cpuCores = options.json && !isFinite(message.cpuCores) ? String(message.cpuCores) : message.cpuCores;
            if (message.diskBytes != null && message.hasOwnProperty("diskBytes"))
                object.diskBytes = options.json && !isFinite(message.diskBytes) ? String(message.diskBytes) : message.diskBytes;
            return object;
        };

        /**
         * Converts this AppResourceUtilisationEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppResourceUtilisationEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppResourceUtilisationEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppResourceUtilisationEvent;
    })();

    analytics.AppViewEvent = (function() {

        /**
         * Properties of an AppViewEvent.
         * @memberof analytics
         * @interface IAppViewEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] AppViewEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] AppViewEvent principal
         * @property {string|null} [appId] AppViewEvent appId
         */

        /**
         * Constructs a new AppViewEvent.
         * @memberof analytics
         * @classdesc Represents an AppViewEvent.
         * @implements IAppViewEvent
         * @constructor
         * @param {analytics.IAppViewEvent=} [properties] Properties to set
         */
        function AppViewEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppViewEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.AppViewEvent
         * @instance
         */
        AppViewEvent.prototype.commonProperties = null;

        /**
         * AppViewEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.AppViewEvent
         * @instance
         */
        AppViewEvent.prototype.principal = null;

        /**
         * AppViewEvent appId.
         * @member {string} appId
         * @memberof analytics.AppViewEvent
         * @instance
         */
        AppViewEvent.prototype.appId = "";

        /**
         * Creates a new AppViewEvent instance using the specified properties.
         * @function create
         * @memberof analytics.AppViewEvent
         * @static
         * @param {analytics.IAppViewEvent=} [properties] Properties to set
         * @returns {analytics.AppViewEvent} AppViewEvent instance
         */
        AppViewEvent.create = function create(properties) {
            return new AppViewEvent(properties);
        };

        /**
         * Encodes the specified AppViewEvent message. Does not implicitly {@link analytics.AppViewEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.AppViewEvent
         * @static
         * @param {analytics.IAppViewEvent} message AppViewEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppViewEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified AppViewEvent message, length delimited. Does not implicitly {@link analytics.AppViewEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.AppViewEvent
         * @static
         * @param {analytics.IAppViewEvent} message AppViewEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppViewEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppViewEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.AppViewEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.AppViewEvent} AppViewEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppViewEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.AppViewEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppViewEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.AppViewEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.AppViewEvent} AppViewEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppViewEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppViewEvent message.
         * @function verify
         * @memberof analytics.AppViewEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppViewEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates an AppViewEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.AppViewEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.AppViewEvent} AppViewEvent
         */
        AppViewEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.AppViewEvent)
                return object;
            let message = new $root.analytics.AppViewEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.AppViewEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.AppViewEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from an AppViewEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.AppViewEvent
         * @static
         * @param {analytics.AppViewEvent} message AppViewEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppViewEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this AppViewEvent to JSON.
         * @function toJSON
         * @memberof analytics.AppViewEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppViewEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppViewEvent;
    })();

    analytics.ProfilePageViewEvent = (function() {

        /**
         * Properties of a ProfilePageViewEvent.
         * @memberof analytics
         * @interface IProfilePageViewEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] ProfilePageViewEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] ProfilePageViewEvent principal
         * @property {string|null} [profileOwnerId] ProfilePageViewEvent profileOwnerId
         * @property {analytics.ProfilePageViewEvent.Source|null} [source] ProfilePageViewEvent source
         * @property {string|null} [referrer] ProfilePageViewEvent referrer
         * @property {string|null} [appId] ProfilePageViewEvent appId
         */

        /**
         * Constructs a new ProfilePageViewEvent.
         * @memberof analytics
         * @classdesc Represents a ProfilePageViewEvent.
         * @implements IProfilePageViewEvent
         * @constructor
         * @param {analytics.IProfilePageViewEvent=} [properties] Properties to set
         */
        function ProfilePageViewEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfilePageViewEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.ProfilePageViewEvent
         * @instance
         */
        ProfilePageViewEvent.prototype.commonProperties = null;

        /**
         * ProfilePageViewEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.ProfilePageViewEvent
         * @instance
         */
        ProfilePageViewEvent.prototype.principal = null;

        /**
         * ProfilePageViewEvent profileOwnerId.
         * @member {string} profileOwnerId
         * @memberof analytics.ProfilePageViewEvent
         * @instance
         */
        ProfilePageViewEvent.prototype.profileOwnerId = "";

        /**
         * ProfilePageViewEvent source.
         * @member {analytics.ProfilePageViewEvent.Source} source
         * @memberof analytics.ProfilePageViewEvent
         * @instance
         */
        ProfilePageViewEvent.prototype.source = 0;

        /**
         * ProfilePageViewEvent referrer.
         * @member {string} referrer
         * @memberof analytics.ProfilePageViewEvent
         * @instance
         */
        ProfilePageViewEvent.prototype.referrer = "";

        /**
         * ProfilePageViewEvent appId.
         * @member {string} appId
         * @memberof analytics.ProfilePageViewEvent
         * @instance
         */
        ProfilePageViewEvent.prototype.appId = "";

        /**
         * Creates a new ProfilePageViewEvent instance using the specified properties.
         * @function create
         * @memberof analytics.ProfilePageViewEvent
         * @static
         * @param {analytics.IProfilePageViewEvent=} [properties] Properties to set
         * @returns {analytics.ProfilePageViewEvent} ProfilePageViewEvent instance
         */
        ProfilePageViewEvent.create = function create(properties) {
            return new ProfilePageViewEvent(properties);
        };

        /**
         * Encodes the specified ProfilePageViewEvent message. Does not implicitly {@link analytics.ProfilePageViewEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.ProfilePageViewEvent
         * @static
         * @param {analytics.IProfilePageViewEvent} message ProfilePageViewEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilePageViewEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.profileOwnerId != null && Object.hasOwnProperty.call(message, "profileOwnerId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.profileOwnerId);
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.source);
            if (message.referrer != null && Object.hasOwnProperty.call(message, "referrer"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.referrer);
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified ProfilePageViewEvent message, length delimited. Does not implicitly {@link analytics.ProfilePageViewEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.ProfilePageViewEvent
         * @static
         * @param {analytics.IProfilePageViewEvent} message ProfilePageViewEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilePageViewEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProfilePageViewEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.ProfilePageViewEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.ProfilePageViewEvent} ProfilePageViewEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilePageViewEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.ProfilePageViewEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.profileOwnerId = reader.string();
                    break;
                case 7:
                    message.source = reader.int32();
                    break;
                case 8:
                    message.referrer = reader.string();
                    break;
                case 9:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProfilePageViewEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.ProfilePageViewEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.ProfilePageViewEvent} ProfilePageViewEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilePageViewEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProfilePageViewEvent message.
         * @function verify
         * @memberof analytics.ProfilePageViewEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProfilePageViewEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.profileOwnerId != null && message.hasOwnProperty("profileOwnerId"))
                if (!$util.isString(message.profileOwnerId))
                    return "profileOwnerId: string expected";
            if (message.source != null && message.hasOwnProperty("source"))
                switch (message.source) {
                default:
                    return "source: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.referrer != null && message.hasOwnProperty("referrer"))
                if (!$util.isString(message.referrer))
                    return "referrer: string expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates a ProfilePageViewEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.ProfilePageViewEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.ProfilePageViewEvent} ProfilePageViewEvent
         */
        ProfilePageViewEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.ProfilePageViewEvent)
                return object;
            let message = new $root.analytics.ProfilePageViewEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.ProfilePageViewEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.ProfilePageViewEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.profileOwnerId != null)
                message.profileOwnerId = String(object.profileOwnerId);
            switch (object.source) {
            case "NO_SOURCE":
            case 0:
                message.source = 0;
                break;
            case "NAV_BAR":
            case 1:
                message.source = 1;
                break;
            }
            if (object.referrer != null)
                message.referrer = String(object.referrer);
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from a ProfilePageViewEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.ProfilePageViewEvent
         * @static
         * @param {analytics.ProfilePageViewEvent} message ProfilePageViewEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfilePageViewEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
                object.profileOwnerId = "";
                object.source = options.enums === String ? "NO_SOURCE" : 0;
                object.referrer = "";
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.profileOwnerId != null && message.hasOwnProperty("profileOwnerId"))
                object.profileOwnerId = message.profileOwnerId;
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = options.enums === String ? $root.analytics.ProfilePageViewEvent.Source[message.source] : message.source;
            if (message.referrer != null && message.hasOwnProperty("referrer"))
                object.referrer = message.referrer;
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this ProfilePageViewEvent to JSON.
         * @function toJSON
         * @memberof analytics.ProfilePageViewEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfilePageViewEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Source enum.
         * @name analytics.ProfilePageViewEvent.Source
         * @enum {number}
         * @property {number} NO_SOURCE=0 NO_SOURCE value
         * @property {number} NAV_BAR=1 NAV_BAR value
         */
        ProfilePageViewEvent.Source = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "NO_SOURCE"] = 0;
            values[valuesById[1] = "NAV_BAR"] = 1;
            return values;
        })();

        return ProfilePageViewEvent;
    })();

    analytics.ProfilePageUpdateEvent = (function() {

        /**
         * Properties of a ProfilePageUpdateEvent.
         * @memberof analytics
         * @interface IProfilePageUpdateEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] ProfilePageUpdateEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] ProfilePageUpdateEvent principal
         * @property {Array.<string>|null} [updatedProperties] ProfilePageUpdateEvent updatedProperties
         */

        /**
         * Constructs a new ProfilePageUpdateEvent.
         * @memberof analytics
         * @classdesc Represents a ProfilePageUpdateEvent.
         * @implements IProfilePageUpdateEvent
         * @constructor
         * @param {analytics.IProfilePageUpdateEvent=} [properties] Properties to set
         */
        function ProfilePageUpdateEvent(properties) {
            this.updatedProperties = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfilePageUpdateEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.ProfilePageUpdateEvent
         * @instance
         */
        ProfilePageUpdateEvent.prototype.commonProperties = null;

        /**
         * ProfilePageUpdateEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.ProfilePageUpdateEvent
         * @instance
         */
        ProfilePageUpdateEvent.prototype.principal = null;

        /**
         * ProfilePageUpdateEvent updatedProperties.
         * @member {Array.<string>} updatedProperties
         * @memberof analytics.ProfilePageUpdateEvent
         * @instance
         */
        ProfilePageUpdateEvent.prototype.updatedProperties = $util.emptyArray;

        /**
         * Creates a new ProfilePageUpdateEvent instance using the specified properties.
         * @function create
         * @memberof analytics.ProfilePageUpdateEvent
         * @static
         * @param {analytics.IProfilePageUpdateEvent=} [properties] Properties to set
         * @returns {analytics.ProfilePageUpdateEvent} ProfilePageUpdateEvent instance
         */
        ProfilePageUpdateEvent.create = function create(properties) {
            return new ProfilePageUpdateEvent(properties);
        };

        /**
         * Encodes the specified ProfilePageUpdateEvent message. Does not implicitly {@link analytics.ProfilePageUpdateEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.ProfilePageUpdateEvent
         * @static
         * @param {analytics.IProfilePageUpdateEvent} message ProfilePageUpdateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilePageUpdateEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.updatedProperties != null && message.updatedProperties.length)
                for (let i = 0; i < message.updatedProperties.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.updatedProperties[i]);
            return writer;
        };

        /**
         * Encodes the specified ProfilePageUpdateEvent message, length delimited. Does not implicitly {@link analytics.ProfilePageUpdateEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.ProfilePageUpdateEvent
         * @static
         * @param {analytics.IProfilePageUpdateEvent} message ProfilePageUpdateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilePageUpdateEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProfilePageUpdateEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.ProfilePageUpdateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.ProfilePageUpdateEvent} ProfilePageUpdateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilePageUpdateEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.ProfilePageUpdateEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    if (!(message.updatedProperties && message.updatedProperties.length))
                        message.updatedProperties = [];
                    message.updatedProperties.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProfilePageUpdateEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.ProfilePageUpdateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.ProfilePageUpdateEvent} ProfilePageUpdateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilePageUpdateEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProfilePageUpdateEvent message.
         * @function verify
         * @memberof analytics.ProfilePageUpdateEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProfilePageUpdateEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                if (!Array.isArray(message.updatedProperties))
                    return "updatedProperties: array expected";
                for (let i = 0; i < message.updatedProperties.length; ++i)
                    if (!$util.isString(message.updatedProperties[i]))
                        return "updatedProperties: string[] expected";
            }
            return null;
        };

        /**
         * Creates a ProfilePageUpdateEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.ProfilePageUpdateEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.ProfilePageUpdateEvent} ProfilePageUpdateEvent
         */
        ProfilePageUpdateEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.ProfilePageUpdateEvent)
                return object;
            let message = new $root.analytics.ProfilePageUpdateEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.ProfilePageUpdateEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.ProfilePageUpdateEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.updatedProperties) {
                if (!Array.isArray(object.updatedProperties))
                    throw TypeError(".analytics.ProfilePageUpdateEvent.updatedProperties: array expected");
                message.updatedProperties = [];
                for (let i = 0; i < object.updatedProperties.length; ++i)
                    message.updatedProperties[i] = String(object.updatedProperties[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProfilePageUpdateEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.ProfilePageUpdateEvent
         * @static
         * @param {analytics.ProfilePageUpdateEvent} message ProfilePageUpdateEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfilePageUpdateEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.updatedProperties = [];
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.updatedProperties && message.updatedProperties.length) {
                object.updatedProperties = [];
                for (let j = 0; j < message.updatedProperties.length; ++j)
                    object.updatedProperties[j] = message.updatedProperties[j];
            }
            return object;
        };

        /**
         * Converts this ProfilePageUpdateEvent to JSON.
         * @function toJSON
         * @memberof analytics.ProfilePageUpdateEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfilePageUpdateEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProfilePageUpdateEvent;
    })();

    analytics.ProfilePageAppStateUpdateEvent = (function() {

        /**
         * Properties of a ProfilePageAppStateUpdateEvent.
         * @memberof analytics
         * @interface IProfilePageAppStateUpdateEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] ProfilePageAppStateUpdateEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] ProfilePageAppStateUpdateEvent principal
         * @property {string|null} [appId] ProfilePageAppStateUpdateEvent appId
         * @property {analytics.ProfilePageAppStateUpdateEvent.State|null} [state] ProfilePageAppStateUpdateEvent state
         */

        /**
         * Constructs a new ProfilePageAppStateUpdateEvent.
         * @memberof analytics
         * @classdesc Represents a ProfilePageAppStateUpdateEvent.
         * @implements IProfilePageAppStateUpdateEvent
         * @constructor
         * @param {analytics.IProfilePageAppStateUpdateEvent=} [properties] Properties to set
         */
        function ProfilePageAppStateUpdateEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfilePageAppStateUpdateEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @instance
         */
        ProfilePageAppStateUpdateEvent.prototype.commonProperties = null;

        /**
         * ProfilePageAppStateUpdateEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @instance
         */
        ProfilePageAppStateUpdateEvent.prototype.principal = null;

        /**
         * ProfilePageAppStateUpdateEvent appId.
         * @member {string} appId
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @instance
         */
        ProfilePageAppStateUpdateEvent.prototype.appId = "";

        /**
         * ProfilePageAppStateUpdateEvent state.
         * @member {analytics.ProfilePageAppStateUpdateEvent.State} state
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @instance
         */
        ProfilePageAppStateUpdateEvent.prototype.state = 0;

        /**
         * Creates a new ProfilePageAppStateUpdateEvent instance using the specified properties.
         * @function create
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @static
         * @param {analytics.IProfilePageAppStateUpdateEvent=} [properties] Properties to set
         * @returns {analytics.ProfilePageAppStateUpdateEvent} ProfilePageAppStateUpdateEvent instance
         */
        ProfilePageAppStateUpdateEvent.create = function create(properties) {
            return new ProfilePageAppStateUpdateEvent(properties);
        };

        /**
         * Encodes the specified ProfilePageAppStateUpdateEvent message. Does not implicitly {@link analytics.ProfilePageAppStateUpdateEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @static
         * @param {analytics.IProfilePageAppStateUpdateEvent} message ProfilePageAppStateUpdateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilePageAppStateUpdateEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.appId);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.state);
            return writer;
        };

        /**
         * Encodes the specified ProfilePageAppStateUpdateEvent message, length delimited. Does not implicitly {@link analytics.ProfilePageAppStateUpdateEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @static
         * @param {analytics.IProfilePageAppStateUpdateEvent} message ProfilePageAppStateUpdateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilePageAppStateUpdateEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProfilePageAppStateUpdateEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.ProfilePageAppStateUpdateEvent} ProfilePageAppStateUpdateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilePageAppStateUpdateEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.ProfilePageAppStateUpdateEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.appId = reader.string();
                    break;
                case 7:
                    message.state = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProfilePageAppStateUpdateEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.ProfilePageAppStateUpdateEvent} ProfilePageAppStateUpdateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilePageAppStateUpdateEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProfilePageAppStateUpdateEvent message.
         * @function verify
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProfilePageAppStateUpdateEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a ProfilePageAppStateUpdateEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.ProfilePageAppStateUpdateEvent} ProfilePageAppStateUpdateEvent
         */
        ProfilePageAppStateUpdateEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.ProfilePageAppStateUpdateEvent)
                return object;
            let message = new $root.analytics.ProfilePageAppStateUpdateEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.ProfilePageAppStateUpdateEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.ProfilePageAppStateUpdateEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.appId != null)
                message.appId = String(object.appId);
            switch (object.state) {
            case "VISIBLE":
            case 0:
                message.state = 0;
                break;
            case "HIDDEN":
            case 1:
                message.state = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ProfilePageAppStateUpdateEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @static
         * @param {analytics.ProfilePageAppStateUpdateEvent} message ProfilePageAppStateUpdateEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfilePageAppStateUpdateEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
                object.appId = "";
                object.state = options.enums === String ? "VISIBLE" : 0;
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.analytics.ProfilePageAppStateUpdateEvent.State[message.state] : message.state;
            return object;
        };

        /**
         * Converts this ProfilePageAppStateUpdateEvent to JSON.
         * @function toJSON
         * @memberof analytics.ProfilePageAppStateUpdateEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfilePageAppStateUpdateEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * State enum.
         * @name analytics.ProfilePageAppStateUpdateEvent.State
         * @enum {number}
         * @property {number} VISIBLE=0 VISIBLE value
         * @property {number} HIDDEN=1 HIDDEN value
         */
        ProfilePageAppStateUpdateEvent.State = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "VISIBLE"] = 0;
            values[valuesById[1] = "HIDDEN"] = 1;
            return values;
        })();

        return ProfilePageAppStateUpdateEvent;
    })();

    analytics.ProfilePageAppOpenedEvent = (function() {

        /**
         * Properties of a ProfilePageAppOpenedEvent.
         * @memberof analytics
         * @interface IProfilePageAppOpenedEvent
         * @property {analytics.ICommonProperties|null} [commonProperties] ProfilePageAppOpenedEvent commonProperties
         * @property {analytics.IPrincipal|null} [principal] ProfilePageAppOpenedEvent principal
         * @property {string|null} [profileOwnerId] ProfilePageAppOpenedEvent profileOwnerId
         * @property {string|null} [appId] ProfilePageAppOpenedEvent appId
         */

        /**
         * Constructs a new ProfilePageAppOpenedEvent.
         * @memberof analytics
         * @classdesc Represents a ProfilePageAppOpenedEvent.
         * @implements IProfilePageAppOpenedEvent
         * @constructor
         * @param {analytics.IProfilePageAppOpenedEvent=} [properties] Properties to set
         */
        function ProfilePageAppOpenedEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfilePageAppOpenedEvent commonProperties.
         * @member {analytics.ICommonProperties|null|undefined} commonProperties
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @instance
         */
        ProfilePageAppOpenedEvent.prototype.commonProperties = null;

        /**
         * ProfilePageAppOpenedEvent principal.
         * @member {analytics.IPrincipal|null|undefined} principal
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @instance
         */
        ProfilePageAppOpenedEvent.prototype.principal = null;

        /**
         * ProfilePageAppOpenedEvent profileOwnerId.
         * @member {string} profileOwnerId
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @instance
         */
        ProfilePageAppOpenedEvent.prototype.profileOwnerId = "";

        /**
         * ProfilePageAppOpenedEvent appId.
         * @member {string} appId
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @instance
         */
        ProfilePageAppOpenedEvent.prototype.appId = "";

        /**
         * Creates a new ProfilePageAppOpenedEvent instance using the specified properties.
         * @function create
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @static
         * @param {analytics.IProfilePageAppOpenedEvent=} [properties] Properties to set
         * @returns {analytics.ProfilePageAppOpenedEvent} ProfilePageAppOpenedEvent instance
         */
        ProfilePageAppOpenedEvent.create = function create(properties) {
            return new ProfilePageAppOpenedEvent(properties);
        };

        /**
         * Encodes the specified ProfilePageAppOpenedEvent message. Does not implicitly {@link analytics.ProfilePageAppOpenedEvent.verify|verify} messages.
         * @function encode
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @static
         * @param {analytics.IProfilePageAppOpenedEvent} message ProfilePageAppOpenedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilePageAppOpenedEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commonProperties != null && Object.hasOwnProperty.call(message, "commonProperties"))
                $root.analytics.CommonProperties.encode(message.commonProperties, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.principal != null && Object.hasOwnProperty.call(message, "principal"))
                $root.analytics.Principal.encode(message.principal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.profileOwnerId != null && Object.hasOwnProperty.call(message, "profileOwnerId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.profileOwnerId);
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified ProfilePageAppOpenedEvent message, length delimited. Does not implicitly {@link analytics.ProfilePageAppOpenedEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @static
         * @param {analytics.IProfilePageAppOpenedEvent} message ProfilePageAppOpenedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfilePageAppOpenedEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProfilePageAppOpenedEvent message from the specified reader or buffer.
         * @function decode
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {analytics.ProfilePageAppOpenedEvent} ProfilePageAppOpenedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilePageAppOpenedEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.analytics.ProfilePageAppOpenedEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commonProperties = $root.analytics.CommonProperties.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.principal = $root.analytics.Principal.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.profileOwnerId = reader.string();
                    break;
                case 7:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProfilePageAppOpenedEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {analytics.ProfilePageAppOpenedEvent} ProfilePageAppOpenedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfilePageAppOpenedEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProfilePageAppOpenedEvent message.
         * @function verify
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProfilePageAppOpenedEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties")) {
                let error = $root.analytics.CommonProperties.verify(message.commonProperties);
                if (error)
                    return "commonProperties." + error;
            }
            if (message.principal != null && message.hasOwnProperty("principal")) {
                let error = $root.analytics.Principal.verify(message.principal);
                if (error)
                    return "principal." + error;
            }
            if (message.profileOwnerId != null && message.hasOwnProperty("profileOwnerId"))
                if (!$util.isString(message.profileOwnerId))
                    return "profileOwnerId: string expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates a ProfilePageAppOpenedEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {analytics.ProfilePageAppOpenedEvent} ProfilePageAppOpenedEvent
         */
        ProfilePageAppOpenedEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.analytics.ProfilePageAppOpenedEvent)
                return object;
            let message = new $root.analytics.ProfilePageAppOpenedEvent();
            if (object.commonProperties != null) {
                if (typeof object.commonProperties !== "object")
                    throw TypeError(".analytics.ProfilePageAppOpenedEvent.commonProperties: object expected");
                message.commonProperties = $root.analytics.CommonProperties.fromObject(object.commonProperties);
            }
            if (object.principal != null) {
                if (typeof object.principal !== "object")
                    throw TypeError(".analytics.ProfilePageAppOpenedEvent.principal: object expected");
                message.principal = $root.analytics.Principal.fromObject(object.principal);
            }
            if (object.profileOwnerId != null)
                message.profileOwnerId = String(object.profileOwnerId);
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from a ProfilePageAppOpenedEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @static
         * @param {analytics.ProfilePageAppOpenedEvent} message ProfilePageAppOpenedEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfilePageAppOpenedEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commonProperties = null;
                object.principal = null;
                object.profileOwnerId = "";
                object.appId = "";
            }
            if (message.commonProperties != null && message.hasOwnProperty("commonProperties"))
                object.commonProperties = $root.analytics.CommonProperties.toObject(message.commonProperties, options);
            if (message.principal != null && message.hasOwnProperty("principal"))
                object.principal = $root.analytics.Principal.toObject(message.principal, options);
            if (message.profileOwnerId != null && message.hasOwnProperty("profileOwnerId"))
                object.profileOwnerId = message.profileOwnerId;
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this ProfilePageAppOpenedEvent to JSON.
         * @function toJSON
         * @memberof analytics.ProfilePageAppOpenedEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfilePageAppOpenedEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProfilePageAppOpenedEvent;
    })();

    return analytics;
})();

export { $root as default };
