import { createSelector } from '@reduxjs/toolkit'

import { type RootState } from 'reducers'
import { type State } from 'reducers/workspace'

export const baseSelector = (state: RootState): State => state.workspace
export const currentWorkspaceSelector = createSelector(
  baseSelector,
  (state) => state.selected,
)
