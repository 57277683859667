import {
  useSelector as baseUseSelector,
  type TypedUseSelectorHook,
} from 'react-redux'
import { combineReducers } from '@reduxjs/toolkit'

import onboardingFlow from 'reducers/onboardingFlow'
import modal from 'reducers/modal'
import analytics from 'reducers/analytics'
import workspace from 'reducers/workspace'
import redirect from 'reducers/redirect'
import linkedRedirect from 'reducers/linkedRedirect'
import warningInfo from 'reducers/warningInfo'

const rootReducer = combineReducers({
  modal: modal.reducer,
  analytics: analytics.reducer,
  workspace: workspace.reducer,
  redirect: redirect.reducer,
  onboardingFlow: onboardingFlow.reducer,
  linkedRedirect: linkedRedirect.reducer,
  warningInfo: warningInfo.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export const useSelector: TypedUseSelectorHook<RootState> = baseUseSelector

export default rootReducer
