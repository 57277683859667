import { type AxiosError } from 'axios'
import { mutate } from 'swr'
import { useCallback, useState } from 'react'

import { type IApp } from 'api/apps'
import { extractErrorMessage } from 'api/utils'
import { useAppStatusKey } from 'api/appStatus'
import { isSubdomainApp } from '../hooks/useUniqueSubdomainFeatures'
import { getAppsApiClient } from './apiV2'

async function updateViewerAuth(
  appId: string,
  isPrivate: boolean,
): Promise<void> {
  const client = getAppsApiClient()
  if (isSubdomainApp()) {
    await client.updateVisibilityFromSubdomain({ isPrivate })
  } else {
    await client.updateVisibility(appId, { isPrivate })
  }
}

interface IUseEnableViewerAuth {
  error?: string
  success: boolean
  isPending: boolean
  doEnableViewerAuth: (enable: boolean) => Promise<void>
}

export function useEnableViewerAuth(app: IApp): IUseEnableViewerAuth {
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState(false)
  const [isPending, setIsPending] = useState(false)

  const appStatusKey = useAppStatusKey(app.appId)

  const doEnableViewerAuth = useCallback(
    async (enable: boolean) => {
      setIsPending(true)

      try {
        await updateViewerAuth(app.appId, enable)

        setIsPending(false)
        setError('')
        setSuccess(true)
        await mutate(appStatusKey)
      } catch (exc) {
        setIsPending(false)
        setError(extractErrorMessage(exc as AxiosError))
        setSuccess(false)
      }
    },
    [app.appId, appStatusKey],
  )

  return {
    error,
    success,
    isPending,
    doEnableViewerAuth,
  }
}
