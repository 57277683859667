import { createSelector } from '@reduxjs/toolkit'

import { type RootState } from 'reducers'
import { type State } from 'reducers/onboardingFlow'

const baseSelector = (state: RootState): State => state.onboardingFlow
export const stepNameSelector = createSelector(
  baseSelector,
  (state) => state.name,
)
