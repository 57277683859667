import useSWR from 'swr'

import { isSubdomainApp } from '../hooks/useUniqueSubdomainFeatures'

import { type APIError } from './types'
import {
  type ContextResponseModel,
  type PermissionModel,
  type UserContextModel,
} from '../gen'
import { getAppsApiClient } from './apiV2'

export interface IUseAppContext {
  context?: ContextResponseModel
  error?: APIError
  isPending: boolean
  isValidating: boolean
}

export type AppContextKey = [string, string | undefined] | null

export const useAppContextKey = (appId?: string): AppContextKey => {
  if (!appId) {
    return [`app/context`, undefined]
  }

  return [`apps/${appId}/context`, appId]
}

export async function fetchAppContext([
  _url,
  appId,
]: string[]): Promise<ContextResponseModel> {
  const client = getAppsApiClient()
  if (isSubdomainApp()) {
    const status = await client.getContextFromSubdomain()
    return status.data
  } else {
    const status = await client.getContext(appId)
    return status.data
  }
}

export function hasUserWorkspacePermission(
  user: UserContextModel | undefined,
  permission: PermissionModel,
): boolean {
  if (user?.workspacePermissions == null) {
    return false
  }

  return user.workspacePermissions.some((p) => p === permission)
}

export function isLoggedIn(user: UserContextModel | undefined): boolean {
  return !(user == null || user.isAnonymous)
}

const useAppContext = (appId?: string): IUseAppContext => {
  const appContextKey = useAppContextKey(appId)
  const { data, error, isValidating, isLoading } = useSWR<
    ContextResponseModel,
    APIError
  >(appContextKey, {
    fetcher: fetchAppContext,
  })

  return {
    context: data,
    isPending: isLoading,
    isValidating,
    error,
  }
}

export default useAppContext
