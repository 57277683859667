import { parse } from '@iarna/toml'
import _isNil from 'lodash/isNil'
import _isEmpty from 'lodash/isEmpty'

export function isValidTOML(text: string): boolean {
  try {
    parse(text)
    return true
  } catch (e) {
    return false
  }
}

export function isNonEmptyString(
  str: string | null | undefined,
): str is string {
  return !_isNil(str) && !_isEmpty(str)
}
