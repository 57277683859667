export default function localStorageAvailable(): boolean {
  const testData = 'testData'

  try {
    const { localStorage } = window
    localStorage.setItem(testData, testData)
    localStorage.getItem(testData)
    localStorage.removeItem(testData)
  } catch (e) {
    return false
  }
  return true
}
