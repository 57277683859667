import { datadogLogs } from '@datadog/browser-logs'
import { Component, type ErrorInfo, type ReactNode } from 'react'
import eventIds from 'constants/eventIds'
import ErrorPage from 'pages/error/errorPage'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    datadogLogs.logger.error(
      `App failed to render. Component stack: ${errorInfo.componentStack}`,
      {
        event_id: eventIds.AppRenderError,
      },
      error,
    )
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <ErrorPage />
    }

    return this.props.children
  }
}

export default ErrorBoundary
