import { Machine, type State } from 'xstate'
import { type AxiosError } from 'axios'

import {
  APP_STATUS,
  PLATFORM_STATUS,
  type AppStatusValue,
  type PlatformStatusValue,
} from 'constants/apps'

interface AppStateSchema {
  states: {
    // Initial state that every FSM starts in. We only stay here while fetching the information
    // required to move to another state.
    INITIAL: Record<string, unknown>

    // When we receive an UPDATING while in INITIAL, we can't tell if the app is updating because
    // it's booting or because it's running. So we keep it in a temporary AMBIGUOUS_UPDATING state
    // that we handle differently in the UI.
    AMBIGUOUS_UPDATING: Record<string, unknown>

    // The app is booting for the first time.
    BOOTING: Record<string, unknown>

    // The app was deleted *right this second*. (If not right this second, a deleted app leads to
    // NOT_FOUND). This more or less maps to DELETING and DELETED in the backend.
    DELETED: Record<string, unknown>

    // The app has an error.
    ERROR: Record<string, unknown>

    // App not found. Either it never existed or it was deleted. But we can't tell which.
    NOT_FOUND: Record<string, unknown>

    // The app is rebooting. This is not the first time this app boots, so no need to show the "In
    // the oven" page, for example.
    REBOOTING: Record<string, unknown>

    // The app is running (i.e. the Streamlit server is reachable).
    RUNNING: Record<string, unknown>

    // Fail-whale. The app is out of quota.
    RESOURCE_BUSY: Record<string, unknown>

    // Spin down
    SUSPENDED: Record<string, unknown>

    // The user does not have the necessary credentials to access the app status endpoint.
    UNAUTHORIZED: Record<string, unknown>
  }
}

interface AppStateEvent {
  type: 'backendStatusReceived'
  error?: AxiosError
  backendStatus: AppStatusValue
  platformStatus: PlatformStatusValue
}

interface AppStateContext {
  error?: AxiosError
}

// Backend states that unambigiously map to BOOTING.
const backendBootingStates = new Set([
  APP_STATUS.UNKNOWN,
  APP_STATUS.CREATING,
  APP_STATUS.CREATED,
  APP_STATUS.INSTALLING,
  APP_STATUS.RESTARTING,
  APP_STATUS.REBOOTING,
])

// Backend states that unambigiously map to ERROR.
const backendErrorStates = new Set([
  APP_STATUS.USER_ERROR,
  APP_STATUS.INSTALLER_ERROR,
  APP_STATUS.PLATFORM_ERROR,
])

// Backend states that unambigiously map to RUNNING.
const backendRunningStates = new Set([
  APP_STATUS.RUNNING,

  // The USER_SCRIPT_ERROR is set when the application is running, but there is an exception in the Python script.
  // Streamlit will show the stacktrace or the error message and the user might still be able to do work, so
  // we consider this a RUNNING state from the frontend's point of view
  APP_STATUS.USER_SCRIPT_ERROR,

  APP_STATUS.POTENTIAL_MINER_DETECTED,
])

// Backend states that unambigiously map to DELETED.
const backendDeletedStates = new Set([APP_STATUS.DELETING, APP_STATUS.DELETED])

export type AppState = State<AppStateContext, AppStateEvent>

// The only backend state that is in none of the Sets above is UPDATING.

export const appStateMachine = Machine<
  AppStateContext,
  AppStateSchema,
  AppStateEvent
>(
  {
    key: 'appStateMachine',
    initial: 'INITIAL',
    context: {
      error: undefined,
    },

    // Transitions that apply no matter what state we're on.
    on: {
      backendStatusReceived: [
        {
          // If an app exists then becomes a 404, that's because it was deleted. Except if on INITIAL
          // state. We special-case that below.
          cond: (_, event) => event.error?.response?.status === 404,
          target: 'DELETED',
        },
        {
          // Always go to DELETED if the backend is in one of the states that unambiguously map to
          // DELETED.
          cond: (_, event) => backendDeletedStates.has(event.backendStatus),
          target: 'DELETED',
        },
        {
          // Always go to ERROR if the backend is in one of the states that unambiguously map to
          // ERROR.
          cond: (_, event) => backendErrorStates.has(event.backendStatus),
          target: 'ERROR',
        },
        {
          // Always go to RUNNING if the backend is in one of the states that unambiguously map to
          // RUNNING.
          cond: (_, event) => backendRunningStates.has(event.backendStatus),
          target: 'RUNNING',
        },
        {
          // Always go to BOOTING if the backend is in one of the states that unambiguously map to
          // BOOTING. Except for when in RUNNING or REBOOTING states. See below.
          cond: (_, event) => backendBootingStates.has(event.backendStatus),
          target: 'BOOTING',
        },
        {
          cond: (_, event) =>
            event.error?.response?.status === 401 ||
            event.error?.response?.status === 403,
          target: 'UNAUTHORIZED',
        },
      ],
    },

    // We handle basically every state above. So the only one we need to handle a few here.
    states: {
      RESOURCE_BUSY: {
        // Terminal state, there is no transition out of RESOURCE_BUSY
        type: 'final',
      },

      INITIAL: {
        on: {
          backendStatusReceived: [
            {
              // Fail-whale, if resource is busy during loading, go to RESOURCE_BUSY
              cond: (_, event) =>
                event.platformStatus === PLATFORM_STATUS.RESOURCE_BUSY,
              target: 'RESOURCE_BUSY',
            },
            {
              // Override the normal 404 behavior. If an app never gets out of INITIAL, then becomes a
              // 404, that's because it never existed.
              cond: (_, event) => event.error?.response?.status === 404,
              target: 'NOT_FOUND',
            },
            {
              // See explanation in definition of AMBIGUOUS_UPDATING above.
              cond: (_, event) => event.backendStatus === APP_STATUS.UPDATING,
              target: 'AMBIGUOUS_UPDATING',
            },
            {
              // Spin-down, If the app is suspended, go to SUSPENDED
              cond: (_, event) =>
                event.backendStatus === APP_STATUS.IS_SHUTDOWN,
              target: 'SUSPENDED',
            },
          ],
        },
      },

      REBOOTING: {
        on: {
          backendStatusReceived: {
            // Override the events that lead the FSM to move to BOOTING, so they instead go to
            // REBOOTING.
            cond: (_, event) => backendBootingStates.has(event.backendStatus),
            target: 'REBOOTING',
          },
        },
      },

      RUNNING: {
        on: {
          backendStatusReceived: {
            // Override the events that lead the FSM to move to BOOTING, so they instead go to
            // REBOOTING.
            cond: (_, event) => backendBootingStates.has(event.backendStatus),
            target: 'REBOOTING',
          },
        },
      },

      AMBIGUOUS_UPDATING: {},
      BOOTING: {},
      DELETED: {},
      ERROR: {
        entry: 'storeError',
        exit: 'clearError',
      },
      NOT_FOUND: {},
      SUSPENDED: {},
      UNAUTHORIZED: {},
    },
  },
  {
    actions: {
      storeError(context: AppStateContext, event: AppStateEvent) {
        context.error = event.error
      },

      clearError(context: AppStateContext) {
        context.error = undefined
      },
    },
  },
)
