import {
  createContext,
  type ReactNode,
  useReducer,
  useContext,
  type ReactElement,
} from 'react'

interface Props {
  children: ReactNode
}

export interface State {
  showSidebar: boolean
  showBalloons: boolean
}

export interface Action {
  type: 'START_DEPLOY' | 'END_DEPLOY' | 'SHOW_SIDEBAR' | 'HIDE_SIDEBAR'
  payload?: Record<string, never>
}

export type DispatchFn = (action: Action) => void

interface IStreamlitAppContext {
  state: State
  dispatch: DispatchFn
}

const initialState: State = {
  showSidebar: false,
  showBalloons: false,
}

const StreamlitAppContext = createContext({})

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'START_DEPLOY': {
      return {
        ...state,
        showBalloons: true,
        showSidebar: true,
      }
    }
    case 'END_DEPLOY': {
      return {
        ...state,
        showBalloons: false,
      }
    }
    case 'SHOW_SIDEBAR': {
      return {
        ...state,
        showSidebar: true,
      }
    }
    case 'HIDE_SIDEBAR': {
      return {
        ...state,
        showSidebar: false,
      }
    }
    default: {
      return state
    }
  }
}

export function StreamlitAppContextProvider({ children }: Props): ReactElement {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StreamlitAppContext.Provider value={{ state, dispatch }}>
      {children}
    </StreamlitAppContext.Provider>
  )
}

function useStreamlitAppContext(): IStreamlitAppContext {
  const context = useContext(StreamlitAppContext)

  if (context === undefined) {
    throw new Error(
      'useStreamlitAppContext must be used within a StreamlitAppContextProvider',
    )
  }

  return context as IStreamlitAppContext
}

export default useStreamlitAppContext
