import { memo, type ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import useUser, { getGitHubSourceProviderConnection } from 'api/user'
import useDisconnect from 'api/disconnect'
import { useGitHubLogin } from 'api/login'
import { useLogout } from 'api/logout'
import useUpgradeRepoScope from 'api/upgradeRepoAuth'

import { useSelector } from 'reducers'
import { setRedirect } from 'reducers/redirect'
import { makeModalIsOpenSelector } from 'reducers/modal'

import Button from 'components/button'
import { TabBody, TabBodyPart } from 'components/modal/components/tabBody'
import WarningBox from 'components/warningBox'

import UserPermissions from 'constants/userPermissions'

import { DeleteAccount } from './deleteAccount/DeleteAccount'
import { LinkedElement } from './linkedElement'
import PrimaryUserAddress from './PrimaryUserAddress'
import NotificationBox from '../../../components/notificationBox/notificationBox'

function LinkedAccountsBody(): ReactElement {
  const { user, permissions, sourceProviderConnections } = useUser()
  const dispatch = useDispatch()
  const disconnectGithub = useDisconnect()
  const [connectAccount, setConnectAccount] = useState<boolean | undefined>()
  const { doLogin, doRedirect, redirectUrl, pending } = useGitHubLogin()
  const { pending: logoutPending } = useLogout()
  const selectModalIsOpen = useMemo(makeModalIsOpenSelector, [])
  const isSimpleLoginOpen = useSelector((state) =>
    selectModalIsOpen(state, 'SIMPLE_LOGIN'),
  )
  const { doUpgradeRepoScope, pending: upgradeRepoScopePending } =
    useUpgradeRepoScope()

  const connectButtonsDisabled =
    pending ||
    !!redirectUrl?.length ||
    isSimpleLoginOpen ||
    upgradeRepoScopePending

  const showUpgradeRepoScopeButton = !permissions?.includes(
    UserPermissions.FEATURE_GITHUB_REPO_OAUTH_SCOPE,
  )

  useEffect(() => {
    if (!connectAccount) return
    dispatch(
      setRedirect({
        url: `${window.location.pathname}?showUserSettings=true`,
      }),
    )

    doLogin()
  }, [connectAccount, dispatch, doLogin])

  useEffect(() => {
    if (!redirectUrl) return

    doRedirect()
  }, [redirectUrl, doRedirect])

  let sourceControlWarning
  if (!user?.email) {
    sourceControlWarning = (
      <WarningBox
        description="You are not signed in with a source control account."
        actions={[
          {
            button: (
              <Button
                data-test-id="connect-github"
                onClick={() => {
                  setConnectAccount(true)
                }}
                disabled={connectButtonsDisabled}
                isLoading={connectAccount && connectButtonsDisabled}
              >
                Sign in with GitHub
              </Button>
            ),
          },
        ]}
      />
    )
  } else if (showUpgradeRepoScopeButton) {
    sourceControlWarning = (
      <NotificationBox
        description="Streamlit does not have access to private repos on this GitHub account."
        title={'Private access'}
        action={{
          text: 'Connect here',
          onClick: () => {
            dispatch(
              setRedirect({
                url: `${window.location.pathname}?showUserSettings=true`,
                callback: doUpgradeRepoScope,
              }),
            )
          },
        }}
      />
    )
  }

  const gitHubSourceProviderConnection = getGitHubSourceProviderConnection(
    sourceProviderConnections,
  )
  let sourceControlLinkedElement
  if (user && gitHubSourceProviderConnection?.active) {
    sourceControlLinkedElement = (
      <LinkedElement
        connectionName={'GitHub'}
        username={gitHubSourceProviderConnection.login}
        isLoading={disconnectGithub.pending || logoutPending}
        onClick={disconnectGithub.doDisconnect}
      />
    )
  }

  return (
    <>
      <TabBody>
        <TabBodyPart
          title="Email identity"
          subTitle="An email identity can be used by others to give you access to view their apps."
          body={<PrimaryUserAddress />}
        />
        <TabBodyPart
          title="Source control"
          subTitle="A source control account is required to create and manage apps."
          body={sourceControlLinkedElement}
          warningComponent={sourceControlWarning}
        />
        <DeleteAccount maybeUser={user} />
      </TabBody>
    </>
  )
}

export default memo(LinkedAccountsBody)
