import useSWR from 'swr'

import { useEffect } from 'react'

import { isSubdomainApp } from 'hooks/useUniqueSubdomainFeatures'

import { type IApp } from 'api/apps'
import { type APIError } from 'api/types'
import { isNonEmptyString } from 'helpers/validation'
import { getAppsApiClient } from './apiV2'
import { type DisambiguateResponseModel } from '../gen'

export interface IUseDisambiguate {
  isPending: boolean
  app?: IApp
  error?: APIError
  notFound?: boolean
}

type AppPath = '/' | string

export type DisambiguateKey = [string, string | undefined, string | undefined]

export type UseDisambiguateURLData =
  // path is used for the legacy scheme `/<owner>/<repo>/*`
  | { path?: AppPath; subdomain?: never }
  // subdomain is used for app placeholder scheme `/app/<subdomain>/*`
  | { subdomain?: string; path?: never }

export const useDisambiguateKey = (
  urlData: UseDisambiguateURLData,
): DisambiguateKey => {
  if (isSubdomainApp()) {
    return ['disambiguate', undefined, undefined]
  }

  if (isNonEmptyString(urlData.subdomain)) {
    return [
      `disambiguate?subdomain=${urlData.subdomain}`,
      urlData.subdomain,
      urlData.path,
    ]
  }

  const path = urlData.path || ''
  return [`disambiguate?path=/${path}`, urlData.subdomain, urlData.path]
}

export async function fetchDisambiguate(key: DisambiguateKey): Promise<IApp> {
  const appSubdomain = key[1]
  const appPath = key[2]

  const appsApiClient = getAppsApiClient()
  let response: DisambiguateResponseModel
  if (isSubdomainApp()) {
    response = await appsApiClient
      .disambiguateFromSubdomain()
      .then((res) => res.data)
  } else {
    if (appSubdomain) {
      response = await appsApiClient
        .disambiguate(appSubdomain, undefined)
        .then((res) => res.data)
    } else {
      response = await appsApiClient
        .disambiguate(undefined, appPath)
        .then((res) => res.data)
    }
  }

  return {
    appId: response.appId,
    branch: response.branch,
    owner: response.owner,
    mainModule: response.mainModule,
    repo: response.repository,
    host: response.host,
    url: response.url,
    subdomain: response.subdomain,
  }
}

const useDisambiguate = (urlData: UseDisambiguateURLData): IUseDisambiguate => {
  const swrKey = useDisambiguateKey(urlData)

  const { data, error, mutate, isLoading } = useSWR<IApp | null>(swrKey, {
    revalidateOnMount: true,
    fetcher: fetchDisambiguate,
  })

  useEffect(() => {
    if (data == null && error == null) return

    // if we receive a 404, remove the data in cache
    if (error?.response?.status === 404) {
      mutate(null, false)
    }
  }, [data, error, mutate])

  return {
    error,
    isPending: isLoading,
    app:
      data != null
        ? {
            appId: data.appId,
            branch: data.branch,
            owner: data.owner,
            mainModule: data.mainModule,
            repo: data.repo,
            host: data.host,
            url: data.url,
            subdomain: data.subdomain,
          }
        : undefined,
  }
}

export default useDisambiguate
