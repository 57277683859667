import queryString from 'query-string'

import { type UseDisambiguateURLData } from 'api/disambiguate'

import { type AppState } from './appStateMachine'

export type IsInStateFn = (stateStr?: string) => boolean

/**
 * Returns a helper function that makes it simpler to check the current state.
 * (This is in a separate file so we can use it in tests)
 */
export function buildIsInState(appState: AppState): IsInStateFn {
  return (stateStr?: string): boolean => {
    if (stateStr === undefined) {
      return appState === undefined
    }
    return appState?.matches(stateStr)
  }
}

export type RouteParams =
  | {
      owner: string
      repo: string
      ['*']: string
      subdomain?: never
    }
  | {
      subdomain: string
      ['*']: string
      owner?: never
      repo?: never
    }

export function filterQueryString(query: string, filter: string[]): string {
  return queryString.exclude(query, filter)
}

export function formatURLDataForDisambiguate(
  routeParams: RouteParams,
  query: string,
): { disambiguateUrlData: UseDisambiguateURLData; rest: string } {
  const { subdomain, owner, repo } = routeParams

  // legacy app
  if (owner && repo) {
    const disambiguateUrlData = {
      path: Object.values(routeParams)?.join('/') || '/',
    }
    return {
      disambiguateUrlData,
      rest: query,
    }
  }

  // placeholder
  if (subdomain) {
    const disambiguateUrlData = { subdomain }
    return { disambiguateUrlData, rest: routeParams['*'] + query }
  }

  // subdomain app
  return { disambiguateUrlData: {}, rest: routeParams['*'] + query }
}
