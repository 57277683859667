import { createSelector } from '@reduxjs/toolkit'

import { type RootState } from 'reducers'
import { type State } from 'reducers/warningInfo'

const baseSelector = (state: RootState): State => state.warningInfo
export const warningInfoSelector = createSelector(
  baseSelector,
  (state) => state,
)
