import { type ReactElement, memo, useState, useEffect } from 'react'
import classNames from 'classnames'

import { ReactComponent as Pie } from 'assets/svg/emoji_u1f967.svg'
import { ReactComponent as Cake } from 'assets/svg/emoji_u1f382.svg'
import { ReactComponent as Bread } from 'assets/svg/emoji_u1f35e.svg'
import { ReactComponent as Cookie } from 'assets/svg/emoji_u1f36a.svg'
import { ReactComponent as Donut } from 'assets/svg/emoji_u1f369.svg'
import { ReactComponent as Shortcake } from 'assets/svg/emoji_u1f370.svg'
import { ReactComponent as Potato } from 'assets/svg/emoji_u1f954.svg'

import { ReactComponent as EmojiOne } from 'assets/svg/emoji_u1f62b.svg'
import { ReactComponent as EmojiTwo } from 'assets/svg/emoji_u1f616.svg'
import { ReactComponent as EmojiThree } from 'assets/svg/emoji_u1f634.svg'
import { ReactComponent as EmojiFour } from 'assets/svg/emoji_u1f635.svg'
import { ReactComponent as EmojiFive } from 'assets/svg/emoji_u1f971.svg'
import { ReactComponent as EmojiSix } from 'assets/svg/emoji_u1f973.svg'
import { ReactComponent as EmojiSeven } from 'assets/svg/emoji_u23f0.svg'
import { ReactComponent as EmojiEight } from 'assets/svg/emoji_u263a.svg'
import { ReactComponent as EmojiNine } from 'assets/svg/emoji_u2615.svg'

import styles from './styles.module.scss'

export interface Props {
  interval?: number
  isAppLoadRedesign?: boolean
  className?: string
}

export const emojiList = [Pie, Cake, Bread, Cookie, Donut, Shortcake, Potato]

export const emojiListRedesign = [
  EmojiOne,
  EmojiTwo,
  EmojiThree,
  EmojiFour,
  EmojiFive,
  EmojiSix,
  EmojiSeven,
  EmojiEight,
  EmojiNine,
]

function EmojiSpinner({
  interval = 250,
  isAppLoadRedesign,
  className,
}: Props): ReactElement {
  const [emojiId, setEmojiId] = useState(0)

  const emojiListLength = isAppLoadRedesign
    ? emojiListRedesign.length
    : emojiList.length

  useEffect(() => {
    const timeoutId = setTimeout(
      () => {
        setEmojiId((prevState) => {
          return (prevState + 1) % emojiListLength
        })
      },
      isAppLoadRedesign ? 800 : interval,
    )

    return () => {
      clearTimeout(timeoutId)
    }
  })

  const Emoji = isAppLoadRedesign
    ? emojiListRedesign[emojiId]
    : emojiList[emojiId]

  return <Emoji className={classNames(styles.emoji, className)} />
}

export default memo(EmojiSpinner)
