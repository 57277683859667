import { type ReactElement } from 'react'

import styles from './badge.module.scss'

// To create a new badge color (ie: impact styling) - append the color
// to this list and modify the badge.module.scss file accordingly
type colors = 'orange' | 'gray'

export interface BadgeProps {
  icon?: string
  title: string
  color: colors
}

export default function Badge({
  title,
  icon,
  color,
}: BadgeProps): ReactElement {
  return (
    <div className={`${styles.container} ${styles[color]}`}>
      {icon && <span>{icon}</span>}
      {title}
    </div>
  )
}
