import { type AxiosError } from 'axios'

export const extractErrorMessage = (exc?: AxiosError): string => {
  if (exc == null) {
    return 'Cannot load error'
  }

  const errorResponse = exc?.response?.data as any
  if (errorResponse?.error != null) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return errorResponse.error
  }

  if (exc.message != null) {
    // Something happened in setting up the request that triggered an Error
    return exc.message
  }

  // We should never see this. Because Typescript.
  return 'Unknown error type'
}

export async function timeout(ms: number): Promise<void> {
  await new Promise((resolve) => setTimeout(resolve, ms))
}
