import { useEffect, useState } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import { type AxiosError } from 'axios'
import { toaster } from 'baseui/toast'
import useSWR, { mutate } from 'swr'

import { type APIError } from 'api/types'
import { extractErrorMessage } from 'api/utils'

import {
  getCurrentPage,
  getLogFieldsOfFavoriteApp,
} from 'helpers/constantsHelpers'

import { isNonEmptyString } from 'helpers/validation'
import { isSubdomainApp } from 'hooks/useUniqueSubdomainFeatures'
import {
  type ContextResponseModel,
  type GetUserFavoriteAppsResponseModel,
  type UpdateAppFavoriteStatusRequestModel,
} from '../gen'
import { getAppsApiClient } from './apiV2'
import { useAppContextKey, type AppContextKey } from './appContext'

export const useFavoriteAppKey = (appId: string | undefined): string => {
  if (isSubdomainApp()) {
    return 'app/favorite'
  }

  return appId ? `app/${appId}/favorite` : ''
}

export const getFetchFavoriteAppsKey = (): string => '/favorited-apps'

interface GetFavoriteAppsResponse {
  error?: string
  appIds: string[]
}

interface UseGetFavoritedApps {
  error?: APIError
  isPending: boolean
  appIds: Set<string>
}

interface useGetFavoritedAppIdsArgs {
  shouldFetch: boolean
}

// The favorite app ids is returned by an extra endpoint! This decision was made so that we don't
// have to add more logic into the "/apps"-endpoint handler (e.g. the handler makes calls to
// different dbapi-endpoints depending on the user type etc. and, as a consequence, we would
// either need to update multiple SQL statements or add the merge-logic of apps with favorite status
// to the backend itself).
// Since the hook uses SWR and, thus, returns data from the cache first, the result of the
// hook should be returned very quickly to the calling component.
// Note: if the "/apps" endpoint ends up to be refactored, we should revisit this decision.
export function useGetFavoritedAppIds(
  { shouldFetch }: useGetFavoritedAppIdsArgs = { shouldFetch: true },
): UseGetFavoritedApps {
  const { data, error, isLoading } = useSWR<GetUserFavoriteAppsResponseModel>(
    shouldFetch ? getFetchFavoriteAppsKey() : null,
    async (): Promise<GetUserFavoriteAppsResponseModel> => {
      const client = getAppsApiClient()
      const favoritedApps = await client.getFavoritedApps()
      return favoritedApps.data
    },
  )

  return {
    error,
    isPending: isLoading,
    appIds: new Set(data?.appIds),
  }
}

interface UseFavoriteApp {
  error?: string
  isPending: boolean
  doFavoriteApp: (favoriteApp: boolean) => Promise<void>
}

const getNewAppIds = (
  appIds: string[],
  // TODO: remove optionality as soon as appIds are returned by the /apps endpoint
  affectedAppId?: string,
  willAppBeFavorited: boolean = false,
): Set<string> => {
  const modifiedAppIds = new Set<string>(appIds)
  if (!isNonEmptyString(affectedAppId)) {
    return modifiedAppIds
  }

  if (!willAppBeFavorited) {
    modifiedAppIds.delete(affectedAppId)
    return modifiedAppIds
  }

  return modifiedAppIds.add(affectedAppId)
}

const updateLocalCachedFavoriteAppIds = (
  allFavoritedAppsKey: string,
  appContextKey: AppContextKey,
  appId: string,
  willAppBeFavorited: boolean,
): void => {
  // update the local favorite-state of the apps
  mutate(
    allFavoritedAppsKey,
    async (favoriteAppsResponse?: GetFavoriteAppsResponse) => {
      const existingAppIds = favoriteAppsResponse?.appIds ?? []
      return {
        appIds: Array.from(
          getNewAppIds(existingAppIds, appId, willAppBeFavorited),
        ),
      }
    },
    false,
  )

  if (appContextKey) {
    mutate(
      appContextKey,
      async (appContext?: ContextResponseModel) => {
        if (appContext == null) {
          return appContext
        }

        const result = JSON.parse(JSON.stringify(appContext))
        result.app.isFavorited = !appContext.app.isFavorited

        return result
      },
      false,
    )
  }
}

export function useFavoriteApp(appId: string | undefined): UseFavoriteApp {
  const [error, setError] = useState<string>()
  const [isPending, setIsPending] = useState<boolean>(false)
  const appContextKey = useAppContextKey(appId)

  useEffect(() => {
    if (!isNonEmptyString(error)) return
    toaster.show(error, {
      kind: 'negative',
    })
  }, [error])

  const favoriteAppKey = useFavoriteAppKey(appId)

  const doFavoriteApp = async (favoriteApp: boolean): Promise<void> => {
    if (!isNonEmptyString(appId) || !isNonEmptyString(favoriteAppKey)) {
      setError('No app id passed')
      return
    }

    setIsPending(true)
    const fetchFavoriteAppsKey = getFetchFavoriteAppsKey()
    const origin = getCurrentPage()
    try {
      updateLocalCachedFavoriteAppIds(
        fetchFavoriteAppsKey,
        appContextKey,
        appId,
        favoriteApp,
      )

      const client = getAppsApiClient()
      const requestBody: UpdateAppFavoriteStatusRequestModel = {
        isFavorite: favoriteApp,
      }
      if (isSubdomainApp()) {
        await client.updateFavoritesFromSubdomain(requestBody)
      } else {
        await client.updateFavorites(appId, requestBody)
      }

      setError(undefined)
      datadogLogs.logger.info(
        'Favorite app successful',
        getLogFieldsOfFavoriteApp(true, appId, favoriteApp, origin),
      )
    } catch (exc) {
      setError(extractErrorMessage(exc as AxiosError))
      // reverse the local mutation upon an error
      updateLocalCachedFavoriteAppIds(
        fetchFavoriteAppsKey,
        appContextKey,
        appId,
        !favoriteApp,
      )
      datadogLogs.logger.info(
        'Favorite app failed',
        getLogFieldsOfFavoriteApp(false, appId, favoriteApp, origin),
      )
    } finally {
      setIsPending(false)
    }
  }

  return {
    error,
    isPending,
    doFavoriteApp,
  }
}
