import { type ReactElement } from 'react'
import _isNil from 'lodash/isNil'

import { StyledLink } from 'components/link'
import { ReactComponent as IconArrowLeftFull } from 'assets/svg/icon_arrow_left_full.svg'

import styles from './goBackButton.module.scss'

interface IGoBackButtonProps {
  to?: string
  onClick?: () => void
}

export const PREVIOUS_PAGE = -1

// GoBackButton will redirect to the given path or to the root path if no path is given
// onClick will be called if it is defined
function GoBackButton({ to, onClick }: IGoBackButtonProps): ReactElement {
  return (
    <div className={styles.goBackWrapper}>
      <StyledLink
        className={styles.goBack}
        to={_isNil(onClick) ? to ?? '/' : ''}
        onClick={onClick}
      >
        <IconArrowLeftFull />
        Back
      </StyledLink>
    </div>
  )
}

export default GoBackButton
