import type { EventSink } from './eventSink'
import { type AnalyticsEvent } from './analyticsEvent'
import { setEventProperties } from './reflection'
import { type TaskScheduler } from '../../helpers/tasks/taskScheduler'

export interface AnalyticsClient {
  publish: (event: AnalyticsEvent) => Promise<void>
}

interface DefaultAnalyticsClientProps {
  publishingPeriodSeconds: number
  maxMessagesPerPublish: number
}

export class DefaultAnalyticsClient implements AnalyticsClient {
  private readonly eventSink: EventSink
  private readonly eventQueue: AnalyticsEvent[] = []
  private readonly maxMessagesPerPublish: number

  constructor(
    props: DefaultAnalyticsClientProps,
    eventSink: EventSink,
    taskScheduler: TaskScheduler,
  ) {
    this.eventSink = eventSink
    this.maxMessagesPerPublish = props.maxMessagesPerPublish

    taskScheduler.scheduleAtFixedRate(() => {
      this.publishAll()
    }, props.publishingPeriodSeconds)

    taskScheduler.scheduleBeforeExit(() => {
      this.publishAll()
    })
  }

  public async publish(event: AnalyticsEvent): Promise<void> {
    this.eventQueue.push(setEventProperties(event))
  }

  private publishAll(): void {
    if (this.eventQueue.length === 0) {
      return
    }

    const events = this.eventQueue.splice(0, this.maxMessagesPerPublish)
    this.eventSink.writeAll(events)
  }
}
