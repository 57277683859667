import { type ReactElement } from 'react'
import { useField } from 'formik'

import Field from 'components/field'
import { Input } from 'components/textField'

import ENVS from 'constants/envs'
import { getCurrentEnv } from 'helpers/environment'

import { type IApp } from 'api/apps'
import useValidateSubdomain, {
  type ValidateErrorMsg,
} from 'api/validateSubdomain'

import styles from './customSubdomainField.module.scss'

interface Props {
  label?: string
  app: IApp
}

interface ExplanationMessageProps {
  touched: boolean
  initialValue: string
  value: string
  isValid?: boolean
  error?: string
}

export function ExplanationMessage({
  touched,
  initialValue,
  value,
  isValid,
  error,
}: ExplanationMessageProps): ReactElement {
  if (!value || !touched) {
    return <></>
  }

  if (initialValue === value) {
    return (
      <p className={styles.error}>
        This subdomain is already in use, please choose a new subdomain
      </p>
    )
  }

  if (!isValid) {
    return <p className={styles.error}>{error}</p>
  }

  return <p className={styles.validated}>Available!</p>
}

export function getCustomError(
  subdomain: string,
  error: ValidateErrorMsg | undefined,
): string | undefined {
  if (error === 'Cannot use this value as a subdomain.') {
    return `The subdomain ‘${subdomain}’ is already taken. Please choose another subdomain.`
  }

  return error
}

function CustomSubdomainField({ label, app }: Props): ReactElement {
  const currentEnv = getCurrentEnv()
  const envValues = ENVS[currentEnv]
  const { doValidate, error, isValid, pending } = useValidateSubdomain(app)

  const [field, meta] = useField({
    name: 'subdomain',
    validate: async (value): Promise<string> => {
      if (value && meta.initialValue !== value) await doValidate(value)

      const maybeValid = isValid || isValid === undefined
      return value && maybeValid && meta.initialValue !== value ? '' : 'Error'
    },
  })

  const enhancer = envValues.app[0].replace('*', '')

  return (
    <div className={styles.container}>
      <Field label={label} {...field}>
        <Input
          {...field}
          error={!isValid && meta.touched && !pending}
          endEnhancer={enhancer}
        />

        <ExplanationMessage
          touched={meta.touched}
          initialValue={meta.initialValue}
          value={field.value}
          isValid={isValid}
          error={getCustomError(field.value, error?.response?.data.error)}
        />
      </Field>
    </div>
  )
}

export default CustomSubdomainField
