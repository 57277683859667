import type React from 'react'
import { type ReactElement, type ReactNode, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ModalHeader } from 'baseui/modal'
import { Navigation as BaseSideNav, type Item } from 'baseui/side-navigation'
import { type Theme } from 'baseui/theme'

import { makeModalRequireConfirmationSelector } from 'reducers/modal/selectors'
import { confirmationOff, showModal, type ModalName } from 'reducers/modal'
import { useSelector } from 'reducers'

import styles from './sideNavigation.module.scss'

interface MenuProps<T> {
  title: string
  subTitle?: string
  activeNav: string
  setActiveNav: React.Dispatch<React.SetStateAction<T>>
  menuItems: Item[]
  modalName: ModalName
}

const navOverrides = {
  NavLink: {
    style: ({ $theme }: { $theme: Theme }) => ({
      ':focus-visible > div': {
        outline: 'none',
      },
    }),
  },
  NavItem: {
    style: ({ $active, $theme }: { $active: boolean; $theme: Theme }) => {
      if (!$active) return { paddingLeft: '20px' }
      return {
        backgroundColor: $theme?.colors?.primary400,
        borderLeftColor: $theme?.colors?.buttonPrimaryFill,
        borderLeftWidth: '4px',
        paddingLeft: '20px',
      }
    },
  },
}

export function SideNavigation<T>({
  title,
  subTitle,
  activeNav,
  setActiveNav,
  menuItems,
  modalName,
}: MenuProps<T>): ReactElement {
  const dispatch = useDispatch()
  const selectModalRequireConfirmation = useMemo(
    makeModalRequireConfirmationSelector,
    [],
  )
  const requireConfirmation = useSelector((state) =>
    selectModalRequireConfirmation(state, modalName),
  )

  const setActiveNavOnConfirm = (itemId: React.SetStateAction<T>): void => {
    dispatch(
      showModal({
        name: 'CONFIRM',
        modalPayload: {
          title: 'You have unsaved changes',
          message: '',
          confirmButtonText: 'Discard',
          cancelButtonText: 'Go back',
          onConfirm: () => {
            dispatch(confirmationOff({ name: modalName }))
            setActiveNav(itemId)
          },
        },
      }),
    )
  }
  return (
    <>
      <ModalHeader className={styles.header}>
        <div className={styles.title}>{title}</div>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      </ModalHeader>
      <div className={styles.itemList}>
        <BaseSideNav
          items={menuItems}
          activeItemId={activeNav}
          onChange={({ event, item }) => {
            event.preventDefault()
            if (item?.itemId == null) {
              // This should never happen.
              throw new Error('Item id is not available.')
            }

            // Open a confirmation modal if necessary when switching tabs.
            if (requireConfirmation) {
              setActiveNavOnConfirm(item.itemId)
            } else {
              setActiveNav(item.itemId)
            }
          }}
          overrides={navOverrides}
        />
      </div>
    </>
  )
}

interface InnerBodyProps<T> extends MenuProps<T> {
  // bottomFade indicates whether bottom edge of scrollable modal faded
  // to indicate additional content
  bottomFade?: boolean
  children?: ReactElement | ReactNode
}

function InnerBody<T>({
  title,
  subTitle,
  activeNav,
  setActiveNav,
  menuItems,
  bottomFade,
  children,
  modalName,
}: InnerBodyProps<T>): ReactElement {
  return (
    <div className={styles.sideNavigationContainer}>
      <div className={styles.topFade}>&nbsp;</div>
      <div className={styles.sideNavigationMenuContainer}>
        <SideNavigation<T>
          title={title}
          subTitle={subTitle}
          activeNav={activeNav}
          setActiveNav={setActiveNav}
          menuItems={menuItems}
          modalName={modalName}
        />
      </div>
      <div className={styles.sideNavigationBodyContainer}>{children}</div>
      {bottomFade && <div className={styles.bottomFade}>&nbsp;</div>}
    </div>
  )
}

export default InnerBody
