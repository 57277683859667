import { matchPath } from 'react-router-dom'

import eventIds from 'constants/eventIds'
import Routes, { type AppCodeSplit, Pages } from 'constants/routes'
import ENVS from 'constants/envs'
import { isNonEmptyString } from './validation'
import { getCurrentEnv } from './environment'
import findKey from 'lodash/findKey'
import { wildcardMatchPattern } from './urls'

export function getCurrentAppSection(): keyof AppCodeSplit {
  const envValues = ENVS[getCurrentEnv()]
  const section = findKey(envValues, (urls) => {
    return urls.some((urlPattern) =>
      wildcardMatchPattern(urlPattern ?? '', window.location.hostname),
    )
  })

  return section as keyof AppCodeSplit
}

export function generateAppURL({
  path = '',
  subdomain,
  section,
  searchParams,
}: {
  path?: string
  subdomain?: string
  section?: keyof AppCodeSplit
  searchParams?: URLSearchParams
}): string {
  if (section) {
    const currentAppSection = getCurrentAppSection()

    if (currentAppSection !== section) {
      const currentEnv = getCurrentEnv()
      const envValues = ENVS[currentEnv]
      let domain = envValues[section][0]

      if (isNonEmptyString(subdomain)) {
        domain = domain.replace('*', subdomain)
      }

      const url = new URL(path, `${window.location.protocol}//${domain}`)
      if (searchParams != null) {
        searchParams.forEach((value, key) => {
          url.searchParams.set(key, value)
        })
      }

      return url.toString()
    }
  }

  if (searchParams != null) {
    return `/${path}?${searchParams.toString()}`
  }

  return `/${path}`
}

export function getCurrentPage(): Pages {
  if (matchPath(Routes.DASHBOARD, window.location.pathname) != null) {
    return Pages.DASHBOARD
  }

  if (
    matchPath(Routes.APP, window.location.pathname) != null ||
    matchPath(Routes.APP_WITH_OPTIONALS, window.location.pathname) != null
  ) {
    return Pages.APP
  }

  return Pages.UNKNOWN
}

export function getLogFieldsOfFavoriteApp(
  success: boolean,
  appId: string,
  favoriteApp: boolean,
  page: Pages,
): Record<string, string | number | boolean> {
  return {
    event_id: success ? eventIds.SuccessFavoriteApp : eventIds.FailFavoriteApp,
    favorite: favoriteApp,
    appId,
    origin: page,
  }
}
