import { useState } from 'react'
import axios from 'axios'

import { type APIError } from 'api/types'
import { type IApp } from 'api/apps'

import { useWorkspaceContext } from 'context/workspaceContext'

import { getCurrentAppSection } from '../helpers/constantsHelpers'
import { Pages } from '../constants/routes'

interface IUseUpdateSubdomain {
  success?: boolean
  error?: APIError
  doUpdateSubdomain: (subdomain: string) => Promise<void>
  isPending: boolean
}

interface IUpdateSubdomainResponse {
  success: boolean
  error?: string
}

export const getUpdateSubdomainKey = ({ appId }: IApp): string => {
  if (getCurrentAppSection() === Pages.APP) {
    return 'app/subdomain'
  }
  return `app/${appId}/subdomain`
}

export function useUpdateSubdomain(app: IApp): IUseUpdateSubdomain {
  const [error, setError] = useState<APIError | undefined>()
  const [success, setSuccess] = useState<boolean | undefined>()
  const [isPending, setIsPending] = useState<boolean>(false)
  const updateSubdomainKey = getUpdateSubdomainKey(app)
  const workspaceContext = useWorkspaceContext()

  const doUpdateSubdomain = async (subdomain: string): Promise<void> => {
    setIsPending(true)
    setError(undefined)
    setSuccess(undefined)

    let revertUpdate: (() => Promise<void>) | undefined

    try {
      // update cache optimistically
      revertUpdate = await workspaceContext.UpdateApp(app, {
        subdomain,
      })

      const { data } = await axios.put<IUpdateSubdomainResponse>(
        updateSubdomainKey,
        {
          app: app.appId,
          subdomain,
        },
      )

      setIsPending(false)
      setSuccess(data.success)
    } catch (exc) {
      if (revertUpdate !== undefined) {
        await revertUpdate()
      }

      setIsPending(false)
      setSuccess(false)
      setError(exc as APIError)
    }

    await workspaceContext.ForceUpdate()
  }

  return {
    error,
    success,
    isPending,
    doUpdateSubdomain,
  }
}

export default useUpdateSubdomain
