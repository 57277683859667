import { memo } from 'react'
import { compareDesc, formatDistanceToNow, parseJSON } from 'date-fns'
import { groupBy } from 'lodash'

import { ADJECTIVES, NOUNS } from 'constants/levity'
import { theme } from 'constants/tailwind.config'
import { getColorClassForEmail } from 'modals/share/viewers'
import { type View } from 'api/appEvents'
import hash from 'helpers/hash'

import styles from './appViewers.module.scss'

const GITHUB_LOGIN_PREFIX = 'github.com/'

interface MostRecentAppViewersProps {
  views: View[]
}

interface ExtendedView extends View {
  indexInGroup: number
}

function MostRecentAppViewers({
  views,
}: MostRecentAppViewersProps): JSX.Element {
  const viewsWithGroupIndices = extendViewsWithGroupIndices(views)
  const sortedViewsWithGroupIndices =
    viewsWithGroupIndices.sort(recentViewersFirst)

  return (
    <div className={styles.mostRecentAppViewers}>
      <h4 className={styles.subtitle}>Most recent viewers</h4>
      {views.length > 0 && (
        <ul className={styles.appViewers}>
          {sortedViewsWithGroupIndices.map((view) => (
            <li className={styles.appViewer} key={getUniqueKeyProp(view)}>
              <Avatar identifier={getIdentifier(view)} />
              <span
                className={styles.identifier}
                style={{
                  color:
                    view.email || view.githubLogin
                      ? theme.colors.black[900]
                      : theme.colors.black[700],
                }}
              >
                {getIdentifier(view)}
              </span>
              <span className={styles.timestamp}>
                {formatDistanceToNow(parseJSON(view.createdAt), {
                  includeSeconds: true,
                  addSuffix: true,
                })}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export function extendViewsWithGroupIndices(views: View[]): ExtendedView[] {
  // Anonymous users with the same `createdAt` get the same random name.
  // To fix that behavior, we group views by `createdAt` and then add
  // the position of the element in its corresponding group to each view.
  const viewsGroupedByCreatedAt = groupBy(views, (view) => view.createdAt)
  return Object.values(viewsGroupedByCreatedAt)
    .map((viewsWithSameDate) =>
      viewsWithSameDate.map((view, index) => ({
        ...view,
        indexInGroup: index,
      })),
    )
    .reduce((acc, cur) => [...acc, ...cur], [])
}

export function recentViewersFirst(a: ExtendedView, b: ExtendedView): number {
  return (
    compareDesc(parseJSON(a.createdAt), parseJSON(b.createdAt)) ||
    a.indexInGroup - b.indexInGroup
  )
}

export function wrapGitHubLogin(githubLogin?: string): string | undefined {
  return githubLogin && `${GITHUB_LOGIN_PREFIX}${githubLogin}`
}

export function getIdentifier({
  email,
  githubLogin,
  createdAt,
  indexInGroup,
}: ExtendedView): string {
  return (
    (email || wrapGitHubLogin(githubLogin)) ??
    generateRandomName(createdAt, indexInGroup)
  )
}

export function generateRandomName(
  createdAt: string,
  indexInGroup: number,
): string {
  const timestamp = parseJSON(createdAt).getTime()
  const h1 = hash(String(timestamp))
  const h2 = hash(String(indexInGroup))
  const h = hash(String(h1 + h2))
  const adjective = ADJECTIVES[mod(h + h1, ADJECTIVES.length)]
  const noun = NOUNS[mod(h + h2, NOUNS.length)]
  return `${adjective} ${noun}`
}

// Proper modulo operation for negative numbers.
function mod(n: number, m: number): number {
  return ((n % m) + m) % m
}

function getUniqueKeyProp(view: ExtendedView): string {
  return `${getIdentifier(view)}:${view.createdAt}:${view.indexInGroup}`
}

interface AvatarProps {
  identifier: string
}

export function Avatar({ identifier }: AvatarProps): JSX.Element {
  const identifierWithoutPrefix = identifier.startsWith(GITHUB_LOGIN_PREFIX)
    ? identifier.replace(GITHUB_LOGIN_PREFIX, '')
    : identifier
  const color = getColorClassForEmail(identifierWithoutPrefix)

  return (
    <span className={styles.avatar} style={{ backgroundColor: color }}>
      {identifierWithoutPrefix.charAt(0)}
    </span>
  )
}

export default memo(MostRecentAppViewers)
