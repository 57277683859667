import { type ReactElement, useCallback, useMemo } from 'react'

import { useGetViewers, useSetViewers } from 'api/viewers'
import { type IApp } from 'api/apps'

import DomainRow from 'modals/share/domainRow'

import Alert from 'components/alert'
import PageSpinner from 'components/pageSpinner'
import useGetWorkspaceDomains from 'api/domains'

import { useWorkspaceContext } from 'context/workspaceContext'

import modalStyles from './share.module.scss'

interface DomainProps {
  app: IApp
  isOwner: boolean
}

function Domains({ app, isOwner }: DomainProps): ReactElement {
  const {
    error: getViewersError,
    domains: appDomains,
    viewers,
    isPending: getViewersIsPending,
  } = useGetViewers(app)

  const { error: setViewersError, doSetViewers } = useSetViewers(app)
  const workspaceContext = useWorkspaceContext()
  const {
    error: workspaceDomainsError,
    domains: workspaceDomains,
    isPending: workspaceDomainsIsPending,
  } = useGetWorkspaceDomains(workspaceContext.Id())

  const removeDomain = useCallback(
    async (domain: string) => {
      const newDomains = appDomains.filter((v) => v !== domain)
      // If viewers is not included here then any domains that
      // are previously set will be removed.
      await doSetViewers({ viewers, domains: newDomains })
    },
    [viewers, appDomains, doSetViewers],
  )

  const addDomain = useCallback(
    async (domain: string) => {
      const newDomains = [...appDomains, domain]
      // If viewers is not included here then any domains that
      // are previously set will be removed.
      await doSetViewers({ viewers, domains: newDomains })
    },
    [viewers, appDomains, doSetViewers],
  )

  const toggleDomainIsShared = useCallback(
    (domain: string, isShared: boolean) => {
      if (isShared) {
        addDomain(domain)
      } else {
        removeDomain(domain)
      }
    },
    [addDomain, removeDomain],
  )

  const domainIsShared = useCallback(
    (domain: string) => appDomains.includes(domain),
    [appDomains],
  )

  // The endpoint we are using returns a domain for each
  // app that is shared with the domain in the workspace
  // TODO: maybe remove the duplication in the backend
  const uniqueWorkspaceDomains = useMemo(
    () => Array.from(new Set(workspaceDomains)),
    [workspaceDomains],
  )

  if (
    getViewersIsPending ||
    workspaceDomainsIsPending ||
    getViewersError ||
    workspaceDomainsError
  ) {
    return <PageSpinner />
  }

  return (
    <>
      {setViewersError && <Alert title="Error" message={setViewersError} />}
      <div className={modalStyles.bodyPadding}>
        {uniqueWorkspaceDomains.map((domain) => {
          const isShared = domainIsShared(domain)
          return (
            <DomainRow
              isOwner={isOwner}
              domain={domain}
              toggleDomainIsShared={toggleDomainIsShared}
              key={domain}
              isShared={isShared}
            />
          )
        })}
      </div>
    </>
  )
}

export default Domains
