import type React from 'react'
import { memo, type ReactElement, useCallback, useMemo, useState } from 'react'
import _noop from 'lodash/noop'
import queryString from 'query-string'

import { useLocation, useNavigate } from 'react-router-dom'

import { useSelector } from 'reducers'

import { type State as Info, warningInfoSelector } from 'reducers/warningInfo'

import { ReactComponent as IconWarning } from 'assets/svg/icon_warning_rounded.svg'

import PageSpinner from 'components/pageSpinner'
import Modal, { SideNavigationBody } from 'components/modal'

import modalOverrides from 'modals/modalOverrides'
import modalStyles from 'modals/styles.module.scss'

import { makeModalPayloadSelector } from 'reducers/modal/selectors'
import { type ModalName, type UsersSettingsPayload } from 'reducers/modal'

import { useWorkspaceContext } from 'context/workspaceContext'

import LimitsBody from './components/limits'
import SupportBody from './components/support'
import LinkedAccountsBody from './components/linkedAccounts'

import styles from './styles.module.scss'

interface ModalProps {
  onClose?: () => void
}

interface InnerBodyProps {
  menuItems: MenuItems
  isLoading?: boolean
  workspaceName?: string
  name: ModalName
}

export enum MenuItemId {
  LinkedAccounts = 'LINKED_ACCOUNTS',
  Limits = 'LIMITS',
  Support = 'SUPPORT',
}

export interface MenuItem {
  title: React.ReactNode
  itemId: MenuItemId
}

export type MenuItems = MenuItem[]

export function InnerBody({
  menuItems,
  isLoading,
  workspaceName,
  name,
}: InnerBodyProps): ReactElement {
  const selectModalPayload = useMemo(makeModalPayloadSelector, [])
  const modalPayload = useSelector((state) =>
    selectModalPayload(state, name),
  ) as UsersSettingsPayload
  const [activeNav, setActiveNav] = useState<MenuItemId>(
    getDefaultActiveNav(modalPayload?.activeNav, menuItems),
  )

  if (isLoading) {
    return (
      <div className={modalStyles.spinnerContainer}>
        <PageSpinner />
      </div>
    )
  }

  return (
    <SideNavigationBody<MenuItemId>
      menuItems={menuItems}
      setActiveNav={setActiveNav}
      activeNav={activeNav}
      title="Workspace settings"
      subTitle={workspaceName}
      modalName={name}
      bottomFade
    >
      {activeNav === MenuItemId.LinkedAccounts && <LinkedAccountsBody />}
      {activeNav === MenuItemId.Limits && <LimitsBody />}
      {activeNav === MenuItemId.Support && <SupportBody />}
    </SideNavigationBody>
  )
}

export function getMenuItems(workspace?: string, info?: Info): MenuItems {
  const isLinkedAccountsWarning =
    !info?.hasLinkedSSO ||
    !info?.hasLinkedGitHub ||
    !info?.hasRepoScopePermission

  const menuItems: MenuItems = [
    {
      title: (
        <div className={styles.menuItem}>
          <div className={styles.title}>Linked accounts</div>
          {isLinkedAccountsWarning && (
            <IconWarning data-testid="icon-warning" />
          )}
        </div>
      ),
      itemId: MenuItemId.LinkedAccounts,
    },
  ]

  menuItems.push({
    title: 'Limits',
    itemId: MenuItemId.Limits,
  })

  // Support tab always last
  menuItems.push({
    title: 'Support',
    itemId: MenuItemId.Support,
  })

  return menuItems
}

function UserSettingsModal({
  onClose = _noop,
}: ModalProps): ReactElement | null {
  const name = 'USER_SETTINGS'
  const navigate = useNavigate()
  const location = useLocation()
  const workspaceContext = useWorkspaceContext()

  const onCloseCallback = useCallback(() => {
    const { showUserSettings, ...restCurrentSearch } = queryString.parse(
      location.search,
    )

    navigate(
      {
        pathname: location.pathname,
        search: queryString.stringify(restCurrentSearch),
      },
      { replace: true },
    )

    onClose()
  }, [navigate, location.pathname, location.search, onClose])

  const menuItems: MenuItems = getMenuItems(
    workspaceContext.Name(),
    useSelector(warningInfoSelector),
  )

  if (!menuItems.length) {
    return null
  }

  return (
    <Modal name={name} onClose={onCloseCallback} overrides={modalOverrides}>
      {() => (
        <InnerBody
          menuItems={menuItems}
          workspaceName={workspaceContext.Name()}
          name={name}
        />
      )}
    </Modal>
  )
}

function getDefaultActiveNav(
  preferredActiveNav: MenuItemId | undefined,
  menuItems: MenuItems,
): MenuItemId {
  if (preferredActiveNav) {
    return preferredActiveNav
  }
  return menuItems[0].itemId
}

export default memo(UserSettingsModal)
