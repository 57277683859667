/* tslint:disable */
/* eslint-disable */
/**
 * Community Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from './base'

/**
 *
 * @export
 * @interface AppContextModel
 */
export interface AppContextModel {
  /**
   *
   * @type {string}
   * @memberof AppContextModel
   */
  id: string
  /**
   *
   * @type {boolean}
   * @memberof AppContextModel
   */
  isFavorited: boolean
  /**
   *
   * @type {AppSourceModel}
   * @memberof AppContextModel
   */
  source: AppSourceModel
  /**
   *
   * @type {boolean}
   * @memberof AppContextModel
   */
  supportHostingSubdomain: boolean
}
/**
 *
 * @export
 * @interface AppSourceModel
 */
export interface AppSourceModel {
  /**
   *
   * @type {AppSourceTypeModel}
   * @memberof AppSourceModel
   */
  type: AppSourceTypeModel
  /**
   *
   * @type {AppSourceRepositoryModel}
   * @memberof AppSourceModel
   */
  repository: AppSourceRepositoryModel
}

/**
 *
 * @export
 * @interface AppSourceRepositoryModel
 */
export interface AppSourceRepositoryModel {
  /**
   *
   * @type {boolean}
   * @memberof AppSourceRepositoryModel
   */
  isPrivate: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export const AppSourceTypeModel = {
  GitHub: 'GitHub',
} as const

export type AppSourceTypeModel =
  (typeof AppSourceTypeModel)[keyof typeof AppSourceTypeModel]

/**
 *
 * @export
 * @enum {string}
 */

export const AppStatusModel = {
  UNKNOWN: 0,
  CREATING: 1,
  CREATED: 2,
  UPDATING: 3,
  INSTALLING: 4,
  RUNNING: 5,
  RESTARTING: 6,
  REBOOTING: 7,
  DELETING: 8,
  DELETED: 9,
  USER_ERROR: 10,
  PLATFORM_ERROR: 11,
  IS_SHUTDOWN: 12,
  INSTALLER_ERROR: 13,
  USER_SCRIPT_ERROR: 14,
  POTENTIAL_MINER_DETECTED: 15,
} as const

export type AppStatusModel =
  (typeof AppStatusModel)[keyof typeof AppStatusModel]

/**
 *
 * @export
 * @interface AppStatusResponseModel
 */
export interface AppStatusResponseModel {
  /**
   *
   * @type {boolean}
   * @memberof AppStatusResponseModel
   */
  isOwner?: boolean
  /**
   *
   * @type {AppStatusModel}
   * @memberof AppStatusResponseModel
   */
  status?: AppStatusModel
  /**
   *
   * @type {string}
   * @memberof AppStatusResponseModel
   */
  creatorId?: string
  /**
   *
   * @type {PlatformStatusModel}
   * @memberof AppStatusResponseModel
   */
  platformStatus?: PlatformStatusModel
  /**
   *
   * @type {boolean}
   * @memberof AppStatusResponseModel
   */
  viewerAuthEnabled?: boolean
  /**
   *
   * @type {string}
   * @memberof AppStatusResponseModel
   */
  streamlitVersion?: string
  /**
   *
   * @type {boolean}
   * @memberof AppStatusResponseModel
   */
  supportHostingSubdomain?: boolean
}

/**
 *
 * @export
 * @interface AppTemplateModel
 */
export interface AppTemplateModel {
  /**
   *
   * @type {string}
   * @memberof AppTemplateModel
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof AppTemplateModel
   */
  appId: string
  /**
   *
   * @type {string}
   * @memberof AppTemplateModel
   */
  url: string
  /**
   *
   * @type {string}
   * @memberof AppTemplateModel
   */
  repositoryUrl: string
  /**
   *
   * @type {string}
   * @memberof AppTemplateModel
   */
  branch: string
  /**
   *
   * @type {string}
   * @memberof AppTemplateModel
   */
  mainModule: string
  /**
   *
   * @type {boolean}
   * @memberof AppTemplateModel
   */
  showOnDashboard?: boolean
}
/**
 *
 * @export
 * @interface AppViewersResponseModel
 */
export interface AppViewersResponseModel {
  /**
   *
   * @type {Array<string>}
   * @memberof AppViewersResponseModel
   */
  viewers?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof AppViewersResponseModel
   */
  domains?: Array<string>
}
/**
 *
 * @export
 * @interface AuthorizationAppResponseModel
 */
export interface AuthorizationAppResponseModel {
  /**
   *
   * @type {string}
   * @memberof AuthorizationAppResponseModel
   */
  id: string
  /**
   *
   * @type {AppStatusModel}
   * @memberof AuthorizationAppResponseModel
   */
  status: AppStatusModel
  /**
   *
   * @type {boolean}
   * @memberof AuthorizationAppResponseModel
   */
  viewerAuthEnabled: boolean
}

/**
 *
 * @export
 * @interface AuthorizationResponseModel
 */
export interface AuthorizationResponseModel {
  /**
   *
   * @type {Array<PermissionModel>}
   * @memberof AuthorizationResponseModel
   */
  permissions: Array<PermissionModel>
  /**
   *
   * @type {AuthorizationAppResponseModel}
   * @memberof AuthorizationResponseModel
   */
  app: AuthorizationAppResponseModel
  /**
   *
   * @type {AuthorizationUserResponseModel}
   * @memberof AuthorizationResponseModel
   */
  user: AuthorizationUserResponseModel
}
/**
 *
 * @export
 * @interface AuthorizationUserResponseModel
 */
export interface AuthorizationUserResponseModel {
  /**
   *
   * @type {string}
   * @memberof AuthorizationUserResponseModel
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof AuthorizationUserResponseModel
   */
  gitHubEmail?: string
  /**
   *
   * @type {string}
   * @memberof AuthorizationUserResponseModel
   */
  ssoEmail?: string
  /**
   *
   * @type {string}
   * @memberof AuthorizationUserResponseModel
   */
  ssoProvider?: string
}
/**
 *
 * @export
 * @interface CategoryTemplatesModel
 */
export interface CategoryTemplatesModel {
  /**
   *
   * @type {string}
   * @memberof CategoryTemplatesModel
   */
  category: string
  /**
   *
   * @type {Array<AppTemplateModel>}
   * @memberof CategoryTemplatesModel
   */
  templates: Array<AppTemplateModel>
}
/**
 *
 * @export
 * @interface ContextResponseModel
 */
export interface ContextResponseModel {
  /**
   *
   * @type {UserContextModel}
   * @memberof ContextResponseModel
   */
  user: UserContextModel
  /**
   *
   * @type {AppContextModel}
   * @memberof ContextResponseModel
   */
  app: AppContextModel
  /**
   *
   * @type {WorkspaceContextModel}
   * @memberof ContextResponseModel
   */
  workspace: WorkspaceContextModel
}
/**
 *
 * @export
 * @interface CreateDevContainerRequestModel
 */
export interface CreateDevContainerRequestModel {
  /**
   *
   * @type {string}
   * @memberof CreateDevContainerRequestModel
   */
  userRepository?: string
  /**
   *
   * @type {string}
   * @memberof CreateDevContainerRequestModel
   */
  branch?: string
  /**
   *
   * @type {string}
   * @memberof CreateDevContainerRequestModel
   */
  mainModule?: string
}
/**
 *
 * @export
 * @interface CreateRepositoryFromTemplate400ResponseModel
 */
export interface CreateRepositoryFromTemplate400ResponseModel {
  /**
   *
   * @type {string}
   * @memberof CreateRepositoryFromTemplate400ResponseModel
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof CreateRepositoryFromTemplate400ResponseModel
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof CreateRepositoryFromTemplate400ResponseModel
   */
  detail?: string
  /**
   *
   * @type {number}
   * @memberof CreateRepositoryFromTemplate400ResponseModel
   */
  status?: number
  /**
   *
   * @type {string}
   * @memberof CreateRepositoryFromTemplate400ResponseModel
   */
  errorCode?: string
  /**
   *
   * @type {string}
   * @memberof CreateRepositoryFromTemplate400ResponseModel
   */
  rawMessage?: string
  /**
   *
   * @type {string}
   * @memberof CreateRepositoryFromTemplate400ResponseModel
   */
  error?: string
}
/**
 *
 * @export
 * @interface CreateRepositoryFromTemplateRequestModel
 */
export interface CreateRepositoryFromTemplateRequestModel {
  /**
   *
   * @type {string}
   * @memberof CreateRepositoryFromTemplateRequestModel
   */
  userRepository?: string
  /**
   *
   * @type {string}
   * @memberof CreateRepositoryFromTemplateRequestModel
   */
  templateRepository?: string
}
/**
 *
 * @export
 * @interface CreationRequestModel
 */
export interface CreationRequestModel {
  /**
   *
   * @type {string}
   * @memberof CreationRequestModel
   */
  idempotencyToken?: string
}
/**
 *
 * @export
 * @interface DisambiguateResponseModel
 */
export interface DisambiguateResponseModel {
  /**
   *
   * @type {boolean}
   * @memberof DisambiguateResponseModel
   */
  success?: boolean
  /**
   *
   * @type {string}
   * @memberof DisambiguateResponseModel
   */
  provider: string
  /**
   *
   * @type {string}
   * @memberof DisambiguateResponseModel
   */
  appId: string
  /**
   *
   * @type {string}
   * @memberof DisambiguateResponseModel
   */
  owner: string
  /**
   *
   * @type {string}
   * @memberof DisambiguateResponseModel
   */
  repository: string
  /**
   *
   * @type {string}
   * @memberof DisambiguateResponseModel
   */
  branch: string
  /**
   *
   * @type {string}
   * @memberof DisambiguateResponseModel
   */
  mainModule: string
  /**
   *
   * @type {string}
   * @memberof DisambiguateResponseModel
   */
  host: string
  /**
   *
   * @type {string}
   * @memberof DisambiguateResponseModel
   */
  url: string
  /**
   *
   * @type {string}
   * @memberof DisambiguateResponseModel
   */
  subdomain: string
  /**
   *
   * @type {boolean}
   * @memberof DisambiguateResponseModel
   */
  supportHostingSubdomain: boolean
  /**
   *
   * @type {string}
   * @memberof DisambiguateResponseModel
   */
  cluster?: string
}
/**
 *
 * @export
 * @interface EventsRequestModel
 */
export interface EventsRequestModel {
  /**
   *
   * @type {Array<string>}
   * @memberof EventsRequestModel
   */
  events: Array<string>
}
/**
 *
 * @export
 * @interface FeatureFlagsResponseModel
 */
export interface FeatureFlagsResponseModel {
  /**
   *
   * @type {Array<string>}
   * @memberof FeatureFlagsResponseModel
   */
  enabled?: Array<string>
}
/**
 *
 * @export
 * @interface GalleryAppModel
 */
export interface GalleryAppModel {
  /**
   *
   * @type {string}
   * @memberof GalleryAppModel
   */
  appId: string
  /**
   *
   * @type {number}
   * @memberof GalleryAppModel
   */
  relevanceScore: number
  /**
   *
   * @type {number}
   * @memberof GalleryAppModel
   */
  codeScore?: number
  /**
   *
   * @type {number}
   * @memberof GalleryAppModel
   */
  readmeScore?: number
  /**
   *
   * @type {string}
   * @memberof GalleryAppModel
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof GalleryAppModel
   */
  image: string
  /**
   *
   * @type {string}
   * @memberof GalleryAppModel
   */
  gitHubUrl: string
  /**
   *
   * @type {string}
   * @memberof GalleryAppModel
   */
  appUrl: string
  /**
   *
   * @type {string}
   * @memberof GalleryAppModel
   */
  author: string
  /**
   *
   * @type {string}
   * @memberof GalleryAppModel
   */
  socialUrl: string
  /**
   *
   * @type {string}
   * @memberof GalleryAppModel
   */
  avatar?: string
  /**
   *
   * @type {number}
   * @memberof GalleryAppModel
   */
  views: number
  /**
   *
   * @type {string}
   * @memberof GalleryAppModel
   */
  appCreatedAt: string
  /**
   *
   * @type {string}
   * @memberof GalleryAppModel
   */
  appUpdatedAt: string
  /**
   *
   * @type {AppStatusModel}
   * @memberof GalleryAppModel
   */
  appStatus: AppStatusModel
  /**
   *
   * @type {string}
   * @memberof GalleryAppModel
   */
  appCreatorProfilePageUserName?: string
}

/**
 *
 * @export
 * @interface GalleryCategoryListModel
 */
export interface GalleryCategoryListModel {
  /**
   *
   * @type {Array<GalleryCategoryModel>}
   * @memberof GalleryCategoryListModel
   */
  data: Array<GalleryCategoryModel>
}
/**
 *
 * @export
 * @interface GalleryCategoryModel
 */
export interface GalleryCategoryModel {
  /**
   *
   * @type {string}
   * @memberof GalleryCategoryModel
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof GalleryCategoryModel
   */
  prettyName: string
}
/**
 *
 * @export
 * @interface GalleryResponseModel
 */
export interface GalleryResponseModel {
  /**
   *
   * @type {Array<GalleryAppModel>}
   * @memberof GalleryResponseModel
   */
  apps?: Array<GalleryAppModel>
  /**
   *
   * @type {string}
   * @memberof GalleryResponseModel
   */
  search_id?: string
}
/**
 *
 * @export
 * @interface GallerySearchQueryModel
 */
export interface GallerySearchQueryModel {
  /**
   *
   * @type {string}
   * @memberof GallerySearchQueryModel
   */
  query?: string
  /**
   *
   * @type {string}
   * @memberof GallerySearchQueryModel
   */
  category?: string
  /**
   *
   * @type {boolean}
   * @memberof GallerySearchQueryModel
   */
  disableSplitSearch?: boolean
  /**
   *
   * @type {boolean}
   * @memberof GallerySearchQueryModel
   */
  disableDeduplication?: boolean
  /**
   *
   * @type {boolean}
   * @memberof GallerySearchQueryModel
   */
  disableOptimizedTokenization?: boolean
  /**
   *
   * @type {boolean}
   * @memberof GallerySearchQueryModel
   */
  disableUserErrorFilter?: boolean
  /**
   *
   * @type {boolean}
   * @memberof GallerySearchQueryModel
   */
  disablePopularitySearch?: boolean
}
/**
 *
 * @export
 * @interface GetUserFavoriteAppsResponseModel
 */
export interface GetUserFavoriteAppsResponseModel {
  /**
   *
   * @type {Array<string>}
   * @memberof GetUserFavoriteAppsResponseModel
   */
  appIds?: Array<string>
}
/**
 *
 * @export
 * @interface GitHubRepositoriesListDataModel
 */
export interface GitHubRepositoriesListDataModel {
  /**
   *
   * @type {GitHubRepositoriesListDataViewerModel}
   * @memberof GitHubRepositoriesListDataModel
   */
  viewer: GitHubRepositoriesListDataViewerModel
}
/**
 *
 * @export
 * @interface GitHubRepositoriesListDataViewerModel
 */
export interface GitHubRepositoriesListDataViewerModel {
  /**
   *
   * @type {GitHubRepositoriesListDataViewerRepositoriesModel}
   * @memberof GitHubRepositoriesListDataViewerModel
   */
  repositories: GitHubRepositoriesListDataViewerRepositoriesModel
}
/**
 *
 * @export
 * @interface GitHubRepositoriesListDataViewerRepositoriesModel
 */
export interface GitHubRepositoriesListDataViewerRepositoriesModel {
  /**
   *
   * @type {Array<GitHubRepositoriesListDataViewerRepositoriesNodesInnerModel>}
   * @memberof GitHubRepositoriesListDataViewerRepositoriesModel
   */
  nodes: Array<GitHubRepositoriesListDataViewerRepositoriesNodesInnerModel>
}
/**
 *
 * @export
 * @interface GitHubRepositoriesListDataViewerRepositoriesNodesInnerModel
 */
export interface GitHubRepositoriesListDataViewerRepositoriesNodesInnerModel {
  /**
   *
   * @type {string}
   * @memberof GitHubRepositoriesListDataViewerRepositoriesNodesInnerModel
   */
  nameWithOwner: string
}
/**
 *
 * @export
 * @interface GitHubRepositoriesListModel
 */
export interface GitHubRepositoriesListModel {
  /**
   *
   * @type {GitHubRepositoriesListDataModel}
   * @memberof GitHubRepositoriesListModel
   */
  data: GitHubRepositoriesListDataModel
}
/**
 *
 * @export
 * @interface GitHubRepositoryBranchesListInnerModel
 */
export interface GitHubRepositoryBranchesListInnerModel {
  /**
   *
   * @type {string}
   * @memberof GitHubRepositoryBranchesListInnerModel
   */
  name: string
}
/**
 *
 * @export
 * @interface GithubFileModel
 */
export interface GithubFileModel {
  /**
   *
   * @type {string}
   * @memberof GithubFileModel
   */
  path: string
}
/**
 *
 * @export
 * @interface GithubListPythonFilesResponseModel
 */
export interface GithubListPythonFilesResponseModel {
  /**
   *
   * @type {Array<GithubFileModel>}
   * @memberof GithubListPythonFilesResponseModel
   */
  files?: Array<GithubFileModel>
}
/**
 *
 * @export
 * @interface GithubRepositoryModel
 */
export interface GithubRepositoryModel {
  /**
   *
   * @type {number}
   * @memberof GithubRepositoryModel
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof GithubRepositoryModel
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof GithubRepositoryModel
   */
  isArchived: boolean
  /**
   *
   * @type {boolean}
   * @memberof GithubRepositoryModel
   */
  isDisabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof GithubRepositoryModel
   */
  isPrivate: boolean
  /**
   *
   * @type {string}
   * @memberof GithubRepositoryModel
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof GithubRepositoryModel
   */
  visibility: GithubRepositoryModelVisibilityEnum
  /**
   *
   * @type {GithubRepositoryPermissionsModel}
   * @memberof GithubRepositoryModel
   */
  permissions: GithubRepositoryPermissionsModel
}

export const GithubRepositoryModelVisibilityEnum = {
  Public: 'public',
  Private: 'private',
  Internal: 'internal',
} as const

export type GithubRepositoryModelVisibilityEnum =
  (typeof GithubRepositoryModelVisibilityEnum)[keyof typeof GithubRepositoryModelVisibilityEnum]

/**
 *
 * @export
 * @interface GithubRepositoryPermissionsModel
 */
export interface GithubRepositoryPermissionsModel {
  /**
   *
   * @type {boolean}
   * @memberof GithubRepositoryPermissionsModel
   */
  admin: boolean
  /**
   *
   * @type {boolean}
   * @memberof GithubRepositoryPermissionsModel
   */
  maintain?: boolean
  /**
   *
   * @type {boolean}
   * @memberof GithubRepositoryPermissionsModel
   */
  push: boolean
  /**
   *
   * @type {boolean}
   * @memberof GithubRepositoryPermissionsModel
   */
  triage?: boolean
  /**
   *
   * @type {boolean}
   * @memberof GithubRepositoryPermissionsModel
   */
  pull: boolean
}
/**
 *
 * @export
 * @interface LimitedUserInfoModel
 */
export interface LimitedUserInfoModel {
  /**
   *
   * @type {string}
   * @memberof LimitedUserInfoModel
   */
  login: string
  /**
   *
   * @type {string}
   * @memberof LimitedUserInfoModel
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof LimitedUserInfoModel
   */
  ssoEmail?: string
}
/**
 *
 * @export
 * @interface PaginationRequestModel
 */
export interface PaginationRequestModel {
  /**
   *
   * @type {number}
   * @memberof PaginationRequestModel
   */
  count?: number
  /**
   *
   * @type {string}
   * @memberof PaginationRequestModel
   */
  token?: string
}
/**
 *
 * @export
 * @interface PaginationResponseModel
 */
export interface PaginationResponseModel {
  /**
   *
   * @type {string}
   * @memberof PaginationResponseModel
   */
  token?: string | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const PermissionModel = {
  Unknown: 0,
  App_Restart: 1,
  App_Resume: 2,
  App_Delete: 3,
  App_ManageVisibility: 4,
  App_ManageFavorites: 5,
  App_ReadSecrets: 6,
  App_UpdateSecrets: 7,
  App_DeleteSecrets: 8,
  App_ReadViewers: 9,
  App_UpdateEmailViewers: 10,
  App_UpdateDomainViewers: 11,
  App_AppStatus: 12,
  App_ViewEvents: 13,
  App_WriteEvents: 14,
  App_View: 15,
  App_UpdateSubdomain: 16,
  App_AppContext: 17,
  App_Deploy: 18,
  App_ViewLogs: 19,
  App_ViewMetrics: 20,
  App_ViewHealth: 21,
  App_OEmbed: 22,
  App_Fork: 23,
  App_DevContainer: 24,
  App_Codespace: 25,
  App_ManageInProfilePage: 26,
  Workspace_ReadSubscriptions: 10001,
  Workspace_UpdateSubscription: 10002,
  Workspace_StartPortalSession: 10003,
  Workspace_ViewAccess: 10004,
  Workspace_ViewEvents: 10005,
  Workspace_ReadDomainViewers: 10006,
  Workspace_DeployApp: 10012,
} as const

export type PermissionModel =
  (typeof PermissionModel)[keyof typeof PermissionModel]

/**
 *
 * @export
 * @enum {string}
 */

export const PlatformStatusModel = {
  STATUS_CLEAN: 0,
  RESOURCE_BUSY: 1,
  PLATFORM_MINER_DETECTED: 2,
  UNDEFINED: 3,
} as const

export type PlatformStatusModel =
  (typeof PlatformStatusModel)[keyof typeof PlatformStatusModel]

/**
 *
 * @export
 * @interface PythonVersionModel
 */
export interface PythonVersionModel {
  /**
   *
   * @type {string}
   * @memberof PythonVersionModel
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PythonVersionModel
   */
  label: string
}
/**
 *
 * @export
 * @interface ReadAppSecretsResponseModel
 */
export interface ReadAppSecretsResponseModel {
  /**
   *
   * @type {string}
   * @memberof ReadAppSecretsResponseModel
   */
  secrets: string
}
/**
 *
 * @export
 * @interface SourceProviderConnectionModel
 */
export interface SourceProviderConnectionModel {
  /**
   *
   * @type {string}
   * @memberof SourceProviderConnectionModel
   */
  id: string
  /**
   *
   * @type {SourceProviderConnectionTypeModel}
   * @memberof SourceProviderConnectionModel
   */
  type: SourceProviderConnectionTypeModel
  /**
   *
   * @type {boolean}
   * @memberof SourceProviderConnectionModel
   */
  active: boolean
  /**
   *
   * @type {string}
   * @memberof SourceProviderConnectionModel
   */
  login: string
}

/**
 *
 * @export
 * @enum {string}
 */

export const SourceProviderConnectionTypeModel = {
  UNKNOWN: 0,
  GITHUB_ACCOUNT: 1,
} as const

export type SourceProviderConnectionTypeModel =
  (typeof SourceProviderConnectionTypeModel)[keyof typeof SourceProviderConnectionTypeModel]

/**
 *
 * @export
 * @interface UpdateAppFavoriteStatusRequestModel
 */
export interface UpdateAppFavoriteStatusRequestModel {
  /**
   *
   * @type {boolean}
   * @memberof UpdateAppFavoriteStatusRequestModel
   */
  isFavorite?: boolean
}
/**
 *
 * @export
 * @interface UpdateAppSecretsRequestModel
 */
export interface UpdateAppSecretsRequestModel {
  /**
   *
   * @type {string}
   * @memberof UpdateAppSecretsRequestModel
   */
  secrets?: string
}
/**
 *
 * @export
 * @interface UpdateAppViewersRequestModel
 */
export interface UpdateAppViewersRequestModel {
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateAppViewersRequestModel
   */
  viewers?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateAppViewersRequestModel
   */
  domains?: Array<string>
}
/**
 *
 * @export
 * @interface UpdateAppVisibilityRequestModel
 */
export interface UpdateAppVisibilityRequestModel {
  /**
   *
   * @type {boolean}
   * @memberof UpdateAppVisibilityRequestModel
   */
  isPrivate: boolean
}
/**
 *
 * @export
 * @interface UpdateUserProfilePageRequestModel
 */
export interface UpdateUserProfilePageRequestModel {
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfilePageRequestModel
   */
  fullName?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfilePageRequestModel
   */
  bio?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfilePageRequestModel
   */
  githubHandle?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfilePageRequestModel
   */
  linkedinHandle?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfilePageRequestModel
   */
  twitterHandle?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfilePageRequestModel
   */
  website?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfilePageRequestModel
   */
  location?: string
}
/**
 *
 * @export
 * @interface UserAppModel
 */
export interface UserAppModel {
  /**
   *
   * @type {string}
   * @memberof UserAppModel
   */
  appId: string
  /**
   *
   * @type {string}
   * @memberof UserAppModel
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof UserAppModel
   */
  image: string
  /**
   *
   * @type {string}
   * @memberof UserAppModel
   */
  gitHubUrl: string
  /**
   *
   * @type {string}
   * @memberof UserAppModel
   */
  appUrl: string
  /**
   *
   * @type {number}
   * @memberof UserAppModel
   */
  views: number
  /**
   *
   * @type {string}
   * @memberof UserAppModel
   */
  appCreatedAt: string
  /**
   *
   * @type {string}
   * @memberof UserAppModel
   */
  appUpdatedAt: string
  /**
   *
   * @type {AppStatusModel}
   * @memberof UserAppModel
   */
  appStatus: AppStatusModel
}

/**
 *
 * @export
 * @interface UserContextModel
 */
export interface UserContextModel {
  /**
   *
   * @type {boolean}
   * @memberof UserContextModel
   */
  isAnonymous: boolean
  /**
   *
   * @type {Array<PermissionModel>}
   * @memberof UserContextModel
   */
  workspacePermissions: Array<PermissionModel>
}
/**
 *
 * @export
 * @interface UserInfoModel
 */
export interface UserInfoModel {
  /**
   *
   * @type {string}
   * @memberof UserInfoModel
   */
  canonicalUserId?: string
  /**
   *
   * @type {LimitedUserInfoModel}
   * @memberof UserInfoModel
   */
  user?: LimitedUserInfoModel
  /**
   *
   * @type {Array<UserPermissionModel>}
   * @memberof UserInfoModel
   */
  permissions?: Array<UserPermissionModel>
  /**
   *
   * @type {Array<UserWorkspaceModel>}
   * @memberof UserInfoModel
   */
  workspaces?: Array<UserWorkspaceModel>
  /**
   *
   * @type {Array<UserOnboardingStatusModel>}
   * @memberof UserInfoModel
   */
  onboarding?: Array<UserOnboardingStatusModel>
  /**
   *
   * @type {string}
   * @memberof UserInfoModel
   */
  linkedGithubUser?: string
  /**
   *
   * @type {Array<SourceProviderConnectionModel>}
   * @memberof UserInfoModel
   */
  sourceProviderConnections?: Array<SourceProviderConnectionModel>
  /**
   *
   * @type {UserSSOInfoModel}
   * @memberof UserInfoModel
   */
  ssoInfo?: UserSSOInfoModel
  /**
   *
   * @type {string}
   * @memberof UserInfoModel
   */
  profilePageUserName?: string
  /**
   *
   * @type {UserPreferencesResponseModel}
   * @memberof UserInfoModel
   */
  preferences?: UserPreferencesResponseModel
}
/**
 *
 * @export
 * @enum {string}
 */

export const UserOnboardingStatusModel = {
  UNKNOWN: 0,
  EXISTING_USER: 1,
  COMMUNITY: 2,
  S4T: 3,
} as const

export type UserOnboardingStatusModel =
  (typeof UserOnboardingStatusModel)[keyof typeof UserOnboardingStatusModel]

/**
 *
 * @export
 * @interface UserOnboardingUpdateRequestModel
 */
export interface UserOnboardingUpdateRequestModel {
  /**
   *
   * @type {Array<UserOnboardingStatusModel>}
   * @memberof UserOnboardingUpdateRequestModel
   */
  onboardingStatus?: Array<UserOnboardingStatusModel>
}
/**
 *
 * @export
 * @enum {string}
 */

export const UserPermissionModel = {
  UNKNOWN_PERMISSION: 0,
  ROLE_S4T: 1,
  FEATURE_PRIVATE_REPO: 2,
  ROLE_ENTHUSIAST: 7,
  USER_BLOCKED: 10,
  ROLE_STREAMLIT_INTERNAL_USER: 12,
  FEATURE_GITHUB_REPO_OAUTH_SCOPE: 14,
  EXCLUDED_FROM_MINING_BLOCK: 17,
  SUSPICIOUS_ACCOUNT: 18,
  FEATURE_EXEC_PLANE_MIGRATION_CLUSTER: 19,
  USER_UNBLOCKED_1: 20,
  USER_UNBLOCKED_2: 21,
} as const

export type UserPermissionModel =
  (typeof UserPermissionModel)[keyof typeof UserPermissionModel]

/**
 *
 * @export
 * @interface UserPreferencesResponseModel
 */
export interface UserPreferencesResponseModel {
  /**
   *
   * @type {boolean}
   * @memberof UserPreferencesResponseModel
   */
  profilePageBannerClosed: boolean
}
/**
 *
 * @export
 * @interface UserPreferencesUpdateRequestModel
 */
export interface UserPreferencesUpdateRequestModel {
  /**
   *
   * @type {boolean}
   * @memberof UserPreferencesUpdateRequestModel
   */
  profilePageBannerClosed?: boolean | null
}
/**
 *
 * @export
 * @interface UserProfilePageAppVisibilityRequestModel
 */
export interface UserProfilePageAppVisibilityRequestModel {
  /**
   *
   * @type {boolean}
   * @memberof UserProfilePageAppVisibilityRequestModel
   */
  isHidden: boolean
}
/**
 *
 * @export
 * @interface UserProfilePageResponseModel
 */
export interface UserProfilePageResponseModel {
  /**
   *
   * @type {string}
   * @memberof UserProfilePageResponseModel
   */
  userName: string
  /**
   *
   * @type {string}
   * @memberof UserProfilePageResponseModel
   */
  fullName?: string
  /**
   *
   * @type {string}
   * @memberof UserProfilePageResponseModel
   */
  avatarUrl: string
  /**
   *
   * @type {string}
   * @memberof UserProfilePageResponseModel
   */
  bio?: string
  /**
   *
   * @type {string}
   * @memberof UserProfilePageResponseModel
   */
  githubUrl: string
  /**
   *
   * @type {string}
   * @memberof UserProfilePageResponseModel
   */
  linkedinUrl?: string
  /**
   *
   * @type {string}
   * @memberof UserProfilePageResponseModel
   */
  twitterUrl?: string
  /**
   *
   * @type {string}
   * @memberof UserProfilePageResponseModel
   */
  website?: string
  /**
   *
   * @type {string}
   * @memberof UserProfilePageResponseModel
   */
  location?: string
  /**
   *
   * @type {boolean}
   * @memberof UserProfilePageResponseModel
   */
  isOwner: boolean
  /**
   *
   * @type {Array<UserAppModel>}
   * @memberof UserProfilePageResponseModel
   */
  apps?: Array<UserAppModel>
  /**
   *
   * @type {string}
   * @memberof UserProfilePageResponseModel
   */
  canonicalUserId: string
}
/**
 *
 * @export
 * @interface UserSSOInfoModel
 */
export interface UserSSOInfoModel {
  /**
   *
   * @type {string}
   * @memberof UserSSOInfoModel
   */
  email: string
}
/**
 *
 * @export
 * @interface UserWorkspaceAppModel
 */
export interface UserWorkspaceAppModel {
  /**
   *
   * @type {string}
   * @memberof UserWorkspaceAppModel
   */
  appId: string
  /**
   *
   * @type {string}
   * @memberof UserWorkspaceAppModel
   */
  owner: string
  /**
   *
   * @type {string}
   * @memberof UserWorkspaceAppModel
   */
  repo: string
  /**
   *
   * @type {string}
   * @memberof UserWorkspaceAppModel
   */
  branch: string
  /**
   *
   * @type {string}
   * @memberof UserWorkspaceAppModel
   */
  main_module: string
  /**
   *
   * @type {string}
   * @memberof UserWorkspaceAppModel
   */
  url: string
  /**
   *
   * @type {boolean}
   * @memberof UserWorkspaceAppModel
   */
  viewOnly: boolean
  /**
   *
   * @type {AppStatusModel}
   * @memberof UserWorkspaceAppModel
   */
  status: AppStatusModel
  /**
   *
   * @type {boolean}
   * @memberof UserWorkspaceAppModel
   */
  viewerAuthEnabled: boolean
  /**
   *
   * @type {string}
   * @memberof UserWorkspaceAppModel
   */
  subdomain: string
  /**
   *
   * @type {boolean}
   * @memberof UserWorkspaceAppModel
   */
  isHiddenInProfilePage: boolean
  /**
   *
   * @type {boolean}
   * @memberof UserWorkspaceAppModel
   */
  isManageableInProfilePage: boolean
}

/**
 *
 * @export
 * @interface UserWorkspaceDetailsModel
 */
export interface UserWorkspaceDetailsModel {
  /**
   *
   * @type {string}
   * @memberof UserWorkspaceDetailsModel
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof UserWorkspaceDetailsModel
   */
  name: string
  /**
   *
   * @type {Array<UserWorkspaceAppModel>}
   * @memberof UserWorkspaceDetailsModel
   */
  apps: Array<UserWorkspaceAppModel>
}
/**
 *
 * @export
 * @interface UserWorkspaceModel
 */
export interface UserWorkspaceModel {
  /**
   *
   * @type {string}
   * @memberof UserWorkspaceModel
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof UserWorkspaceModel
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof UserWorkspaceModel
   */
  viewOnly: boolean
  /**
   *
   * @type {Array<WorkspacePermissionModel>}
   * @memberof UserWorkspaceModel
   */
  permissions?: Array<WorkspacePermissionModel>
}
/**
 *
 * @export
 * @interface WorkspaceContextModel
 */
export interface WorkspaceContextModel {
  /**
   *
   * @type {string}
   * @memberof WorkspaceContextModel
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof WorkspaceContextModel
   */
  name: string
  /**
   *
   * @type {Array<WorkspacePermissionModel>}
   * @memberof WorkspaceContextModel
   */
  featureFlags: Array<WorkspacePermissionModel>
}
/**
 *
 * @export
 * @enum {string}
 */

export const WorkspacePermissionModel = {
  UNKNOWN_PERMISSION: 0,
  ROLE_S4T: 1,
  FEATURE_UNLIMITED_APPS: 6,
  ROLE_ENTHUSIAST: 7,
  ROLE_STREAMLIT_INTERNAL_WORKSPACE: 12,
  FEATURE_NO_APP_SPINDOWN: 13,
  FEATURE_DOMAIN_ALLOWLISTING: 16,
  FEATURE_UNIQUE_APP_SUBDOMAINS: 18,
  FEATURE_FREE_TIER_ENFORCEMENT: 20,
  PRIVATE_WORKSPACE: 21,
  FEATURE_UNLIMITED_PRIVATE_APPS: 22,
  FEATURE_CUSTOM_APP_SUBDOMAINS: 23,
  FEATURE_APP_LOAD_REDESIGN: 24,
} as const

export type WorkspacePermissionModel =
  (typeof WorkspacePermissionModel)[keyof typeof WorkspacePermissionModel]

/**
 * AppsApi - axios parameter creator
 * @export
 */
export const AppsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Delete the application with the given ID
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApp: async (
      appId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('deleteApp', 'appId', appId)
      const localVarPath = `/dashboard/apps/{appId}`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete the application with the given ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAppFromSubdomain: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/subdomain/app`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Disambiguate the given path or app ID into a specific application
     * @param {string} [subdomain]
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disambiguate: async (
      subdomain?: string,
      path?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard/apps/disambiguate`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (subdomain !== undefined) {
        localVarQueryParameter['subdomain'] = subdomain
      }

      if (path !== undefined) {
        localVarQueryParameter['path'] = path
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Lookup the application for the current subdomain and return critical information required for rendering it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disambiguateFromSubdomain: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/subdomain/app/disambiguate`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the status of the specified application
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppStatus: async (
      appId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('getAppStatus', 'appId', appId)
      const localVarPath = `/dashboard/apps/{appId}/status`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the status of the specified application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppStatusFromSubdomain: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/subdomain/app/status`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the viewers with which the application was shared
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppViewers: async (
      appId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('getAppViewers', 'appId', appId)
      const localVarPath = `/dashboard/apps/{appId}/viewers`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the viewers with which the application was shared
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppViewersFromSubdomain: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/subdomain/app/viewers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get user, app, and workspace context for the application on the current subdomain
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContext: async (
      appId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('getContext', 'appId', appId)
      const localVarPath = `/dashboard/apps/{appId}/context`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get user, app, and workspace context for the application on the current subdomain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContextFromSubdomain: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/subdomain/app/context`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get apps favorited by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFavoritedApps: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard/favorited-apps`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the secrets associated with the application
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAppSecrets: async (
      appId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('readAppSecrets', 'appId', appId)
      const localVarPath = `/dashboard/apps/{appId}/secrets`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the secrets associated with the application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAppSecretsFromSubdomain: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/subdomain/app/secrets`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Restart the application. If the application is sleeping, restarting it is equivalent to resuming it
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restartApp: async (
      appId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('restartApp', 'appId', appId)
      const localVarPath = `/dashboard/apps/{appId}/restart`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Restart the application. If the application is sleeping, restarting it is equivalent to resuming it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restartAppFromSubdomain: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/subdomain/app/restart`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Resume the application from a spun down/sleeping state
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resumeApp: async (
      appId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('resumeApp', 'appId', appId)
      const localVarPath = `/dashboard/apps/{appId}/resume`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Resume the application from a sleeping state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resumeAppFromSubdomain: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/subdomain/app/resume`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update the secrets associated with the application
     * @param {string} appId
     * @param {UpdateAppSecretsRequestModel} updateAppSecretsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAppSecrets: async (
      appId: string,
      updateAppSecretsRequestModel: UpdateAppSecretsRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('updateAppSecrets', 'appId', appId)
      // verify required parameter 'updateAppSecretsRequestModel' is not null or undefined
      assertParamExists(
        'updateAppSecrets',
        'updateAppSecretsRequestModel',
        updateAppSecretsRequestModel,
      )
      const localVarPath = `/dashboard/apps/{appId}/secrets`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAppSecretsRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update the secrets associated with the application
     * @param {UpdateAppSecretsRequestModel} updateAppSecretsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAppSecretsFromSubdomain: async (
      updateAppSecretsRequestModel: UpdateAppSecretsRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateAppSecretsRequestModel' is not null or undefined
      assertParamExists(
        'updateAppSecretsFromSubdomain',
        'updateAppSecretsRequestModel',
        updateAppSecretsRequestModel,
      )
      const localVarPath = `/subdomain/app/secrets`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAppSecretsRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update the list of viewers with which the application is shared
     * @param {string} appId
     * @param {UpdateAppViewersRequestModel} updateAppViewersRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAppViewers: async (
      appId: string,
      updateAppViewersRequestModel: UpdateAppViewersRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('updateAppViewers', 'appId', appId)
      // verify required parameter 'updateAppViewersRequestModel' is not null or undefined
      assertParamExists(
        'updateAppViewers',
        'updateAppViewersRequestModel',
        updateAppViewersRequestModel,
      )
      const localVarPath = `/dashboard/apps/{appId}/viewers`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAppViewersRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update the list of viewers with which the application is shared
     * @param {UpdateAppViewersRequestModel} updateAppViewersRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAppViewersFromSubdomain: async (
      updateAppViewersRequestModel: UpdateAppViewersRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateAppViewersRequestModel' is not null or undefined
      assertParamExists(
        'updateAppViewersFromSubdomain',
        'updateAppViewersRequestModel',
        updateAppViewersRequestModel,
      )
      const localVarPath = `/subdomain/app/viewers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAppViewersRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Marking an app as favorite
     * @param {string} appId
     * @param {UpdateAppFavoriteStatusRequestModel} updateAppFavoriteStatusRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFavorites: async (
      appId: string,
      updateAppFavoriteStatusRequestModel: UpdateAppFavoriteStatusRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('updateFavorites', 'appId', appId)
      // verify required parameter 'updateAppFavoriteStatusRequestModel' is not null or undefined
      assertParamExists(
        'updateFavorites',
        'updateAppFavoriteStatusRequestModel',
        updateAppFavoriteStatusRequestModel,
      )
      const localVarPath = `/dashboard/apps/{appId}/favorite`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAppFavoriteStatusRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Marking an app as favorite
     * @param {UpdateAppFavoriteStatusRequestModel} updateAppFavoriteStatusRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFavoritesFromSubdomain: async (
      updateAppFavoriteStatusRequestModel: UpdateAppFavoriteStatusRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateAppFavoriteStatusRequestModel' is not null or undefined
      assertParamExists(
        'updateFavoritesFromSubdomain',
        'updateAppFavoriteStatusRequestModel',
        updateAppFavoriteStatusRequestModel,
      )
      const localVarPath = `/subdomain/app/favorite`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAppFavoriteStatusRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update the application\'s visibility
     * @param {string} appId
     * @param {UpdateAppVisibilityRequestModel} updateAppVisibilityRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVisibility: async (
      appId: string,
      updateAppVisibilityRequestModel: UpdateAppVisibilityRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('updateVisibility', 'appId', appId)
      // verify required parameter 'updateAppVisibilityRequestModel' is not null or undefined
      assertParamExists(
        'updateVisibility',
        'updateAppVisibilityRequestModel',
        updateAppVisibilityRequestModel,
      )
      const localVarPath = `/dashboard/apps/{appId}/visibility`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAppVisibilityRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update the application\'s visibility
     * @param {UpdateAppVisibilityRequestModel} updateAppVisibilityRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVisibilityFromSubdomain: async (
      updateAppVisibilityRequestModel: UpdateAppVisibilityRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateAppVisibilityRequestModel' is not null or undefined
      assertParamExists(
        'updateVisibilityFromSubdomain',
        'updateAppVisibilityRequestModel',
        updateAppVisibilityRequestModel,
      )
      const localVarPath = `/subdomain/app/visibility`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAppVisibilityRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AppsApi - functional programming interface
 * @export
 */
export const AppsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AppsApiAxiosParamCreator(configuration)
  return {
    /**
     * Delete the application with the given ID
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteApp(
      appId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApp(
        appId,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.deleteApp']?.[localVarOperationServerIndex]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Delete the application with the given ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAppFromSubdomain(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAppFromSubdomain(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.deleteAppFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Disambiguate the given path or app ID into a specific application
     * @param {string} [subdomain]
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disambiguate(
      subdomain?: string,
      path?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DisambiguateResponseModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.disambiguate(
        subdomain,
        path,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.disambiguate']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Lookup the application for the current subdomain and return critical information required for rendering it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disambiguateFromSubdomain(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DisambiguateResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.disambiguateFromSubdomain(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.disambiguateFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Get the status of the specified application
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppStatus(
      appId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppStatusResponseModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAppStatus(
        appId,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.getAppStatus']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Get the status of the specified application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppStatusFromSubdomain(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppStatusResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAppStatusFromSubdomain(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.getAppStatusFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Get the viewers with which the application was shared
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppViewers(
      appId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppViewersResponseModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAppViewers(
        appId,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.getAppViewers']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Get the viewers with which the application was shared
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppViewersFromSubdomain(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppViewersResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAppViewersFromSubdomain(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.getAppViewersFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Get user, app, and workspace context for the application on the current subdomain
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContext(
      appId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ContextResponseModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContext(
        appId,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.getContext']?.[localVarOperationServerIndex]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Get user, app, and workspace context for the application on the current subdomain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContextFromSubdomain(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ContextResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getContextFromSubdomain(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.getContextFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Get apps favorited by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFavoritedApps(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserFavoriteAppsResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFavoritedApps(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.getFavoritedApps']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Get the secrets associated with the application
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readAppSecrets(
      appId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReadAppSecretsResponseModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.readAppSecrets(
        appId,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.readAppSecrets']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Get the secrets associated with the application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readAppSecretsFromSubdomain(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReadAppSecretsResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readAppSecretsFromSubdomain(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.readAppSecretsFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Restart the application. If the application is sleeping, restarting it is equivalent to resuming it
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restartApp(
      appId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.restartApp(
        appId,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.restartApp']?.[localVarOperationServerIndex]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Restart the application. If the application is sleeping, restarting it is equivalent to resuming it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restartAppFromSubdomain(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.restartAppFromSubdomain(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.restartAppFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Resume the application from a spun down/sleeping state
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resumeApp(
      appId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resumeApp(
        appId,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.resumeApp']?.[localVarOperationServerIndex]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Resume the application from a sleeping state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resumeAppFromSubdomain(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resumeAppFromSubdomain(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.resumeAppFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Update the secrets associated with the application
     * @param {string} appId
     * @param {UpdateAppSecretsRequestModel} updateAppSecretsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAppSecrets(
      appId: string,
      updateAppSecretsRequestModel: UpdateAppSecretsRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAppSecrets(
          appId,
          updateAppSecretsRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.updateAppSecrets']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Update the secrets associated with the application
     * @param {UpdateAppSecretsRequestModel} updateAppSecretsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAppSecretsFromSubdomain(
      updateAppSecretsRequestModel: UpdateAppSecretsRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAppSecretsFromSubdomain(
          updateAppSecretsRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.updateAppSecretsFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Update the list of viewers with which the application is shared
     * @param {string} appId
     * @param {UpdateAppViewersRequestModel} updateAppViewersRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAppViewers(
      appId: string,
      updateAppViewersRequestModel: UpdateAppViewersRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAppViewers(
          appId,
          updateAppViewersRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.updateAppViewers']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Update the list of viewers with which the application is shared
     * @param {UpdateAppViewersRequestModel} updateAppViewersRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAppViewersFromSubdomain(
      updateAppViewersRequestModel: UpdateAppViewersRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAppViewersFromSubdomain(
          updateAppViewersRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.updateAppViewersFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Marking an app as favorite
     * @param {string} appId
     * @param {UpdateAppFavoriteStatusRequestModel} updateAppFavoriteStatusRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFavorites(
      appId: string,
      updateAppFavoriteStatusRequestModel: UpdateAppFavoriteStatusRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFavorites(
        appId,
        updateAppFavoriteStatusRequestModel,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.updateFavorites']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Marking an app as favorite
     * @param {UpdateAppFavoriteStatusRequestModel} updateAppFavoriteStatusRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFavoritesFromSubdomain(
      updateAppFavoriteStatusRequestModel: UpdateAppFavoriteStatusRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateFavoritesFromSubdomain(
          updateAppFavoriteStatusRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.updateFavoritesFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Update the application\'s visibility
     * @param {string} appId
     * @param {UpdateAppVisibilityRequestModel} updateAppVisibilityRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateVisibility(
      appId: string,
      updateAppVisibilityRequestModel: UpdateAppVisibilityRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateVisibility(
          appId,
          updateAppVisibilityRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.updateVisibility']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Update the application\'s visibility
     * @param {UpdateAppVisibilityRequestModel} updateAppVisibilityRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateVisibilityFromSubdomain(
      updateAppVisibilityRequestModel: UpdateAppVisibilityRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateVisibilityFromSubdomain(
          updateAppVisibilityRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AppsApi.updateVisibilityFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * AppsApi - factory interface
 * @export
 */
export const AppsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AppsApiFp(configuration)
  return {
    /**
     * Delete the application with the given ID
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApp(appId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteApp(appId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Delete the application with the given ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAppFromSubdomain(options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteAppFromSubdomain(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Disambiguate the given path or app ID into a specific application
     * @param {string} [subdomain]
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disambiguate(
      subdomain?: string,
      path?: string,
      options?: any,
    ): AxiosPromise<DisambiguateResponseModel> {
      return localVarFp
        .disambiguate(subdomain, path, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Lookup the application for the current subdomain and return critical information required for rendering it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disambiguateFromSubdomain(
      options?: any,
    ): AxiosPromise<DisambiguateResponseModel> {
      return localVarFp
        .disambiguateFromSubdomain(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the status of the specified application
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppStatus(
      appId: string,
      options?: any,
    ): AxiosPromise<AppStatusResponseModel> {
      return localVarFp
        .getAppStatus(appId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the status of the specified application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppStatusFromSubdomain(
      options?: any,
    ): AxiosPromise<AppStatusResponseModel> {
      return localVarFp
        .getAppStatusFromSubdomain(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the viewers with which the application was shared
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppViewers(
      appId: string,
      options?: any,
    ): AxiosPromise<AppViewersResponseModel> {
      return localVarFp
        .getAppViewers(appId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the viewers with which the application was shared
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppViewersFromSubdomain(
      options?: any,
    ): AxiosPromise<AppViewersResponseModel> {
      return localVarFp
        .getAppViewersFromSubdomain(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get user, app, and workspace context for the application on the current subdomain
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContext(
      appId: string,
      options?: any,
    ): AxiosPromise<ContextResponseModel> {
      return localVarFp
        .getContext(appId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get user, app, and workspace context for the application on the current subdomain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContextFromSubdomain(options?: any): AxiosPromise<ContextResponseModel> {
      return localVarFp
        .getContextFromSubdomain(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get apps favorited by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFavoritedApps(
      options?: any,
    ): AxiosPromise<GetUserFavoriteAppsResponseModel> {
      return localVarFp
        .getFavoritedApps(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the secrets associated with the application
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAppSecrets(
      appId: string,
      options?: any,
    ): AxiosPromise<ReadAppSecretsResponseModel> {
      return localVarFp
        .readAppSecrets(appId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the secrets associated with the application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAppSecretsFromSubdomain(
      options?: any,
    ): AxiosPromise<ReadAppSecretsResponseModel> {
      return localVarFp
        .readAppSecretsFromSubdomain(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Restart the application. If the application is sleeping, restarting it is equivalent to resuming it
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restartApp(appId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .restartApp(appId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Restart the application. If the application is sleeping, restarting it is equivalent to resuming it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restartAppFromSubdomain(options?: any): AxiosPromise<void> {
      return localVarFp
        .restartAppFromSubdomain(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Resume the application from a spun down/sleeping state
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resumeApp(appId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .resumeApp(appId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Resume the application from a sleeping state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resumeAppFromSubdomain(options?: any): AxiosPromise<void> {
      return localVarFp
        .resumeAppFromSubdomain(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update the secrets associated with the application
     * @param {string} appId
     * @param {UpdateAppSecretsRequestModel} updateAppSecretsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAppSecrets(
      appId: string,
      updateAppSecretsRequestModel: UpdateAppSecretsRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateAppSecrets(appId, updateAppSecretsRequestModel, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update the secrets associated with the application
     * @param {UpdateAppSecretsRequestModel} updateAppSecretsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAppSecretsFromSubdomain(
      updateAppSecretsRequestModel: UpdateAppSecretsRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateAppSecretsFromSubdomain(updateAppSecretsRequestModel, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update the list of viewers with which the application is shared
     * @param {string} appId
     * @param {UpdateAppViewersRequestModel} updateAppViewersRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAppViewers(
      appId: string,
      updateAppViewersRequestModel: UpdateAppViewersRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateAppViewers(appId, updateAppViewersRequestModel, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update the list of viewers with which the application is shared
     * @param {UpdateAppViewersRequestModel} updateAppViewersRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAppViewersFromSubdomain(
      updateAppViewersRequestModel: UpdateAppViewersRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateAppViewersFromSubdomain(updateAppViewersRequestModel, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Marking an app as favorite
     * @param {string} appId
     * @param {UpdateAppFavoriteStatusRequestModel} updateAppFavoriteStatusRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFavorites(
      appId: string,
      updateAppFavoriteStatusRequestModel: UpdateAppFavoriteStatusRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateFavorites(appId, updateAppFavoriteStatusRequestModel, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Marking an app as favorite
     * @param {UpdateAppFavoriteStatusRequestModel} updateAppFavoriteStatusRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFavoritesFromSubdomain(
      updateAppFavoriteStatusRequestModel: UpdateAppFavoriteStatusRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateFavoritesFromSubdomain(
          updateAppFavoriteStatusRequestModel,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Update the application\'s visibility
     * @param {string} appId
     * @param {UpdateAppVisibilityRequestModel} updateAppVisibilityRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVisibility(
      appId: string,
      updateAppVisibilityRequestModel: UpdateAppVisibilityRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateVisibility(appId, updateAppVisibilityRequestModel, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update the application\'s visibility
     * @param {UpdateAppVisibilityRequestModel} updateAppVisibilityRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVisibilityFromSubdomain(
      updateAppVisibilityRequestModel: UpdateAppVisibilityRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateVisibilityFromSubdomain(updateAppVisibilityRequestModel, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AppsApi - object-oriented interface
 * @export
 * @class AppsApi
 * @extends {BaseAPI}
 */
export class AppsApi extends BaseAPI {
  /**
   * Delete the application with the given ID
   * @param {string} appId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public deleteApp(appId: string, options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .deleteApp(appId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete the application with the given ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public deleteAppFromSubdomain(options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .deleteAppFromSubdomain(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Disambiguate the given path or app ID into a specific application
   * @param {string} [subdomain]
   * @param {string} [path]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public disambiguate(
    subdomain?: string,
    path?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .disambiguate(subdomain, path, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Lookup the application for the current subdomain and return critical information required for rendering it
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public disambiguateFromSubdomain(options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .disambiguateFromSubdomain(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the status of the specified application
   * @param {string} appId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public getAppStatus(appId: string, options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .getAppStatus(appId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the status of the specified application
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public getAppStatusFromSubdomain(options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .getAppStatusFromSubdomain(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the viewers with which the application was shared
   * @param {string} appId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public getAppViewers(appId: string, options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .getAppViewers(appId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the viewers with which the application was shared
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public getAppViewersFromSubdomain(options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .getAppViewersFromSubdomain(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get user, app, and workspace context for the application on the current subdomain
   * @param {string} appId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public getContext(appId: string, options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .getContext(appId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get user, app, and workspace context for the application on the current subdomain
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public getContextFromSubdomain(options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .getContextFromSubdomain(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get apps favorited by user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public getFavoritedApps(options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .getFavoritedApps(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the secrets associated with the application
   * @param {string} appId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public readAppSecrets(appId: string, options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .readAppSecrets(appId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the secrets associated with the application
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public readAppSecretsFromSubdomain(options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .readAppSecretsFromSubdomain(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Restart the application. If the application is sleeping, restarting it is equivalent to resuming it
   * @param {string} appId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public restartApp(appId: string, options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .restartApp(appId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Restart the application. If the application is sleeping, restarting it is equivalent to resuming it
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public restartAppFromSubdomain(options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .restartAppFromSubdomain(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Resume the application from a spun down/sleeping state
   * @param {string} appId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public resumeApp(appId: string, options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .resumeApp(appId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Resume the application from a sleeping state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public resumeAppFromSubdomain(options?: RawAxiosRequestConfig) {
    return AppsApiFp(this.configuration)
      .resumeAppFromSubdomain(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update the secrets associated with the application
   * @param {string} appId
   * @param {UpdateAppSecretsRequestModel} updateAppSecretsRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public updateAppSecrets(
    appId: string,
    updateAppSecretsRequestModel: UpdateAppSecretsRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .updateAppSecrets(appId, updateAppSecretsRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update the secrets associated with the application
   * @param {UpdateAppSecretsRequestModel} updateAppSecretsRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public updateAppSecretsFromSubdomain(
    updateAppSecretsRequestModel: UpdateAppSecretsRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .updateAppSecretsFromSubdomain(updateAppSecretsRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update the list of viewers with which the application is shared
   * @param {string} appId
   * @param {UpdateAppViewersRequestModel} updateAppViewersRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public updateAppViewers(
    appId: string,
    updateAppViewersRequestModel: UpdateAppViewersRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .updateAppViewers(appId, updateAppViewersRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update the list of viewers with which the application is shared
   * @param {UpdateAppViewersRequestModel} updateAppViewersRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public updateAppViewersFromSubdomain(
    updateAppViewersRequestModel: UpdateAppViewersRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .updateAppViewersFromSubdomain(updateAppViewersRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Marking an app as favorite
   * @param {string} appId
   * @param {UpdateAppFavoriteStatusRequestModel} updateAppFavoriteStatusRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public updateFavorites(
    appId: string,
    updateAppFavoriteStatusRequestModel: UpdateAppFavoriteStatusRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .updateFavorites(appId, updateAppFavoriteStatusRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Marking an app as favorite
   * @param {UpdateAppFavoriteStatusRequestModel} updateAppFavoriteStatusRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public updateFavoritesFromSubdomain(
    updateAppFavoriteStatusRequestModel: UpdateAppFavoriteStatusRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .updateFavoritesFromSubdomain(
        updateAppFavoriteStatusRequestModel,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update the application\'s visibility
   * @param {string} appId
   * @param {UpdateAppVisibilityRequestModel} updateAppVisibilityRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public updateVisibility(
    appId: string,
    updateAppVisibilityRequestModel: UpdateAppVisibilityRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .updateVisibility(appId, updateAppVisibilityRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update the application\'s visibility
   * @param {UpdateAppVisibilityRequestModel} updateAppVisibilityRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public updateVisibilityFromSubdomain(
    updateAppVisibilityRequestModel: UpdateAppVisibilityRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .updateVisibilityFromSubdomain(updateAppVisibilityRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * CodespacesApi - axios parameter creator
 * @export
 */
export const CodespacesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates a dev container in the users repository
     * @param {CreateDevContainerRequestModel} createDevContainerRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDevContainer: async (
      createDevContainerRequestModel: CreateDevContainerRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createDevContainerRequestModel' is not null or undefined
      assertParamExists(
        'createDevContainer',
        'createDevContainerRequestModel',
        createDevContainerRequestModel,
      )
      const localVarPath = `/dashboard/codespaces/devcontainer`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDevContainerRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Creates a repository for the current user using an existing template repository
     * @param {CreateRepositoryFromTemplateRequestModel} createRepositoryFromTemplateRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRepositoryFromTemplate: async (
      createRepositoryFromTemplateRequestModel: CreateRepositoryFromTemplateRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createRepositoryFromTemplateRequestModel' is not null or undefined
      assertParamExists(
        'createRepositoryFromTemplate',
        'createRepositoryFromTemplateRequestModel',
        createRepositoryFromTemplateRequestModel,
      )
      const localVarPath = `/dashboard/codespaces/repository-from-template`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRepositoryFromTemplateRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Creates a dev container in the users repository
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fork: async (
      appId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('fork', 'appId', appId)
      const localVarPath = `/dashboard/apps/{appId}/fork`.replace(
        `{${'appId'}}`,
        encodeURIComponent(String(appId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Creates a dev container in the users repository
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forkAppFromSubdomain: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/subdomain/apps/fork`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Returns the categories for templates to get started
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard/codespaces/templates`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CodespacesApi - functional programming interface
 * @export
 */
export const CodespacesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CodespacesApiAxiosParamCreator(configuration)
  return {
    /**
     * Creates a dev container in the users repository
     * @param {CreateDevContainerRequestModel} createDevContainerRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDevContainer(
      createDevContainerRequestModel: CreateDevContainerRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createDevContainer(
          createDevContainerRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['CodespacesApi.createDevContainer']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Creates a repository for the current user using an existing template repository
     * @param {CreateRepositoryFromTemplateRequestModel} createRepositoryFromTemplateRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRepositoryFromTemplate(
      createRepositoryFromTemplateRequestModel: CreateRepositoryFromTemplateRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createRepositoryFromTemplate(
          createRepositoryFromTemplateRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['CodespacesApi.createRepositoryFromTemplate']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Creates a dev container in the users repository
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fork(
      appId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fork(
        appId,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['CodespacesApi.fork']?.[localVarOperationServerIndex]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Creates a dev container in the users repository
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forkAppFromSubdomain(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.forkAppFromSubdomain(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['CodespacesApi.forkAppFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Returns the categories for templates to get started
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTemplates(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CategoryTemplatesModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTemplates(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['CodespacesApi.getTemplates']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * CodespacesApi - factory interface
 * @export
 */
export const CodespacesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CodespacesApiFp(configuration)
  return {
    /**
     * Creates a dev container in the users repository
     * @param {CreateDevContainerRequestModel} createDevContainerRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDevContainer(
      createDevContainerRequestModel: CreateDevContainerRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createDevContainer(createDevContainerRequestModel, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Creates a repository for the current user using an existing template repository
     * @param {CreateRepositoryFromTemplateRequestModel} createRepositoryFromTemplateRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRepositoryFromTemplate(
      createRepositoryFromTemplateRequestModel: CreateRepositoryFromTemplateRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createRepositoryFromTemplate(
          createRepositoryFromTemplateRequestModel,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Creates a dev container in the users repository
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fork(appId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .fork(appId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Creates a dev container in the users repository
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forkAppFromSubdomain(options?: any): AxiosPromise<void> {
      return localVarFp
        .forkAppFromSubdomain(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Returns the categories for templates to get started
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates(options?: any): AxiosPromise<Array<CategoryTemplatesModel>> {
      return localVarFp
        .getTemplates(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * CodespacesApi - object-oriented interface
 * @export
 * @class CodespacesApi
 * @extends {BaseAPI}
 */
export class CodespacesApi extends BaseAPI {
  /**
   * Creates a dev container in the users repository
   * @param {CreateDevContainerRequestModel} createDevContainerRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CodespacesApi
   */
  public createDevContainer(
    createDevContainerRequestModel: CreateDevContainerRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return CodespacesApiFp(this.configuration)
      .createDevContainer(createDevContainerRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Creates a repository for the current user using an existing template repository
   * @param {CreateRepositoryFromTemplateRequestModel} createRepositoryFromTemplateRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CodespacesApi
   */
  public createRepositoryFromTemplate(
    createRepositoryFromTemplateRequestModel: CreateRepositoryFromTemplateRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return CodespacesApiFp(this.configuration)
      .createRepositoryFromTemplate(
        createRepositoryFromTemplateRequestModel,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Creates a dev container in the users repository
   * @param {string} appId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CodespacesApi
   */
  public fork(appId: string, options?: RawAxiosRequestConfig) {
    return CodespacesApiFp(this.configuration)
      .fork(appId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Creates a dev container in the users repository
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CodespacesApi
   */
  public forkAppFromSubdomain(options?: RawAxiosRequestConfig) {
    return CodespacesApiFp(this.configuration)
      .forkAppFromSubdomain(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Returns the categories for templates to get started
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CodespacesApi
   */
  public getTemplates(options?: RawAxiosRequestConfig) {
    return CodespacesApiFp(this.configuration)
      .getTemplates(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get available Python versions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPythonVersions: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard/config/python-versions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ConfigApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get available Python versions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPythonVersions(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<PythonVersionModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPythonVersions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConfigApi.getPythonVersions']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ConfigApiFp(configuration)
  return {
    /**
     *
     * @summary Get available Python versions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPythonVersions(options?: any): AxiosPromise<Array<PythonVersionModel>> {
      return localVarFp
        .getPythonVersions(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
  /**
   *
   * @summary Get available Python versions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public getPythonVersions(options?: RawAxiosRequestConfig) {
    return ConfigApiFp(this.configuration)
      .getPythonVersions(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ControlPlaneApi - axios parameter creator
 * @export
 */
export const ControlPlaneApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get authorization data for an application, for the current user
     * @param {string} subdomain Specifies the subdomain of the application for which we want to retrieve the authorization information
     * @param {boolean} [embed] Specifies if the originating session is for an embedded application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserAuthorizationForApp: async (
      subdomain: string,
      embed?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'subdomain' is not null or undefined
      assertParamExists('getUserAuthorizationForApp', 'subdomain', subdomain)
      const localVarPath = `/dashboard/user/authorization`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (subdomain !== undefined) {
        localVarQueryParameter['subdomain'] = subdomain
      }

      if (embed !== undefined) {
        localVarQueryParameter['embed'] = embed
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ControlPlaneApi - functional programming interface
 * @export
 */
export const ControlPlaneApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ControlPlaneApiAxiosParamCreator(configuration)
  return {
    /**
     * Get authorization data for an application, for the current user
     * @param {string} subdomain Specifies the subdomain of the application for which we want to retrieve the authorization information
     * @param {boolean} [embed] Specifies if the originating session is for an embedded application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserAuthorizationForApp(
      subdomain: string,
      embed?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AuthorizationResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserAuthorizationForApp(
          subdomain,
          embed,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ControlPlaneApi.getUserAuthorizationForApp']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * ControlPlaneApi - factory interface
 * @export
 */
export const ControlPlaneApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ControlPlaneApiFp(configuration)
  return {
    /**
     * Get authorization data for an application, for the current user
     * @param {string} subdomain Specifies the subdomain of the application for which we want to retrieve the authorization information
     * @param {boolean} [embed] Specifies if the originating session is for an embedded application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserAuthorizationForApp(
      subdomain: string,
      embed?: boolean,
      options?: any,
    ): AxiosPromise<AuthorizationResponseModel> {
      return localVarFp
        .getUserAuthorizationForApp(subdomain, embed, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ControlPlaneApi - object-oriented interface
 * @export
 * @class ControlPlaneApi
 * @extends {BaseAPI}
 */
export class ControlPlaneApi extends BaseAPI {
  /**
   * Get authorization data for an application, for the current user
   * @param {string} subdomain Specifies the subdomain of the application for which we want to retrieve the authorization information
   * @param {boolean} [embed] Specifies if the originating session is for an embedded application
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControlPlaneApi
   */
  public getUserAuthorizationForApp(
    subdomain: string,
    embed?: boolean,
    options?: RawAxiosRequestConfig,
  ) {
    return ControlPlaneApiFp(this.configuration)
      .getUserAuthorizationForApp(subdomain, embed, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * FeatureFlagsApi - axios parameter creator
 * @export
 */
export const FeatureFlagsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get feature flags enabled for the requesting user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeatureFlags: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard/feature-flags`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get feature flags enabled for the requesting user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeatureFlagsFromSubdomain: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/subdomain/feature-flags`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * FeatureFlagsApi - functional programming interface
 * @export
 */
export const FeatureFlagsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    FeatureFlagsApiAxiosParamCreator(configuration)
  return {
    /**
     * Get feature flags enabled for the requesting user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFeatureFlags(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FeatureFlagsResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFeatureFlags(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['FeatureFlagsApi.getFeatureFlags']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Get feature flags enabled for the requesting user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFeatureFlagsFromSubdomain(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FeatureFlagsResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFeatureFlagsFromSubdomain(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['FeatureFlagsApi.getFeatureFlagsFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * FeatureFlagsApi - factory interface
 * @export
 */
export const FeatureFlagsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FeatureFlagsApiFp(configuration)
  return {
    /**
     * Get feature flags enabled for the requesting user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeatureFlags(options?: any): AxiosPromise<FeatureFlagsResponseModel> {
      return localVarFp
        .getFeatureFlags(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get feature flags enabled for the requesting user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeatureFlagsFromSubdomain(
      options?: any,
    ): AxiosPromise<FeatureFlagsResponseModel> {
      return localVarFp
        .getFeatureFlagsFromSubdomain(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * FeatureFlagsApi - object-oriented interface
 * @export
 * @class FeatureFlagsApi
 * @extends {BaseAPI}
 */
export class FeatureFlagsApi extends BaseAPI {
  /**
   * Get feature flags enabled for the requesting user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureFlagsApi
   */
  public getFeatureFlags(options?: RawAxiosRequestConfig) {
    return FeatureFlagsApiFp(this.configuration)
      .getFeatureFlags(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get feature flags enabled for the requesting user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureFlagsApi
   */
  public getFeatureFlagsFromSubdomain(options?: RawAxiosRequestConfig) {
    return FeatureFlagsApiFp(this.configuration)
      .getFeatureFlagsFromSubdomain(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * GalleryApi - axios parameter creator
 * @export
 */
export const GalleryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Retrieve the set of categories by which gallery apps can be filtered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGalleryCategories: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard/gallery/categories`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Search the set of gallery apps
     * @param {GallerySearchQueryModel} filter Specifies the criteria by which to filter gallery apps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGalleryApps: async (
      filter: GallerySearchQueryModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'filter' is not null or undefined
      assertParamExists('searchGalleryApps', 'filter', filter)
      const localVarPath = `/dashboard/gallery/search`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GalleryApi - functional programming interface
 * @export
 */
export const GalleryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GalleryApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Retrieve the set of categories by which gallery apps can be filtered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGalleryCategories(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GalleryCategoryListModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getGalleryCategories(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['GalleryApi.getGalleryCategories']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Search the set of gallery apps
     * @param {GallerySearchQueryModel} filter Specifies the criteria by which to filter gallery apps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchGalleryApps(
      filter: GallerySearchQueryModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GalleryResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.searchGalleryApps(filter, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['GalleryApi.searchGalleryApps']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * GalleryApi - factory interface
 * @export
 */
export const GalleryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GalleryApiFp(configuration)
  return {
    /**
     *
     * @summary Retrieve the set of categories by which gallery apps can be filtered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGalleryCategories(
      options?: any,
    ): AxiosPromise<GalleryCategoryListModel> {
      return localVarFp
        .getGalleryCategories(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Search the set of gallery apps
     * @param {GallerySearchQueryModel} filter Specifies the criteria by which to filter gallery apps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGalleryApps(
      filter: GallerySearchQueryModel,
      options?: any,
    ): AxiosPromise<GalleryResponseModel> {
      return localVarFp
        .searchGalleryApps(filter, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * GalleryApi - object-oriented interface
 * @export
 * @class GalleryApi
 * @extends {BaseAPI}
 */
export class GalleryApi extends BaseAPI {
  /**
   *
   * @summary Retrieve the set of categories by which gallery apps can be filtered
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GalleryApi
   */
  public getGalleryCategories(options?: RawAxiosRequestConfig) {
    return GalleryApiFp(this.configuration)
      .getGalleryCategories(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Search the set of gallery apps
   * @param {GallerySearchQueryModel} filter Specifies the criteria by which to filter gallery apps
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GalleryApi
   */
  public searchGalleryApps(
    filter: GallerySearchQueryModel,
    options?: RawAxiosRequestConfig,
  ) {
    return GalleryApiFp(this.configuration)
      .searchGalleryApps(filter, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * GithubApi - axios parameter creator
 * @export
 */
export const GithubApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get the branches in specific {repo} owned by specific user {owner}
     * @param {string} owner
     * @param {string} repo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGitHubRepositoryBranches: async (
      owner: string,
      repo: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'owner' is not null or undefined
      assertParamExists('getGitHubRepositoryBranches', 'owner', owner)
      // verify required parameter 'repo' is not null or undefined
      assertParamExists('getGitHubRepositoryBranches', 'repo', repo)
      const localVarPath = `/dashboard/github/repository/branches`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (owner !== undefined) {
        localVarQueryParameter['owner'] = owner
      }

      if (repo !== undefined) {
        localVarQueryParameter['repo'] = repo
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get GitHub user repositories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGitHubUserRepositories: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard/github/user/repositories`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Fetch python specific files from {repo} owned by specific user {owner}, api returns only files on top level and in single nested folders
     * @param {string} owner
     * @param {string} repo
     * @param {string} ref
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPythonFiles: async (
      owner: string,
      repo: string,
      ref: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'owner' is not null or undefined
      assertParamExists('listPythonFiles', 'owner', owner)
      // verify required parameter 'repo' is not null or undefined
      assertParamExists('listPythonFiles', 'repo', repo)
      // verify required parameter 'ref' is not null or undefined
      assertParamExists('listPythonFiles', 'ref', ref)
      const localVarPath = `/dashboard/github/list-python-files`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (owner !== undefined) {
        localVarQueryParameter['owner'] = owner
      }

      if (repo !== undefined) {
        localVarQueryParameter['repo'] = repo
      }

      if (ref !== undefined) {
        localVarQueryParameter['ref'] = ref
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Returns the information of a github repository
     * @param {string} owner
     * @param {string} repo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queryRepository: async (
      owner: string,
      repo: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'owner' is not null or undefined
      assertParamExists('queryRepository', 'owner', owner)
      // verify required parameter 'repo' is not null or undefined
      assertParamExists('queryRepository', 'repo', repo)
      const localVarPath = `/dashboard/github/query-repository`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (owner !== undefined) {
        localVarQueryParameter['owner'] = owner
      }

      if (repo !== undefined) {
        localVarQueryParameter['repo'] = repo
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Validate if file {path} exists in specific {repo} owned by specific user {owner}
     * @param {string} owner
     * @param {string} repo
     * @param {string} path
     * @param {string} [ref]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyFileExists: async (
      owner: string,
      repo: string,
      path: string,
      ref?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'owner' is not null or undefined
      assertParamExists('verifyFileExists', 'owner', owner)
      // verify required parameter 'repo' is not null or undefined
      assertParamExists('verifyFileExists', 'repo', repo)
      // verify required parameter 'path' is not null or undefined
      assertParamExists('verifyFileExists', 'path', path)
      const localVarPath = `/dashboard/github/validate-file-exists`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (owner !== undefined) {
        localVarQueryParameter['owner'] = owner
      }

      if (repo !== undefined) {
        localVarQueryParameter['repo'] = repo
      }

      if (path !== undefined) {
        localVarQueryParameter['path'] = path
      }

      if (ref !== undefined) {
        localVarQueryParameter['ref'] = ref
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GithubApi - functional programming interface
 * @export
 */
export const GithubApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GithubApiAxiosParamCreator(configuration)
  return {
    /**
     * Get the branches in specific {repo} owned by specific user {owner}
     * @param {string} owner
     * @param {string} repo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGitHubRepositoryBranches(
      owner: string,
      repo: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GitHubRepositoryBranchesListInnerModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getGitHubRepositoryBranches(
          owner,
          repo,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['GithubApi.getGitHubRepositoryBranches']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Get GitHub user repositories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGitHubUserRepositories(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GitHubRepositoriesListModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getGitHubUserRepositories(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['GithubApi.getGitHubUserRepositories']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Fetch python specific files from {repo} owned by specific user {owner}, api returns only files on top level and in single nested folders
     * @param {string} owner
     * @param {string} repo
     * @param {string} ref
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listPythonFiles(
      owner: string,
      repo: string,
      ref: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GithubListPythonFilesResponseModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listPythonFiles(
        owner,
        repo,
        ref,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['GithubApi.listPythonFiles']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Returns the information of a github repository
     * @param {string} owner
     * @param {string} repo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async queryRepository(
      owner: string,
      repo: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GithubRepositoryModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.queryRepository(
        owner,
        repo,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['GithubApi.queryRepository']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Validate if file {path} exists in specific {repo} owned by specific user {owner}
     * @param {string} owner
     * @param {string} repo
     * @param {string} path
     * @param {string} [ref]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyFileExists(
      owner: string,
      repo: string,
      path: string,
      ref?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyFileExists(
          owner,
          repo,
          path,
          ref,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['GithubApi.verifyFileExists']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * GithubApi - factory interface
 * @export
 */
export const GithubApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GithubApiFp(configuration)
  return {
    /**
     * Get the branches in specific {repo} owned by specific user {owner}
     * @param {string} owner
     * @param {string} repo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGitHubRepositoryBranches(
      owner: string,
      repo: string,
      options?: any,
    ): AxiosPromise<Array<GitHubRepositoryBranchesListInnerModel>> {
      return localVarFp
        .getGitHubRepositoryBranches(owner, repo, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get GitHub user repositories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGitHubUserRepositories(
      options?: any,
    ): AxiosPromise<GitHubRepositoriesListModel> {
      return localVarFp
        .getGitHubUserRepositories(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Fetch python specific files from {repo} owned by specific user {owner}, api returns only files on top level and in single nested folders
     * @param {string} owner
     * @param {string} repo
     * @param {string} ref
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPythonFiles(
      owner: string,
      repo: string,
      ref: string,
      options?: any,
    ): AxiosPromise<GithubListPythonFilesResponseModel> {
      return localVarFp
        .listPythonFiles(owner, repo, ref, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Returns the information of a github repository
     * @param {string} owner
     * @param {string} repo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queryRepository(
      owner: string,
      repo: string,
      options?: any,
    ): AxiosPromise<GithubRepositoryModel> {
      return localVarFp
        .queryRepository(owner, repo, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Validate if file {path} exists in specific {repo} owned by specific user {owner}
     * @param {string} owner
     * @param {string} repo
     * @param {string} path
     * @param {string} [ref]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyFileExists(
      owner: string,
      repo: string,
      path: string,
      ref?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .verifyFileExists(owner, repo, path, ref, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * GithubApi - object-oriented interface
 * @export
 * @class GithubApi
 * @extends {BaseAPI}
 */
export class GithubApi extends BaseAPI {
  /**
   * Get the branches in specific {repo} owned by specific user {owner}
   * @param {string} owner
   * @param {string} repo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GithubApi
   */
  public getGitHubRepositoryBranches(
    owner: string,
    repo: string,
    options?: RawAxiosRequestConfig,
  ) {
    return GithubApiFp(this.configuration)
      .getGitHubRepositoryBranches(owner, repo, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get GitHub user repositories
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GithubApi
   */
  public getGitHubUserRepositories(options?: RawAxiosRequestConfig) {
    return GithubApiFp(this.configuration)
      .getGitHubUserRepositories(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Fetch python specific files from {repo} owned by specific user {owner}, api returns only files on top level and in single nested folders
   * @param {string} owner
   * @param {string} repo
   * @param {string} ref
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GithubApi
   */
  public listPythonFiles(
    owner: string,
    repo: string,
    ref: string,
    options?: RawAxiosRequestConfig,
  ) {
    return GithubApiFp(this.configuration)
      .listPythonFiles(owner, repo, ref, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Returns the information of a github repository
   * @param {string} owner
   * @param {string} repo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GithubApi
   */
  public queryRepository(
    owner: string,
    repo: string,
    options?: RawAxiosRequestConfig,
  ) {
    return GithubApiFp(this.configuration)
      .queryRepository(owner, repo, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Validate if file {path} exists in specific {repo} owned by specific user {owner}
   * @param {string} owner
   * @param {string} repo
   * @param {string} path
   * @param {string} [ref]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GithubApi
   */
  public verifyFileExists(
    owner: string,
    repo: string,
    path: string,
    ref?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return GithubApiFp(this.configuration)
      .verifyFileExists(owner, repo, path, ref, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ProfilesApi - axios parameter creator
 * @export
 */
export const ProfilesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get user profile page
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfilePage: async (
      userName: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userName' is not null or undefined
      assertParamExists('getUserProfilePage', 'userName', userName)
      const localVarPath = `/dashboard/profiles/{userName}`.replace(
        `{${'userName'}}`,
        encodeURIComponent(String(userName)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update user app visibility in profile page
     * @param {string} userName
     * @param {string} appId
     * @param {UserProfilePageAppVisibilityRequestModel} userProfilePageAppVisibilityRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserAppVisibility: async (
      userName: string,
      appId: string,
      userProfilePageAppVisibilityRequestModel: UserProfilePageAppVisibilityRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userName' is not null or undefined
      assertParamExists('updateUserAppVisibility', 'userName', userName)
      // verify required parameter 'appId' is not null or undefined
      assertParamExists('updateUserAppVisibility', 'appId', appId)
      // verify required parameter 'userProfilePageAppVisibilityRequestModel' is not null or undefined
      assertParamExists(
        'updateUserAppVisibility',
        'userProfilePageAppVisibilityRequestModel',
        userProfilePageAppVisibilityRequestModel,
      )
      const localVarPath =
        `/dashboard/profiles/{userName}/apps/{appId}/visibility`
          .replace(`{${'userName'}}`, encodeURIComponent(String(userName)))
          .replace(`{${'appId'}}`, encodeURIComponent(String(appId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userProfilePageAppVisibilityRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update user profile page
     * @param {string} userName
     * @param {UpdateUserProfilePageRequestModel} updateUserProfilePageRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfilePage: async (
      userName: string,
      updateUserProfilePageRequestModel: UpdateUserProfilePageRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userName' is not null or undefined
      assertParamExists('updateUserProfilePage', 'userName', userName)
      // verify required parameter 'updateUserProfilePageRequestModel' is not null or undefined
      assertParamExists(
        'updateUserProfilePage',
        'updateUserProfilePageRequestModel',
        updateUserProfilePageRequestModel,
      )
      const localVarPath = `/dashboard/profiles/{userName}`.replace(
        `{${'userName'}}`,
        encodeURIComponent(String(userName)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserProfilePageRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProfilesApi - functional programming interface
 * @export
 */
export const ProfilesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProfilesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get user profile page
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserProfilePage(
      userName: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfilePageResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserProfilePage(userName, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ProfilesApi.getUserProfilePage']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Update user app visibility in profile page
     * @param {string} userName
     * @param {string} appId
     * @param {UserProfilePageAppVisibilityRequestModel} userProfilePageAppVisibilityRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserAppVisibility(
      userName: string,
      appId: string,
      userProfilePageAppVisibilityRequestModel: UserProfilePageAppVisibilityRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserAppVisibility(
          userName,
          appId,
          userProfilePageAppVisibilityRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ProfilesApi.updateUserAppVisibility']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Update user profile page
     * @param {string} userName
     * @param {UpdateUserProfilePageRequestModel} updateUserProfilePageRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserProfilePage(
      userName: string,
      updateUserProfilePageRequestModel: UpdateUserProfilePageRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfilePageResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserProfilePage(
          userName,
          updateUserProfilePageRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ProfilesApi.updateUserProfilePage']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * ProfilesApi - factory interface
 * @export
 */
export const ProfilesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ProfilesApiFp(configuration)
  return {
    /**
     *
     * @summary Get user profile page
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfilePage(
      userName: string,
      options?: any,
    ): AxiosPromise<UserProfilePageResponseModel> {
      return localVarFp
        .getUserProfilePage(userName, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update user app visibility in profile page
     * @param {string} userName
     * @param {string} appId
     * @param {UserProfilePageAppVisibilityRequestModel} userProfilePageAppVisibilityRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserAppVisibility(
      userName: string,
      appId: string,
      userProfilePageAppVisibilityRequestModel: UserProfilePageAppVisibilityRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateUserAppVisibility(
          userName,
          appId,
          userProfilePageAppVisibilityRequestModel,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update user profile page
     * @param {string} userName
     * @param {UpdateUserProfilePageRequestModel} updateUserProfilePageRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfilePage(
      userName: string,
      updateUserProfilePageRequestModel: UpdateUserProfilePageRequestModel,
      options?: any,
    ): AxiosPromise<UserProfilePageResponseModel> {
      return localVarFp
        .updateUserProfilePage(
          userName,
          updateUserProfilePageRequestModel,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ProfilesApi - object-oriented interface
 * @export
 * @class ProfilesApi
 * @extends {BaseAPI}
 */
export class ProfilesApi extends BaseAPI {
  /**
   *
   * @summary Get user profile page
   * @param {string} userName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public getUserProfilePage(userName: string, options?: RawAxiosRequestConfig) {
    return ProfilesApiFp(this.configuration)
      .getUserProfilePage(userName, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update user app visibility in profile page
   * @param {string} userName
   * @param {string} appId
   * @param {UserProfilePageAppVisibilityRequestModel} userProfilePageAppVisibilityRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public updateUserAppVisibility(
    userName: string,
    appId: string,
    userProfilePageAppVisibilityRequestModel: UserProfilePageAppVisibilityRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return ProfilesApiFp(this.configuration)
      .updateUserAppVisibility(
        userName,
        appId,
        userProfilePageAppVisibilityRequestModel,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update user profile page
   * @param {string} userName
   * @param {UpdateUserProfilePageRequestModel} updateUserProfilePageRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilesApi
   */
  public updateUserProfilePage(
    userName: string,
    updateUserProfilePageRequestModel: UpdateUserProfilePageRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return ProfilesApiFp(this.configuration)
      .updateUserProfilePage(
        userName,
        updateUserProfilePageRequestModel,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * TelemetryApi - axios parameter creator
 * @export
 */
export const TelemetryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Submit a set of events for processing
     * @param {EventsRequestModel} eventsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitEvents: async (
      eventsRequestModel: EventsRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventsRequestModel' is not null or undefined
      assertParamExists(
        'submitEvents',
        'eventsRequestModel',
        eventsRequestModel,
      )
      const localVarPath = `/dashboard/telemetry/events`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        eventsRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Submit a set of events for processing
     * @param {EventsRequestModel} eventsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitEventsFromSubdomain: async (
      eventsRequestModel: EventsRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventsRequestModel' is not null or undefined
      assertParamExists(
        'submitEventsFromSubdomain',
        'eventsRequestModel',
        eventsRequestModel,
      )
      const localVarPath = `/subdomain/telemetry/events`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        eventsRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TelemetryApi - functional programming interface
 * @export
 */
export const TelemetryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TelemetryApiAxiosParamCreator(configuration)
  return {
    /**
     * Submit a set of events for processing
     * @param {EventsRequestModel} eventsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitEvents(
      eventsRequestModel: EventsRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submitEvents(
        eventsRequestModel,
        options,
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['TelemetryApi.submitEvents']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Submit a set of events for processing
     * @param {EventsRequestModel} eventsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitEventsFromSubdomain(
      eventsRequestModel: EventsRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.submitEventsFromSubdomain(
          eventsRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['TelemetryApi.submitEventsFromSubdomain']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * TelemetryApi - factory interface
 * @export
 */
export const TelemetryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TelemetryApiFp(configuration)
  return {
    /**
     * Submit a set of events for processing
     * @param {EventsRequestModel} eventsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitEvents(
      eventsRequestModel: EventsRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .submitEvents(eventsRequestModel, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Submit a set of events for processing
     * @param {EventsRequestModel} eventsRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitEventsFromSubdomain(
      eventsRequestModel: EventsRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .submitEventsFromSubdomain(eventsRequestModel, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * TelemetryApi - object-oriented interface
 * @export
 * @class TelemetryApi
 * @extends {BaseAPI}
 */
export class TelemetryApi extends BaseAPI {
  /**
   * Submit a set of events for processing
   * @param {EventsRequestModel} eventsRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelemetryApi
   */
  public submitEvents(
    eventsRequestModel: EventsRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return TelemetryApiFp(this.configuration)
      .submitEvents(eventsRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Submit a set of events for processing
   * @param {EventsRequestModel} eventsRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelemetryApi
   */
  public submitEventsFromSubdomain(
    eventsRequestModel: EventsRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return TelemetryApiFp(this.configuration)
      .submitEventsFromSubdomain(eventsRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get information about the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard/user`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get information about the given workspace for the authenticated user
     * @param {string} workspaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserWorkspace: async (
      workspaceId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceId' is not null or undefined
      assertParamExists('getUserWorkspace', 'workspaceId', workspaceId)
      const localVarPath = `/dashboard/user/workspaces/{workspaceId}`.replace(
        `{${'workspaceId'}}`,
        encodeURIComponent(String(workspaceId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update user preferences
     * @param {UserPreferencesUpdateRequestModel} userPreferencesUpdateRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePreferences: async (
      userPreferencesUpdateRequestModel: UserPreferencesUpdateRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userPreferencesUpdateRequestModel' is not null or undefined
      assertParamExists(
        'updatePreferences',
        'userPreferencesUpdateRequestModel',
        userPreferencesUpdateRequestModel,
      )
      const localVarPath = `/dashboard/user/preferences`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userPreferencesUpdateRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Updates the onboarding status for the user
     * @param {UserOnboardingUpdateRequestModel} userOnboardingUpdateRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserOnboarding: async (
      userOnboardingUpdateRequestModel: UserOnboardingUpdateRequestModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userOnboardingUpdateRequestModel' is not null or undefined
      assertParamExists(
        'updateUserOnboarding',
        'userOnboardingUpdateRequestModel',
        userOnboardingUpdateRequestModel,
      )
      const localVarPath = `/dashboard/user/onboarding`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userOnboardingUpdateRequestModel,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get information about the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserApi.getUser']?.[localVarOperationServerIndex]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Get information about the given workspace for the authenticated user
     * @param {string} workspaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserWorkspace(
      workspaceId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWorkspaceDetailsModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserWorkspace(workspaceId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserApi.getUserWorkspace']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Update user preferences
     * @param {UserPreferencesUpdateRequestModel} userPreferencesUpdateRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePreferences(
      userPreferencesUpdateRequestModel: UserPreferencesUpdateRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserPreferencesResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updatePreferences(
          userPreferencesUpdateRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserApi.updatePreferences']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Updates the onboarding status for the user
     * @param {UserOnboardingUpdateRequestModel} userOnboardingUpdateRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserOnboarding(
      userOnboardingUpdateRequestModel: UserOnboardingUpdateRequestModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserOnboarding(
          userOnboardingUpdateRequestModel,
          options,
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserApi.updateUserOnboarding']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserApiFp(configuration)
  return {
    /**
     *
     * @summary Get information about the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(options?: any): AxiosPromise<UserInfoModel> {
      return localVarFp
        .getUser(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get information about the given workspace for the authenticated user
     * @param {string} workspaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserWorkspace(
      workspaceId: string,
      options?: any,
    ): AxiosPromise<UserWorkspaceDetailsModel> {
      return localVarFp
        .getUserWorkspace(workspaceId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update user preferences
     * @param {UserPreferencesUpdateRequestModel} userPreferencesUpdateRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePreferences(
      userPreferencesUpdateRequestModel: UserPreferencesUpdateRequestModel,
      options?: any,
    ): AxiosPromise<UserPreferencesResponseModel> {
      return localVarFp
        .updatePreferences(userPreferencesUpdateRequestModel, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Updates the onboarding status for the user
     * @param {UserOnboardingUpdateRequestModel} userOnboardingUpdateRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserOnboarding(
      userOnboardingUpdateRequestModel: UserOnboardingUpdateRequestModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateUserOnboarding(userOnboardingUpdateRequestModel, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary Get information about the authenticated user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUser(options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .getUser(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get information about the given workspace for the authenticated user
   * @param {string} workspaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserWorkspace(
    workspaceId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return UserApiFp(this.configuration)
      .getUserWorkspace(workspaceId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update user preferences
   * @param {UserPreferencesUpdateRequestModel} userPreferencesUpdateRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updatePreferences(
    userPreferencesUpdateRequestModel: UserPreferencesUpdateRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return UserApiFp(this.configuration)
      .updatePreferences(userPreferencesUpdateRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Updates the onboarding status for the user
   * @param {UserOnboardingUpdateRequestModel} userOnboardingUpdateRequestModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUserOnboarding(
    userOnboardingUpdateRequestModel: UserOnboardingUpdateRequestModel,
    options?: RawAxiosRequestConfig,
  ) {
    return UserApiFp(this.configuration)
      .updateUserOnboarding(userOnboardingUpdateRequestModel, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
