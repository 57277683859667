import type { EventSink } from './eventSink'
import type { AnalyticsEvent } from './analyticsEvent'
import type { EventsRequestModel, TelemetryApi } from '../../gen'
import { isSubdomainApp } from '../../hooks/useUniqueSubdomainFeatures'
import { serializeToBase64EncodedBytes } from './reflection'
import { datadogLogs } from '@datadog/browser-logs'

export class CommunityEventSink implements EventSink {
  private readonly client: TelemetryApi

  constructor(client: TelemetryApi) {
    this.client = client
  }

  public async write(event: AnalyticsEvent): Promise<void> {
    try {
      const payload = await this.createPayload(event)
      await this.publishPayload(payload)
    } catch (ex: any) {
      datadogLogs.logger.error('Failed to write event', { event }, ex)
    }
  }

  public async writeAll(events: AnalyticsEvent[]): Promise<void> {
    try {
      const payload = await this.createPayload(...events)
      await this.publishPayload(payload)
    } catch (ex: any) {
      datadogLogs.logger.error('Failed to write event', { events }, ex)
    }
  }

  private async publishPayload(payload: EventsRequestModel): Promise<void> {
    if (isSubdomainApp()) {
      await this.client.submitEventsFromSubdomain(payload)
    } else {
      await this.client.submitEvents(payload)
    }
  }

  private async createPayload(
    ...events: AnalyticsEvent[]
  ): Promise<EventsRequestModel> {
    const payloadElements = []
    for (const event of events) {
      // In order to measure the event creation time, we send to the server a negative value which
      // represent the difference between event creation time and event publishing time. The difference will be in
      // milliseconds and it ignores the clock of the client, which most likely will be out of sync with the server
      // clock. On the server side, we update the event timestamp as "UTC.now() - drift" which would give a
      // pretty accurate approximation of event creation time in UTC. Note: this method is ignoring the client-server
      // latency.
      if (
        event.commonProperties?.timestamp &&
        typeof event.commonProperties.timestamp === 'number'
      ) {
        event.commonProperties.timestamp =
          event.commonProperties.timestamp - new Date().getTime()
      }
      payloadElements.push(serializeToBase64EncodedBytes(event))
    }

    return {
      events: payloadElements,
    }
  }
}
