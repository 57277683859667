import { memo, type ReactElement } from 'react'

import { type APIError } from 'api/types'
import { type AxiosError } from 'axios'
import styles from './styles.module.scss'
import { type CreateRepositoryFromTemplate400ResponseModel } from '../../gen'

interface ErrorDetailsType {
  errorMessage: string
  errorCode: string
}

export function parseAPIError(
  error: AxiosError<CreateRepositoryFromTemplate400ResponseModel | any>,
): ErrorDetailsType {
  let errorMessage = 'Unknown error'

  // Adding made-up error codes, so it's easier to find which branch of this code drew the error on
  // the screen, to help debugging.
  let errorCode = '0ST'

  // Following pattern from https://github.com/axios/axios#handling-errors
  if (error.response) {
    const errorResponse = error.response?.data

    if (error?.response?.data.detail) {
      errorMessage = error?.response?.data?.detail
    } else if (errorResponse?.error) {
      errorMessage = errorResponse?.error
    }
    if (error.response?.status) {
      errorCode = `${error.response?.status}`
    }
  } else if (error.request) {
    errorMessage = 'Received no response from server'
    errorCode = '1ST'
  } else if (error.message) {
    errorMessage = error.message
    errorCode = `2ST ${error.code || ''}`
  }

  return { errorMessage, errorCode }
}

function formatErrorDetails(errorDetails: string | APIError): string {
  if (typeof errorDetails === 'string') {
    return errorDetails
  }

  const { errorMessage, errorCode } = parseAPIError(errorDetails)

  return `
    Error: ${errorMessage}
    Code: ${errorCode}
  `
}

interface Props {
  details?: string | APIError
}

function ErrorDetails({ details }: Props): ReactElement {
  return (
    <>
      {details && (
        <div className={styles.errorDetails}>{formatErrorDetails(details)}</div>
      )}
    </>
  )
}

export default memo(ErrorDetails)
