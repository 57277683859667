import { memo, type ReactElement } from 'react'

import useUser from 'api/user'

import useWorkspacePermissions from 'hooks/useWorkspacePermissions'

import urls from 'constants/urls'

import { StyledLink } from 'components/link'
import { TabBody } from 'components/modal/components/tabBody'
import TabBodyPart from 'components/modal/components/tabBody/tabBodyPart'

import WorkspacePermissions from 'constants/workspacePermissions'

import StarterLimits from './limitsComponents/starterLimits'

import styles from './limits.module.scss'

function LimitsBody(): ReactElement {
  const { workspaces } = useUser()
  const wsPerms = useWorkspacePermissions(workspaces)
  const hasRoleS4T = wsPerms.includes(WorkspacePermissions.ROLE_S4T)

  return (
    <TabBody>
      {hasRoleS4T ? (
        <TabBodyPart
          title="Limits"
          body={
            <div className={styles.transitionMessage}>
              <p>
                Streamlit is now a part of Snowflake 🎈❄️! We’re working on a
                new iteration of our Cloud product. During the transition,
                you’ll enjoy all the resources previously agreed on as part of
                your paid plan, but we won’t charge you.
              </p>
              <p>
                <StyledLink external to={`${urls.snowflakeSupportLink}`}>
                  Contact us
                </StyledLink>{' '}
                to learn more!
              </p>
            </div>
          }
        />
      ) : (
        <StarterLimits />
      )}
    </TabBody>
  )
}

export default memo(LimitsBody)
