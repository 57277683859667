import {
  type ButtonHTMLAttributes,
  type ReactElement,
  forwardRef,
  type Ref,
  memo,
  type ReactNode,
} from 'react'
import classNames from 'classnames'
import { StyledSpinnerNext as Spinner, SIZE } from 'baseui/spinner'

import styles from './button.module.scss'

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'button'
  children: ReactNode
  disabled?: boolean
  className?: string
  isLoading?: boolean
  theme?:
    | 'primary'
    | 'destructive'
    | 'destructive_outline'
    | 'outline'
    | 'cancel'
}

function Button(
  {
    type = 'button',
    theme = 'primary',
    disabled = false,
    isLoading = false,
    className: extendClassName,
    children,

    ...props
  }: Props,
  ref: Ref<HTMLButtonElement>,
): ReactElement {
  const className = classNames(
    styles.button,
    styles[`button_${theme}`],
    {
      [styles.isLoading]: isLoading,
    },
    extendClassName,
  )

  return (
    <button
      ref={ref}
      type={type}
      className={className}
      disabled={isLoading || disabled}
      {...props}
    >
      {children}
      {isLoading && (
        <span className={styles.spinner}>
          <Spinner $size={SIZE.small} />
        </span>
      )}
    </button>
  )
}

export default memo(forwardRef(Button))
