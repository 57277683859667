import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { type Surface, type UserRole } from 'constants/analytics'
import { type MenuItemId as AnalyticsModalTabs } from 'modals/analytics/analytics'
import { enqueue } from 'reducers/analytics'

const ANALYTICS_MODAL = 'analytics_modal'

export interface TrackAnalyticsArgs {
  surface: Surface
  tab: AnalyticsModalTabs
  workspaceName?: string
  appId?: string
  role?: UserRole
}

function useTrackAnalytics(): (args: TrackAnalyticsArgs) => void {
  const dispatch = useDispatch()
  const trackAnalytics = useCallback(
    ({ surface, tab, workspaceName, appId, role }: TrackAnalyticsArgs) => {
      dispatch(
        enqueue({
          name: ANALYTICS_MODAL,
          properties: {
            surface,
            tab,
            workspaceName,
            appId,
            role,
          },
        }),
      )
    },
    [dispatch],
  )
  return trackAnalytics
}

export default useTrackAnalytics
