import { type ReactElement, type ReactNode } from 'react'
import { DefaultAnalyticsClient } from 'api/analytics/analyticsClient'
import { AnalyticsClientContext } from 'context/analyticsClientContext'
import { TaskSchedulerFactoryImpl } from 'helpers/tasks/taskSchedulerFactory'
import { CommunityEventSink } from '../../api/analytics/communityEventSink'
import { getTelemetryApiClient } from '../../api/apiV2'

interface Props {
  children: ReactNode
}

function AnalyticsClientProvider({ children }: Props): ReactElement {
  const taskSchedulerFactory = new TaskSchedulerFactoryImpl()
  const telemetryApiClient = getTelemetryApiClient()
  const analyticsClient = new DefaultAnalyticsClient(
    {
      publishingPeriodSeconds: 5,
      maxMessagesPerPublish: 100,
    },
    new CommunityEventSink(telemetryApiClient),
    taskSchedulerFactory.create(),
  )

  return (
    <AnalyticsClientContext.Provider value={analyticsClient}>
      {children}
    </AnalyticsClientContext.Provider>
  )
}

export default AnalyticsClientProvider
