import { memo, type ReactElement, type ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useSessionStorage } from 'react-use'

import useHasSeenLinkedRedirect from './hooks/useHasSeenLinkedRedirect'
import useRedirectInSessionStorage from './hooks/useRedirectInSessionStorage'

interface Props {
  children: ReactNode
}

function SessionStorageProvider({ children }: Props): ReactElement {
  useRedirectInSessionStorage()
  useHasSeenLinkedRedirect()

  return <>{children}</>
}

export function RedirectFromSessionStorage(): ReactElement {
  const [redirectUrlFromSessionStorage] = useSessionStorage<string | undefined>(
    'redirect',
  )

  return <Navigate to={redirectUrlFromSessionStorage ?? '/'} />
}

export default memo(SessionStorageProvider)
