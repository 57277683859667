import { type ReactElement, memo } from 'react'

import classnames from 'classnames'

import { type IApp } from 'api/apps'

import Viewers from 'modals/share/viewers'
import Domains from 'modals/share/domains'
import { EmailsInputForm } from 'modals/share/emailsInput'

import styles from 'modals/share/invite/invite.module.scss'

interface InnerBodyProps {
  app: IApp
  isOwner: boolean
}

function Invite({ app, isOwner }: InnerBodyProps): ReactElement {
  return (
    <div className={styles.modalBody}>
      <div className={classnames(styles.modalTop, styles.bodyPadding)}>
        <EmailsInputForm app={app} isOwner={isOwner} />
      </div>
      <div className={styles.modalContent}>
        <Domains isOwner={isOwner} app={app} />
        <Viewers app={app} isOwner={isOwner} />
      </div>
    </div>
  )
}

export default memo(Invite)
