import { type ReactElement, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import urls from 'constants/urls'

import { setRedirect } from 'reducers/redirect'
import { linkedRedirectSelector } from 'reducers/linkedRedirect/selectors'
import useUser from 'api/user'
import { performLogout } from 'api/logout'

import Page from 'components/page'
import Header from 'components/header'
import { StyledLink } from 'components/link'
import LinkButton from 'components/linkButton'
import LoginRedirect, { LinkedUserRedirect } from 'components/loginRedirect'

import { ReactComponent as NeutralFace } from 'assets/svg/emoji_u1f610.svg'

import styles from './styles.module.scss'
import { showModal } from '../../reducers/modal'
import { MenuItemId } from '../../modals/userSettings/userSettings'
import { startStreamlitLogin } from '../../api/login'
import { datadogLogs } from '@datadog/browser-logs'

function NotFoundPage(): ReactElement {
  const dispatch = useDispatch()
  const location = useLocation()
  const { user, linkedUser } = useUser()
  const [loginWithGH] = useState<boolean>(false)
  const { hasSeenLinkedRedirect } = useSelector(linkedRedirectSelector)

  const onClickSignOutAndSignIn = useCallback(async () => {
    try {
      // Save the redirect so that the user is returned to the application page
      dispatch(
        setRedirect({
          url: location.pathname + location.search,
        }),
      )

      // The user is logged in, so we first log them out and then send them directly to the AuthKit page
      await performLogout()
      startStreamlitLogin()
    } catch (error) {
      datadogLogs.logger.error('Failed to sign the user out', { error })
    }
  }, [dispatch, location.pathname, location.search])

  const onClickOpenSettingsDialog = useCallback(() => {
    dispatch(
      showModal({
        name: 'USER_SETTINGS',
        modalPayload: {
          activeNav: MenuItemId.LinkedAccounts,
        },
      }),
    )
  }, [dispatch])

  const onClickSignIn = useCallback(
    () => async () => {
      try {
        dispatch(
          setRedirect({
            url: location.pathname + location.search,
          }),
        )

        startStreamlitLogin()
      } catch (error) {
        datadogLogs.logger.error('Failed to sign the user in', {
          error,
        })
      }
    },
    [dispatch, location.pathname, location.search],
  )

  // when a connected account has no session
  if (!hasSeenLinkedRedirect && linkedUser) {
    return <LinkedUserRedirect linkedUser={linkedUser} />
  }

  return (
    <Page header={<Header showExternalLinksNavigation hideAnalytics />}>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <span className={styles.neutralFaceEmoji}>
        <NeutralFace />
      </span>

      {loginWithGH && <LoginRedirect />}

      {/* Case 1: user is signed out completely */}
      {!user && (
        <>
          <h1 className={styles.heading}>
            You do not have access to this app or it does not exist
          </h1>
          <p>
            Please <LinkButton onClick={onClickSignIn()}>sign in</LinkButton> to
            continue.
          </p>
        </>
      )}

      {/* Case 2: user is signed in with GitHub & SSO */}
      {user?.login && user?.ssoEmail && (
        <>
          <h1 className={styles.heading}>
            You do not have access to this app or it does not exist
          </h1>
          <p>
            You&apos;re currently signed in as <strong>{user.ssoEmail}</strong>{' '}
            and with github.com
            <strong>/{user.login}</strong>. Are you sure these accounts have
            access?
            <br />
            <br />
            <LinkButton onClick={onClickSignOutAndSignIn}>
              Sign out and sign
            </LinkButton>{' '}
            in with a different account. If you are the app&apos;s developer,{' '}
            <LinkButton onClick={onClickOpenSettingsDialog}>
              update the source control account
            </LinkButton>{' '}
            associated with your account.
          </p>
        </>
      )}

      {/* Case 3: user is signed out from GitHub & signed in with SSO */}
      {user && !user.login && user.ssoEmail && (
        <>
          <h1 className={styles.heading}>
            You do not have access to this app or it does not exist
          </h1>
          <p>
            You&apos;re currently signed in as <strong>{user.ssoEmail}</strong>.
            Are you sure this account has access?
            <br />
            <br />
            <LinkButton onClick={onClickSignOutAndSignIn}>
              Sign out and sign in
            </LinkButton>{' '}
            with a different account. If you are the app&apos;s developer,{' '}
            <LinkButton onClick={onClickOpenSettingsDialog}>
              update the source control account
            </LinkButton>{' '}
            associated with your account.
          </p>
        </>
      )}

      <div className={styles.contactSupport}>
        <div className={styles.smallDivider} />
        <p>
          If you believe this is a bug,{' '}
          <StyledLink
            to={urls.snowflakeCommunityForumLink}
            className={styles.mailTo}
          >
            contact support
          </StyledLink>
          .
        </p>
      </div>
    </Page>
  )
}

export default NotFoundPage
