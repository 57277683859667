import { PLACEMENT } from 'baseui/tooltip'

import tailwind from 'constants/tailwind.config'

const DEFAULT_STYLES = {
  accessibilityType: 'tooltip' as const,
}

const DEFAULT_INNER_OVERRIDES = {
  style: {
    backgroundColor: '#FFF',
    borderRadius: '4px',
    boxShadow:
      '0px 2px 20px 0px rgba(0, 0, 0, 0.04), 0px 8px 32px 0px rgba(0, 0, 0, 0.08)',
    color: '#000',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
}

export default {
  bottomLeft: {
    placement: PLACEMENT.bottomLeft,
    overrides: {
      Arrow: {
        style: {
          width: '0.5rem',
          height: '0.5rem',
          left: `calc(${tailwind.theme.container.padding}/4)`,
        },
      },
      Inner: {
        ...DEFAULT_INNER_OVERRIDES,
      },
    },
    ...DEFAULT_STYLES,
  },

  bottomRight: {
    placement: PLACEMENT.bottomRight,
    overrides: {
      Arrow: {
        style: {
          width: '0.5rem',
          height: '0.5rem',
          left: `calc(100% - ${tailwind.theme.container.padding}/4 - 0.5rem)`,
        },
      },
      Inner: {
        ...DEFAULT_INNER_OVERRIDES,
      },
    },
    ...DEFAULT_STYLES,
  },

  left: {
    placement: PLACEMENT.left,
    overrides: {
      Arrow: {
        style: {
          height: '0.5rem',
          width: '0.5rem',
          left: `calc(100% - ${tailwind.theme.container.padding}/4 - 0.5rem)`,
        },
      },
      Inner: {
        ...DEFAULT_INNER_OVERRIDES,
      },
    },
    ...DEFAULT_STYLES,
  },
}
