import { createSlice, type PayloadAction, type Draft } from '@reduxjs/toolkit'

/*
  - hasSeenLinkedRedirect:
    - application code reads to determine whether or not to send on linked account redirect
    - Session Storage sets from refreshed page state
  - hasSeenLinkedRedirectSetter:
    - application code sets when user is sent on a linked account redirect
    - Session Storage follows to know when to persist

    application -> hasSeenLinkedRedirectSetter -> Session Storage
                                                  /
                 ---------------------------------
                /
    Session Storage -> hasSeenLinkedRedirect -> application
*/
export interface State {
  hasSeenLinkedRedirect?: boolean
  hasSeenLinkedRedirectSetter?: boolean
}

const initialState: State = {}

interface SetHasSeenLinkedRedirectPayload {
  hasSeenLinkedRedirect: boolean
}

interface SetLinkedRedirectSetterPayload {
  hasSeenLinkedRedirectSetter: boolean
}

const slice = createSlice({
  name: 'linkedRedirect',
  initialState,
  reducers: {
    setHasSeenLinkedRedirect: (
      state: Draft<State>,
      action: PayloadAction<SetHasSeenLinkedRedirectPayload>,
    ) => {
      state.hasSeenLinkedRedirect = action.payload.hasSeenLinkedRedirect
    },
    setLinkedRedirectSetter: (
      state: Draft<State>,
      action: PayloadAction<SetLinkedRedirectSetterPayload>,
    ) => {
      state.hasSeenLinkedRedirectSetter =
        action.payload.hasSeenLinkedRedirectSetter
    },
  },
})

export const { setHasSeenLinkedRedirect, setLinkedRedirectSetter } =
  slice.actions

export default slice
