import { useState } from 'react'
import { mutate } from 'swr'

import { APP_STATUS } from 'constants/apps'

import mutateAppWithStatus from 'helpers/mutateAppWithStatus'

import { type APIError } from 'api/types'
import { getAppsKey, type IApp } from 'api/apps'
import useAppStatus, { useAppStatusKey } from 'api/appStatus'
import { getAppsApiClient } from './apiV2'
import { isSubdomainApp } from 'hooks/useUniqueSubdomainFeatures'
import { type WorkspaceInfo } from './user'

export interface IUseAppRestart {
  pending: boolean
  error?: APIError
  doAppRestart: (callback?: () => void) => Promise<void>
}

async function restartApp(appId: string): Promise<void> {
  const client = getAppsApiClient()
  if (isSubdomainApp()) {
    await client.restartAppFromSubdomain()
  } else {
    await client.restartApp(appId)
  }
}

const useAppRestart = (
  app: IApp,
  containingWorkspace?: WorkspaceInfo,
): IUseAppRestart => {
  const [pending, setPending] = useState(false)
  const [error, setError] = useState<APIError | undefined>()
  const { isOwner, status } = useAppStatus(app.appId)

  const appStatusKey = useAppStatusKey(app.appId)
  const appsKey = getAppsKey(containingWorkspace)

  const doAppRestart = async (callback?: () => void): Promise<void> => {
    setPending(true)

    try {
      await mutateAppWithStatus(
        {
          appsKey,
          appStatusKey,
        },
        {
          app,
          isOwner,
          status: APP_STATUS.REBOOTING,
        },
      )

      setPending(false)
      if (callback != null) callback()

      await restartApp(app.appId)
    } catch (exc) {
      // Revert to initial state
      await mutateAppWithStatus(
        {
          appsKey,
          appStatusKey,
        },
        {
          app,
          isOwner,
          status,
        },
      )

      setPending(false)
      setError(exc as APIError)
    }

    await Promise.all([mutate(appStatusKey), mutate(appsKey)])
  }

  return {
    pending,
    error,
    doAppRestart,
  }
}

export default useAppRestart
