import type React from 'react'
import { type ReactElement, memo, useState } from 'react'

import _noop from 'lodash/noop'

import { type Theme } from 'baseui/theme'

import Modal, { type CloseModalArgs, TopNavigationBody } from 'components/modal'

import { type ModalName, type ShareMenuPayload } from 'reducers/modal'

import { type IApp } from 'api/apps'

import { type Surface } from 'constants/analytics'

import EmbedApp from './embedApp/embedApp'

import Invite from './invite/invite'

import Social from './social/social'

const modalBorderRadius = '0.2rem'

interface ModalProps {
  iframeWidth?: number
  onClose?: (args?: CloseModalArgs) => void
}

interface InnerBodyProps {
  app: IApp
  isOwner: boolean
  surface?: Surface
  name: ModalName
  onClose?: (args?: CloseModalArgs) => void
  hideTab?: MenuItemId[]
}

interface TabsProps extends Omit<InnerBodyProps, 'hideTab'> {
  appEmbedCode: string
  params: string[]
  setParams: React.Dispatch<React.SetStateAction<string[]>>
  menuItems: MenuItems
}

export enum MenuItemId {
  Embed = 'Embed',
  Invite = 'Invite',
  Social = 'Social',
}

export interface MenuItem {
  title: string
  itemId: MenuItemId
}
export type MenuItems = MenuItem[]

export function getMenuItems(hideTab?: MenuItemId[]): MenuItems {
  const menuItems: MenuItems = []

  menuItems.push(
    {
      title: 'Invite',
      itemId: MenuItemId.Invite,
    },
    {
      title: 'Social',
      itemId: MenuItemId.Social,
    },
    {
      title: 'Embed',
      itemId: MenuItemId.Embed,
    },
  )

  if (hideTab?.length) {
    return menuItems.filter((menuItem) => !hideTab.includes(menuItem.itemId))
  }

  return menuItems
}

export const InnerBody = memo(function InnerBody({
  app,
  isOwner,
  surface,
  name,
  onClose,
  hideTab,
}: InnerBodyProps): ReactElement {
  const menuItems = getMenuItems(hideTab)
  const [params, setParams] = useState<string[]>([])

  const appEmbedCode = `https://${app.host as string}/`

  return (
    <Tabs
      app={app}
      isOwner={isOwner}
      surface={surface}
      name={name}
      onClose={onClose}
      menuItems={menuItems}
      appEmbedCode={
        params.length === 0
          ? appEmbedCode
          : `${appEmbedCode}?embed_options=${params.join(',')}`
      }
      params={params}
      setParams={setParams}
    />
  )
})

export const Tabs = memo(function Tabs({
  app,
  isOwner,
  menuItems,
  appEmbedCode,
  params,
  setParams,
}: TabsProps): ReactElement {
  const [activeNav, setActiveNav] = useState<React.Key>(menuItems[0].itemId)
  const [availableOptions] = useState<string[]>([
    'Show toolbar',
    'Show padding',
    'Show footer',
    'Light theme',
    'Disable scrolling',
    'Dark theme',
    'Show colored line',
  ])

  return (
    <TopNavigationBody<MenuItemId>
      app={app}
      activeNav={activeNav}
      setActiveNav={setActiveNav}
      menuItems={menuItems}
      appEmbedCode={appEmbedCode}
    >
      {activeNav === MenuItemId.Embed && (
        <EmbedApp
          params={params}
          setParams={setParams}
          availableOptions={availableOptions}
        />
      )}
      {activeNav === MenuItemId.Invite && (
        <Invite app={app} isOwner={isOwner} />
      )}
      {activeNav === MenuItemId.Social && (
        <Social app={app} isOwner={isOwner} />
      )}
    </TopNavigationBody>
  )
})

function ShareModal({
  iframeWidth,
  onClose = _noop,
}: ModalProps): ReactElement {
  const name = 'SHARE_MENU'

  return (
    <Modal<ShareMenuPayload>
      name={name}
      onClose={onClose}
      overrides={{
        Root: {
          style: {
            width: iframeWidth ? `${iframeWidth}px` : '100%',
          },
        },
        DialogContainer: {
          style: {
            backgroundColor: 'transparent',
          },
        },
        Dialog: {
          style: ({ $theme }: { $theme: Theme }) => ({
            backgroundColor: $theme.colors.white,
            position: 'absolute',
            top: '3rem',
            width: 'initial',
            // Don't set to 100% to avoid the modal filling the entire width of a mobile screen
            maxWidth: '95%',
            height: '21.25rem',
            maxHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0 0 0.5rem rgba(0, 0, 0, 0.25)',
            borderTopLeftRadius: modalBorderRadius,
            borderTopRightRadius: modalBorderRadius,
            borderBottomRightRadius: modalBorderRadius,
            borderBottomLeftRadius: modalBorderRadius,
            [$theme.mediaQuery.medium]: {
              right: '1rem',
              width: '29.5rem',
            },
          }),
        },
        Close: {
          style: {
            visibility: 'hidden',
          },
        },
      }}
    >
      {({ modalPayload }) =>
        modalPayload?.app && (
          <InnerBody
            name={name}
            app={modalPayload.app}
            isOwner={modalPayload.isOwner}
          />
        )
      }
    </Modal>
  )
}

export default ShareModal
