// noinspection JSVoidFunctionReturnValueUsed

import { useDispatch } from 'react-redux'

import axios from 'axios'

import { OnboardingFlow, setStep } from '../reducers/onboardingFlow'
import { datadogLogs } from '@datadog/browser-logs'

interface IUseDeleteAccount {
  deleteAccount: () => Promise<void>
}

export const useDeleteAccount = (): IUseDeleteAccount => {
  const dispatch = useDispatch()

  const deleteAccount = async (): Promise<void> => {
    // We use OnboardingFlow reducer here, because
    // currently OnboardingFlow is used as RootPage state handler
    // One of our TODO is to refactor it to be more stable and name informative
    // We update the Reducer in OnboardingFlow to change Main Page,
    // while processing account deletion request
    dispatch(setStep({ name: OnboardingFlow.pending }))

    try {
      await axios.delete('/user')
      dispatch(setStep({ name: OnboardingFlow.userDeleted }))
    } catch (err) {
      dispatch(setStep({ name: OnboardingFlow.error }))
      datadogLogs.logger.error('Failed to delete account', {}, err as Error)
    }
  }

  return { deleteAccount }
}
