import type React from 'react'
import { type ReactElement, type ReactNode } from 'react'
import { type Item } from 'baseui/side-navigation'
import { Tabs, Tab } from 'baseui/tabs-motion'
import { type Theme } from 'baseui/theme'
import { type Override } from 'baseui/overrides'

import IsAppPublicToggle from 'modals/share/isAppPublicToggle'

import { theme } from 'constants/tailwind.config'

import { type IApp } from 'api/apps'

import EmbedCodeButton from 'modals/share/embedCodeButton'

import CopyLinkButton from 'modals/share/copyLinkButton'

import { MenuItemId } from 'modals/share/share'

import styles from './topNavigation.module.scss'

interface MenuProps<T> {
  activeNav: React.Key
  setActiveNav: React.Dispatch<React.SetStateAction<T | string | number>>
  menuItems: Item[]
}

const navOverrides = {
  Root: {
    style: ({ $theme }: { $theme: Theme }) => ({
      display: 'flex',
    }),
  },
  TabHighlight: {
    style: ({ $theme }: { $theme: Theme }) => ({
      backgroundColor: 'transparent',
    }),
  },
}

const navTabOverrides = (isActiveTab: boolean): { Tab: Override<any> } => ({
  Tab: {
    style: ({ $theme }: { $theme: Theme }) => ({
      background: 'transparent',
      fontWeight: isActiveTab ? 'bold' : 'normal',
      color: isActiveTab ? theme.colors.blue[700] : 'inherit',
      ':focus-visible': {
        outline: 'none',
        boxShadow: 'none',
      },
    }),
  },
})

export function TopNavigation<T>({
  activeNav,
  setActiveNav,
  menuItems,
}: MenuProps<T>): ReactElement {
  return (
    <Tabs
      activeKey={activeNav}
      onChange={({ activeKey }) => {
        if (activeKey == null) {
          // This should never happen.
          throw new Error('Item id is not available.')
        }
        setActiveNav(activeKey)
      }}
      overrides={navOverrides}
    >
      {menuItems.map((menuItem) => {
        return (
          <Tab
            key={menuItem.itemId}
            title={menuItem.title}
            overrides={navTabOverrides(activeNav === menuItem.itemId)}
          />
        )
      })}
    </Tabs>
  )
}

interface InnerBodyProps<T> extends MenuProps<T> {
  app: IApp
  appEmbedCode: string
  children?: ReactElement | ReactNode
}

function InnerBody<T>({
  activeNav,
  setActiveNav,
  menuItems,
  app,
  appEmbedCode,
  children,
}: InnerBodyProps<T>): ReactElement {
  return (
    <div className={styles.topNavigationContainer}>
      <div className={styles.topNavigationMenuContainer}>
        <TopNavigation<T>
          activeNav={activeNav}
          setActiveNav={setActiveNav}
          menuItems={menuItems}
        />
      </div>
      <div className={styles.topNavigationBodyContainer}>{children}</div>
      <div className={styles.footer}>
        <IsAppPublicToggle app={app} />
        <div className={styles.footerCopy}>
          {activeNav === MenuItemId.Embed && (
            <EmbedCodeButton appEmbedCode={appEmbedCode} />
          )}
          {activeNav !== MenuItemId.Embed && <CopyLinkButton />}
        </div>
      </div>
    </div>
  )
}

export default InnerBody
