import { createSlice, type PayloadAction, type Draft } from '@reduxjs/toolkit'

/**
 * TODO sfc-gh-tszerszen, sfc-gh-pbelczyk
 * OnboardingFLow is currently used as a RootPage reducer,
 * we'd like to refactor this reducer to be more descriptive
 */
export enum OnboardingFlow {
  undefined,
  connectToGitHub,
  selectWorkspace,
  signupForm,
  success,
  pending,
  userDeleted,
  error,
}

export type StepName = OnboardingFlow

export interface State {
  name?: StepName
}

const initialState: State = {}

interface SetStepPayload {
  name?: StepName
}

const slice = createSlice({
  name: 'onboardingFlow',
  initialState,
  reducers: {
    setStep: (state: Draft<State>, action: PayloadAction<SetStepPayload>) => {
      state.name = action.payload.name
    },
  },
})

export const { setStep } = slice.actions

export default slice
