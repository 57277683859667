import { type RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { type IAppCoordinates } from 'api/apps'
import useAppStatus from 'api/appStatus'

import urls from 'constants/urls'

import { wildcardMatchPattern } from 'helpers/urls'
import { isNonEmptyString } from 'helpers/validation'
import { isSubdomainApp } from './useUniqueSubdomainFeatures'

export const S4A_COMM_VERSION = 1

export enum MenuItemType {
  text = 'text',
  separator = 'separator',
}

export type IMenuItem =
  | {
      type: MenuItemType.text
      label: string
      key: string
    }
  | {
      type: MenuItemType.separator
    }

export interface IToolbarItem {
  label?: string
  icon?: string
  key: string
  borderless: boolean
}

interface DerivedColors {
  linkText: string
  fadedText05: string
  fadedText10: string
  fadedText40: string
  fadedText60: string

  bgMix: string
  darkenedBgMix60: string
  transparentDarkenedBgMix60: string
  lightenedBg05: string
}

export type ExportedTheme = {
  base: string
  primaryColor: string
  backgroundColor: string
  secondaryBackgroundColor: string
  textColor: string
  font: string
} & DerivedColors

type IHostToGuestMessage =
  | {
      type: 'CLOSE_MODALS'
    }
  | {
      type: 'SET_IS_OWNER'
      isOwner: boolean
    }
  | {
      type: 'SET_MENU_ITEMS'
      items: IMenuItem[]
    }
  | {
      type: 'SET_METADATA'
      metadata: {
        hostedAt: string
        creatorId: string
        owner: string
        branch: string
        repo: string
        mainModule: string
      }
    }
  | {
      type: 'SET_PAGE_LINK_BASE_URL'
      pageLinkBaseUrl: string
    }
  | {
      type: 'SET_TOOLBAR_ITEMS'
      items: IToolbarItem[]
    }
  | {
      type: 'UPDATE_FROM_QUERY_PARAMS'
      queryParams: string
    }
  | {
      type: 'UPDATE_HASH'
      hash: string
    }

export type IGuestToHostMessage =
  | {
      type: 'GUEST_READY'
    }
  | {
      type: 'MENU_ITEM_CALLBACK'
      key: string
    }
  | {
      // NOTE: Differs from SET_PAGE_TITLE as currentPageName specifies which
      //       page we are on in a multipage app.
      type: 'SET_CURRENT_PAGE_NAME'
      currentPageName: string
    }
  | {
      type: 'SET_PAGE_FAVICON'
      favicon: string
    }
  | {
      type: 'SET_PAGE_TITLE'
      title: string
    }
  | {
      type: 'SET_QUERY_PARAM'
      queryParams: string
    }
  | {
      type: 'TOOLBAR_ITEM_CALLBACK'
      key: string
    }
  | {
      type: 'UPDATE_HASH'
      hash: string
    }
  | {
      type: 'SET_THEME_CONFIG'
      themeInfo: ExportedTheme
    }

export type VersionedMessage<Message> = {
  stCommVersion: number
} & Message

export type ICallbacks = Record<string, () => void>

export interface ICommunicationResponse {
  isReady: boolean
  iframeRef: RefObject<HTMLIFrameElement>
  title?: string
  favicon?: string
  sendMessage: (message: IHostToGuestMessage) => void
}

function useCoreCommunication(
  app: IAppCoordinates,
  menuItems: IMenuItem[],
  menuCallbacks: ICallbacks,
  toolbarItems: IToolbarItem[],
  toolbarCallbacks: ICallbacks,
  onThemeInfo: (theme: ExportedTheme) => void,
): ICommunicationResponse {
  const navigate = useNavigate()
  const location = useLocation()
  const { creatorId, isOwner } = useAppStatus(app.appId)

  const [isReady, setReady] = useState<boolean>(false)
  const [title, setTitle] = useState<string>()
  const [pageName, setPageName] = useState<string | null>(null)
  const [favicon, setFavicon] = useState<string>()

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const appOnSubdomain = isSubdomainApp()

  const callbacksConsistencyCheck = Object.keys(menuCallbacks).every((key) => {
    return menuItems.find((item) => item.type === 'text' && item.key === key)
  })

  if (!callbacksConsistencyCheck) {
    throw new Error('every menuCallback should be included in menuItems')
  }

  const toolbarCallbacksConsistencyCheck = Object.keys(toolbarCallbacks).every(
    (key) => {
      return toolbarItems.find((item) => item.key === key)
    },
  )

  if (!toolbarCallbacksConsistencyCheck) {
    throw new Error(
      'every toolbarItemCallback should be included in toolbarItems',
    )
  }

  function sendCoreMessage(message: IHostToGuestMessage): void {
    const versionedMessage: VersionedMessage<IHostToGuestMessage> = {
      ...message,
      stCommVersion: S4A_COMM_VERSION,
    }

    if (iframeRef?.current != null) {
      // eslint-disable-next-line no-unused-expressions
      iframeRef.current.contentWindow?.postMessage(versionedMessage, '*')
    }
  }

  useEffect(() => {
    function receiveMessage(event: MessageEvent): void {
      let origin: string
      const message: VersionedMessage<IGuestToHostMessage> = event.data

      try {
        const url = new URL(event.origin)
        origin = url.hostname
      } catch (e) {
        origin = event.origin
      }

      if (
        !isNonEmptyString(origin) ||
        !urls.coreCommunicationAllowedMessageOrigins.some((el) =>
          wildcardMatchPattern(el, origin),
        ) ||
        event.source !== iframeRef?.current?.contentWindow ||
        message.stCommVersion !== S4A_COMM_VERSION
      ) {
        return
      }

      if (message.type === 'GUEST_READY') {
        setReady(true)
      }

      if (message.type === 'MENU_ITEM_CALLBACK') {
        const callbackFunc = menuCallbacks[message.key]

        callbackFunc?.()
      }

      if (message.type === 'TOOLBAR_ITEM_CALLBACK') {
        const callbackFunc = toolbarCallbacks[message.key]
        callbackFunc?.()
      }

      if (message.type === 'SET_QUERY_PARAM') {
        navigate({
          search: message.queryParams,
          hash: location.hash,
        })
      }

      if (message.type === 'SET_PAGE_FAVICON') {
        setFavicon(message.favicon)
      }

      if (message.type === 'SET_PAGE_TITLE') {
        setTitle(message.title)
      }

      if (message.type === 'SET_CURRENT_PAGE_NAME') {
        let navigateTo: string
        if (appOnSubdomain) {
          navigateTo = `/${message.currentPageName}`
        } else {
          navigateTo = isNonEmptyString(message.currentPageName)
            ? `${app.url}/${message.currentPageName}`
            : app.url
        }

        if (navigateTo.split('/').some((p) => p === '..')) {
          return
        }

        if (pageName !== message.currentPageName) {
          // Initial run saves the hash, otherwise we ignore it because
          // hashes are not preserved between page changes
          const hash = pageName === null ? location.hash : ''
          navigate(`${navigateTo}${location.search}${hash}`, {
            replace: true,
          })
          setPageName(message.currentPageName)
        } else {
          // No page change has happened, so we keep the hash
          navigate(`${navigateTo}${location.search}${location.hash}`, {
            replace: true,
          })
        }
      }

      if (message.type === 'UPDATE_HASH') {
        navigate({
          search: location.search,
          hash: message.hash,
        })
      }

      if (message.type === 'SET_THEME_CONFIG') {
        onThemeInfo(message.themeInfo)
      }
    }

    window.addEventListener('message', receiveMessage)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [
    menuCallbacks,
    toolbarCallbacks,
    onThemeInfo,
    navigate,
    app.url,
    appOnSubdomain,
    pageName,
    location.hash,
    location.search,
  ])

  useEffect(() => {
    if (!isReady || menuItems.length === 0) return

    sendCoreMessage({
      type: 'SET_MENU_ITEMS',
      items: menuItems,
    })
  }, [isReady, menuItems])

  useEffect(() => {
    if (!isReady || toolbarItems.length === 0) return

    sendCoreMessage({
      type: 'SET_TOOLBAR_ITEMS',
      items: toolbarItems,
    })
  }, [isReady, toolbarItems])

  useEffect(() => {
    if (!isReady) return

    sendCoreMessage({
      type: 'SET_METADATA',
      metadata: {
        hostedAt: 'S4A',
        creatorId,
        owner: app.owner,
        branch: app.branch,
        repo: app.repo,
        mainModule: app.mainModule,
      },
    })
  }, [isReady, creatorId, app.owner, app.branch, app.repo, app.mainModule])

  useEffect(() => {
    if (!isReady || location.hash === '') return

    sendCoreMessage({
      type: 'UPDATE_HASH',
      hash: location.hash,
    })
  }, [isReady, location.hash])

  useEffect(() => {
    if (!isReady) return

    sendCoreMessage({
      type: 'SET_IS_OWNER',
      isOwner,
    })
  }, [isOwner, isReady])

  useEffect(() => {
    if (!isReady) return

    const pageLinkBaseUrl = `${window.location.origin}${
      appOnSubdomain ? '' : app.url
    }`

    sendCoreMessage({
      type: 'SET_PAGE_LINK_BASE_URL',
      pageLinkBaseUrl,
    })
  }, [isReady, appOnSubdomain, app.url])

  return {
    isReady,
    iframeRef,
    title,
    favicon,
    sendMessage: useCallback(sendCoreMessage, [iframeRef]),
  }
}

export default useCoreCommunication
