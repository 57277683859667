import { memo, type ReactElement } from 'react'

import TabBodyPart from 'components/modal/components/tabBody/tabBodyPart'

import styles from './starterLimits.module.scss'

function StarterLimits(): ReactElement {
  return (
    <TabBodyPart
      title="Limits"
      subTitle="Streamlit Community Cloud has the following limits:"
      body={
        <>
          <dl className={styles.limitsTable}>
            <div>
              <dt>Resources per app</dt>
              <dd>2.7 GBs</dd>
            </div>
            <div>
              <dt>Private apps</dt>
              <dd>1 app</dd>
            </div>
            <div>
              <dt>Public apps</dt>
              <dd>Unlimited</dd>
            </div>
          </dl>
        </>
      }
    />
  )
}

export default memo(StarterLimits)
