import { type Theme } from 'baseui/theme'
import { type ModalOverrides } from 'baseui/modal'

const modalOverrides: ModalOverrides = {
  Dialog: {
    style: {
      // don't use `borderRadius` shorthand here, because then the console will show warnings as the component itself uses the long-hand forms already in css
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',

      backgroundColor: 'white',
      width: '100vw',
      height: '100vh',
      maxHeight: '37.5rem',
      maxWidth: '56.25rem',
      // TODO: scrolls side-navigation and dialog body at the same time.
      // Should be removed and set to the side-navigation and tabBody elements
      // separately as soon as the tabBody component is used in all tabs.

      // 1. "overflow: scroll" creates an empty space for the scrollbar in browsers
      //    that use Webkit or Blink (in browsers that use Gecko the scrollbar is overlayed by default).
      // 2. "overflow: overlay" removes that empty space and forces the scrollbar to overlay.
      //    NOTE: Browsers that use Gecko do *NOT* support "overlay" property.
      // HACK: use "overflow: scroll" followed by "overflow: overlay".
      // This will set the overflow property to "overlay" in browsers that support it,
      // and will set it to "scroll" in others.
      overflowY: 'scroll' as const,
      overflow: 'overlay',
    },
  },
  DialogContainer: {
    style: ({ $theme }: { $theme: Theme }) => ({
      // corresponds to $theme.colors.primary600, but it has to include the alpha value
      // as the opacity is only allowed to be applied to the background!
      backgroundColor: 'rgba(85, 88, 103, 0.7)',
    }),
  },
  Close: {
    style: () => ({
      ':focus-visible': {
        outline: 'none',
        boxShadow: 'none',
      },
      top: '1.9rem',
    }),
  },
}

export default modalOverrides
