import {
  type ReactNode,
  type ReactElement,
  useState,
  useRef,
  useEffect,
} from 'react'

import classNames from 'classnames'

import Tooltip from './tooltip'

import styles from './overflowTooltip.module.scss'

interface Props {
  children: ReactNode
  content: ReactNode
  className?: string
}

function OverflowTooltip({
  children,
  content,
  className,
}: Props): ReactElement {
  const childRef = useRef<HTMLDivElement>(null)
  const [allowTooltip, setAllowTooltip] = useState(false)

  useEffect(() => {
    const newAllowTooltip = childRef?.current
      ? childRef.current.offsetWidth < childRef.current.scrollWidth
      : false
    if (newAllowTooltip !== allowTooltip) {
      setAllowTooltip(newAllowTooltip)
    }
  }, [children, allowTooltip])

  return (
    <Tooltip content={allowTooltip ? content : ''}>
      <div className={classNames(styles.wrapper, className)}>
        <div ref={childRef} className={styles.ellipsized}>
          {children}
        </div>
      </div>
    </Tooltip>
  )
}

export default OverflowTooltip
