import { type ReactElement } from 'react'
import _noop from 'lodash/noop'
import { ModalBody, ModalHeader } from 'baseui/modal'

import { type RestartPayload } from 'reducers/modal'

import { type IApp } from 'api/apps'
import useAppRestart from 'api/appRestart'
import { extractErrorMessage } from 'api/utils'

import Button from 'components/button'
import Alert from 'components/alert'
import Modal from 'components/modal'

import styles from '../styles.module.scss'
import { type WorkspaceInfo } from '../../api/user'

interface BodyProps {
  onClose: () => void
  onRestart: () => void
  app: IApp
  containingWorkspace?: WorkspaceInfo
}

interface ModalProps {
  onClose?: () => void
  onRestart?: () => void
  containingWorkspace?: WorkspaceInfo
}

export function InnerBody({
  app,
  onClose,
  onRestart,
  containingWorkspace,
}: BodyProps): ReactElement {
  const { doAppRestart, error, pending } = useAppRestart(
    app,
    containingWorkspace,
  )

  return (
    <>
      <ModalHeader>Reboot app?</ModalHeader>
      <ModalBody>
        <div className={styles.body}>
          {error && (
            <Alert title="Error" message={extractErrorMessage(error)} />
          )}
          <p>This will disrupt all current users.</p>
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.button}
            disabled={pending}
            isLoading={pending}
            onClick={() => {
              doAppRestart(() => {
                onRestart()
                onClose()
              })
            }}
            data-testid="restart-app-confirmation"
          >
            Reboot
          </Button>
        </div>
      </ModalBody>
    </>
  )
}

function RestartAppModal({
  onClose = _noop,
  onRestart = _noop,
  containingWorkspace,
}: ModalProps): ReactElement {
  return (
    <Modal<RestartPayload> name="RESTART" onClose={onClose}>
      {({ closeModal, modalPayload }) =>
        modalPayload?.app && (
          <InnerBody
            app={modalPayload.app}
            onRestart={onRestart}
            onClose={closeModal}
            containingWorkspace={containingWorkspace}
          />
        )
      }
    </Modal>
  )
}

export default RestartAppModal
