import {
  memo,
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import mergeRefs from 'react-merge-refs'
import { ResizeObserver } from '@juggle/resize-observer'
import useResizeObserver from 'use-resize-observer'

import useFavicon from 'hooks/useFavicon'
import { isSubdomainApp } from 'hooks/useUniqueSubdomainFeatures'
import useCoreCommunication, {
  type ExportedTheme,
} from 'hooks/useCoreCommunication'

import ShareModal from 'modals/share/share'
import Balloons from 'components/balloons/Balloons'

import { type IApp } from 'api/apps'

import { useSelector } from 'reducers'
import { makeModalIsAnyOpen, showModal } from 'reducers/modal'

import useToolbarItems from './useToolbarItems'
import useMenuItems from './useMenuItems'

import {
  DEFAULT_IFRAME_FEATURE_POLICY,
  DEFAULT_IFRAME_SANDBOX_POLICY,
} from './iframe'
import PageSpinner from '../../../../components/pageSpinner'

// Polyfill for browsers that don't support `ResizeObserver`
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}

declare global {
  interface Window {
    streamlitShareMetadata: Record<string, string>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    ResizeObserver: typeof ResizeObserver
  }
}
export interface Props {
  app: IApp
  isOwner: boolean
  className?: string
  showBalloons: boolean
  onAnimationEnd: () => void
  isPrivate: boolean
}

export const extractPageName = (pathname: string, app: IApp): string => {
  // NOTE: We need to %-encode everything *except* for slashes in the branch/module
  // names because whether slashes are encoded or not depends on their placement
  // within a URL (In a path, they can be either. In a query, they should be
  // encoded.) Slashes in an app's branch/module appear unencoded as part of the
  // app path displayed in the browser URL-bar.
  const encodedSlashRegex = new RegExp(encodeURIComponent('/'), 'g')
  const cleanedBranch = encodeURIComponent(app.branch).replace(
    encodedSlashRegex,
    '/',
  )
  const cleanedMainModule = encodeURIComponent(app.mainModule).replace(
    encodedSlashRegex,
    '/',
  )

  const branchOptional = cleanedBranch === 'master'
  const mainModuleOptional = cleanedMainModule === 'streamlit_app.py'

  // We create two regex and call .replace() twice below because adding flags
  // to specific capture groups is not well-supported by JS-regex implementations.
  // This forces us to split our regex up to use the case-insensitive flag.
  const ownerRepoRegex = new RegExp(`/${app.owner}/${app.repo}`, 'i')
  const branchModuleRegex = new RegExp(
    [
      branchOptional ? '(/master)?' : `/${cleanedBranch}`,
      mainModuleOptional ? '(/streamlit_app.py)?' : `/${cleanedMainModule}`,
      '/?',
    ].join(''),
  )

  return pathname.replace(ownerRepoRegex, '').replace(branchModuleRegex, '')
}

function SuccessState({
  app,
  isOwner,
  className,
  showBalloons,
  onAnimationEnd,
  isPrivate,
}: Props): ReactElement {
  const location = useLocation()
  const hostedOnSubdomain = isSubdomainApp()

  const [appThemeInfo, setAppThemeInfo] = useState<ExportedTheme>()
  const name = 'SHARE_MENU' // TODO: move this more centrally?
  const dispatch = useDispatch()

  const selectIsAnyModalOpen = useMemo(makeModalIsAnyOpen, [])
  const isAnyModalOpen = useSelector((state) =>
    selectIsAnyModalOpen(state, undefined),
  )

  const onClickShare = useCallback(() => {
    dispatch(
      showModal({
        name,
        modalPayload: {
          isOwner,
          app,
        },
      }),
    )
  }, [isOwner, app, dispatch])

  const { toolbarItems, toolbarCallbacks } = useToolbarItems({
    appId: app.appId,
    appOwner: app.owner,
    repository: app.repo,
    branch: app.branch,
    mainModule: app.mainModule,
    isFavorited: Boolean(app.isFavorited),
    onClickShare,
    appThemeInfo,
  })

  const { menuItems, menuCallbacks } = useMenuItems({
    appId: app.appId,
    appOwner: app.owner,
    repository: app.repo,
    branch: app.branch,
    mainModule: app.mainModule,
  })

  const { isReady, iframeRef, title, favicon, sendMessage } =
    useCoreCommunication(
      {
        appId: app.appId,
        repo: app.repo,
        branch: app.branch,
        mainModule: app.mainModule,
        owner: app.owner,
        url: app.url,
      },
      menuItems,
      menuCallbacks,
      toolbarItems,
      toolbarCallbacks,
      setAppThemeInfo,
    )

  // NOTE: This solution is not ideal, but as `BaseModal` cannot be inserted into
  // a specific DOM element, the only way is to restrain its size.
  const { ref: resizeObserverRef, width: iframeWidth } =
    useResizeObserver<HTMLDivElement>()

  // Cleaning core modals
  useEffect(() => {
    if (!isAnyModalOpen) return

    sendMessage({
      type: 'CLOSE_MODALS',
    })
  }, [isAnyModalOpen, sendMessage])

  const cleanedBranch = encodeURIComponent(app.branch)
  const cleanedMainModule = encodeURIComponent(app.mainModule)

  let pageName = hostedOnSubdomain
    ? location.pathname
    : extractPageName(location.pathname, app)

  pageName = pageName.charAt(0) === '/' ? pageName.slice(1) : pageName

  let iframeUrl = `//${app.host as string}/${app.owner}/${app.repo}/${cleanedBranch}/${cleanedMainModule}/+/${pageName}${location.search}`
  if (
    hostedOnSubdomain &&
    app.subdomain &&
    window.location.hostname.startsWith(app.subdomain)
  ) {
    iframeUrl = `//${window.location.hostname}/~/+/${pageName}${location.search}`
  }

  // We basically don't want to reset the iframe URL. Doing so will refresh the app completely.
  // Under the hood the iframe URL will not change, but the commands sent to the parent window
  // will change to reflect what's important to the iframe.
  const [initialIframeUrl] = useState(iframeUrl)
  const cleanedFavicon = favicon ? favicon.replace(/^\./, iframeUrl) : undefined

  useFavicon(cleanedFavicon)

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <ShareModal iframeWidth={iframeWidth} />

      <iframe
        allow={DEFAULT_IFRAME_FEATURE_POLICY}
        sandbox={DEFAULT_IFRAME_SANDBOX_POLICY}
        height="auto"
        title="streamlitApp"
        src={initialIframeUrl}
        className={className}
        ref={mergeRefs([iframeRef, resizeObserverRef])}
        hidden={!isReady}
      />

      {!isReady && <PageSpinner title="appLoadingSpinner" />}

      {showBalloons && <Balloons onAnimationEnd={onAnimationEnd} />}
    </>
  )
}

export default memo(SuccessState)
