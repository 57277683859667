import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { type Surface, type UserRole } from 'constants/analytics'
import { enqueue } from 'reducers/analytics'
import eventIds from 'constants/eventIds'
import useUser from 'api/user'
import axios from 'axios'

const SHARE_APP_NAME = 'share_app'

export enum Operation {
  AddViewers = 'addViewers',
  RemoveViewers = 'removeViewers',
}

export interface trackShareArgs {
  surface: Surface
  role: UserRole
  emails: string[]
  operation: Operation
  appId: string
  appWorkspaceName: string
}

function useTrackShare(): (args: trackShareArgs) => void {
  const { user } = useUser()
  const dispatch = useDispatch()
  const trackShare = useCallback(
    ({
      surface,
      role,
      emails,
      operation,
      appId,
      appWorkspaceName,
    }: trackShareArgs) => {
      axios.post('/event', {
        event_id: eventIds.ShareApp,
        user: user?.canonicalUserId ?? '',
        surface,
        role,
        emails,
        operation,
        appId,
        appWorkspaceName,
      })
      dispatch(
        enqueue({
          name: SHARE_APP_NAME,
          properties: {
            emails,
            operation,
            surface,
            role,
            appId,
            appWorkspaceName,
          },
        }),
      )
    },
    [dispatch, user?.canonicalUserId],
  )
  return trackShare
}

export default useTrackShare
