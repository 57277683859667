import { type ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import useUser from 'api/user'

import ErrorPage from 'pages/error'
import LoginPage from 'pages/login'

import PageSpinner from 'components/pageSpinner'
import LoginRedirect, { LinkedUserRedirect } from 'components/loginRedirect'

import { setRedirect } from 'reducers/redirect'
import { linkedRedirectSelector } from 'reducers/linkedRedirect/selectors'

function PrivateComponent({
  children,
  gitHubUserOnly,
}: {
  children: ReactElement
  gitHubUserOnly?: boolean
}): ReactElement {
  const dispatch = useDispatch()
  const { hasSeenLinkedRedirect } = useSelector(linkedRedirectSelector)
  const { user, error, isPending, linkedUser } = useUser()
  const location = useLocation()

  if (error) {
    if (error.response?.status === 401) {
      if (location.pathname !== '/') {
        dispatch(
          setRedirect({
            url: location.pathname + location.search,
          }),
        )
      }

      return <LoginPage />
    }

    return <ErrorPage details={error} />
  }

  if (isPending) {
    return <PageSpinner overlay />
  }

  if (!user) {
    if (location.pathname !== '/') {
      dispatch(
        setRedirect({
          url: location.pathname + location.search,
        }),
      )
    }

    return <LoginPage />
  }

  // when page is for GH users and the user is SSO only
  if (gitHubUserOnly && !user.email && !user.login) {
    dispatch(
      setRedirect({
        url: location.pathname + location.search,
      }),
    )

    return <LoginRedirect />
  }

  // when a connected account has no session
  if (!hasSeenLinkedRedirect && linkedUser) {
    return <LinkedUserRedirect linkedUser={linkedUser} />
  }

  return children
}

export default PrivateComponent
