import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface State {
  hasLinkedSSO: boolean
  hasLinkedGitHub: boolean
  hasRepoScopePermission: boolean
  freeTrialWillExpire: boolean
  freeTrialExpired: boolean

  warningText: string
}

export const initialState: WarningInfoPayload = {
  hasLinkedSSO: true,
  hasLinkedGitHub: true,
  hasRepoScopePermission: true,
  freeTrialWillExpire: false,
  freeTrialExpired: false,

  warningText: '',
}

export type WarningInfoPayload = State

const slice = createSlice({
  name: 'warning',
  initialState,
  reducers: {
    setWarningInfo: (
      state: State,
      action: PayloadAction<WarningInfoPayload>,
    ): State => {
      return action.payload
    },
  },
})

export const { setWarningInfo } = slice.actions

export default slice
