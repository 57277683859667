import { type ReactElement, useEffect, useState } from 'react'

import copy from 'helpers/clipboard'

import { ReactComponent as IconEmbedCode } from 'assets/svg/icon_embed_code.svg'

import styles from 'modals/share/copyLinkButton.module.scss'

import LinkButton from 'components/linkButton'

interface EmbedCodeProps {
  appEmbedCode: string
}

export default function EmbedCodeButton({
  appEmbedCode,
}: EmbedCodeProps): ReactElement {
  const [isUrlCopied, setUrlIsCopied] = useState<boolean | false>()
  let timeoutId: NodeJS.Timeout

  const copyEmbedURLAndUpdateState = (appEmbedCode: string): void => {
    copy(appEmbedCode)
    setUrlIsCopied(true)

    timeoutId = setTimeout(() => {
      setUrlIsCopied(false)
    }, 2000)
  }

  useEffect(() => () => {
    clearTimeout(timeoutId)
  })

  return (
    <LinkButton
      className={styles.copyLinkButton}
      onClick={() => {
        copyEmbedURLAndUpdateState(appEmbedCode)
      }}
    >
      <>
        <IconEmbedCode className={styles.iconLink} />
        {isUrlCopied ? <p>Copied!</p> : <p>Get embed link</p>}
      </>
    </LinkButton>
  )
}
