export enum Surface {
  InAppShareMenu = 'in-app-share-menu',
  DashboardAppSettings = 'dashboard-app-settings',
  CloudLogsAppSettings = 'cloud-logs-app-settings',
  DashboardAnalytics = 'dashboard-analytics',
  DashboardAppAnalytics = 'dashboard-app-analytics',
  CloudLogsAppAnalytics = 'cloud-logs-app-analytics',
  AnalyticsModalTabSwitch = 'analytics-modal-tab-switch',
}

export enum UserRole {
  Developer = 'developer',
  Viewer = 'viewer',
}
