import { type ReactElement, memo, type ReactNode } from 'react'

import { useField, useFormikContext } from 'formik'
import { type PopoverOverrides } from 'baseui/popover'
import { type Option, Select } from 'baseui/select'
import { StatefulTooltip } from 'baseui/tooltip'

import WorkspacePermissions from 'constants/workspacePermissions'

const tooltipOverrides: PopoverOverrides = {
  Body: {
    style: {
      marginTop: '4.5rem',
    },
  },
  Inner: {
    style: {
      lineHeight: 1.4,
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
}

interface SharingOptionFieldProps {
  checkUserAppLimits: (selectOption: string) => void
  wsPerms: WorkspacePermissions[]
}

function SharingOptionField({
  checkUserAppLimits,
  wsPerms,
}: SharingOptionFieldProps): ReactElement {
  const { resetForm } = useFormikContext()
  const [, meta, { setValue, setTouched }] = useField('isPublic')

  const isPrivateWorkspace = wsPerms.includes(
    WorkspacePermissions.PRIVATE_WORKSPACE,
  )

  const selectPrivateApp: Option = {
    label: 'Only specific people can view this app',
    id: 'private',
  }

  const selectPublicApp: Option = {
    label: 'This app is public and searchable',
    id: 'public',
    disabled: isPrivateWorkspace,
  }

  const getLabel = ({ option }: Option): ReactNode => {
    if (isPrivateWorkspace && option.id === 'public') {
      return (
        <StatefulTooltip
          content="You don’t have permission to change this setting"
          accessibilityType="tooltip"
          placement="bottomLeft"
          popoverMargin={2}
          overrides={tooltipOverrides}
        >
          {option.label}
        </StatefulTooltip>
      )
    }
    return option.label
  }

  return (
    <Select
      overrides={{
        DropdownListItem: {
          style: {
            fontSize: '1rem',
          },
        },
        SelectArrow: {
          style: {
            transform: 'scale(1.2)',
          },
        },
      }}
      clearable={false}
      deleteRemoves={false}
      backspaceRemoves={false}
      options={[selectPrivateApp, selectPublicApp]}
      getOptionLabel={getLabel}
      value={meta?.value ? [selectPublicApp] : [selectPrivateApp]}
      searchable={false}
      onChange={(params) => {
        // This will reset the form when sharing options are changed.
        // Showing a confirmation modal before resetting the form will be in the next PR.
        resetForm()
        const selectedOption = String(params.value?.[0]?.id)
        setValue(selectedOption === 'public')
        checkUserAppLimits(selectedOption)
        setTouched(true)
      }}
    />
  )
}

export default memo(SharingOptionField)
