import { type ReactElement, memo } from 'react'
import { useField } from 'formik'
import { Textarea } from 'baseui/textarea'
import { type BaseInputOverrides, type SharedProps } from 'baseui/input'

import { StyledLink } from 'components/link'
import Field from 'components/field'
import Alert from 'components/alert'
import urls from 'constants/urls'
import { isValidTOML } from 'helpers/validation'

import styles from './secretsField.module.scss'

const PLACEHOLDER = `DB_USERNAME = "myuser"
DB_TOKEN = "abcdef"

[some_section]
some_key = 1234
`
export const ERROR_MESSAGE = 'Invalid format: please enter valid TOML.'

const textAreaOverrides: BaseInputOverrides<SharedProps> = {
  Input: {
    style: {
      maxHeight: '30em',
      minHeight: '5em',
      height: '15em',
      resize: 'vertical',
      fontFamily: 'IBM Plex Mono, monospace',
      fontSize: '1rem',
    },
  },
  InputContainer: {
    style: {
      borderRadius: '4px',
    },
  },
}

interface Props {
  label?: string
  errors?: string[]
}

function SecretsField({ label, errors }: Props): ReactElement | null {
  const [field, meta] = useField({
    name: 'secrets',
    validate: (value): string => (isValidTOML(value) ? '' : ERROR_MESSAGE),
  })
  const isInvalid = Boolean(meta.error)

  return (
    <div>
      {errors?.map(
        (error) => error && <Alert key={error} title="Error" message={error} />,
      )}
      <Field label={label} {...field}>
        <div className={styles.callout}>
          Provide environment variables and other secrets to your app using{' '}
          <StyledLink to={urls.tomlSpec} external target="_blank">
            TOML
          </StyledLink>{' '}
          format. This information is encrypted and served securely to your app
          at runtime. Learn more about Secrets in our{' '}
          <StyledLink to={urls.secretsDocs} external target="_blank">
            docs
          </StyledLink>
          . Changes take around a minute to propagate.
        </div>
        <Textarea
          {...field}
          placeholder={PLACEHOLDER}
          overrides={textAreaOverrides}
          error={isInvalid}
        />
        {isInvalid && <p className={styles.error}>{meta.error}</p>}
      </Field>
    </div>
  )
}

export default memo(SecretsField)
