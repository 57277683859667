import { type ReactElement, useEffect, useState, createRef } from 'react'

import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import { ReactComponent as StreamlitLogo } from 'assets/svg/streamlit-logo-white.svg'

import urls from 'constants/urls'

import styles from './viewerBadge.module.scss'

interface ViewerBadgeProps {
  className?: string
}

function ViewerBadge({ className }: ViewerBadgeProps): ReactElement {
  const [isHovered, setIsHovered] = useState(false)
  const [showText, setShowText] = useState(true)
  const textRef = createRef<HTMLDivElement>()

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Only hide the text after 5 seconds if user is not hovering over the button
      if (!isHovered) {
        setShowText(false)
      }
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <a
      href={urls.cloudMarketing}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(styles.container, className)}
      onMouseEnter={() => {
        setIsHovered(true)
        setShowText(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
        setShowText(false)
      }}
    >
      <CSSTransition
        in={showText}
        timeout={300}
        nodeRef={textRef}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: styles.textEnter,
          enterActive: styles.textEnterActive,
          exit: styles.textExit,
          exitActive: styles.textExitActive,
        }}
      >
        <p ref={textRef} className={styles.text}>
          Hosted with Streamlit
        </p>
      </CSSTransition>
      <div className={styles.link}>
        <StreamlitLogo />
      </div>
    </a>
  )
}

export default ViewerBadge
