import { memo } from 'react'

import styles from './styles.module.scss'

function StreamlitExampleApp({ image }: { image: string }): JSX.Element {
  return (
    <div className={styles.streamlitApp}>
      <img
        src={image}
        alt="streamlit example app"
        loading="lazy"
        height="253"
        width="450"
      />
    </div>
  )
}

export default memo(StreamlitExampleApp)
