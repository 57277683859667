import { type ReactElement } from 'react'

import useUser from 'api/user'

import styles from './linkedAccounts.module.scss'

function PrimaryUserAddress(): ReactElement | null {
  const { user } = useUser()
  const username = user?.ssoEmail

  if (!username) {
    return null
  }

  return (
    <>
      <div className={styles.linkedElement}>
        <span>
          Logged in as <strong>{username}</strong>
        </span>
      </div>
    </>
  )
}

export default PrimaryUserAddress
