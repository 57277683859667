import { type ReactElement, memo, useEffect } from 'react'
import range from 'lodash/range'

import Balloon0 from 'assets/images/balloons/balloon-0.png'
import Balloon1 from 'assets/images/balloons/balloon-1.png'
import Balloon2 from 'assets/images/balloons/balloon-2.png'
import Balloon3 from 'assets/images/balloons/balloon-3.png'
import Balloon4 from 'assets/images/balloons/balloon-4.png'
import Balloon5 from 'assets/images/balloons/balloon-5.png'

import styles from './Balloons.module.scss'

export const NUM_BALLOONS = 30
const POS_MIN_VW = 20
const POS_MAX_VW = 80
export const DELAY_MAX_MS = 1000

const ANIMATION_DURATION_MS = 750 // Copy from CSS value

export const BALLOON_IMAGES: string[] = [
  Balloon0,
  Balloon1,
  Balloon2,
  Balloon3,
  Balloon4,
  Balloon5,
]

interface Props {
  onAnimationEnd: () => void
  numBalloons?: number
  balloonColor?: BalloonColors
  animationProps?: AnimationProps
}

export enum BalloonColors {
  darkblue,
  red,
  green,
  purple,
  orange,
  lightblue,
}

interface BalloonProps {
  animationProps?: AnimationProps
  balloonColor?: BalloonColors
}

interface AnimationProps {
  top?: string
  left?: string
  animationDelay?: number
  animationDuration?: number
  animationName?: string
}

function getMaxTimeToEnd(
  animationDuration: number | undefined,
  animationDelay: number | undefined,
): number {
  return animationDuration != null &&
    animationDuration > 0 &&
    animationDelay != null &&
    animationDelay > 0
    ? animationDuration + animationDelay
    : DELAY_MAX_MS + ANIMATION_DURATION_MS
}
interface DefaultStyles {
  left?: string
  animationDelay?: string
  animationDuration?: string
}
function getDefaultStyles(
  animationProps: AnimationProps | undefined,
): DefaultStyles {
  return animationProps != null
    ? {
        ...animationProps,
        animationDuration: animationProps.animationDuration
          ? `${animationProps.animationDuration}ms`
          : '',
        animationDelay: animationProps.animationDelay
          ? `${animationProps.animationDelay}ms`
          : '',
      }
    : {
        left: `${Math.random() * (POS_MAX_VW - POS_MIN_VW) + POS_MIN_VW}vw`,
        animationDelay: `${Math.random() * DELAY_MAX_MS}ms`,
      }
}

function getBalloonNumber(balloonColor: BalloonColors | undefined): number {
  return balloonColor || Math.floor(Math.random() * BALLOON_IMAGES.length)
}

function Balloon({ animationProps, balloonColor }: BalloonProps): ReactElement {
  const defaultStyles = getDefaultStyles(animationProps)
  const num = getBalloonNumber(balloonColor)

  return (
    <img
      src={BALLOON_IMAGES[num]}
      className={styles.balloon}
      alt=""
      style={{ ...defaultStyles }}
    />
  )
}

function Balloons({
  onAnimationEnd,
  animationProps,
  balloonColor,
  numBalloons = NUM_BALLOONS,
}: Props): ReactElement {
  useEffect(() => {
    const maxTimeToEnd = getMaxTimeToEnd(
      animationProps?.animationDuration,
      animationProps?.animationDelay,
    )
    const timeout = setTimeout(() => {
      onAnimationEnd()
    }, maxTimeToEnd)

    return () => {
      clearTimeout(timeout)
    }
  }, [
    onAnimationEnd,
    animationProps?.animationDuration,
    animationProps?.animationDelay,
  ])

  // Keys should be unique each time, so React replaces the images in the DOM and their animations
  // actually rerun.
  return (
    <div className={styles.balloons}>
      {range(numBalloons).map((i) => (
        <Balloon
          key={i}
          animationProps={animationProps ?? undefined}
          balloonColor={balloonColor}
        />
      ))}
    </div>
  )
}

export default memo(Balloons)
