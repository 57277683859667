import { BackgroundTaskScheduler, type TaskScheduler } from './taskScheduler'

export interface TaskSchedulerFactory {
  create: () => TaskScheduler
}

export class TaskSchedulerFactoryImpl implements TaskSchedulerFactory {
  create(): TaskScheduler {
    return new BackgroundTaskScheduler()
  }
}
