import { useMachine } from '@xstate/react'

import { useMemo, useCallback } from 'react'

import { type IApp } from 'api/apps'
import useAppStatus from 'api/appStatus'

import { appStateMachine, type AppState } from './appStateMachine'

import { buildIsInState, type IsInStateFn } from './helpers'

interface Output {
  appState: AppState
  isInState: IsInStateFn
}

export default function useAppState(app: IApp): Output {
  // Get backend state.
  const { status, error, platformStatus } = useAppStatus(app.appId)

  // Get frontend state machine.
  // (Skipping the 0th item on purpose)
  const [, send, service] = useMachine(appStateMachine)

  const prevValue = service?.state?.value

  useMemo(() => {
    send('backendStatusReceived', {
      backendStatus: status,
      platformStatus,
      error,
    })
  }, [send, status, error, platformStatus])

  // Using service.state instead of the state variable (i.e. the 0th return value from useMachine,
  // above) because it is mutable, so there's no off-by-one error.
  const appState = service?.state
  const currValue = appState?.value

  if (currValue !== prevValue) {
    // Actually print to the console some info that helps us debug user issues.
    // eslint-disable-next-line no-console
    console.log(
      `${prevValue as string} -> (${status}, ${platformStatus}, ${error?.message || ''}) -> ${currValue as string}`,
    )
  }

  return {
    appState,
    isInState: useCallback(buildIsInState(appState), [appState]), // eslint-disable-line react-hooks/exhaustive-deps
  }
}
