import axios, {
  type AxiosError,
  AxiosHeaders,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios'
import { isNonEmptyString } from 'helpers/validation'
import { fetchOrCreateMachineIdFromStorage } from '../helpers/machineId'

let token: string | undefined

function setRequestHeader(request: any, name: string, value: string): void {
  if (request.headers?.set) {
    request.headers.set(name, value)
  } else if (request.headers) {
    request.headers[name] = value
  } else {
    request.headers = new AxiosHeaders({ name: value })
  }
}

export function requestInterceptor(
  request: InternalAxiosRequestConfig<unknown>,
): InternalAxiosRequestConfig<unknown> {
  const isNonMutatingRequest = ['GET', 'HEAD', 'OPTIONS', 'TRACE'].includes(
    request.method?.toUpperCase() ?? 'GET',
  )
  if (!isNonMutatingRequest && isNonEmptyString(token)) {
    // github.com/gorilla/csrf, the csrf library used on the server,
    // requires x-csrf-token to be set on every mutating request
    setRequestHeader(request, 'x-csrf-token', token)
  }

  // Try to read the machine ID from local storage if it is available
  const machineId = fetchOrCreateMachineIdFromStorage()
  if (machineId) {
    // Used by frontend for api endpoint migration
    setRequestHeader(request, 'x-streamlit-targeting-key', machineId)
    // Used by community service (e.g. for feature flagging)
    setRequestHeader(request, 'x-streamlit-machine-id', machineId)
  }

  return request
}

function updateCSRFTokenFromResponse(
  success: boolean,
  response?: AxiosResponse<unknown>,
): void {
  if (!response) {
    return
  }

  // this token is set besides an encrypted cookie
  // it has to be sent back on every mutating request on this page.
  // The server will compare the token with the cookie and fail the
  // request if they don't match
  if (isNonEmptyString(response?.request?.responseURL)) {
    try {
      const url = new URL(response?.request?.responseURL)
      // The following codepaths are ignored in csrf.go so they won't have the token set. In these cases, we keep the old token
      if (
        url.pathname.startsWith('/api/v1/telemetry/datadog') ||
        url.pathname.startsWith('/api/v1/event')
      ) {
        return
      }
    } catch (err) {}
  }

  // Always update the CSRF header for successful responses, but only update it for failed responses if the header is present
  // This is done to maintain compatibility with the previous implementation where the /user endpoint returned 200 on failure
  if (success || response.headers['x-csrf-token']) {
    token = response.headers['x-csrf-token']
  }
}

export function errorResponseInterceptor(
  error: AxiosError<unknown>,
): AxiosError<unknown> {
  updateCSRFTokenFromResponse(false, error.response)

  // We need to throw the error to satisfy the contract of this interceptor
  // If we do not throw the error, the error is considered as a success
  throw error
}

export function responseInterceptor(
  response: AxiosResponse<unknown>,
): AxiosResponse<unknown> {
  updateCSRFTokenFromResponse(true, response)
  return response
}

const setInterceptors = (): void => {
  axios.interceptors.request.use(requestInterceptor)
  axios.interceptors.response.use(responseInterceptor, errorResponseInterceptor)
}

const setBaseURL = (): void => {
  axios.defaults.baseURL = '/api/v1/'
}

export function initAxios(): void {
  setBaseURL()
  setInterceptors()
}
