import type React from 'react'
import { type ReactElement, memo } from 'react'

import styles from 'modals/share/embedApp/embedApp.module.scss'

import PreviewOption from './previewOption/previewOption'

interface InnerBodyProps {
  params: string[]
  setParams: React.Dispatch<React.SetStateAction<string[]>>
  availableOptions: string[]
}

function EmbedApp({
  params,
  setParams,
  availableOptions,
}: InnerBodyProps): ReactElement {
  return (
    <div className={styles.embedAppContainer}>
      <h4 className={styles.previewOptionsText}>Preview options</h4>
      <div className={styles.previewOptionContainer}>
        {availableOptions.map((param) => {
          return (
            <PreviewOption
              key={param}
              optionName={param}
              params={params}
              setParams={setParams}
            />
          )
        })}
      </div>
    </div>
  )
}

export default memo(EmbedApp)
