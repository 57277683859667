import { type ReactElement, memo } from 'react'
import { Input as BaseUIInput, type InputProps } from 'baseui/input'

import { primitives } from 'theme/s4t-light-theme'

import Field from 'components/field'
import { type FieldProps } from 'components/field/field'

export function Input(props: InputProps): ReactElement {
  return (
    <BaseUIInput
      {...props}
      overrides={{
        InputContainer: {
          style: ({ $disabled }) => {
            // don't change color on disabled
            if ($disabled) {
              return {
                backgroundColor: primitives.primary50,
              }
            }

            return {}
          },
        },
        Root: {
          style: ({ $disabled }) => {
            // don't change color on disabled
            if ($disabled) {
              return {
                borderColor: primitives.primary50,
              }
            }

            return {}
          },
        },
      }}
    />
  )
}

function TextField({
  id,
  error,
  ...restProps
}: FieldProps<HTMLInputElement>): ReactElement {
  return (
    <Field {...restProps} error={error}>
      <Input id={id} type="text" error={!!error} {...restProps} />
    </Field>
  )
}

export default memo(TextField)
