import { useDispatch, useSelector } from 'react-redux'
import { useSessionStorage } from 'react-use'
import { useEffect } from 'react'

import useUser from 'api/user'
import { linkedRedirectSelector } from 'reducers/linkedRedirect/selectors'
import { setHasSeenLinkedRedirect as setHasSeenLinkedRedirectAction } from 'reducers/linkedRedirect'

function useHasSeenLinkedRedirect(): void {
  const dispatch = useDispatch()
  const { linkedUser, isPending } = useUser()

  const {
    hasSeenLinkedRedirect: hasSeenLinkedRedirectFromRedux,
    hasSeenLinkedRedirectSetter,
  } = useSelector(linkedRedirectSelector)
  const [hasSeenLinkedRedirect, setHasSeenLinkedRedirect] = useSessionStorage(
    'hasSeenLinkedRedirect',
    false,
  )

  // Session Storage follows `hasSeenLinkedRedirectSetter` in Redux state
  // this is the mechanism for persisting that the user has been sent on the linked account redirect
  useEffect(() => {
    if (hasSeenLinkedRedirectSetter) {
      setHasSeenLinkedRedirect(true)
    }
  }, [hasSeenLinkedRedirectSetter, setHasSeenLinkedRedirect])

  // Refreshed page state.  If the elements in Redux are empty but we have persisted
  // data in Session Storage, we set Redux for application code to read and clear out Session Storage.
  // It's cleared out so that, on a subsequant refresh, we start over and send on linked account redirect again.
  useEffect(() => {
    if (
      !hasSeenLinkedRedirectSetter &&
      !hasSeenLinkedRedirectFromRedux &&
      hasSeenLinkedRedirect
    ) {
      dispatch(
        setHasSeenLinkedRedirectAction({
          hasSeenLinkedRedirect: true,
        }),
      )
      setHasSeenLinkedRedirect(false)
    }
  }, [
    hasSeenLinkedRedirectSetter,
    hasSeenLinkedRedirectFromRedux,
    hasSeenLinkedRedirect,
    dispatch,
    setHasSeenLinkedRedirect,
  ])

  // This is for a succesful linked account redirect, ie the user has been sent on the redirect
  // and logged in to both accounts.  We reset the Redux store to have a clean slate.
  useEffect(() => {
    if (linkedUser == null && !isPending && hasSeenLinkedRedirectFromRedux) {
      dispatch(
        setHasSeenLinkedRedirectAction({
          hasSeenLinkedRedirect: false,
        }),
      )
    }
  }, [linkedUser, isPending, hasSeenLinkedRedirectFromRedux, dispatch])
}

export default useHasSeenLinkedRedirect
