import { type ReactElement, memo } from 'react'
import { Helmet } from 'react-helmet-async'

import ErrorDetails from 'components/errorDetails'
import Page from 'components/page'
import urls from 'constants/urls'
import { type APIError } from 'api/types'
import { StyledLink } from 'components/link'

import { ReactComponent as FrowningFace } from 'assets/svg/emoji_u1f626.svg'

import Header from '../../components/header'

import styles from './styles.module.scss'
import classNames from 'classnames'

export interface Props {
  details?: string | APIError
  header?: ReactElement
  children?: ReactElement
}

function ErrorPage({
  details,
  header = <Header showExternalLinksNavigation />,
  children,
}: Props): ReactElement {
  return (
    <Page
      className={classNames(styles.errorContainer, 'w-fit')}
      header={header}
    >
      <Helmet>
        <title>Error</title>
      </Helmet>

      <FrowningFace className={styles.frowningFaceEmoji} />
      <h1>Huh.</h1>

      {children ?? (
        <p>
          This isn’t supposed to happen.
          <br />
          Please{' '}
          <StyledLink external to={`${urls.snowflakeCommunityForumLink}`}>
            contact support
          </StyledLink>
          .
        </p>
      )}
      <ErrorDetails details={details} />
    </Page>
  )
}

export default memo(ErrorPage)
