import { isNonEmptyString } from 'helpers/validation'
import type React from 'react'
import { type ReactElement, type ReactNode, forwardRef, type Ref } from 'react'
import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from 'react-router-dom'

export interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: RouterLinkProps['to']
  children: ReactNode
  external?: boolean
  state?: RouterLinkProps['state']
}

function Link(
  { external = false, to, children, ...rest }: Props,
  ref: Ref<HTMLAnchorElement>,
): ReactElement {
  if (external && typeof to === 'string' && isNonEmptyString(to)) {
    return (
      <a
        ref={ref}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {children}
      </a>
    )
  }

  return (
    <RouterLink to={to} {...rest} ref={ref}>
      {children}
    </RouterLink>
  )
}

export default forwardRef(Link)
