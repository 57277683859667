import { datadogLogs } from '@datadog/browser-logs'
import {
  datadogRum,
  type RumEvent,
  type RumEventDomainContext,
} from '@datadog/browser-rum'
import Routes, { Pages } from 'constants/routes'
import { getCurrentAppSection } from 'helpers/constantsHelpers'
import { memoize } from 'lodash'
import { useEffect } from 'react'
import { matchPath } from 'react-router'
import { useLocation } from 'react-router-dom'
import { getCurrentEnv } from '../helpers/environment'

const APPLICATION_ID = '7ebf6793-02ce-40e8-8776-5818438777a2'
const CLIENT_TOKEN = 'pub80bea74eec96ecfc73fa52f86798cadf'
const DEFAULT_PRIVACY_LEVEL = 'mask-user-input'
const SERVICE = 'web'
const SESSION_REPLAY_SAMPLE_RATE = 1
const SESSION_SAMPLE_RATE = 100
const SITE = 'datadoghq.com'
const env = getCurrentEnv()
const ALLOWED_DOMAINS = [
  'https://share.streamlit.io',
  'https://devel.streamlit.test',
  `https://share-${env}.streamlit.io`,
]

const ALL_ROUTES_URLS = Object.values<string>(Routes)

function isLocalEnvironment(): boolean {
  return env === 'local'
}

function anonymizeUrlImpl(url: string): string {
  const parsed = new URL(url)
  const pathname = parsed.pathname
  for (const route of ALL_ROUTES_URLS) {
    const matchedRoute = matchPath(route, pathname)
    if (matchedRoute != null) {
      return matchedRoute.pattern.path
    }
  }

  return pathname
}

const anonymizeUrl = memoize(anonymizeUrlImpl)

function isDatadogRumEnabled(): boolean {
  return [Pages.DASHBOARD, Pages.GALLERY, Pages.EXPLORE].includes(
    getCurrentAppSection(),
  )
}

function getSessionSampleRate(): number {
  return SESSION_SAMPLE_RATE
}

function getTelemetrySampleRate(): number {
  return 100
}

function getUseSecureSessionCookie(): boolean {
  return !isLocalEnvironment()
}

function getUsePartitionedCrossSiteSessionCookie(): boolean {
  return !isLocalEnvironment()
}

// IMPORTANT: You must exclude any testing apps to avoid generating millions of RUM events when running tests
export function initDatadog(): void {
  // IMPORTANT: We should ensure that the RUM and LOGS have matching properties
  // See https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters for the list of properties that must match
  datadogLogs.init({
    clientToken: CLIENT_TOKEN,
    site: SITE,
    service: SERVICE,
    env,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    forwardReports: 'all',
    useSecureSessionCookie: getUseSecureSessionCookie(),
    usePartitionedCrossSiteSessionCookie: getUseSecureSessionCookie(),
    sessionSampleRate: getSessionSampleRate(),
    telemetrySampleRate: getTelemetrySampleRate(),
    proxy: (options: { path: string; parameters: string }) =>
      `/api/v1/telemetry/datadog?path=${encodeURIComponent(options.path)}&parameters=${encodeURIComponent(options.parameters)}`,
  })

  if (isLocalEnvironment()) {
    // If you want to send logs to DataDog when running locally, you can add 'http' to the array
    datadogLogs.logger.setHandler(['console'])
  }

  // IMPORTANT: We should ensure that the RUM and LOGS have matching properties
  // See https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters for the list of properties that must match
  if (isDatadogRumEnabled()) {
    datadogRum.init({
      allowedTracingUrls: ALLOWED_DOMAINS,
      applicationId: APPLICATION_ID,
      beforeSend: function (
        event: RumEvent,
        context: RumEventDomainContext,
      ): boolean {
        event.view.url = anonymizeUrl(event.view.url)
        return true
      },
      clientToken: CLIENT_TOKEN,
      defaultPrivacyLevel: DEFAULT_PRIVACY_LEVEL,
      env,
      service: SERVICE,
      sessionReplaySampleRate: SESSION_REPLAY_SAMPLE_RATE,
      sessionSampleRate: getSessionSampleRate(),
      site: SITE,
      telemetrySampleRate: getTelemetrySampleRate(),
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      trackViewsManually: true,
      useSecureSessionCookie: getUseSecureSessionCookie(),
      usePartitionedCrossSiteSessionCookie:
        getUsePartitionedCrossSiteSessionCookie(),
      proxy: (options: { path: string; parameters: string }) =>
        `/api/v1/telemetry/datadog?path=${encodeURIComponent(options.path)}&parameters=${encodeURIComponent(options.parameters)}`,
    })
  }
}

export function useDatadogManualViewTracking(): void {
  const location = useLocation()
  useEffect(() => {
    if (isDatadogRumEnabled()) {
      datadogRum.startView()
    }
  }, [location])
}
