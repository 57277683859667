import { type ReactElement, memo } from 'react'

import { ModalBody } from 'baseui/modal'

import queryString from 'query-string'

import { type IApp } from 'api/apps'
import urls from 'constants/urls'

import { ReactComponent as IconForum } from 'assets/svg/icon_forum.svg'
import { ReactComponent as IconTwitter } from 'assets/svg/icon_twitter.svg'
import { ReactComponent as IconLinkedin } from 'assets/svg/icon_linkedin.svg'
import { ReactComponent as IconYCombinator } from 'assets/svg/icon_y_combinator.svg'

import styles from 'modals/share/social/social.module.scss'

interface InnerBodyProps {
  app: IApp
  isOwner: boolean
}

function Social({ app, isOwner }: InnerBodyProps): ReactElement {
  const appUrl = window.location.origin
  const message = `Check out this awesome Streamlit app I ${
    isOwner ? 'built' : 'found'
  }\n`

  return (
    <>
      <ModalBody className={styles.socialModal}>
        <ul className={styles.promoteOptions}>
          <li>
            <a
              href={`https://twitter.com/intent/tweet?${queryString.stringify({
                text: message,
                url: appUrl,
                hashtags: ['streamlit', 'python'].join(','),
              })}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconTwitter />
              Twitter
            </a>
          </li>
          <li>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?${queryString.stringify(
                {
                  url: appUrl,
                  title: message,
                  summary: `${appUrl} #streamlit #python`,
                },
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconLinkedin />
              LinkedIn
            </a>
          </li>
          <li>
            <a
              href={`https://news.ycombinator.com/submitlink?${queryString.stringify(
                {
                  u: appUrl,
                  t: document.title,
                },
              )}
`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconYCombinator className={styles.yCombinatorLogo} />
              Hacker News
            </a>
          </li>
          <li>
            <a
              href={`${urls.forumBasePath}/new-topic?${queryString.stringify({
                title: `New ${app.owner}/${app.repo} Streamlit App`,
                body: `${message}${appUrl}`,
                category: 'streamlit-examples',
                tags: '',
              })}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconForum />
              Forum
            </a>
          </li>
        </ul>
      </ModalBody>
    </>
  )
}

export default memo(Social)
