import axios, {
  type AxiosInstance,
  type InternalAxiosRequestConfig,
} from 'axios'
import {
  AppsApi,
  CodespacesApi,
  ConfigApi,
  Configuration,
  FeatureFlagsApi,
  GalleryApi,
  GithubApi,
  ProfilesApi,
  TelemetryApi,
  UserApi,
} from 'gen'
import { memoize } from 'lodash'
import {
  errorResponseInterceptor,
  requestInterceptor,
  responseInterceptor,
} from './axios'

const BASE_PATH = '/api/v2'

/**
 * The client that is generated from the Open API spec has the full path, including the dashboard and subdomain segments.
 * These segments are added by the ingress and the client should be unaware of them.
 * This interceptor removes the segments
 * @param request
 */
export function removeDashboardAndSubdomainPath(
  request: InternalAxiosRequestConfig<unknown>,
): InternalAxiosRequestConfig<unknown> {
  if (request.url) {
    let requestUrl = request.url
    if (requestUrl.startsWith('/api/v2/dashboard/')) {
      requestUrl = requestUrl.replace('/api/v2/dashboard/', '/api/v2/')
    } else if (requestUrl.startsWith('/api/v2/subdomain/')) {
      requestUrl = requestUrl.replace('/api/v2/subdomain/', '/api/v2/')
    } else if (requestUrl.startsWith('/dashboard/')) {
      requestUrl = BASE_PATH + requestUrl.replace('/dashboard/', '/')
    } else if (requestUrl.startsWith('/subdomain/')) {
      requestUrl = BASE_PATH + requestUrl.replace('/subdomain/', '/')
    }

    request.url = requestUrl
  }

  return { ...request }
}

function createApiConfiguration(): {
  configuration: Configuration
  axiosInstance: AxiosInstance
} {
  const configuration = new Configuration({ basePath: BASE_PATH })
  const axiosInstance = axios.create({ baseURL: '/' })
  axiosInstance.interceptors.request.use(requestInterceptor)
  axiosInstance.interceptors.request.use(removeDashboardAndSubdomainPath)

  axiosInstance.interceptors.response.use(
    responseInterceptor,
    errorResponseInterceptor,
  )
  return {
    configuration,
    axiosInstance,
  }
}

function getAppsApiImpl(): AppsApi {
  const { configuration, axiosInstance } = createApiConfiguration()
  return new AppsApi(configuration, BASE_PATH, axiosInstance)
}

export const getAppsApiClient = memoize(getAppsApiImpl)

function getCodespacesApiImpl(): CodespacesApi {
  const { configuration, axiosInstance } = createApiConfiguration()
  return new CodespacesApi(configuration, BASE_PATH, axiosInstance)
}

export const getCodespacesApiClient = memoize(getCodespacesApiImpl)

function getTelemetryApiImpl(): TelemetryApi {
  const { configuration, axiosInstance } = createApiConfiguration()
  return new TelemetryApi(configuration, BASE_PATH, axiosInstance)
}

export const getTelemetryApiClient = memoize(getTelemetryApiImpl)

function getUserApiImpl(): UserApi {
  const { configuration, axiosInstance } = createApiConfiguration()
  return new UserApi(configuration, BASE_PATH, axiosInstance)
}

export const getUserApiClient = memoize(getUserApiImpl)

function getFeatureFlagsImpl(): FeatureFlagsApi {
  const { configuration, axiosInstance } = createApiConfiguration()
  return new FeatureFlagsApi(configuration, BASE_PATH, axiosInstance)
}

export const getFeatureFlagsApiClient = memoize(getFeatureFlagsImpl)

function getGalleryApiImpl(): GalleryApi {
  const { configuration, axiosInstance } = createApiConfiguration()
  return new GalleryApi(configuration, BASE_PATH, axiosInstance)
}

export const getGalleryApiClient = memoize(getGalleryApiImpl)

function getProfilePageApiImpl(): ProfilesApi {
  const { configuration, axiosInstance } = createApiConfiguration()
  return new ProfilesApi(configuration, BASE_PATH, axiosInstance)
}

function getGitHubApiImpl(): GithubApi {
  const { configuration, axiosInstance } = createApiConfiguration()
  return new GithubApi(configuration, BASE_PATH, axiosInstance)
}

export const getGithubApiClient = memoize(getGitHubApiImpl)

export const getProfilePageApiClient = memoize(getProfilePageApiImpl)

function getConfigApiImpl(): ConfigApi {
  const { configuration, axiosInstance } = createApiConfiguration()
  return new ConfigApi(configuration, BASE_PATH, axiosInstance)
}

export const getConfigApiClient = memoize(getConfigApiImpl)
