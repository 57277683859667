import { type ReactElement } from 'react'

import classNames from 'classnames'

import Alert from 'components/alert'
import PageSpinner from 'components/pageSpinner'

import { useGetViewers, useSetViewers } from 'api/viewers'

import { Surface, UserRole } from 'constants/analytics'
import { type IApp } from 'api/apps'

import useTrackShare, { Operation } from 'hooks/analytics/useTrackShare'

import tailwind from 'constants/tailwind.config'

import { ReactComponent as IconClose } from 'assets/svg/icon_close.svg'

import modalStyles from './share.module.scss'
import styles from './viewers.module.scss'

interface ViewersProps {
  app: IApp
  isOwner: boolean
}

export const colors = [
  tailwind.theme.colors.red['700'],
  tailwind.theme.colors.orange['700'],
  tailwind.theme.colors.yellow['800'],
  tailwind.theme.colors.green['700'],
  tailwind.theme.colors.bluegreen['700'],
  tailwind.theme.colors.lightblue['700'],
  tailwind.theme.colors.blue['700'],
  tailwind.theme.colors.violet['700'],
]

export function getColorClassForEmail(email: string): string {
  let code = 0
  // A cheap way to get fairly different values for different email addresses
  Array.from(email).forEach((value) => {
    code += value.charCodeAt(0)
  })

  // The calculated value module the list length will return a number between 0 to (list length - 1)
  return colors[code % colors.length]
}

function Viewers({ app, isOwner }: ViewersProps): ReactElement {
  const {
    error: getViewersError,
    viewers,
    domains,
    isPending,
  } = useGetViewers(app)
  const { error: setViewersError, doSetViewers } = useSetViewers(app)
  const trackShare = useTrackShare()

  if (isPending || getViewersError) {
    return <PageSpinner />
  }

  const removeViewer = async (viewer: string): Promise<void> => {
    const newViewers = viewers.filter((v) => v !== viewer)
    // If domains is not included here then any domains that
    // are previously set will be removed.
    await doSetViewers({ viewers: newViewers, domains })

    const role = isOwner ? UserRole.Developer : UserRole.Viewer
    const appId = app.appId ?? ''
    const appWorkspaceName = app.owner ?? ''
    trackShare({
      surface: Surface.InAppShareMenu,
      role,
      emails: [viewer],
      operation: Operation.RemoveViewers,
      appId,
      appWorkspaceName,
    })
  }

  const viewerElements = viewers.map((viewer) => {
    const colorClassName = getColorClassForEmail(viewer)
    return (
      <div
        key={viewer}
        className={classNames(styles.viewerRow, modalStyles.bodyPadding)}
      >
        <div
          className={styles.avatar}
          style={{ backgroundColor: colorClassName }}
        >
          {viewer.charAt(0)}
        </div>
        <div className={styles.viewer}>{viewer}</div>
        <IconClose
          aria-label={`remove-${viewer}`}
          className={styles.close}
          onClick={async () => {
            await removeViewer(viewer)
          }}
        />
      </div>
    )
  })
  return (
    <>
      {setViewersError && <Alert title="Error" message={setViewersError} />}
      <div className={styles.root}>{viewerElements}</div>
    </>
  )
}

export default Viewers
