import { memo, type ReactElement, useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import queryString from 'query-string'

import useUser from 'api/user'
import { useEventSession } from 'api/appEvents'
import useDisambiguate from 'api/disambiguate'

import { LinkedUserRedirect } from 'components/loginRedirect'
import PageSpinner from 'components/pageSpinner'
import ErrorPage from 'pages/error'
import NotFoundPage from 'pages/notFound'

import { linkedRedirectSelector } from 'reducers/linkedRedirect/selectors'
import { generateAppURL } from 'helpers/constantsHelpers'
import {
  Pages,
  redirectURIQueryParameterName,
  ServerPaths,
} from 'constants/routes'

import useAppContext from 'api/appContext'

import {
  filterQueryString,
  formatURLDataForDisambiguate,
  type RouteParams,
} from 'pages/streamlitApp/common/helpers'

import AppLoader from './appLoader'

export enum AppPageLocation {
  ConsolePlaceholder = 'CONSOLE_PLACEHOLDER',
  LegacyAppViewer = 'LEGACY_APP_VIEWER',
  SubdomainAppViewer = 'SUBDOMAIN_APP_VIEWER',
}

function useRedirectToSubdomainApp(
  appPageLocation: AppPageLocation,
  rest: string | undefined,
): (subdomain: string, element: ReactElement) => ReactElement {
  return useCallback(
    (subdomain: string, element: ReactElement): ReactElement => {
      const isPlaceholder =
        appPageLocation === AppPageLocation.ConsolePlaceholder
      const isLegacyRedirect =
        appPageLocation === AppPageLocation.LegacyAppViewer
      const shouldGoToSubdomain = isPlaceholder || isLegacyRedirect

      if (subdomain && shouldGoToSubdomain) {
        const targetAppURL = generateAppURL({
          section: Pages.APP,
          subdomain,
          path: rest,
        })

        window.location.href = generateAppURL({
          section: Pages.APP,
          subdomain,
          path: ServerPaths.SUBDOMAIN_LOGOUT,
          searchParams: new URLSearchParams(
            `${redirectURIQueryParameterName}=${targetAppURL}`,
          ),
        })

        return <PageSpinner overlay />
      }

      return element
    },
    [appPageLocation, rest],
  )
}

/*

The StreamlitAppPage now has 3 distinct locations where it's rendered.
  1. The legacy app viewing page: `share.streamlit.io/<owner>/<repo>/<etc>
    - This renders all the functionality of StreamlitAppPageCore

  2. The unique subdomain app viewing page: `<app-subdomain>.streamlit.app`
    - It's more secure to restrict login and redirect functionality to the main console
      application.  So any check that happens here that results in user data or redirects
      instead "bails out" to the placeholder page.
    - API errors are shown in place to prevent redirect loops.
    - The happy path renders the app

  3. The console app placeholder page: `share.streamlit.io/app/<app-subdomain>`
    - Uses the same component to ensure it has the exact same logic checks.
    - If any check results in not taking the happy path, then render the respective components.
    - The happy path redirects to the subdomain app viewing page to render the app.

                     ┌─────────────────────────────┐
                     ▼                             │
    Legacy           Subdomain   ┌─► Placeholder   │
    │                │           │   │             │
    ├─►redirect      ├─►Bail out─►   ├─►redirect   │
    │                │           │   │             │
    ├─►not-found     ├─►Bail out─►   ├─►not-found  │
    │                │               │             │
    ├─►error-page    ├─►error-page   ├─►error-page │
    │                │               │             │
    ▼                ▼               └─────────────┘
    render app       render app      to subdomain

*/

interface StreamlitAppPageProps {
  appPageLocation?: AppPageLocation
}

function StreamlitAppPage({
  appPageLocation = AppPageLocation.LegacyAppViewer,
}: StreamlitAppPageProps): ReactElement | null {
  const location = useLocation()
  const navigate = useNavigate()

  const routeParams = useParams<RouteParams>()
  const { disambiguateUrlData, rest } = formatURLDataForDisambiguate(
    routeParams as RouteParams,
    location.search,
  )
  const {
    app,
    error: disambiguateError,
    isPending: disambiguateIsPending,
  } = useDisambiguate(disambiguateUrlData)

  const maybeGoToSubdomain = useRedirectToSubdomainApp(appPageLocation, rest)
  const { context } = useAppContext(app?.appId)

  const { hasSeenLinkedRedirect } = useSelector(linkedRedirectSelector)
  const {
    user,
    error: userError,
    isPending: userIsPending,
    linkedUser,
    workspaces,
  } = useUser()

  // TODO(spicysouvlaki): make this environment-wise configurable
  const flushInterval = 30 * 1000
  const useFocusEvents = true
  useEventSession(app?.appId, useFocusEvents, flushInterval)

  if (userIsPending || disambiguateIsPending) {
    return <PageSpinner overlay />
  }

  // when a connected account has no session
  if (!hasSeenLinkedRedirect && linkedUser) {
    return <LinkedUserRedirect linkedUser={linkedUser} />
  }

  // This query string is set on app invite emails.
  // If a user navigates to a URL with inviteAccepted, we assume with high confidence that the app exists
  const { query } = queryString.parseUrl(location.search)

  // XXX TODO: Handle this with state machine.
  if (
    disambiguateError?.response?.status === 404 ||
    userError?.response?.status === 404
  ) {
    return <NotFoundPage />
  }

  if (userError) {
    return <ErrorPage details={userError} />
  }

  if (disambiguateError) {
    return <ErrorPage details={disambiguateError} />
  }

  if (!app?.subdomain) {
    return <NotFoundPage />
  }

  if (app && context?.app.isFavorited) {
    app.isFavorited = true
  }

  if (query?.acceptInvite) {
    navigate({
      search: filterQueryString(location.search, ['acceptInvite']),
    })
  }

  return maybeGoToSubdomain(
    app.subdomain,
    <AppLoader app={app} user={user} workspaces={workspaces} />,
  )
}

export default memo(StreamlitAppPage)
