import { memo, type ReactElement } from 'react'
import { Helmet } from 'react-helmet-async'

import Page from 'components/page'
import Button from 'components/button'
import PageSpinner from 'components/pageSpinner'
import Header from 'components/header'
import CarouselOfStreamlitExampleApp from 'components/carouselOfStreamlitExampleApp/CarouselOfStreamlitExampleApp'

import styles from './styles.module.scss'
import SmallDivider from '../../components/smallDivider/SmallDivider'

interface Props {
  onClick: () => () => void
  showSpinner: boolean
}

function LoginPage({ onClick, showSpinner }: Props): ReactElement {
  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <Header showExternalLinksNavigation={true} />
      <div className={styles.container}>
        <Page
          className={styles.pageContainer}
          justifyCenter={false}
          header={false}
        >
          {showSpinner && <PageSpinner overlay />}

          <div className={styles.title}>
            <h1 className={styles.onboarding}>Streamlit Community Cloud</h1>
          </div>

          <div className={styles.subtitle}>
            <p>A place for the community to publicly share</p>
            <p>Streamlit apps and learn from each other!</p>
          </div>

          <div className={styles.loginContainer}>
            <ul className={styles.loginOptions}>
              <>
                <li>
                  <Button
                    onClick={onClick()}
                    data-testid="new-login"
                    className={styles.continueWithSignInButton}
                  >
                    Continue to sign-in
                  </Button>
                </li>
              </>
            </ul>
          </div>
          <div className={styles.termsOfUseSectionContainer}>
            <SmallDivider className={styles.termsOfUseSectionDivider} />
            <p className={styles.termsOfUseSection}>
              By signing in, I agree to the{' '}
              <a
                className={styles.loginPageLink}
                href="https://streamlit.io/deployment-terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{' '}
              and understand Streamlit will process my personal information in
              accordance with its{' '}
              <a
                className={styles.loginPageLink}
                href="https://streamlit.io/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Notice
              </a>
              .
            </p>
          </div>
        </Page>
        <CarouselOfStreamlitExampleApp />
      </div>
    </>
  )
}

export default memo(LoginPage)
