/* eslint-disable react/no-array-index-key */
import {
  type ButtonHTMLAttributes,
  type FunctionComponent,
  type ReactElement,
  type SVGProps,
  type AnchorHTMLAttributes,
  type ElementType,
  type ReactNode,
} from 'react'
import classNames from 'classnames'
import { type LinkProps } from 'react-router-dom'

import { StatefulPopover } from 'baseui/popover'
import { type TetherPlacement } from 'baseui/layer'

import HamburgerMenu from './hamburgerMenu'

import styles from './popoverMenu.module.scss'

type IconProps = {
  title?: string | undefined
} & SVGProps<SVGSVGElement>

interface CustomElementType {
  closeTooltip: () => void
}

interface DataButtonHTMLAttributes<T> extends ButtonHTMLAttributes<T> {
  'data-testid'?: string
}

export interface MenuItem {
  label?: string | ReactElement
  Component?: ElementType
  Icon?: FunctionComponent<IconProps>
  iconClassName?: string
  buttonProps?:
    | AnchorHTMLAttributes<HTMLAnchorElement>
    | DataButtonHTMLAttributes<HTMLButtonElement>
    | LinkProps
  selected?: boolean
  disabled?: boolean
}

export interface Menu {
  items: MenuItem[]
  className?: string
}

export interface Props {
  menus: Menu[]
  placement: keyof TetherPlacement
  theme?: 'dark' | 'light'
  spacing?: 'normal' | 'thin'
  children?: ReactNode
  buttonClassName?: string
}

function PopoverMenu({
  menus,
  theme = 'light',
  spacing = 'normal',
  placement,
  children,
  buttonClassName,
}: Props): ReactElement {
  const containerClasses = classNames(
    styles.container,
    styles[`container_${theme}`],
  )

  return (
    <StatefulPopover
      content={({ close }) => (
        <div className={containerClasses}>
          {menus.map(({ items }, idx) => (
            <ul
              key={idx}
              className={classNames(
                styles.menuList,
                styles[`menuList_${spacing}`],
              )}
            >
              {items.map(
                (
                  {
                    label,
                    Component,
                    Icon,
                    iconClassName,
                    buttonProps = {},
                    selected,
                    disabled,
                  },
                  jdx,
                ) => {
                  const {
                    onClick,
                    className: buttonClassName,
                    ...restButtonProps
                  } = buttonProps
                  const ButtonComponent: ElementType | CustomElementType =
                    Component ?? 'button'

                  const buttonClasses = classNames(
                    buttonClassName,
                    styles.button,
                    styles[`button_${theme}`],
                    styles[`button_${spacing}`],
                    {
                      [styles.button_selected]: selected,
                      [styles.button_disabled]: disabled,
                    },
                  )

                  const iconClasses = classNames(iconClassName, styles.icon)

                  return (
                    <li key={jdx}>
                      <ButtonComponent
                        className={buttonClasses}
                        {...restButtonProps}
                        disabled={disabled}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onClick={(e: any) => {
                          close()

                          if (onClick) {
                            onClick(e)
                          }
                        }}
                      >
                        {Icon && <Icon className={iconClasses} />}
                        {label}
                      </ButtonComponent>
                    </li>
                  )
                },
              )}
            </ul>
          ))}
        </div>
      )}
      returnFocus
      autoFocus
      placement={placement}
    >
      {children || <HamburgerMenu theme={theme} className={buttonClassName} />}
    </StatefulPopover>
  )
}

export default PopoverMenu
