import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'

import { Input } from 'baseui/input'

import { type ReactElement, useState, type Dispatch } from 'react'

import Button from 'components/button/button'

import { useDeleteAccount } from 'api/useDeleteAccount'

import { DeleteAccountNotification } from './deleteAccountNotification/DeleteAccountNotification'

import {
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FOREVER,
  getAccountDeleteProceedMessage,
} from './messages'

interface IDeleteAccountModalProps {
  email: string
  maybeLogin?: string
  isOpen: boolean
  setIsOpen: Dispatch<boolean>
}

export function DeleteAccountModal({
  email,
  maybeLogin,
  isOpen,
  setIsOpen,
}: IDeleteAccountModalProps): ReactElement {
  const { deleteAccount } = useDeleteAccount()

  const [textInput, setTextInput] = useState('')

  return (
    <Modal
      animate
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
    >
      <ModalHeader>{DELETE_ACCOUNT}?</ModalHeader>
      <ModalBody>
        <DeleteAccountNotification />
        <p>{getAccountDeleteProceedMessage(email)}</p>
        <Input
          onChange={(event) => {
            setTextInput(event.currentTarget.value)
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          theme="destructive"
          disabled={textInput !== `delete ${email}`}
          onClick={async () => {
            await deleteAccount()
          }}
        >
          {DELETE_ACCOUNT_FOREVER}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
