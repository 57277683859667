import { createSelector } from '@reduxjs/toolkit'

import { type OutputParametricSelector } from 'reselect'

import {
  type State,
  type ModalName,
  type ModalPayload,
  type ModalState,
} from 'reducers/modal'
import { type RootState } from 'reducers'

const baseSelector = (state: RootState): State => state.modal

const modalSelector = (state: RootState, name: ModalName): ModalState<any> =>
  state.modal[name]

export const makeModalIsOpenSelector = (): OutputParametricSelector<
  RootState,
  ModalName,
  boolean,
  (res: ModalState) => boolean
> => createSelector(modalSelector, (modal) => modal?.isOpen)

export const makeModalRequireConfirmationSelector =
  (): OutputParametricSelector<
    RootState,
    ModalName,
    boolean,
    (res: ModalState) => boolean
  > =>
    createSelector(
      modalSelector,
      (modal) => modal?.requireConfirmation === true,
    )

export const makeModalPayloadSelector = (): OutputParametricSelector<
  RootState,
  ModalName,
  ModalPayload,
  (res: ModalState) => ModalPayload
> => createSelector(modalSelector, (modal) => modal?.modalPayload)

export const makeModalIsAnyOpen = (): OutputParametricSelector<
  RootState,
  undefined,
  boolean,
  (res: State) => boolean
> =>
  createSelector(baseSelector, (state) =>
    Object.values(state).some(({ isOpen }) => isOpen),
  )
