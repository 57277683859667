import { isNonEmptyString } from './validation'

interface SemanticVersion {
  major: number
  minor: number
  patch: number
}

export const parseVersionString = (
  versionString?: string,
): SemanticVersion | undefined => {
  if (!isNonEmptyString(versionString)) {
    return undefined
  }

  const parts = versionString.split('.')
  if (parts.length !== 3) {
    return undefined
  }

  const [major, minor, patch] = parts.map(Number)
  return { major, minor, patch }
}

const VERSION_WITH_FIX = parseVersionString('1.1.0') as SemanticVersion

export const isVersionWithMemoryLeak = (versionString?: string): boolean => {
  const v = parseVersionString(versionString)
  if (v === undefined) {
    return false
  }

  if (v.major < VERSION_WITH_FIX.major) {
    return true
  }

  if (v.major > VERSION_WITH_FIX.major) {
    return false
  }

  return v.minor < VERSION_WITH_FIX.minor
}
