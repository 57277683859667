import { useMemo } from 'react'
import ReactDOMServer from 'react-dom/server'

import { ReactComponent as IconFavorite } from 'assets/svg/icon_star.svg'
import { ReactComponent as IconFavoriteOutlined } from 'assets/svg/icon_star_outline.svg'
import { ReactComponent as IconGithub } from 'assets/svg/icon_github.svg'

import { theme } from 'constants/tailwind.config'

import { useFavoriteApp } from 'api/appFavorite'

import {
  type ExportedTheme,
  type ICallbacks,
  type IToolbarItem,
} from 'hooks/useCoreCommunication'

import useAppContext, {
  hasUserWorkspacePermission,
  isLoggedIn,
} from 'api/appContext'
import { generateAppURL } from 'helpers/constantsHelpers'
import Routes, { Pages } from 'constants/routes'
import useAppStatus from 'api/appStatus'
import { useSearchParams } from 'react-router-dom'
import { PermissionModel } from '../../../../gen'

enum ToolbarItemTypes {
  edit = 'edit',
  favorite = 'favorite',
  fork = 'fork',
  share = 'share',
  source = 'source',
}

interface IResponse {
  toolbarItems: IToolbarItem[]
  toolbarCallbacks: ICallbacks
}

interface ToolbarItemParams {
  appId: string
  appOwner: string
  repository: string
  branch: string
  mainModule: string
  isFavorited: boolean
  onClickShare: () => void
  appThemeInfo?: ExportedTheme
}

export default function useToolbarItems({
  appId,
  appOwner,
  repository,
  branch,
  mainModule,
  isFavorited,
  onClickShare,
  appThemeInfo,
}: ToolbarItemParams): IResponse {
  const { doFavoriteApp } = useFavoriteApp(appId)

  const { context } = useAppContext(appId)
  const { viewerAuthEnabled } = useAppStatus(appId)
  const userIsLoggedIn = isLoggedIn(context?.user)
  const [searchParams] = useSearchParams()

  const repositoryUrl = `https://github.com/${appOwner}/${repository}/blob/${branch}/${mainModule}`

  const appIsPrivate = viewerAuthEnabled ?? true
  const repoIsPrivate = context?.app.source.repository.isPrivate ?? true

  const hasAccessToAppWorkspace = useMemo(() => {
    return hasUserWorkspacePermission(
      context?.user,
      PermissionModel.Workspace_ViewAccess,
    )
  }, [context])

  const toolbarItems: IToolbarItem[] = useMemo(() => {
    const items = []

    const favIconThemeColor = appThemeInfo
      ? appThemeInfo.textColor
      : theme.colors.black['600']

    if (userIsLoggedIn && hasAccessToAppWorkspace) {
      // If a user is logged in and they are seeing this app because it is public, but they have NOT been
      // allow-listed (i.e they don't have access to the workspace)
      // then they should not have access to the share at this time.
      items.push({
        key: ToolbarItemTypes.share,
        label: 'Share',
        borderless: false,
      })

      const iconFavoriteSvg = ReactDOMServer.renderToString(
        <IconFavorite fill={theme.colors.yellow['800']} />,
      )
      // fill sets the linecolor for the outlined favorite icon
      const iconFavoriteOutlinedSvg = ReactDOMServer.renderToString(
        <IconFavoriteOutlined fill="currentColor" color={favIconThemeColor} />,
      )
      items.push({
        key: ToolbarItemTypes.favorite,
        icon: isFavorited
          ? `data:image/svg+xml;base64,${btoa(iconFavoriteSvg)}`
          : `data:image/svg+xml;base64,${btoa(iconFavoriteOutlinedSvg)}`,
        borderless: true,
      })
    } else if (!appIsPrivate && !repoIsPrivate) {
      items.push({
        key: ToolbarItemTypes.fork,
        label: 'Fork',
        borderless: false,
      })
    }

    // fill sets the linecolor for the outlined favorite icon
    const iconGithubSvg = ReactDOMServer.renderToString(
      <IconGithub fill="currentColor" color={favIconThemeColor} />,
    )

    if (!repoIsPrivate) {
      items.push({
        key: ToolbarItemTypes.source,
        icon: `data:image/svg+xml;base64,${btoa(iconGithubSvg)}`,
        borderless: true,
      })
    }

    return items
  }, [
    appIsPrivate,
    appThemeInfo,
    hasAccessToAppWorkspace,
    isFavorited,
    repoIsPrivate,
    userIsLoggedIn,
  ])

  const toolbarCallbacks: ICallbacks = useMemo(() => {
    const callbacks: ICallbacks = {}

    if (!repoIsPrivate) {
      callbacks[ToolbarItemTypes.source] = () => {
        window.open(repositoryUrl, '_blank')
      }
    }

    if (userIsLoggedIn && hasAccessToAppWorkspace) {
      callbacks[ToolbarItemTypes.share] = onClickShare

      callbacks[ToolbarItemTypes.favorite] = () => {
        doFavoriteApp(!isFavorited)
      }
    } else if (!appIsPrivate && !repoIsPrivate) {
      callbacks[ToolbarItemTypes.fork] = () => {
        window.location.href = generateAppURL({
          section: Pages.DASHBOARD,
          path: Routes.CREATE_FROM_FORK,
          searchParams: new URLSearchParams({
            owner: appOwner,
            repository,
            branch,
            mainModule,
            appId,
            ...Object.fromEntries(searchParams.entries()),
          }),
        })
      }
    }

    return callbacks
  }, [
    appIsPrivate,
    appOwner,
    branch,
    doFavoriteApp,
    hasAccessToAppWorkspace,
    isFavorited,
    mainModule,
    onClickShare,
    repoIsPrivate,
    repository,
    repositoryUrl,
    searchParams,
    userIsLoggedIn,
    appId,
  ])

  return {
    toolbarItems,
    toolbarCallbacks,
  }
}
