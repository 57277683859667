import { getCurrentAppSection } from 'helpers/constantsHelpers'

import { Pages } from 'constants/routes'

export const useUniqueSubdomainFeature = (): boolean => {
  const appSection = getCurrentAppSection()

  // We want to allow both Pages.DASHBOARD and Pages.APP for custom subdomain,
  // so the app owner can change subdomain also from the application console
  const onConsole = appSection === Pages.DASHBOARD || appSection === Pages.APP

  return onConsole
}

export const isSubdomainApp = (): boolean => {
  const appSection = getCurrentAppSection()
  return appSection === Pages.APP
}

export const useCustomSubdomainFeature = (): boolean => {
  return useUniqueSubdomainFeature()
}
