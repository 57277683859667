import { type ReactElement, type ReactNode, memo } from 'react'
import classNames from 'classnames'
import {
  ToasterContainer,
  type PLACEMENT,
  type ToasterOverrides,
} from 'baseui/toast'

import Header from 'components/header'
import useFavicon from 'hooks/useFavicon'

import { ReactComponent as IconAlert } from 'assets/svg/icon_alert.svg'

import styles from './styles.module.scss'

const TOASTS_DURATION = 5000
const TOASTS_POSITION: keyof PLACEMENT = 'bottomRight'

interface Props {
  header?: boolean | ReactElement
  className?: string
  children: ReactNode
  justifyCenter?: boolean
}

const toasterOverrides: ToasterOverrides = {
  ToastCloseIcon: {
    component: () => <IconAlert className={styles.iconClose} />,
  },
  ToastBody: {
    style: ({ $theme, $kind = 'negative' }) => {
      const kindAttributes = {
        info: {
          backgroundColor: $theme?.colors?.positive50,
          borderColor: $theme?.colors?.positive300,
          color: $theme?.colors?.primaryA,
        },
        positive: {
          path: {
            color: $theme?.colors?.positive300,
          },
          backgroundColor: $theme?.colors?.positive50,
          borderColor: $theme?.colors?.positive300,
          color: $theme?.colors?.primaryA,
        },
        warning: {
          backgroundColor: $theme?.colors?.negative50,
          borderColor: $theme?.colors?.negative300,
          color: $theme?.colors?.primaryA,
        },
        negative: {
          backgroundColor: $theme?.colors?.negative50,
          borderColor: $theme?.colors?.negative300,
          color: $theme?.colors?.primaryA,
        },
      }

      return {
        border: '1px solid',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'row-reverse',
        fontSize: '16px',
        fontWeight: 'normal',
        justifyContent: 'flex-end',
        lineHeight: '24px',
        ...kindAttributes[$kind],
      }
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  ToastInnerContainer: {
    style: {
      maxWidth: '200px',
      paddingLeft: '8px',
    },
  },
}

function Page({
  header = <Header />,
  className,
  children,
  justifyCenter = true,
}: Props): ReactElement {
  useFavicon()
  return (
    <ToasterContainer
      placement={TOASTS_POSITION}
      autoHideDuration={TOASTS_DURATION}
      overrides={toasterOverrides}
    >
      <div className={classNames(styles.wrapper)}>
        {header === true ? <Header /> : header}
        <main
          className={classNames(
            'container',
            styles.pageContainer,
            {
              [styles.justifyCenter]: justifyCenter,
            },
            className,
          )}
        >
          {children}
        </main>
      </div>
    </ToasterContainer>
  )
}

export default memo(Page)
