import { type ReactElement } from 'react'
import classNames from 'classnames'

import Link, { type Props } from './link'

import styles from './styles.module.scss'

function StyledLink({ children, className, ...rest }: Props): ReactElement {
  return (
    <Link {...rest} className={classNames(styles.link, className)}>
      {children}
    </Link>
  )
}

export default StyledLink
