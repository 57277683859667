import _debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'

import TailwindConfig from 'helpers/tailwind/tailwind'

export const MOBILE_BREAKPOINT = TailwindConfig.theme.screens.lg.slice(0, -2)
const COLLAPSE_DEBOUNCE_TIME = 200

function useMobileBreakpoint(): boolean {
  const [isMobile, setMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT)

  useEffect(() => {
    const checkMobileOnResize = _debounce(() => {
      const { innerWidth } = window

      if (innerWidth <= MOBILE_BREAKPOINT) setMobile(true)

      return true
    }, COLLAPSE_DEBOUNCE_TIME)

    window.addEventListener('resize', checkMobileOnResize)

    return () => {
      window.removeEventListener('resize', checkMobileOnResize)
    }
  }, [])

  return isMobile
}

export default useMobileBreakpoint
