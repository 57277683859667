import { useMemo, useState } from 'react'
import { debounce as _debounce, type DebouncedFunc } from 'lodash'
import axios from 'axios'

import { type IApp } from 'api/apps'
import { type APIError } from 'api/types'

import { getCurrentAppSection } from '../helpers/constantsHelpers'
import { Pages } from '../constants/routes'

export type ValidateErrorMsg =
  | 'This subdomain is already taken. Please provide an alternative subdomain.'
  | 'Failed to validate the app subdomain value.'
  | 'This subdomain is not available. Please provide an alternative subdomain.'
  | "Custom subdomains cannot include the term 'streamlit' or any similar term."
  | 'A subdomain only contain a-z, 0-9, and - characters. Please provide an alternative subdomain.'
  | 'Please provide a subdomain between 6 and 63 characters in length.'
  | string

export interface IUseValidateSubdomainResponse {
  // eslint-disable-next-line camelcase
  is_valid: boolean
  error?: ValidateErrorMsg
  success?: boolean
}

type doValidate = (subdomain: string) => Promise<void>

export interface IUseValidateSubdomain {
  pending: boolean
  isValid?: boolean
  doValidate: DebouncedFunc<doValidate>
  error?: APIError<ValidateErrorMsg>
}

export const getValidateSubdomainKey = ({ appId }: IApp): string => {
  if (getCurrentAppSection() === Pages.APP) {
    return '/app/subdomain/validate'
  }
  return `app/${appId}/subdomain/validate`
}

const useValidateSubdomain = (app: IApp): IUseValidateSubdomain => {
  const [error, setError] = useState<APIError | undefined>()
  const [isValid, setIsValid] = useState<boolean | undefined>()
  const [pending, setPending] = useState<boolean>(false)

  const doValidate = useMemo(
    () =>
      _debounce(async (subdomain: string) => {
        setPending(true)
        setIsValid(undefined)
        setError(undefined)

        try {
          const { data } = await axios.post<IUseValidateSubdomainResponse>(
            getValidateSubdomainKey(app),
            {
              subdomain,
            },
          )

          setIsValid(data.success)
        } catch (exc) {
          setIsValid(false)
          setError(exc as APIError<ValidateErrorMsg>)
        }

        setPending(false)
      }, 100),
    [app],
  )

  return {
    error,
    pending,
    doValidate,
    isValid,
  }
}

export default useValidateSubdomain
