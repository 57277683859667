import { createSlice, type PayloadAction, type Draft } from '@reduxjs/toolkit'

type RedirectURL = string
type Callback = () => void | Promise<void>

export interface State {
  url?: RedirectURL
  callback?: Callback
}

const initialState: State = {}

interface SetRedirectPayload {
  url: RedirectURL | undefined
  callback?: Callback
}

const slice = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    setRedirect: (
      state: Draft<State>,
      action: PayloadAction<SetRedirectPayload>,
    ) => {
      state.url = action.payload.url
      state.callback = action.payload.callback
    },
  },
})

export const { setRedirect } = slice.actions

export default slice
