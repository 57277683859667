import { type Task } from './task'

export interface TaskScheduler {
  scheduleAtFixedRate: (task: () => void, intervalSeconds: number) => Task
  scheduleBeforeExit: (task: () => void) => Task
}

export class BackgroundTaskScheduler implements TaskScheduler {
  scheduleAtFixedRate(task: () => void, intervalSeconds: number): Task {
    const interval = setInterval(task, intervalSeconds * 1000)
    return {
      cancel: () => {
        clearInterval(interval)
      },
    }
  }

  scheduleBeforeExit(task: () => void): Task {
    window.addEventListener('beforeunload', task)
    return {
      cancel: () => {
        window.removeEventListener('beforeunload', task)
      },
    }
  }
}
