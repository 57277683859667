// noinspection JSVoidFunctionReturnValueUsed

import { useDispatch, useSelector } from 'react-redux'
import { useLocalStorage } from 'react-use'
import { useEffect } from 'react'

import {
  OnboardingFlow,
  setStep,
  type StepName,
  stepNameSelector,
} from 'reducers/onboardingFlow'

function useOnBoardingFlowInStorage(): void {
  const dispatch = useDispatch()

  const [stepNameFromStorage, setStepToStorage] = useLocalStorage<StepName>(
    'onboardingFlow',
    OnboardingFlow.undefined,
  )
  const onboardingCurrentStep = useSelector(stepNameSelector)

  useEffect(() => {
    if (
      stepNameFromStorage === OnboardingFlow.pending ||
      stepNameFromStorage === OnboardingFlow.userDeleted ||
      stepNameFromStorage === OnboardingFlow.error
    ) {
      return
    }

    dispatch(
      setStep({
        name: stepNameFromStorage,
      }),
    )
  }, [dispatch, stepNameFromStorage])

  useEffect(() => {
    setStepToStorage(onboardingCurrentStep)
  }, [setStepToStorage, onboardingCurrentStep])
}

export default useOnBoardingFlowInStorage
