import { Children, type ReactElement } from 'react'

import { type TabBodyPartProps } from './tabBodyPart'

import styles from './tabBody.module.scss'

interface TabBodyProps {
  children:
    | ReactElement<TabBodyPartProps>
    | Array<ReactElement<TabBodyPartProps>>
}

export function Separator(): ReactElement {
  return <hr className={styles.separator} />
}

function TabBody({ children }: TabBodyProps): ReactElement {
  if (!children) {
    return <></>
  }
  const childrenCount = Children.count(children)
  let body: ReactElement | ReactElement[] = children
  if (childrenCount > 1) {
    body = Children.map(children, (child, index) => {
      const isLastElement = index === childrenCount - 1
      // The title + subTitle combination should be unique, so we are able to use it as the key
      return (
        <div key={`${child.props.title} ${child.props.subTitle ?? ''}`}>
          {child}
          {isLastElement ? null : <Separator />}
        </div>
      )
    })
  }

  return <div className={styles.root}>{body}</div>
}

export default TabBody
