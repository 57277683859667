import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSessionStorage } from 'react-use'

import {
  redirectUrlCallbackSelector,
  redirectUrlSelector,
} from 'reducers/redirect/selectors'

function useRedirectInSessionStorage(): void {
  const redirectUrlFromRedux = useSelector(redirectUrlSelector)
  const redirectUrlCallbackFromRedux = useSelector(redirectUrlCallbackSelector)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [redirectUrl, setRedirectUrl] = useSessionStorage<string | undefined>(
    'redirect',
  )

  // Syncing redux with sessionStorage
  useEffect(() => {
    setRedirectUrl(redirectUrlFromRedux)
  }, [redirectUrlFromRedux, setRedirectUrl])

  // Callback for redirects
  useEffect(() => {
    if (
      redirectUrlCallbackFromRedux == null ||
      redirectUrl == null ||
      redirectUrl === ''
    )
      return

    redirectUrlCallbackFromRedux()
  }, [redirectUrlCallbackFromRedux, redirectUrl])
}

export default useRedirectInSessionStorage
