import ENVS, { type CodeSplitEnvs, type IEnvs } from '../constants/envs'
import findKey from 'lodash/findKey'
import flatten from 'lodash/flatten'
import { wildcardMatchPattern } from './urls'
import { Pages } from '../constants/routes'

export function getCurrentEnv(): keyof IEnvs<string> {
  return findKey(ENVS, (urls) => {
    const allUrlsFromEnv = flatten(Object.values(urls))

    return allUrlsFromEnv.some((urlPattern) =>
      wildcardMatchPattern(urlPattern ?? '', window.location.hostname),
    )
  }) as keyof CodeSplitEnvs
}

export function isLocalEnv(): boolean {
  const currentEnv: keyof IEnvs<string> = getCurrentEnv()
  if (!currentEnv || !ENVS[currentEnv]) {
    return true
  }
  return (
    ENVS.local[Pages.DASHBOARD][0] === ENVS[getCurrentEnv()][Pages.DASHBOARD][0]
  )
}
