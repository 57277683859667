import { type ReactNode, type ReactElement } from 'react'
import { SWRConfig } from 'swr'
import axios, { type AxiosPromise } from 'axios'

export const fetcher = async (url: string): Promise<AxiosPromise> =>
  await axios(url).then(({ data }) => data)
interface Props {
  children: ReactNode
}

export default function APIProvider({ children }: Props): ReactElement {
  return <SWRConfig value={{ fetcher }}>{children}</SWRConfig>
}
