import React, { useContext } from 'react'
import { type AnalyticsClient } from '../api/analytics/analyticsClient'

export const AnalyticsClientContext =
  React.createContext<AnalyticsClient | null>(null)

export function useAnalyticsClientContext(): AnalyticsClient {
  const context = useContext(AnalyticsClientContext)

  if (!context) {
    throw new Error(
      'useAnalyticsClientContext must be used within a AnalyticsClientContext provider',
    )
  }

  return context
}
