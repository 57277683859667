/**
 * Function that sets a name to an anonymous function.
 * This is very handy to force a name for lazy loaded components
 * in order to test it and create snapshots with their names.
 *
 * @param    {string} name    Name to be set
 * @param    {any} fn         Given function to set the name
 * @return   {any}            Named function
 */
// eslint-disable-next-line
function nameFunction(name: string, fn: any): any {
  return Object.defineProperty(fn, 'name', { value: name })
}

export default nameFunction
