import { useState, type ReactElement, useCallback } from 'react'

import CloseButton from 'components/closeButton'

import styles from './styles.module.scss'

interface Props {
  title: string
  message: string
  hideClose?: boolean
}

function Alert({
  title,
  message,
  hideClose = false,
}: Props): ReactElement | null {
  const [visible, setVisible] = useState(true)

  const onClickClose = useCallback(() => {
    setVisible(false)
  }, [])

  if (!visible) {
    return null
  }

  return (
    <div className={styles.alertContainer} role="alert">
      <strong className={styles.title}>{title}</strong>
      <span className={styles.message}>{message}</span>
      {!hideClose && (
        <span className={styles.buttonContainer}>
          <CloseButton onClick={onClickClose} />
        </span>
      )}
    </div>
  )
}

export default Alert
