import { type ReactElement, useState, useEffect } from 'react'

import { copyCurrentUrlToClipboard } from 'helpers/clipboard'

import { ReactComponent as IconLink } from 'assets/svg/icon_link.svg'

import styles from 'modals/share/copyLinkButton.module.scss'

import LinkButton from 'components/linkButton'

export default function CopyLinkButton(): ReactElement {
  const [isUrlCopied, setUrlIsCopied] = useState<boolean | false>()
  let timeoutId: NodeJS.Timeout

  const copyUrlAndUpdateState = (): void => {
    copyCurrentUrlToClipboard()
    setUrlIsCopied(true)

    timeoutId = setTimeout(() => {
      setUrlIsCopied(false)
    }, 2000)
  }

  useEffect(() => () => {
    clearTimeout(timeoutId)
  })

  return (
    <LinkButton
      className={styles.copyLinkButton}
      onClick={() => {
        copyUrlAndUpdateState()
      }}
    >
      <>
        <IconLink className={styles.iconLink} />
        {isUrlCopied ? 'Copied!' : 'Copy link'}
      </>
    </LinkButton>
  )
}
