export const APP_STATUS = {
  UNKNOWN: 0,
  CREATING: 1,
  CREATED: 2,
  UPDATING: 3,
  INSTALLING: 4,
  RUNNING: 5,
  RESTARTING: 6,
  REBOOTING: 7,
  DELETING: 8,
  DELETED: 9,
  USER_ERROR: 10,
  PLATFORM_ERROR: 11,
  IS_SHUTDOWN: 12,
  INSTALLER_ERROR: 13,
  USER_SCRIPT_ERROR: 14,
  POTENTIAL_MINER_DETECTED: 15,
}

export type AppStatusValue = (typeof APP_STATUS)[keyof typeof APP_STATUS]

export const PLATFORM_STATUS = {
  STATUS_CLEAN: 0,
  RESOURCE_BUSY: 1,
}

export type PlatformStatusValue =
  (typeof PLATFORM_STATUS)[keyof typeof PLATFORM_STATUS]
