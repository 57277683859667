import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import axios, { type AxiosResponse } from 'axios'

import { toaster } from 'baseui/toast'

import { setRedirect } from 'reducers/redirect'
import { type APIError } from 'api/types'
import { extractErrorMessage } from 'api/utils'
import { isNonEmptyString } from 'helpers/validation'
import useUser from './user'

interface ILogoutResponse {
  success: boolean
  error?: string
  redirectUrl?: string
}

interface IUseLogout {
  pending: boolean
  success?: boolean
  error?: APIError
  doLogout: (sendToHomePage: boolean) => Promise<void>
}

export const getLogoutKey = (): string => 'logout'

export async function performLogout(): Promise<void> {
  await axios.post(getLogoutKey())
}

export const useLogout = (): IUseLogout => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [pending, setPending] = useState(false)
  const [success, setSuccess] = useState<boolean>()
  const [error, setError] = useState<APIError | undefined>()
  const { setUser } = useUser()

  useEffect(() => {
    if (success !== true) return

    setUser()
  }, [success, setUser])

  useEffect(() => {
    if (error == null) return

    toaster.show(extractErrorMessage(error), { kind: 'negative' })
  }, [error])

  const doLogout = async (sendToHomePage: boolean): Promise<void> => {
    setPending(true)
    try {
      const { data }: AxiosResponse<ILogoutResponse> =
        await axios.post(getLogoutKey())

      if (isNonEmptyString(data.redirectUrl)) {
        const url = data.redirectUrl // capture the value for the callback closure
        const callback = (): void => {
          window.location.href = url
        }

        const redirectUrl = sendToHomePage
          ? '/'
          : location.pathname + location.search

        dispatch(
          setRedirect({
            callback,
            url: redirectUrl,
          }),
        )
      } else {
        setSuccess(false)
        setPending(false)
      }

      setSuccess(data.success)
    } catch (exc) {
      setSuccess(false)
      setError(exc as APIError)
      setPending(false)
    }
  }

  return {
    pending,
    success,
    error,
    doLogout,
  }
}
