import { type ReactElement } from 'react'

import {
  Checkbox,
  type CheckboxOverrides,
  type CheckboxProps,
  STYLE_TYPE,
} from 'baseui/checkbox'

import { type Theme } from 'baseui/theme'

import { theme } from 'constants/tailwind.config'

const borderRadius = '0.5rem'

export const toggleOverrides: CheckboxOverrides = {
  Toggle: {
    style: ({ $theme, $checked }: { $theme: Theme; $checked: boolean }) => ({
      backgroundColor: $theme.colors.white,
      height: '0.625rem',
      margin: '0.125rem',
      transform: $checked ? 'translateX(140%)' : 'none',
      width: '0.625rem',
    }),
  },
  ToggleInner: {
    style: {
      display: 'none',
    },
  },
  ToggleTrack: {
    style: ({
      $theme,
      $checked,
      $disabled,
    }: {
      $theme: Theme
      $checked: boolean
      $disabled: boolean
    }) => {
      let backgroundColor = $checked
        ? $theme.colors.accent600
        : $theme.colors.primary300

      if ($disabled) {
        backgroundColor = theme.colors.black['600']
      }

      return {
        backgroundColor,
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
        borderBottomLeftRadius: borderRadius,
        height: '0.875rem',
        width: '1.75rem',
      }
    },
  },
}

export default function Toggle({
  children,
  ...rest
}: CheckboxProps): ReactElement {
  return (
    <Checkbox
      checkmarkType={STYLE_TYPE.toggle_round}
      overrides={toggleOverrides}
      {...rest}
    >
      {children}
    </Checkbox>
  )
}
