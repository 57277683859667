import { memo, type ReactElement, useCallback, useEffect } from 'react'
import { useDispatch as useReduxDispatch } from 'react-redux'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { datadogLogs } from '@datadog/browser-logs'

import { type IApp } from 'api/apps'
import { type IUser, type IWorkspace } from 'api/user'
import { useLogout } from 'api/logout'
import useAppStatus from 'api/appStatus'
import useMobileBreakpoint from 'hooks/useMobileBreakpoint'
import useGithubRepository from 'hooks/useGithubRepository'
import useStreamlitAppContext, {
  type DispatchFn,
  type State as AppContextState,
} from 'context/streamlitAppContext'

import NotFoundPage from 'pages/notFound'
import Terminal from 'components/terminal'
import PageSpinner from 'components/pageSpinner'
import ViewerBadge from 'components/viewerBadge'

import ConfirmationModal from 'modals/confirmationModal'
import DeleteAppModal from 'modals/deleteApp'
import RestartAppModal from 'modals/restartApp'

import AnalyticsModal from 'modals/analytics'
import AppSettingsModal, { MenuItemId } from 'modals/appSettings/appSettings'

import eventIds from 'constants/eventIds'

import { select as selectWorkspaceAction } from 'reducers/workspace'

import useAppState from 'pages/streamlitApp/common/useAppState'
import { type AppState } from 'pages/streamlitApp/common/appStateMachine'
import { type IsInStateFn } from 'pages/streamlitApp/common/helpers'

import { generateAppURL } from 'helpers/constantsHelpers'
import { Pages, workspaceQueryParameterName } from 'constants/routes'
import { useWorkspaceContext } from 'context/workspaceContext'

import {
  DeployingState,
  ErrorState,
  ResourceBusyState,
  SuccessState,
  SuspendedState,
} from 'pages/streamlitApp/states'

import styles from 'pages/streamlitApp/styles.module.scss'
import { isSubdomainApp } from '../../../hooks/useUniqueSubdomainFeatures'

interface AppScreenProps {
  app: IApp
  appContext: AppContextState
  dispatch: DispatchFn
  isOwner: boolean
  appState: AppState
  isInState: IsInStateFn
  isPrivate: boolean
  streamlitVersion?: string
}

// The reason this is not a component is because we want to see the output of this function in our
// shallow tests. This way we test *all* app states together, including the states that get drawn in
// the AppLoader component.
function getAppScreen({
  app,
  appContext,
  dispatch,
  isOwner,
  appState,
  isInState,
  isPrivate,
  streamlitVersion,
}: AppScreenProps): ReactElement {
  if (isInState('INITIAL')) {
    return <PageSpinner />
  }

  if (isInState('AMBIGUOUS_UPDATING')) {
    return <PageSpinner message="App is updating" />
  }

  if (isInState('BOOTING')) {
    return isOwner ? (
      <DeployingState />
    ) : (
      <PageSpinner message="App is booting" />
    )
  }

  if (isInState('REBOOTING')) {
    return isOwner ? (
      <DeployingState />
    ) : (
      <PageSpinner message="App is updating" />
    )
  }

  /**
   * If the user is the owner, we want DELETED to map to SuccessState so while we navigate the user
   * to the dashboard we don't flicker the 404 page.
   *
   * This means that if the app owner has another tab open pointing to the app, that other tab
   * will stay as-is, and show the app as "disconnected". That UX is fine as a trade-off.
   */
  if (isInState('RUNNING') || (isInState('DELETED') && isOwner)) {
    return (
      <>
        {!isOwner && <ViewerBadge />}
        <SuccessState
          app={app}
          isOwner={isOwner}
          className={styles.iframe}
          showBalloons={appContext.showBalloons && isInState('RUNNING')}
          onAnimationEnd={() => {
            dispatch({ type: 'END_DEPLOY' })
          }}
          isPrivate={isPrivate}
        />
      </>
    )
  }

  if (isInState('ERROR')) {
    return (
      <ErrorState
        app={app}
        isOwner={isOwner}
        details={appState?.context?.error}
      />
    )
  }

  if (isInState('RESOURCE_BUSY')) {
    return (
      <ResourceBusyState
        app={app}
        isOwner={isOwner}
        isPrivateRepo={isPrivate}
        streamlitVersion={streamlitVersion}
      />
    )
  }

  if (isInState('SUSPENDED')) {
    return <SuspendedState app={app} isOwner={isOwner} />
  }

  // NOT_FOUND is handled outside getAppScreen.

  return (
    <ErrorState
      app={app}
      isOwner={isOwner}
      details={`Encountered an unknown app state: ${appState?.value as string}`}
    />
  )
}

interface AppLoaderProps {
  app: IApp
  user?: IUser
  workspaces?: IWorkspace[]
}

function AppLoader({ app, user, workspaces }: AppLoaderProps): ReactElement {
  const navigate = useNavigate()
  const appOnSubdomain = isSubdomainApp()
  const { doLogout } = useLogout()
  const isMobileBreakpoint = useMobileBreakpoint()
  const { state: appContext, dispatch } = useStreamlitAppContext()
  const workspaceContext = useWorkspaceContext()
  const {
    isOwner,
    isPending: statusIsPending,
    platformStatus,
    status,
    streamlitVersion,
    viewerAuthEnabled,
  } = useAppStatus(app.appId)
  const { appState, isInState } = useAppState(app)
  const { repository } = useGithubRepository(app.owner, app.repo)
  const reduxDispatch = useReduxDispatch()

  useEffect(() => {
    if (isMobileBreakpoint) {
      dispatch({
        type: 'HIDE_SIDEBAR',
      })
    }
  }, [isMobileBreakpoint, dispatch])

  useEffect(() => {
    const workspaceNames = workspaces?.map((w) => w.name)

    if (workspaceNames?.includes(app.owner)) {
      reduxDispatch(
        selectWorkspaceAction({
          workspace: app.owner,
        }),
      )
    }
  }, [app.owner, reduxDispatch, workspaces])

  const toggleCollapse = (): void => {
    if (appContext.showSidebar) {
      dispatch({
        type: 'HIDE_SIDEBAR',
      })
    } else {
      dispatch({
        type: 'SHOW_SIDEBAR',
      })
    }
  }

  // For SLIs, we want to track the successful completion of the deployment operation
  // We consider a deployment successful if the user will see the balloons after clicking deploy
  // If another user is the first to see an app that has been deployed by somebody else
  // or if the user abandons the screen before the deployment completes, we consider that a failure
  useEffect(() => {
    if (appContext.showBalloons && !statusIsPending && isInState('RUNNING')) {
      datadogLogs.logger.info('Completed triggering deployment', {
        event_id: eventIds.CompleteDeploy,
        app_status: status,
        platform_status: platformStatus,
      })
    }
  }, [
    appContext.showBalloons,
    statusIsPending,
    isInState,
    status,
    platformStatus,
  ])

  const onDeleteCallback = useCallback(() => {
    if (appOnSubdomain) {
      const dashboardURL = generateAppURL({
        section: Pages.DASHBOARD,
        searchParams: new URLSearchParams(
          `${workspaceQueryParameterName}=${workspaceContext.Name() || ''}`,
        ),
      })

      window.location.href = dashboardURL
    } else {
      navigate('/')
    }
  }, [appOnSubdomain, workspaceContext, navigate])

  // Show spinner for transient states.
  if (statusIsPending || isInState(undefined)) {
    return <PageSpinner overlay />
  }

  if (isInState('NOT_FOUND') || (isInState('DELETED') && !isOwner)) {
    return <NotFoundPage />
  }

  return (
    <div className={styles.streamlitAppContainer}>
      <DeleteAppModal onDelete={onDeleteCallback} />
      <AppSettingsModal hideTab={[MenuItemId.General]} />
      <AnalyticsModal />
      <ConfirmationModal />
      <RestartAppModal />

      <div
        className={classNames(styles.stateContainer, {
          [styles.withTerminal]: user && isOwner && appContext.showSidebar,
        })}
      >
        {getAppScreen({
          app,
          appContext,
          dispatch,
          isOwner,
          appState,
          isInState,
          isPrivate: repository ? repository.isPrivate : true,
          streamlitVersion,
        })}

        {user && isOwner && (
          <Terminal
            user={user}
            app={app}
            appState={appState}
            doLogout={async () => {
              await doLogout(viewerAuthEnabled)
            }}
            hasError={isInState('ERROR')}
            className={styles.terminal}
            toggleCollapse={toggleCollapse}
            isCollapsed={!appContext.showSidebar}
            viewerAuthEnabled={viewerAuthEnabled}
          />
        )}
      </div>
    </div>
  )
}

export default memo(AppLoader)
