import { getGithubApiClient } from '../api/apiV2'
import { type GithubRepositoryModel } from '../gen'
import { type AxiosResponse } from 'axios'

async function queryGithubRepository(
  owner: string,
  repository: string,
): Promise<AxiosResponse<GithubRepositoryModel>> {
  return await getGithubApiClient().queryRepository(owner, repository)
}

export default queryGithubRepository
