import { type ElementType, type ReactElement } from 'react'
import { Link } from 'react-router-dom'

import urls from 'constants/urls'
import { Pages, Routes, workspaceQueryParameterName } from 'constants/routes'

import { type IUser } from 'api/user'

import PopoverMenu, { type Menu } from 'components/popoverMenu/popoverMenu'

import { generateAppURL } from 'helpers/constantsHelpers'

import { ReactComponent as IconRebuild } from 'assets/svg/icon_refresh.svg'
import { ReactComponent as IconDelete } from 'assets/svg/icon_delete.svg'
import { ReactComponent as IconGear } from 'assets/svg/icon_gear.svg'
import { ReactComponent as IconDashboard } from 'assets/svg/icon_dashboard.svg'
import { ReactComponent as IconDownload } from 'assets/svg/icon_download.svg'
import { ReactComponent as IconSignOut } from 'assets/svg/icon_sign_out.svg'
import { ReactComponent as IconSupport } from 'assets/svg/icon_support.svg'
import { ReactComponent as IconDocumentation } from 'assets/svg/icon_document.svg'
import { ReactComponent as IconAnalytics } from 'assets/svg/icon_analytics.svg'

import { useWorkspaceContext } from 'context/workspaceContext'

import styles from './styles.module.scss'
import { isSubdomainApp } from '../../hooks/useUniqueSubdomainFeatures'

interface Props {
  user?: IUser
  onLogout?: () => void
  onRebuild: () => void
  onDelete: () => void
  onDownloadLog: () => void
  openAnalytics: () => void
  openSettings: () => void
}

function TerminalMenu({
  user,
  onLogout,
  onDelete,
  onRebuild,
  onDownloadLog,
  openAnalytics,
  openSettings,
}: Props): ReactElement {
  const appOnSubdomain = isSubdomainApp()
  const workspaceContext = useWorkspaceContext()

  const menu: Menu[] = [
    {
      items: [
        {
          Icon: IconDownload,
          label: 'Download log',
          buttonProps: {
            onClick: onDownloadLog,
            className: styles.button,
          },
        },
        {
          Icon: IconAnalytics,
          label: 'Analytics',
          buttonProps: {
            onClick: openAnalytics,
            className: styles.button,
          },
        },
        {
          Icon: IconRebuild,
          label: 'Reboot app',
          buttonProps: {
            onClick: onRebuild,
            className: styles.button,
            'data-testid': 'restart-app-button',
          },
        },
        {
          Icon: IconDelete,
          label: 'Delete app',
          buttonProps: {
            onClick: onDelete,
            className: styles.button,
            'data-testid': 'delete-app-button',
          },
        },
        {
          Icon: IconGear,
          label: 'Settings',
          buttonProps: {
            onClick: openSettings,
            className: styles.button,
            'data-testid': 'settings-app-button',
          },
        },
      ],
    },
    {
      items: [
        appOnSubdomain
          ? {
              Icon: IconDashboard,
              label: 'Your apps',
              Component: 'a' as ElementType,
              buttonProps: {
                className: styles.button,
                href: generateAppURL({
                  section: Pages.DASHBOARD,
                  searchParams: new URLSearchParams(
                    `${workspaceQueryParameterName}=${workspaceContext.Name() || ''}`,
                  ),
                }),
              },
            }
          : {
              Icon: IconDashboard,
              label: 'Your apps',
              Component: Link,
              buttonProps: {
                className: styles.button,

                to: Routes.DASHBOARD,
              },
            },
      ],
    },
    {
      items: [
        {
          Icon: IconDocumentation,
          Component: 'a' as ElementType,
          label: 'Documentation',
          buttonProps: {
            className: styles.button,
            href: 'https://docs.streamlit.io',
            target: '_blank',
            rel: 'noreferrer',
          },
        },
        {
          Icon: IconSupport,
          Component: 'a' as ElementType,
          label: 'Support',
          buttonProps: {
            className: styles.button,
            href: urls.snowflakeSupportLink,
            target: '_blank',
            rel: 'noreferrer',
          },
        },
      ],
    },
  ]

  // Only show sign out on legacy app viewing page
  if (!appOnSubdomain && user && onLogout) {
    menu.push({
      items: [
        {
          Icon: IconSignOut,
          buttonProps: {
            onClick: onLogout,
            className: styles.signOut,
          },
          label: (
            <p>
              <span>Sign out</span>
              <span className={styles.username}>{user.login}</span>
            </p>
          ),
        },
      ],
    })
  }

  return <PopoverMenu theme="dark" placement="topRight" menus={menu} />
}

export default TerminalMenu
