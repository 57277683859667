import { cloneElement, type ReactElement } from 'react'

import classnames from 'classnames'

import { ReactComponent as IconWarning } from 'assets/svg/icon_warning_rounded.svg'

import Button, { type Props } from 'components/button/button'

import styles from './warningBox.module.scss'

interface ActionWithOnClick {
  text: string
  onClick: () => void
  button?: never
}

interface ActionWithButton {
  text?: never
  onClick?: never
  button: ReactElement<Props>
}

export type Action = ActionWithOnClick | ActionWithButton

export interface WarningBoxProps {
  title?: string | ReactElement
  icon?: ReactElement
  description: string | ReactElement
  actions?: Action[]
}

function WarningBox({
  icon,
  title,
  description,
  actions,
}: WarningBoxProps): ReactElement {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {icon || <IconWarning />}
        <div className={styles.title}>{title || 'Warning'}</div>
      </div>
      <div>{description}</div>
      {actions && actions.length > 0 && (
        <div className={styles.actionButtonGroup}>
          {actions?.map((action: Action) => {
            if (action.onClick) {
              return (
                <Button
                  key={action.text}
                  className={styles.actionButton}
                  onClick={action.onClick}
                >
                  {action.text}
                </Button>
              )
            }

            return (
              <div key={action?.button?.props?.children?.toString()}>
                {cloneElement(action.button, {
                  className: classnames(
                    styles.actionButton,
                    action.button.props.className,
                  ),
                })}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default WarningBox
