import { type ReactElement, useEffect, useRef } from 'react'
import { type FormikErrors, useField, useFormikContext } from 'formik'
import { Textarea } from 'baseui/textarea'
import { type BaseInputOverrides, type SharedProps } from 'baseui/input'

import Field from 'components/field'

import { type FormErrors } from './sharingSettings'

import styles from './sharingSettings.module.scss'

const PLACEHOLDER = 'example1.com\nexample2.com'

const textAreaOverrides: BaseInputOverrides<SharedProps> = {
  Input: {
    style: {
      maxHeight: '30em',
      minHeight: '5em',
      height: '7em',
      resize: 'vertical',
      fontFamily: 'IBM Plex Mono, monospace',
      fontSize: '1rem',
    },
  },
  InputContainer: {
    style: {
      borderRadius: '4px',
    },
  },
}

interface Props {
  label?: string
  disabled?: boolean
}

function DomainsField({ label, disabled }: Props): ReactElement | null {
  const { errors, validateForm } = useFormikContext<FormErrors>()
  const [field] = useField('rawDomains')
  const inputRef = useRef<HTMLTextAreaElement>(null)

  // click handler for better validation UX
  useEffect(() => {
    if (inputRef.current === null) return undefined
    const textArea = inputRef.current
    const handleClick = (): void => {
      const asyncCall = async (): Promise<FormikErrors<FormErrors>> =>
        await validateForm()

      asyncCall()
    }

    textArea.addEventListener('click', handleClick, true)

    return () => {
      textArea.removeEventListener('click', handleClick)
    }
  }, [inputRef, validateForm])

  return (
    <Field label={label} {...field}>
      <Textarea
        {...field}
        placeholder={PLACEHOLDER}
        overrides={textAreaOverrides}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            validateForm()
          }
        }}
        inputRef={inputRef}
        disabled={disabled}
      />
      {errors.rawDomains && (
        <div className={styles.fieldErrorMessage}>
          {errors.rawDomains.split('\n').map((domain, index) => (
            // eslint-disable-next-line
            <p key={index}>{domain}</p>
          ))}
        </div>
      )}
    </Field>
  )
}

export default DomainsField
