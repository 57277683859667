import { pathToRegexp } from 'path-to-regexp'
import { isNonEmptyString } from './validation'

export interface RepoCoordinates {
  owner: string
  repo: string
}

export interface FileCoordinates extends RepoCoordinates {
  branch: string
  mainModule: string
}

const FILE_PATH_PART_RE_ARR = [
  pathToRegexp('github.com/:owner/:repo/blob/:branch/:mainModule+'),
  pathToRegexp('raw.githubusercontent.com/:owner/:repo/:branch/:mainModule+'),
]

export function isGithubRepoUrl(
  url: string,
): { owner: string; repo: string } | undefined {
  const githubUrl = url.trim().replace(/^https?:\/\//, '')
  const regex = pathToRegexp('github.com/:owner/:repo')
  const results = regex.exec(githubUrl)

  if (!results || results.length === 0) {
    return undefined
  }

  return {
    owner: escape(results?.[1] ?? ''),
    repo: escape(results?.[2] ?? ''),
  }
}

export function parseGithubFileUrl(url: string): FileCoordinates | undefined {
  const githubUrl = url.trim().replace(/^https?:\/\//, '')

  const results = FILE_PATH_PART_RE_ARR.map((regex) =>
    regex.exec(githubUrl),
  ).filter((res) => res != null)

  if (results.length === 0) {
    return undefined
  }
  const result = results[0]

  return {
    owner: escape(result?.[1] ?? ''),
    repo: escape(result?.[2] ?? ''),
    branch: escape(result?.[3] ?? ''),
    mainModule: escape(result?.[4] ?? ''),
  }
}

/**
 * Check if the given url follows the url pattern which could include
 * a wildcard.
 */
export function wildcardMatchPattern(
  pattern: string,
  hostname: string,
): boolean {
  if (pattern === hostname) return true

  const splitPattern = pattern.split('.')
  const splitHostname = hostname.split('.')

  if (splitPattern.length !== splitHostname.length) return false

  return splitPattern.every((el, index) => {
    if (el === '*') {
      return true
    }

    return el === splitHostname[index]
  })
}

export function canGenerateInitialSubdomainURL(
  repositoryName?: string,
): boolean {
  /**
   * Checks if initial subdomain URL can be generated, by
   *  1. ensuring repositoryName is defined
   *  2. ensuring there is at least one character behind '/'
   */
  if (
    !isNonEmptyString(repositoryName) ||
    repositoryName?.toString().lastIndexOf('/') === -1
  ) {
    return false
  }

  // repositoryName has to be converted toString, to ensure backwards compatibility
  const idx = repositoryName.toString().lastIndexOf('/')
  const name = repositoryName
    .toString()
    .slice(idx + 1)
    .replace(/[^a-zA-Z0-9-]/g, '')

  return name.length > 0
}

export function getRandomSuffix(): string {
  return Math.random().toString(36).slice(2).replace('qa', 'app')
}

export function generateInitialSubdomainURL(
  repositoryName: string,
  suffix?: string,
): string {
  const idx = repositoryName.lastIndexOf('/')
  if (!suffix) {
    suffix = getRandomSuffix()
  }

  const name = repositoryName
    .slice(idx + 1)
    .replace('streamlit', 'app')
    .replace('qa', 'app')
    .replace(/[^a-zA-Z0-9-]/g, '')

  return `${name}-${suffix}`.toLowerCase()
}

export function getCoordinatesUrl({
  owner,
  repo,
  branch,
  mainModule,
}: FileCoordinates): string {
  return `app/${owner}/${repo}/${encodeURIComponent(branch)}/${encodeURIComponent(mainModule)}`
}
