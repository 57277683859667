import { type ReactElement } from 'react'

import { type IUser } from 'api/user'

import { DeleteAccountTab } from './DeleteAccountTab'

interface IDeleteAccountProps {
  maybeUser?: IUser
}
export function DeleteAccount({
  maybeUser,
}: IDeleteAccountProps): ReactElement {
  /**
   * We're not sure if user is defined here,
   * we need both user and his email or ssoEmail to display deleteAccount operation.
   * This component ensures user and user.email or user.ssoEmail are defined,
   * returns DeleteAccountTab if so, otherwise it returns empty react component <></>.
   */
  if (!maybeUser) {
    return <></>
  }
  const maybeEmail = maybeUser.ssoEmail ? maybeUser.ssoEmail : maybeUser.email
  if (!maybeEmail) {
    return <></>
  }

  // We're sure email is defined, proceed...
  return <DeleteAccountTab email={maybeEmail} maybeLogin={maybeUser.login} />
}
