import { useState, useEffect } from 'react'
import isEqual from 'lodash/isEqual'

import useLogs, { type ILogLine } from 'hooks/useLogs'
import { type AppState } from 'pages/streamlitApp/common/appStateMachine'
import { type IApp } from 'api/apps'

export const FRONTEND_MESSAGE_DELAY_MS = 5000

interface ClientLog {
  important: boolean
  msg: string
}

const FUNNY_LOGS = [
  { important: false, msg: '🎈 Inflating balloons...' },
  { important: false, msg: '📖 Unpacking Comic Sans RAR files...' },
  { important: false, msg: '🚧 Loading "Under construction" GIF...' },
  { important: false, msg: '🛠 Compiling <blink> tags...' },
  { important: false, msg: '📠 Initializing Java applet...' },
  { important: false, msg: '⏳ Please wait...' },
]

// any server logs that we want to suppress as they leak internal details
// or aren't applicable to the managed environment
const SUPPRESSED_LOGS = [
  / {2}You can now view your Streamlit app in your browser\./,
  / {2}(Network |External |Local )?URL:/,
]

const FRONTEND_LOGS: Record<string, ClientLog[]> = {
  BOOTING: [
    { important: true, msg: '🖥 Provisioning machine...' },
    { important: true, msg: '🎛 Preparing system...' },
    { important: true, msg: '⛓ Spinning up manager process...' },
  ].concat(FUNNY_LOGS),
  REBOOTING: [
    { important: true, msg: '🔌 Disconnecting...' },
    { important: true, msg: '🖥 Provisioning machine...' },
    { important: true, msg: '🎛 Preparing system...' },
    { important: true, msg: '⛓ Spinning up manager process...' },
  ].concat(FUNNY_LOGS),
  RUNNING: [],
  DELETED: [{ important: true, msg: '🗑️ Deleting app...' }],
  AMBIGUOUS_UPDATING: [
    { important: true, msg: '🐙 Detected source code change upstream...' },
  ],
}

const ANSI_YELLOW_CODE = '\x1b[33m'
const ANSI_RESET_CODE = '\x1b[0m'

export function getNextLog(
  currState: string,
  lastProcessedState: string,
  pendingClientLogs: ClientLog[],
  lastClientLogTimestamp: number,
  haveNewRemoteLogs: boolean,
): [ClientLog[], ClientLog[]] {
  let appendNow: ClientLog[] = []
  let appendLater: ClientLog[] = []

  // If there are new remote logs, append all important pending client logs immediately
  if (haveNewRemoteLogs) {
    appendNow = pendingClientLogs.filter((log) => log.important)
  } else {
    appendLater = Array.from(pendingClientLogs)
  }

  if (currState !== lastProcessedState) {
    // Insert all pending lines immediately.
    appendNow = appendNow.concat(appendLater.filter((log) => log.important))

    // Grab lines to insert for the new state.
    appendLater = Array.from(FRONTEND_LOGS[currState] ?? [])

    // Insert first line immediately.
    if (appendLater.length > 0) {
      appendNow.push(appendLater.shift() as ClientLog)
    }
    return [appendNow, appendLater]
  }

  if (Date.now() - lastClientLogTimestamp < FRONTEND_MESSAGE_DELAY_MS) {
    return [appendNow, appendLater]
  }

  if (appendLater.length === 0) {
    return [appendNow, appendLater]
  }

  appendNow.push(appendLater.shift() as ClientLog)
  return [appendNow, appendLater]
}

// This hook inserts some fake client-generated logs into the server-generated log stream. The
// client-side logs come in when there are FSM state transitions, and some states have several
// client-side logs that animate in little by little, every X seconds.
export default function useEmbellishedLogs(
  app: IApp,
  appState: AppState,
): ILogLine[] {
  const usedLogs = useLogs(app)

  const logs = usedLogs.logs.filter(
    (log) => !SUPPRESSED_LOGS.some((regex) => regex.test(log.Text)),
  )

  // Keep track of what the FSM state was last time we inserted logs, so we only insert logs when
  // there's a state transition.
  const [lastProcessedState, setLastProcessedState] = useState<string>('')

  // Keep a a list of logs that we want to slowly insert into the log stream for the current FSM
  // state.
  const [pendingClientLogs, setPendingClientLogs] = useState<ClientLog[]>([])

  // Keep track of how many logs came in from the server side after we injected the last
  // client-generated message. We don't want to intersperse client-side messages with server-side
  // messages, and this lets us decide to just dump all remaining client-side messages into
  // the console when a server-side one comes in. (By the way, that very first server-side message
  // *does* come in "interspersed" right now, but that's because a perfect solution would require a
  // lot more work, and this is kind of a small detail...)
  const [numLogsAfterAppend, setNumLogsAfterAppend] = useState(logs.length)

  // Keep track of how long ago we injected the previous client-generated message. This is used so
  // we can show messages every X seconds. For that, we need to keep track of how long ago we showed
  // the previous message.
  const [lastClientLogTimestamp, setLastClientLogTimestamp] = useState(
    Date.now(),
  )

  // This is a dummy variable used to manually cause this hook to rerun based on a timer, to
  // guarantee that the scheduled logs come in at the correct time.
  const [, setTrigger] = useState(true)

  const currState = appState.value as string

  const [appendNow, appendLater] = getNextLog(
    currState,
    lastProcessedState,
    pendingClientLogs,
    lastClientLogTimestamp,
    logs.length > numLogsAfterAppend,
  )

  if (currState !== lastProcessedState) {
    setLastProcessedState(currState)
  }

  if (logs.length === 0) {
    usedLogs.appendLog(
      // Uses special whitespace characters to have the same length as the timestamp tags and a centered UTC string
      `${ANSI_YELLOW_CODE}[ \u2009\u2009\u2005\u2005UTC\u2005\u2005\u2009\u2009 ]${ANSI_RESET_CODE} Logs for ${
        window.location.host + window.location.pathname
      }`,
    )
    // Add divider line
    usedLogs.appendLog(
      '────────────────────────────────────────────────────────────────────────────────────────',
    )
  }

  if (appendNow.length > 0) {
    setNumLogsAfterAppend(appendNow.length + logs.length)
    setLastClientLogTimestamp(Date.now())
    // This uses a hacky way to extract the time from the UTC string
    // since there is no native date formattor for the Date class.
    appendNow.forEach((log) => {
      usedLogs.appendLog(
        `${ANSI_YELLOW_CODE}[${new Date()
          .toUTCString()
          .substring(17, 25)}]${ANSI_RESET_CODE} ${log.msg}`,
      )
    })
  }

  const shouldScheduleLogs = !isEqual(appendLater, pendingClientLogs)

  if (shouldScheduleLogs) {
    setPendingClientLogs(appendLater)
  }

  useEffect((): undefined | (() => void) => {
    if (!shouldScheduleLogs) {
      return undefined
    }

    const tid = window.setTimeout(() => {
      setTrigger(true)
    }, FRONTEND_MESSAGE_DELAY_MS)
    return (): void => {
      window.clearTimeout(tid)
    }
  }, [appendLater, shouldScheduleLogs, setPendingClientLogs, setTrigger])

  return logs
}
