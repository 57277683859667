import { type AppSections, Pages } from './routes'

export interface IEnvs<T> {
  local: T
  head: T
  staging: T
  prod: T
  demo: T
}

type UrlsPattern = string[]
export type CodeSplitEnvs = IEnvs<AppSections<UrlsPattern>>

// NOTE: the first domain in the `Pages.APP` array is the prioritized
// entry and is used when generating an app's URL
export const envs: CodeSplitEnvs = {
  local: {
    [Pages.DASHBOARD]: ['devel.streamlit.test'],
    [Pages.APP]: [
      '*.streamlit.apptest',
      '*.streamlitapp.test',
      '*.streamlit.test',
      '*.localhost',
    ],
  },
  demo: {
    [Pages.DASHBOARD]: ['share-demo.streamlit.io'],
    [Pages.APP]: [
      '*.demo.streamlit.app',
      '*.demo.streamlit.run',
      '*.demo.streamlitapp.com',
    ],
  },
  head: {
    [Pages.DASHBOARD]: ['share-head.streamlit.io'],
    [Pages.APP]: [
      '*.head.streamlit.app',
      '*.head.streamlit.run',
      '*.head.streamlitapp.com',
    ],
  },
  staging: {
    [Pages.DASHBOARD]: ['share-staging.streamlit.io'],
    [Pages.APP]: [
      '*.staging.streamlit.app',
      '*.staging.streamlit.run',
      '*.staging.streamlitapp.com',
    ],
  },
  prod: {
    [Pages.DASHBOARD]: ['share.streamlit.io'],
    [Pages.APP]: ['*.streamlit.app', '*.streamlitapp.com', '*.streamlit.run'],
  },
}

export default envs
