import { type ReactElement, useEffect, memo, type ReactNode } from 'react'
import { type AxiosError } from 'axios'
import { useDispatch } from 'react-redux'

import { enqueue } from 'reducers/analytics'

import urls from 'constants/urls'
import { APP_STATUS } from 'constants/apps'

import { type IApp } from 'api/apps'
import useAppStatus from 'api/appStatus'

import Page from 'components/page'
import { StyledLink } from 'components/link'
import ErrorDetails from 'components/errorDetails'

import { ReactComponent as FrowningFace } from 'assets/svg/emoji_u1f626.svg'

import styles from './styles.module.scss'
import type { APIError } from '../../../../api/types'

interface Props {
  app: IApp
  isOwner: boolean
  details?: string | AxiosError
}

const OWNER_MESSAGE = (
  <>
    If you need help, try the{' '}
    <StyledLink external to={urls.documentation}>
      Streamlit docs
    </StyledLink>{' '}
    and{' '}
    <StyledLink external to={urls.forum}>
      forums
    </StyledLink>
    .
  </>
)

function getViewerMessage(app: IApp): ReactNode {
  const issueTracker = `https://github.com/${app.owner}/${app.repo}/issues/new`

  return (
    <>
      If this keeps happening, please{' '}
      <StyledLink external to={issueTracker}>
        contact support
      </StyledLink>
      .
    </>
  )
}

function ErrorState({ app, isOwner, details }: Props): ReactElement {
  const message = isOwner ? OWNER_MESSAGE : getViewerMessage(app)
  const st = useAppStatus(app.appId)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      enqueue({
        name: 'app_error',
        properties: {
          kind: st.status,
        },
      }),
    )
  }, [dispatch, st.status])

  // @todo We should move this to a different State to be handled by the state machine
  if (isOwner && st.status === APP_STATUS.INSTALLER_ERROR) {
    return (
      <Page header={false} className={styles.errorContainer}>
        <FrowningFace className={styles.frowningFaceEmoji} />
        <h1>Error installing requirements.</h1>
        <p>
          Click &quot;Manage App&quot; and consult the terminal for more
          details.
        </p>
        <p>
          If you still have questions, leave a message in
          <StyledLink external to={urls.forum}>
            {' '}
            our forums
          </StyledLink>{' '}
          and we will get back to you ASAP.
        </p>
      </Page>
    )
  }
  return (
    <Page header={false} className={styles.errorContainer}>
      <FrowningFace className={styles.frowningFaceEmoji} />
      <h1>Oh no.</h1>
      <p>Error running app. {message}</p>
      <ErrorDetails details={details as undefined | string | APIError} />
    </Page>
  )
}

export default memo(ErrorState)
