import _noop from 'lodash/noop'
import { type ReactElement, memo, useCallback } from 'react'
import { KIND as ButtonKind } from 'baseui/button'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal'

import { type ConfirmationPayload } from 'reducers/modal'

import Modal from 'components/modal'

import styles from './styles.module.scss'

interface ModalProps {
  onClose?: () => void
}

interface BodyProps extends ConfirmationPayload {
  onClose: () => void
}

export const InnerBody = memo(function InnerBody({
  title,
  message,
  onClose,
  onConfirm,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
}: BodyProps): ReactElement {
  const onClickConfirm = useCallback(() => {
    onConfirm()
    onClose()
  }, [onClose, onConfirm])

  return (
    <>
      <div className={styles.TitleContainer}>
        <ModalHeader>{title}</ModalHeader>
      </div>
      <ModalBody>{message}</ModalBody>
      <div className={styles.ButtonsContainer}>
        <ModalFooter>
          <ModalButton kind={ButtonKind.tertiary} onClick={onClose}>
            {cancelButtonText}
          </ModalButton>
          <ModalButton onClick={onClickConfirm}>
            {confirmButtonText}
          </ModalButton>
        </ModalFooter>
      </div>
    </>
  )
})

function ConfirmationModal({ onClose = _noop }: ModalProps): ReactElement {
  return (
    <Modal<ConfirmationPayload>
      name="CONFIRM"
      onClose={onClose}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.alertdialog}
      overrides={{
        Close: {
          style: () => ({
            right: '1rem',
            top: '1rem',
          }),
        },
      }}
    >
      {({ closeModal, modalPayload }) =>
        modalPayload && (
          <InnerBody
            title={modalPayload.title}
            message={modalPayload.message}
            onClose={closeModal}
            onConfirm={modalPayload.onConfirm}
            cancelButtonText={modalPayload.cancelButtonText}
            confirmButtonText={modalPayload.confirmButtonText}
          />
        )
      }
    </Modal>
  )
}

export default memo(ConfirmationModal)
