import { createSelector } from '@reduxjs/toolkit'

import { type RootState } from 'reducers'
import { type State } from 'reducers/redirect'

export const baseSelector = (state: RootState): State => state.redirect
export const redirectUrlSelector = createSelector(
  baseSelector,
  (state) => state.url,
)
export const redirectUrlCallbackSelector = createSelector(
  baseSelector,
  (state) => state.callback,
)
