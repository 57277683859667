import { useState } from 'react'
import { type AxiosError } from 'axios'
import useSWR, { mutate } from 'swr'
import { type APIError, type ErrorBody } from './types'
import { getAppsApiClient } from './apiV2'
import type { ReadAppSecretsResponseModel } from '../gen'
import { isSubdomainApp } from '../hooks/useUniqueSubdomainFeatures'

interface GetSecretsResponse {
  secrets?: string
  error?: string
}

interface UseGetSecrets {
  error?: APIError
  secrets: string
  isPending: boolean
}

export type AppSecretsKey = [string, string] | null

export const useAppSecretsKey = (appId: string): AppSecretsKey => {
  if (!appId) {
    return null
  }

  return [`apps/${appId}/secrets`, appId]
}

export async function fetchAppSecrets([
  _url,
  appId,
]: string[]): Promise<ReadAppSecretsResponseModel> {
  const client = getAppsApiClient()
  if (isSubdomainApp()) {
    const status = await client.readAppSecretsFromSubdomain()
    return status.data
  } else {
    const status = await client.readAppSecrets(appId)
    return status.data
  }
}

async function updateAppSecrets(appId: string, secrets: string): Promise<void> {
  const client = getAppsApiClient()
  if (isSubdomainApp()) {
    await client.updateAppSecretsFromSubdomain({ secrets })
  } else {
    await client.updateAppSecrets(appId, { secrets })
  }
}

export function useGetSecrets(appId: string): UseGetSecrets {
  const secretsKey: AppSecretsKey = useAppSecretsKey(appId)
  const { data, error, isLoading } = useSWR<GetSecretsResponse>(secretsKey, {
    fetcher: fetchAppSecrets,
  })

  return {
    error,
    secrets: data?.secrets ?? '',
    isPending: isLoading,
  }
}

interface UseUpdateSecrets {
  error?: APIError
  doUpdateSecrets: (secrets: string) => Promise<void>
  isPending: boolean
}

export function useUpdateSecrets(appId: string): UseUpdateSecrets {
  const [error, setError] = useState<AxiosError>()
  const [isPending, setIsPending] = useState<boolean>(true)

  const secretsKey = useAppSecretsKey(appId)
  const doUpdateSecrets = async (secrets: string): Promise<void> => {
    try {
      await mutate(secretsKey, { secrets }, false) // update cache optimistically

      await updateAppSecrets(appId, secrets)

      setIsPending(false)
      setError(undefined)
    } catch (exc) {
      setIsPending(false)
      setError(exc as AxiosError)
    }

    await mutate(secretsKey) // revalidate cache
  }

  return {
    error: error as AxiosError<ErrorBody<string>, any>,
    isPending,
    doUpdateSecrets,
  }
}
