import { type ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Form, Formik, useFormikContext } from 'formik'
import { ModalFooter } from 'baseui/modal'

import { type IApp } from 'api/apps'
import { extractErrorMessage } from 'api/utils'
import useUpdateSubdomain from 'api/updateSubdomain'

import Alert from 'components/alert'
import Button from 'components/button'
import { type CloseModalArgs } from 'components/modal'
import PageSpinner from 'components/pageSpinner/pageSpinner'
import TabBody from 'components/modal/components/tabBody/tabBody'
import TabBodyPart from 'components/modal/components/tabBody/tabBodyPart'
import CustomSubdomainField from 'modals/appSettings/components/customSubdomainField'

import { confirmationOff, confirmationOn, type ModalName } from 'reducers/modal'

import { getCurrentAppSection } from '../../../helpers/constantsHelpers'

import { Pages } from '../../../constants/routes'

import ENVS from '../../../constants/envs'

import modalStyles from './modal.module.scss'
import styles from './secretsSettings.module.scss'
import { getCurrentEnv } from '../../../helpers/environment'

interface DomainManagementBodyProps {
  app: IApp
  name: ModalName
  onClose: (args?: CloseModalArgs) => void
}

interface DomainManagementTabBodyProps {
  app: IApp
  name: ModalName
}

interface FormValues {
  subdomain: string
}

export function DomainManagementTabBody({
  name,
  app,
}: DomainManagementTabBodyProps): ReactElement {
  const dispatch = useDispatch()
  const { submitForm, isSubmitting, errors, dirty } =
    useFormikContext<FormValues>()

  const isSaveDisabled = !dirty || isSubmitting || !!errors.subdomain

  useEffect(() => {
    dispatch(dirty ? confirmationOn({ name }) : confirmationOff({ name }))
  }, [dirty, dispatch, name])

  return (
    <>
      <div className={modalStyles.modalBody}>
        <TabBody>
          <TabBodyPart
            title="App URL"
            subTitle="Pick a custom subdomain for your app’s URL. The default URL is based on the app's location in GitHub."
            body={<CustomSubdomainField app={app} />}
            classNames={{ header: styles.bodyAsSubTitle }}
          />
        </TabBody>
      </div>
      <ModalFooter className={modalStyles.modalFooter}>
        <Button onClick={submitForm} disabled={isSaveDisabled}>
          Save
        </Button>
      </ModalFooter>
    </>
  )
}

function DomainManagementBody({
  app,
  name,
  onClose,
}: DomainManagementBodyProps): ReactElement {
  const {
    doUpdateSubdomain,
    error: updateError,
    success,
    isPending,
  } = useUpdateSubdomain(app)
  const [submittedSubdomain, setSubmittedSubdomain] = useState('')
  useEffect(() => {
    if (isPending || !success || updateError) {
      return
    }
    if (
      submittedSubdomain &&
      !isPending &&
      getCurrentAppSection() === Pages.APP
    ) {
      window.location.href = `${window.location.protocol}//${ENVS[
        getCurrentEnv()
      ].app[0].replace('*', submittedSubdomain)}`
    }

    onClose({ disableConfirmation: true })
  }, [isPending, submittedSubdomain, updateError, success, onClose])

  if (!app.subdomain || isPending) {
    return <PageSpinner />
  }

  return (
    <>
      {updateError && (
        <Alert title="Error" message={extractErrorMessage(updateError)} />
      )}
      <Formik<FormValues>
        initialValues={{ subdomain: app.subdomain }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)

          setSubmittedSubdomain(values.subdomain)

          await doUpdateSubdomain(values.subdomain)

          setSubmitting(false)
        }}
        enableReinitialize
      >
        <Form className={modalStyles.modal}>
          <DomainManagementTabBody name={name} app={app} />
        </Form>
      </Formik>
    </>
  )
}

export default DomainManagementBody
